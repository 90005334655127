import React from 'react';
import styled from 'styled-components';

const CheckBoxFormOnIcon: React.FC = () => (
  <Wrap>
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.60545 8.22193L3.58783 8.23955L0.187988 4.8397L1.62067 3.40703L3.60551 5.39188L8.37953 0.617859L9.81221 2.05054L3.62313 8.23961L3.60545 8.22193Z" fill="#FFFFFF" />
    </svg>
  </Wrap>
);

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  background: #0066ff;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default CheckBoxFormOnIcon;
