import { createSelector, Selector } from 'reselect';

import { RequestState } from '@/store/reducers/common';
import { State } from '@/store';
import { IAssetsSearch } from '@/api/main-protected';

const assetsSearch = (state: State) => state.AssetsSearchReducer;

export const selectAssetsSearch: Selector<State, IAssetsSearch | null> = createSelector(
  assetsSearch,
  ({ globalSearch }) => globalSearch,
);

export const selectAssetsSearchState: Selector<State, RequestState> = createSelector(
  assetsSearch,
  ({ globalSearchState }) => globalSearchState,
);
