import React, { useMemo } from 'react';
import styled from 'styled-components';

import arrowIcon from '@/assets/images/icons/sidebar/arrow-down.svg';
import filterIcon from '@/assets/images/icons/events/filter.svg';
import filterIconBlue from '@/assets/images/icons/events/filter-blue.svg';
import pluralize from '@/utils/pluralize';

interface IFilter {
  appliedFiltersAmount: number
  isFilterActive: boolean
  onFilterIconClick: () => void
  isActiveFilter: boolean
}

const FilterToggleComponent: React.FC<IFilter> = ({
  isFilterActive, onFilterIconClick, isActiveFilter, appliedFiltersAmount,
}) => {
  const isActive = useMemo(
    () => isActiveFilter || isFilterActive,
    [isActiveFilter, isFilterActive],
  );

  return (
    <Inner>
      <Item onClick={onFilterIconClick}>
        <Icon>
          <Image src={isActive ? filterIconBlue : filterIcon} alt="icon" />
        </Icon>
        <Text isOpen={isActive}>Filter</Text>
        <Arrow>
          <ArrowImg isOpen={isFilterActive}>
            <Image src={arrowIcon} alt="icon" />
          </ArrowImg>
        </Arrow>
      </Item>
      <AppliedAmount>
        {appliedFiltersAmount > 0
          && `${pluralize(appliedFiltersAmount, 'filter')} applied`}
      </AppliedAmount>
    </Inner>
  );
};

const Image = styled.img`
`;

const Inner = styled.div`
  height: 35px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  padding: 0px 11px;
  cursor: pointer;
`;

const Item = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  width: 18px;
  height: 18px;
  margin: 0px 10px 0px 0px;

  & img {
    max-width: 100%;
  }
`;

const Text = styled.p<{ isOpen: boolean }>`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: ${({ isOpen }) => (isOpen ? '#0066FF' : '#4A4949')};
  font-size: 12px;
  text-transform: capitalize;
  padding-right: 10px;
  cursor: pointer;
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  min-width: 10px;
  height: 20px;
`;

const ArrowImg = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  transform: rotate(${({ isOpen }) => (isOpen ? 180 : 0)}deg);

  & img {
    max-width: 100%;
  }
`;

const AppliedAmount = styled.div`
  margin: 5px 0;
  font-weight: 400;
  font-size: 14px;
  // line-height: 20px;
`;

export default FilterToggleComponent;
