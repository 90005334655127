import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectUserInfo } from '@/store/selectors/user';

import defaultProfile from '@/assets/images/icons/user-settings/user-profile-default-icon.svg';

import UserProfilePopupComponent from '@/Components/common/UserProfilePopup';
import useOnClickOutside from '@/Components/hooks/useOnClickOutside';

const UserInfo: React.FC = () => {
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const user = useSelector(selectUserInfo);

  const profileClickOutsideRef = useOnClickOutside(() => setShowProfilePopup(false));

  return (
    <Round ref={profileClickOutsideRef}>
      <UserImg onClick={() => setShowProfilePopup(true)}>
        <img
          src={user?.picture || defaultProfile}
          alt={`${user?.first_name || ''} ${user?.last_name || ''}` || 'default user'}
        />
      </UserImg>
      <UserOnlineStatus isOnline={user?.status ? user?.status.toLowerCase() === 'active' : false} />

      {showProfilePopup && (
        <UserProfilePopupComponent closeUserInfo={() => setShowProfilePopup(false)} />
      )}
    </Round>
  );
};

const Round = styled.div`
  position: relative;
  flex: 0 0 34px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: lightblue;
  margin-left: auto;

  cursor: pointer;
`;

const UserImg = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;

  & img {
    max-width: 100%;
  }
`;
const UserOnlineStatus = styled.div<{ isOnline: boolean }>`
  position: absolute;
  bottom: 0;
  right: 0;
  flex: 0 0 9px;
  width: 9px;
  height: 9px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  background: ${({ isOnline }) => (isOnline ? '#1EDB0E' : 'red')};
  z-index: 1;
`;

export default UserInfo;
