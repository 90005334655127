import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ROLES } from '@/api/main';
import MainProtected, { IUpdateProfile, IUpdatePsw, IUser } from '@/api/main-protected';

import { AppDispatch } from '@/App';

import profileIcon from '@/assets/images/icons/edit-user/profile-black-icon.svg';
// import addressIcon from '@/assets/images/icons/edit-user/address-icon.svg'
import changePswIcon from '@/assets/images/icons/edit-user/change-psw-icon.svg';
import closeIcon from '@/assets/images/icons/close-black-icon.svg';

import IconComponent from '@/Components/common/IconComponent';
import Btn from '@/Components/common/Signin/Btn';
import LoaderDots from '@/Components/LoaderDots';
import EditSettingsForm from '@/Components/UserAdmin/EditSettingsForm';

import SuccessIcon from '@/Components/icons/SuccessIcon';

import { selectIsLocalLoading } from '@/store/selectors/login';
import { loginActions } from '@/store/actions/login';
import { selectDeleteUserState, selectUserInfo, selectUsers } from '@/store/selectors/user';
import { deleteUser, userActions } from '@/store/actions/user';
import { RequestState } from '@/store/reducers/common';

import useOnClickOutside from '@/Components/hooks/useOnClickOutside';

import customScrollCss from '@/utils/custom-scroll-css';
import { getUserLevelPermission } from '@/utils/permissions';

interface IEditUserModal {
  editedUser: IUser;
  onCloseModal: () => void;
}

export const EDIT_NAVS = {
  PROFILE: 'Profile',
  CHANGE_PSW: 'Change password',
  // NOTIFICATIONS: 'Notifications'
};

const EditUserModal: React.FC<IEditUserModal> = ({ editedUser, onCloseModal }) => {
  const [activeNav, setActiveNav] = useState(EDIT_NAVS.PROFILE);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDeleteUserModal, setIsDeleteUserModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [updatedData, setUpdatedData] = useState<IUpdateProfile | IUpdatePsw | null>(null);
  const editModalClickOutsideRef = useOnClickOutside(onCloseModal);
  const isLocalLoading = useSelector(selectIsLocalLoading);
  const users = useSelector(selectUsers);
  const userInfo = useSelector(selectUserInfo)!;

  const dispatch = useDispatch<AppDispatch>();
  const deleteUserState = useSelector(selectDeleteUserState);

  useEffect(() => {
    if (!isSubmit) return;

    handleSubmit(updatedData!);
  }, [isSubmit]);

  useEffect(() => {
    setIsSubmit(false);
  }, [activeNav]);

  const handleSubmit = async (data: IUpdateProfile | IUpdatePsw) => {
    const mainProtected = MainProtected.getInstance();

    dispatch(loginActions.setIsLocalLoading(true));

    await mainProtected
      .updateUser(encodeURI(editedUser.user_id), data)
      .then(() => {
        const updatedUsers = users?.map((user) => {
          const updateData = { ...(data as IUpdateProfile) };

          if (user.user_id === editedUser.user_id) {
            return {
              ...user,
              ...data,
              ...(updateData.role ? { roles: [[updateData.role][0]] } : {}),
            };
          }
          return user;
        }) as IUser[];

        dispatch(userActions.setUsers(updatedUsers));
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch(loginActions.setIsLocalLoading(false));
        onCloseModal();
      });
  };

  const handleDeleteUser = () => {
    setIsDeleteUserModal(true);
  };

  const onCloseConfirmModal = () => {
    setIsDeleteUserModal(false);
  };

  const onConfirmDeletUser = async () => {
    await dispatch(deleteUser(editedUser.user_id));
    setIsSuccess(true);
  };

  const isValidFields = useMemo(
    () =>  
    (updatedData && 'business_unit' in updatedData) ?
       updatedData.business_unit != '' 
       : false,
    [updatedData]
  );


  const handleSaveClick = () => {
    if (!isValidFields) {
      return;
    }

    setIsSubmit(true)
  };

  return (
    <Layout>
      <ModalWrap ref={editModalClickOutsideRef}>
        {(!isDeleteUserModal && !isSuccess) && (
        <>
          <TitleWrap>
            <Title>
              Edit User
            </Title>
            <CloseIconBox onClick={onCloseModal}>
              <IconComponent icon={closeIcon} width={20} />
            </CloseIconBox>
          </TitleWrap>
          {/* <Nav>
            <NavItem
              isActive={activeNav === EDIT_NAVS.PROFILE}
              onClick={() => setActiveNav(EDIT_NAVS.PROFILE)}
            >
              <IconComponent icon={profileIcon} width={10} />
              <Text>Profile</Text>
            </NavItem>
            <NavItem
              isActive={activeNav === EDIT_NAVS.CHANGE_PSW}
              onClick={() => setActiveNav(EDIT_NAVS.CHANGE_PSW)}
            >
              <IconComponent icon={changePswIcon} width={10} />
              <Text>Change password</Text>
            </NavItem>
            <NavItem
              isActive={activeNav === EDIT_NAVS.NOTIFICATIONS}
              onClick={() => setActiveNav(EDIT_NAVS.NOTIFICATIONS)}
            >
              <IconComponent icon={changePswIcon} width={10} />
              <Text>Notifications</Text>
            </NavItem>
          </Nav> */}
          <Main>
            <EditSettingsForm
              editedUser={editedUser}
              activeNav={activeNav}
              // handleSubmit={handleSubmit}
              setUpdatedData={setUpdatedData}
            />
          </Main>
          {userInfo?.roles[0] !== ROLES.USER && (
          <DeleteBtnBox>
            <Btn onClick={handleDeleteUser} isTransparent>
              Delete User
            </Btn>
          </DeleteBtnBox>
          )}
          <Btns isMarginTop={userInfo?.roles[0] === ROLES.USER}>
            <Btn isTransparent onClick={onCloseModal}>
              Cancel
            </Btn>
            <Btn onClick={() => handleSaveClick()} isLoading={isSubmit}>
              {isLocalLoading ? <LoaderDots /> : <BtnText>Save changes</BtnText>}
            </Btn>
          </Btns>
        </>
        )}
        {isDeleteUserModal && (
          <>
            <CloseIconBoxDelete onClick={onCloseModal}>
              <IconComponent icon={closeIcon} width={20} />
            </CloseIconBoxDelete>
            <TitleDelete>Delete a User Confirmation</TitleDelete>
            <BirdBox>
              {deleteUserState === RequestState.LOADED && <SuccessIcon />}
            </BirdBox>
            <SubTitle>
              {!isSuccess && 'Are you sure you want to delete this user?'}
              {isSuccess && deleteUserState === RequestState.ERROR && 'Something went wrong'}
              {isSuccess && deleteUserState === RequestState.LOADED && 'User was successfully deleted.'}
            </SubTitle>
            <BtnWrap>
              <Btn
                onClick={!isSuccess ? onCloseConfirmModal : onCloseModal}
                isTransparent
                isActive
                isLogInBtn
              >
                {!isSuccess ? 'Cancel' : 'Close'}
              </Btn>
              {!isSuccess && getUserLevelPermission(userInfo.roles[0], editedUser.roles[0]) && (
              <Btn onClick={onConfirmDeletUser} isActive isLogInBtn>
                {deleteUserState === RequestState.LOADING ? <LoaderDots /> : 'Delete user'}
              </Btn>
              )}
            </BtnWrap>
          </>
        )}
      </ModalWrap>
    </Layout>
  );
};

const CloseIconBox = styled.div`
  cursor: pointer;
`;

const CloseIconBoxDelete = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: end;
`;

const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Layout = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  z-index: 100;
  padding: 20px;
`;

const ModalWrap = styled.div`
  width: 672px;
  max-height: calc(100vh - 160px);
  background: #ffffff;
  border-radius: 10px;
  padding: 26px 35px 58px 33px;
  margin: 90px auto 30px;
  overflow-y: auto;

  ${customScrollCss}
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: #000000;
`;
const TitleDelete = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #000000;
  text-align: center;
  margin-top: 30px;
`;

const Nav = styled.div`
  display: inline-flex;
  align-items: center;
  // justify-content: space-between;
  margin: 16px 0px 21px;
  padding: 3px 14px;
  background: rgba(229, 229, 229, 0.4);
  border-radius: 4px;
`;

const NavItem = styled.div<{ isActive: boolean }>`
  display: flex;
  min-width: 112px;
  align-items: center;
  padding: 4px 24px;
  margin: 0 11px;
  background: ${({ isActive }) => (isActive ? '#ffffff' : 'transparent')};
  box-shadow: ${({ isActive }) => (isActive ? '1px 3px 4px rgba(0, 0, 0, 0.05)' : 'none')};
  border-radius: 4px;
  cursor: pointer;
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 12px;
  padding: 2.5px 0;
  margin-left: 6px;
`;

const Main = styled.div``;
const DeleteBtnBox = styled.div`
  & button {
    width: 115px;
    color: #FF1A1A;
    border: 1px solid #FF1A1A;
    margin-left: 165px;
    margin-top: 30px;
  }
`;

const Btns = styled.div<{ isMarginTop?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${({ isMarginTop }) => (isMarginTop ? 55 : 0)}px;

  & button:first-child {
    width: 90px;
    margin: 0 16px;
  }
  & button:last-child {
    width: 130px;
  }
`;

const BtnText = styled.div``;

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #000000;
  margin: 0px 0 150px;
`;

const BirdBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 55px;
  margin: 10px 0;
`;

const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  & button:first-child {
    flex: 0 0 95px;
    width: 95px;
  }
  & button:last-child {
    flex: 0 0 130px;
    width: 130px;
  }
`;

export default EditUserModal;
