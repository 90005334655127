import React, { Fragment } from 'react';
import styled from 'styled-components';

import { IAsset } from '@/api/main-protected';

import customScrollCss from '@/utils/custom-scroll-css';

interface ITable {
  assets: IAsset[];
  handleItemClick: (asset: IAsset) => void;
}

const SearchedItemsTable: React.FC<ITable> = ({
  assets,
  handleItemClick,
}) => (
  <Container>
    <Grid>
      <FixedTop
        className="fixed-left"
      >
        <Text>Serial Number</Text>
      </FixedTop>
      <FixedTop>
        <Text>Asset Type</Text>
      </FixedTop>
      <FixedTop>
        <Text>Make/Model</Text>
      </FixedTop>
      <FixedTop>
        <Text>Status</Text>
      </FixedTop>
      <FixedTop>
        <Text>Impacted user</Text>
      </FixedTop>

      {assets.map((item) => (
        <Fragment key={item.serial_number}>
          <GridCell
            className="fixed-left"
            isBlue
            isPointer
            onClick={() => handleItemClick(item)}
          >
            {item.serial_number}
          </GridCell>
          <GridCell>{item.asset_type}</GridCell>
          <GridCell>{item.model}</GridCell>
          <GridCell>{item.status}</GridCell>
          <GridCell>
            {item.requests.map((req, i) => (
              <Text key={i}>
                {`${req.impacted_user?.first_name || ''} ${req.impacted_user?.last_name || ''}`}
              </Text>
            ))}
          </GridCell>
        </Fragment>
      ))}
    </Grid>
  </Container>

);

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 40px;

  ${customScrollCss}
`;

const Grid = styled.div`
  min-width: 900px;
  // width: 100%; // adds unnecessary scroll
  // height: 100%; // adds hiding effect
  position: relative;

  display: grid;
  grid-template-columns:
    minmax(200px, auto)
    minmax(150px, auto)
    minmax(200px, auto)
    minmax(150px, auto)
    minmax(200px, auto);

  grid-auto-rows: min-content;

  font-weight: 400;
  font-size: 12px;
  color: #000000;
`;
const FixedTop = styled.div<{ isCursorPointer?: boolean }>`
  position: sticky;
  top: 0;
  z-index: 3;

  // height: 50px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: 800;
  font-size: 14px;
  padding: 8px 0px 8px 10px;

  background: #d9d9d9;

  &.fixed-left {
    position: sticky;
    left: 0;
    z-index: 4;

    padding-left: 15px;
  }
`;

const GridCell = styled.div<{ isBlue?: boolean; isRelative?: boolean; isPointer?: boolean }>`
  // padding: 12px 10px 4px 15px; // with container
  padding: 8px 10px;
  background-color: white;
  color: ${({ isBlue }) => (isBlue ? '#0066FF' : '#000000')};
  position: ${({ isRelative }) => (isRelative ? 'relative' : 'static')};
  text-transform: capitalize;
  line-height: 17px;
  word-break: break-word;

  &.fixed-left {
    position: sticky;
    left: 0;
    z-index: 1;

    padding-left: 15px;

    cursor: ${({ isPointer }) => (isPointer ? 'pointer' : 'default')};

    @media (hover: hover) {
      &:hover {
        text-decoration: ${({ isPointer }) => (isPointer ? 'underline' : 'none')};
      }
    }
  }

  &:nth-child(10n + 1),
  &:nth-child(10n + 2),
  &:nth-child(10n + 3),
  &:nth-child(10n + 4),
  &:nth-child(10n + 5) {
    background: #f6f6f6;
  }
`;

const Text = styled.div`
  text-transform: capitalize;
  margin-right: 10px;
`;

export default SearchedItemsTable;
