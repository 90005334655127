import React from 'react';

import '@/styles/global.css';
import '@/styles/null.css';

import { Switch, Route, Redirect } from 'react-router';
import { ROLES } from '@/api/main';

import { PAGES } from '@/Components/Main/Main';
import ProtectedRoute from '@/Components/ProtectedRoute';

import AdminPage from '@/Pages/AdminPage';
import LoginPage from '@/Pages/LoginPage';
import RedirectPage from '@/Pages/Redirect';
import ResetPswPage from '@/Pages/ResetPswPage';

import Toasty from '@/Components/common/Toast/Toasty';

import store from '@/store';

import Logger from '@/utils/logger';
import useSubdomainCheck from '@/Components/hooks/useSubdomainCheck';

export const USER_ROLES = [ROLES.USER, ROLES.MANAGER, ROLES.SUPER_ADMIN];

export type AppDispatch = typeof store.dispatch;

function App() {
  useSubdomainCheck();
  Logger.initialize();

  return (
    <>
      <Toasty />
      <Switch>
        <Route path={PAGES.REDIRECT} component={RedirectPage} />
        <Route path={PAGES.LOGIN} component={LoginPage} />
        <Route path={PAGES.RESET_PSW} component={ResetPswPage} />
        {/* <Route path='/login-next-step' component={LoginMFAPage} /> */}
        {/* <Route path='/project/:companyName/:companyId' component={ProjectPage} /> */}
        <ProtectedRoute
          exact
          path={PAGES.ADD_NEW_USER}
          roles={[ROLES.SUPER_ADMIN, ROLES.MANAGER]}
          component={AdminPage}
        />
        <ProtectedRoute exact path={PAGES.USER_SETTINGS} roles={USER_ROLES} component={AdminPage} />
        <ProtectedRoute exact path={PAGES.USER_PROFILE} roles={USER_ROLES} component={AdminPage} />
        <ProtectedRoute exact path={PAGES.ITEMS} roles={USER_ROLES} component={AdminPage} />
        <ProtectedRoute exact path={PAGES.REQUESTS} roles={USER_ROLES} component={AdminPage} />
        <ProtectedRoute exact path={PAGES.REQUESTS_ID} roles={USER_ROLES} component={AdminPage} />
        <ProtectedRoute exact path={PAGES.CHANGELOG} roles={USER_ROLES} component={AdminPage} />
        <ProtectedRoute exact path={PAGES.ALL_RESULTS} roles={USER_ROLES} component={AdminPage} />
        <ProtectedRoute
          exact
          path={PAGES.SUPPORT}
          roles={USER_ROLES}
          component={AdminPage}
        />
        {/* <Route exact path={PAGES.INCIDENTS} component={AdminPage} /> */}
        {/* <Route exact path={PAGES.EVENTS} component={AdminPage} /> */}
        {/* <Route exact path={PAGES.USER_ADMIN} component={AdminPage} /> */}
        {/* <Route exact path={PAGES.CONTROL_CENTER} component={AdminPage} /> */}
        <ProtectedRoute
          exact
          path={PAGES.USER_ADMIN}
          roles={[ROLES.SUPER_ADMIN, ROLES.MANAGER]}
          component={AdminPage}
        />
        <ProtectedRoute
          exact
          path={PAGES.CONTROL_CENTER}
          roles={USER_ROLES}
          component={AdminPage}
        />
        <Redirect to={PAGES.CONTROL_CENTER} />
      </Switch>
    </>
  );
}

export default App;
