import React from 'react';

interface Props {
  width?: number | string;
  height?: number | string;
  color?: string;
  fillColor?: string;
}

const CloseIcon: React.FC<Props> = ({
  width = '24',
  height = '24',
  color = '#212121',
  fillColor = '#F3F4F5',
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fillColor} d="M24 18C24 21.3137 21.3137 24 18 24L6 24C2.68629 24 0 21.3137 0 18L0 6C0 2.68629 2.68629 0 6 0L18 0C21.3137 0 24 2.68629 24 6L24 18Z" />
    <path fill={color} d="M13.0994 12.0001L17.7719 7.32729C18.076 7.02334 18.076 6.53191 17.7719 6.22796C17.468 5.92401 16.9766 5.92401 16.6726 6.22796L11.9999 10.9008L7.32737 6.22796C7.02329 5.92401 6.532 5.92401 6.22806 6.22796C5.92398 6.53191 5.92398 7.02334 6.22806 7.32729L10.9006 12.0001L6.22806 16.6729C5.92398 16.9768 5.92398 17.4683 6.22806 17.7722C6.37953 17.9238 6.5787 18 6.77772 18C6.97674 18 7.17576 17.9238 7.32737 17.7722L11.9999 13.0994L16.6726 17.7722C16.8243 17.9238 17.0233 18 17.2223 18C17.4213 18 17.6203 17.9238 17.7719 17.7722C18.076 17.4683 18.076 16.9768 17.7719 16.6729L13.0994 12.0001Z" />
  </svg>
);

export default CloseIcon;
