import { createSelector, Selector } from 'reselect';

import {
  IAsset, IAssetsByMake, IItemsFilters, IPeripheral, RequestsChartItem,
} from '@/api/main-protected';

import { State } from '@/store';
import { RequestState } from '@/store/reducers/common';

const selectItems = (state: State) => state.ItemsReducer;

export const selectReturns: Selector<State, RequestsChartItem | null> = createSelector(
  selectItems,
  ({ returns }) => returns,
);

export const selectReturnsState: Selector<State, RequestState> = createSelector(
  selectItems,
  ({ returnsState }) => returnsState,
);

export const selectAssetsByType: Selector<State, RequestsChartItem | null> = createSelector(
  selectItems,
  ({ assetsByType }) => assetsByType,
);

export const selectAssetsByTypeState: Selector<State, RequestState> = createSelector(
  selectItems,
  ({ assetsByTypeState }) => assetsByTypeState,
);

export const selectAssetsByMake: Selector<State, IAssetsByMake | null> = createSelector(
  selectItems,
  ({ assetsByMake }) => assetsByMake,
);

export const selectAssetsByMakeState: Selector<State, RequestState> = createSelector(
  selectItems,
  ({ assetsByMakeState }) => assetsByMakeState,
);

export const selectAssets: Selector<State, IAsset[]> = createSelector(
  selectItems,
  ({ assets }) => assets,
);

export const selectGlobalSearchItems: Selector<State, IAsset[]> = createSelector(
  selectItems,
  ({ globalSearchItems }) => globalSearchItems,
);

export const selectAssetsTotal: Selector<State, number> = createSelector(
  selectItems,
  ({ assetsTotal }) => assetsTotal,
);

export const selectPeripherals: Selector<State, IPeripheral[]> = createSelector(
  selectItems,
  ({ peripherals }) => peripherals,
);

export const selectPeripheralsState: Selector<State, RequestState> = createSelector(
  selectItems,
  ({ peripheralsState }) => peripheralsState,
);

export const selectPeripheralsTotalState: Selector<State, RequestState> = createSelector(
  selectItems,
  ({ peripheralsTotalState }) => peripheralsTotalState,
);

export const selectPeripheralsPaginationState: Selector<State, RequestState> = createSelector(
  selectItems,
  ({ peripheralsPaginationState }) => peripheralsPaginationState,
);

export const selectPeripheralsTotal: Selector<State, number> = createSelector(
  selectItems,
  ({ peripheralsTotal }) => peripheralsTotal,
);

export const selectItemsFiltersState: Selector<State, RequestState> = createSelector(
  selectItems,
  ({ itemsFiltersState }) => itemsFiltersState,
);

export const selectItemsFilters: Selector<State, IItemsFilters> = createSelector(
  selectItems,
  ({ itemsFilters }) => itemsFilters,
);

export const selectAssetsState: Selector<State, RequestState> = createSelector(
  selectItems,
  ({ assetsState }) => assetsState,
);

export const selectAssetsPaginationState: Selector<State, RequestState> = createSelector(
  selectItems,
  ({ assetsPaginationState }) => assetsPaginationState,
);

export const selectAssetsTotalState: Selector<State, RequestState> = createSelector(
  selectItems,
  ({ assetsTotalState }) => assetsTotalState,
);

export const selectGlobalSearchItemsState: Selector<State, RequestState> = createSelector(
  selectItems,
  ({ globalSearchItemsState }) => globalSearchItemsState,
);

export const selectModalItem: Selector<State, IAsset | null> = createSelector(
  selectItems,
  ({ modalItem }) => modalItem,
);
