import HttpClient from '@/api/http-client';

export enum ROLES {
  USER = 'user',
  MANAGER = 'manager',
  SUPER_ADMIN = 'superAdmin',
  DEVELOPER = 'developer',
  PARTNER = 'partner',
}

class Main extends HttpClient {
  private static instanceCached: Main;

  private constructor() {
    super(process.env.BASE_URL);
  }

  static getInstance = () => {
    if (!Main.instanceCached) {
      Main.instanceCached = new Main();
    }

    return Main.instanceCached;
  };

  public refresh = (body:{ refreshToken: string }) => this.instance.post('/login/refresh', body);
}

export default Main;
