import React from 'react';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import CloseIconTwo from '@/Components/icons/CloseIcon';
import FilledCloseIcon from '@/Components/icons/FilledCloseIcon';
import TickIcon from '@/Components/icons/TickIcon';

import { colorConfig } from '@/store/reducers/toast';

import {
  IColorConfig, IMessageToast, IToast, TextType, ToastIcon, ToastTheme,
} from '@/interfaces/toast';

import {
  lineClamp, singleLine,
} from '@/styles/mixins';

interface Props extends IMessageToast {
  theme?: ToastTheme,
  toast: IToast,
  onClose: (toast: IToast) => void;
}

const MessageToast: React.FC<Props> = ({
  theme = ToastTheme.Light,
  icon,
  header,
  toast,
  onClose,
}) => {
  const config = colorConfig[theme];
  const headerType = (header.length > rowLimit) ? TextType.DoubleLine : TextType.SingleLine;

  const handleToastClose = React.useCallback(() => {
    onClose(toast);
  }, [toast, onClose]);

  return (
    <CSSTransition in timeout={0} unmountOnExit>
      <MessageToastStyled config={config}>
        <ContentWrap>
          <IconWrap>
            {toastIcons[icon]}
          </IconWrap>
          <TextWrap>
            <Header config={config} textType={headerType}>{header}</Header>
          </TextWrap>
        </ContentWrap>
        <CloseWrap onClick={handleToastClose}>
          <CloseIconTwo color={config.close} />
        </CloseWrap>
      </MessageToastStyled>
    </CSSTransition>
  );
};

const rowLimit = 50;
const toastIcons = {
  [ToastIcon.Success]: <TickIcon width={20} height={20} />,
  [ToastIcon.Error]: <FilledCloseIcon width={20} height={20} />,
};

const MessageToastStyled = styled.div<{ config: IColorConfig }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 520px;
  padding: 24px 40px 24px 24px;
  background-color: ${({ config }) => (config.background)};
  border: 2px solid #c96d6d;
  border-radius: 16px;

  &.enter {
    opacity: 0;
  }

  &.enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
`;

const ContentWrap = styled.div`
  display: inline-flex;
  overflow: hidden;
  align-items: center;
`;

const Header = styled.div<{ config: IColorConfig; textType: TextType }>`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 17px;
  letter-spacing: normal;
  color: ${({ config }) => (config.text)};

  ${({ textType }) => textType === TextType.SingleLine
  && css`
    ${singleLine}
  `};

  ${({ textType }) => textType === TextType.DoubleLine
  && css`
    min-width: 340px;
    ${lineClamp()}
  `};
`;

const IconWrap = styled.div`
  width: 20px;
  height: 20px;
`;

const CloseWrap = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  overflow: hidden;
`;

export default MessageToast;
