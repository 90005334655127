/* eslint-disable max-len */
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import IconComponent from '@/Components/common/IconComponent';

import { PAGES } from '@/Components/Main/Main';

import searchIcon from '@/assets/images/icons/search-icon.svg';

import useOnClickOutside from '@/Components/hooks/useOnClickOutside';

import convertFromStringToDate, { FormatDate } from '@/utils/convertFromStringToDate';

import { Request, IAsset, IEvent } from '@/api/main-protected';
import LocalStorage from '@/local-storage/LocalStorage';
import { AppDispatch } from '@/App';
import { eventsActions, getIncidentRequest } from '@/store/actions/events';
import { itemsActions } from '@/store/actions/items';
import { RequestState } from '@/store/reducers/common';
import LoaderDots from '../LoaderDots';

interface ISearchResults {
  searchedRequestsState: RequestState
  searchedAssetsState: RequestState
  searchedEventsState: RequestState
  requests: Request[]
  assets: IAsset[]
  events: IEvent[]
  isSearched: boolean
  isError: boolean
  isAllResultsPage?: boolean
  handleClose?: () => void
  handleRecentSearchClick?: (search: string) => void
}

const SearchResultsComponent: React.FC<ISearchResults> = ({
  searchedRequestsState,
  searchedAssetsState,
  searchedEventsState,
  requests,
  assets,
  events,
  isSearched,
  isError,
  handleClose,
  isAllResultsPage = false,
  handleRecentSearchClick,
}) => {
  const recentSearch = LocalStorage.getRecentSearch();
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const clickOutsideRef = isSearched ? useOnClickOutside(handleClose!) : null;

  const onRecentClick = () => {
    if (!handleRecentSearchClick || !recentSearch) return;

    handleRecentSearchClick(recentSearch!);
  };

  const handleRequestClick = (ev: Request) => {
    history.push(PAGES.REQUESTS);

    const modalRequest = {
      ...ev,
      date_opened: ev.date_opened ? convertFromStringToDate(ev.date_opened) : '-',
      date_closed: ev.date_closed ? convertFromStringToDate(ev.date_closed) : '-',
      action_date: ev.action_date ? convertFromStringToDate(ev.action_date, FormatDate.ShortFormat) : '-',
      isModal: true,
    };

    dispatch(eventsActions.setIncidentRequest(modalRequest));

    if (!ev.items) {
      dispatch(getIncidentRequest(ev.event_id, !ev.items, modalRequest));
    }
  };

  const handleItemClick = (item: IAsset) => {
    history.push(PAGES.ITEMS);
    dispatch(itemsActions.setModalItem(item));
  };

  const handleEventClick = (event: IEvent) => {
    dispatch(eventsActions.setIncidentRequest(event.request));
    dispatch(eventsActions.setIncidentEvent(event));
    dispatch(getIncidentRequest(event.event_id));
    history.push(PAGES.REQUESTS);
  };

  return (
    <SearchResults
      ref={clickOutsideRef}
      isSearched={isSearched}
      isAllResultsPage={isAllResultsPage}
    >
      {!!recentSearch && (
        <Block isAllResultsPage={isAllResultsPage}>
          <Title>RECENT SEARCHES</Title>
          <Recent onClick={onRecentClick}>
            <RecentText>{recentSearch}</RecentText>
            <Split />
            <IconComponent icon={searchIcon} width={11} />
          </Recent>
        </Block>
      )}
      {requests.length > 0 && (
        <Block isAllResultsPage={isAllResultsPage}>
          <Title>REQUESTS</Title>
          {requests.map((item) => (
            <Text
              key={item.request_id}
              isAllResultsPage={isAllResultsPage}
              onClick={() => handleRequestClick(item)}
            >
              {`${item.itsm_id}${item.status ? ` - ${item.status}` : ''}
              ${
                item.date_opened
                  ? ` - ${convertFromStringToDate(item.date_opened, FormatDate.ShortFormat)}`
                  : ''
              }
              ${item.category ? ` - ${item.category}` : ''}
              ${item.impacted_user?.first_name ? ` - ${item.impacted_user.first_name}` : ''}
              ${item.impacted_user?.last_name ? ` ${item.impacted_user.last_name}` : ''}`}
            </Text>
          ))}
        </Block>
      )}
      {assets.length > 0 && (
        <Block isAllResultsPage={isAllResultsPage}>
          <Title>ITEMS</Title>
          {assets.map((item) => (
            <Text
              key={item.serial_number}
              isAllResultsPage={isAllResultsPage}
              onClick={() => handleItemClick(item)}
            >
              {`${item.serial_number}${item.status ? ` - ${item.status}` : ''}
              ${
                item?.warranty_date
                  ? ` - ${convertFromStringToDate(item.warranty_date, FormatDate.ShortFormat)}`
                  : ''
              }
              ${
                item?.requests.length > 0 && item?.requests[0]?.impacted_user
                  ? ` - ${item?.requests[0]?.impacted_user?.first_name} ${item?.requests[0]?.impacted_user?.last_name}`
                  : ''
              }`}
            </Text>
          ))}
        </Block>
      )}
      {events.length > 0 && (
        <Block isAllResultsPage={isAllResultsPage}>
          <Title>EVENTS</Title>
          {events.map((item) => (
            <Text
              key={item.event_id}
              isAllResultsPage={isAllResultsPage}
              onClick={() => handleEventClick(item)}
            >
              {`${item.status ? `${item.status}` : ''}
                ${
                  item?.event_date
                    ? ` - ${convertFromStringToDate(item.event_date, FormatDate.ShortFormat)}`
                    : ''
                }
                ${
                  item?.request?.impacted_user?.first_name
                    ? ` - ${item?.request?.impacted_user?.first_name} ${item?.request?.impacted_user?.last_name}`
                    : ''
                }`}
            </Text>
          ))}
        </Block>
      )}
      {(searchedRequestsState === RequestState.LOADING || searchedAssetsState === RequestState.LOADING || searchedEventsState === RequestState.LOADING)
          && (
            <Block isAllResultsPage={isAllResultsPage}>
              <More><LoaderDots size={8} color="#0066FF" /></More>
            </Block>

          )}
      {(searchedRequestsState === RequestState.LOADED && searchedAssetsState === RequestState.LOADED && searchedEventsState === RequestState.LOADED) && (assets.length > 0 || requests.length > 0 || events.length > 0) && !isAllResultsPage && (
        <Block isAllResultsPage={isAllResultsPage}>
          <Link to={PAGES.ALL_RESULTS}>
            <More>{'See all results >'}</More>
          </Link>
        </Block>
      )}
      {(searchedRequestsState === RequestState.LOADED && searchedAssetsState === RequestState.LOADED && searchedEventsState === RequestState.LOADED) && (assets.length === 0 && requests.length === 0 && events.length === 0) && (
        <Block isAllResultsPage={isAllResultsPage}>
          <More isBlack isError={isError}>
            {isError ? 'Something went wrong' : 'Nothing was found'}
          </More>
        </Block>
      )}
    </SearchResults>
  );
};

const Split = styled.span`
  width: 5px;
`;

const SearchResults = styled.div<{ isSearched: boolean, isAllResultsPage: boolean }>`
  position: ${({ isAllResultsPage }) => (isAllResultsPage ? 'static' : 'absolute')};
  top: 100%;
  left: 0;
  width: 200%;
  display: ${({ isSearched }) => (isSearched ? 'block' : 'none')};
  background: #FFFFFF;
  box-shadow: 0px 4px 5px 4px rgba(0, 0, 0, 0.25);
  border-radius: ${({ isAllResultsPage }) => (isAllResultsPage ? 0 : 10)}px;
  padding-top: 5px;

  & > div:not(:last-child){
    border-bottom: 1px solid #E5E5E5;
  };

  @media(max-width: 980px){
    width: 145%;
  }
`;

const Recent = styled.div`
  display: inline-flex;
  align-items: center;
  background: #D9D9D9;
  border-radius: 20px;
  padding: 0px 10px;
  margin: 7px 0px;
  cursor: pointer;
`;

const RecentText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  padding: 2px 0px;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #848486;
  margin: 5px 0;
`;
const Text = styled.p<{ isAllResultsPage: boolean }>`
  font-weight: 400;
  font-size: ${({ isAllResultsPage }) => (isAllResultsPage ? '14px' : '12px')};
  line-height: 26px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ isAllResultsPage }) => (isAllResultsPage ? '#0066FF' : '#000000')};

  @media(hover: hover) {
    &:hover{
      color: #0066FF;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const Block = styled.div<{ isAllResultsPage: boolean }>`
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  padding: ${({ isAllResultsPage }) => (isAllResultsPage ? '5px 20px 25px' : '5px 20px')};
  &:first-child {
    padding-left: ${({ isAllResultsPage }) => (isAllResultsPage ? '0' : '20px')};
  }
`;

const More = styled.p<{ isBlack?: boolean, isError?: boolean }>`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: ${({ isBlack, isError }) => (isError ? '#ff0707' : (isBlack ? '#000000' : '#0066FF'))};
  text-align: center;
  margin: 10px 0;
  cursor: ${({ isBlack }) => (isBlack ? 'default' : 'pointer')};
`;

export default SearchResultsComponent;
