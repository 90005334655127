import React from 'react';
import styled from 'styled-components';
import customScrollCss from '@/utils/custom-scroll-css';

import IconComponent from '@/Components/common/IconComponent';
// import NotesEventComponent from './NotesEventComponent';
import NotesIncidentrequestComponent from '@/Components/Requests/NotesIncidentrequestComponent';
import LoaderDots from '@/Components/LoaderDots';

import useOnClickOutside from '@/Components/hooks/useOnClickOutside';
import { Request } from '@/api/main-protected';

import closeIcon from '@/assets/images/icons/close-black-icon.svg';

interface IModal {
  isLoading: boolean
  incidentRequest: Request
  // event: IEvent
  onCloseModal: () => void;
}

const ModalNotesEvent: React.FC<IModal> = ({
  isLoading,
  incidentRequest,
  // event,
  onCloseModal,
}) => {
  const editModalClickOutsideRef = useOnClickOutside(onCloseModal);

  return (
    <Layout>
      {isLoading
        ? <LoaderDots />
        : (
          <ModalWrap ref={editModalClickOutsideRef}>
            <Title>
              {`Notes for ${incidentRequest.itsm_id}`}
              <CloseIconBox onClick={onCloseModal}>
                <IconComponent icon={closeIcon} width={20} />
              </CloseIconBox>
            </Title>
            <Main>
              <NoteBlock>
                <NotesIncidentrequestComponent
                  incidentRequest={incidentRequest}
                />
              </NoteBlock>
            </Main>
            <GradientLine />
          </ModalWrap>
        )}
    </Layout>
  );
};

const Layout = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
const ModalWrap = styled.div`
  max-height: calc(100vh - 160px);
  max-width: 975px;
  min-width: 675px;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding: 0px 45px;
  overflow-y: auto;

  position: relative;

  @media (max-width: 725px) {
    min-width: 100%;
  }

  ${customScrollCss}
`;

const GradientLine = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #000000;
  background: #ffffff;
  padding: 35px 40px 25px 0;

  position: sticky;
  top: 0;
  z-index: 1;
`;

const Main = styled.div`
  margin: 25px 0 0;
`;

// const Row = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   border-bottom: 1px solid #c4c4c4;
//   padding: 30px 0;

//   &:first-child {
//     border-top: 1px solid #c4c4c4;
//   }
// `;

// const Column = styled.div`
//   &:not(:last-child) {
//     margin-right: 4.5vw;
//   }
// `;
// const Label = styled.p`
//   font-weight: 600;
//   font-size: 14px;
//   line-height: 16px;
//   letter-spacing: 0.02em;
//   text-transform: uppercase;
//   padding: 0 0 20px 0;
// `;
// const Text = styled.p<{ isBlue?: boolean, isNoCapitalize?: boolean }>`
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 26px;
//   color: ${({ isBlue }) => (isBlue ? '#0066FF' : '#000000')};
//   text-transform: ${({ isNoCapitalize }) => (isNoCapitalize ? 'none' : 'capitalize')};
// `;

// const NotesLabel = styled.div`
//   margin: 40px 0 0;
// `;
const NoteBlock = styled.div``;

// const Btns = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   margin-top: 55px;

//   & button {
//     width: 130px;
//   }
// `;
const CloseIconBox = styled.div`
  position: absolute;
  top: 35px;
  right: 0px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

// const ImpactedUser = styled.div`
//   margin-bottom: 10px;
// `;

export default ModalNotesEvent;
