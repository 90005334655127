import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '@/App';
import TableContainer, { IMultiItemsFiltersLabel, ITableTabs } from '@/Components/Items/ItemsTable/TableContainer';
import { IDateFilters } from '@/Components/Items/ItemsTable/TableFilters';

import {
  getAssets, getAssetsTotal, getItemsFilters,
} from '@/store/actions/items';
import { RequestOptions, RequestState } from '@/store/reducers/common';
import {
  selectAssets,
  selectAssetsPaginationState,
  selectAssetsState,
  selectAssetsTotal,
  selectAssetsTotalState,
  selectItemsFilters,
  selectItemsFiltersState,
} from '@/store/selectors/items';

import { ITableBody } from '@/interfaces/api';

import { assetsPerPage } from '@/utils/api';
import { sort, SortOrder, SortType } from '@/utils/sortUtil';

interface Props<T> extends ITableTabs<T> {
}

const AssetsContainer = <T extends string>({
  interval,
  selectedTab,
  setSelectedTab,
  tabs,
  title,
}: Props<T>) => {
  const dispatch = useDispatch<AppDispatch>();

  const itemsFilters = useSelector(selectItemsFilters);
  const assets = useSelector(selectAssets);
  const assetsTotal = useSelector(selectAssetsTotal);
  const assetsState = useSelector(selectAssetsState);
  const assetsPaginationState = useSelector(selectAssetsPaginationState);
  const assetsTotalState = useSelector(selectAssetsTotalState);
  const itemsFiltersState = useSelector(selectItemsFiltersState);

  const filtersLabel: IMultiItemsFiltersLabel = {
    asset_types: { title: 'Asset Type' },
    make_model: { title: 'Make/Model' },
    statuses: { title: 'Status' },
  };

  const sortedFilters = useMemo(() => {
    const arrFilters = Object.entries(itemsFilters).map(
      ([key, value]) => [key, sort(
        value,
        (sortedValue) => sortedValue,
        SortOrder.ASCENDING,
        SortType.String,
      )].filter((item) => !!item),
    );
    return Object.fromEntries(arrFilters);
  }, [itemsFilters]);

  const dateFilters: IDateFilters = {
    warranty_date: {
      startDate: null,
      endDate: null,
      title: 'Warranty Date',
    },
  };

  const getItems = (body: ITableBody, option?: RequestOptions) => {
    dispatch(getAssets({ ...body, ...{ interval } }, option));
  };

  const getItemsTotal = (body: ITableBody) => {
    dispatch(getAssetsTotal({ ...body, ...{ interval } }));
  };

  const getItemsFilter = () => {
    dispatch(getItemsFilters());
  };

  return (
    <TableContainer
      defaultSort={{ sortBy: 'serial_number', direction: SortOrder.ASCENDING }}
      title={title}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      getItems={getItems}
      getItemsTotal={getItemsTotal}
      getItemsFilter={getItemsFilter}
      interval={interval}
      filtersLabel={{}}
      itemsFilters={{}}
      dateFilters={dateFilters}
      multiItemsFilters={sortedFilters}
      multiItemsFiltersLabel={filtersLabel}
      items={assets}
      initialItemsPerPage={assetsPerPage}
      itemsTotal={assetsTotal}
      isItemsLoading={assetsState !== RequestState.LOADED}
      isItemsTotalLoading={assetsTotalState !== RequestState.LOADED}
      isItemsFilterLoading={itemsFiltersState !== RequestState.LOADED}
      isItemsPaginationLoading={assetsPaginationState !== RequestState.LOADED}
      exportCSVAssets
    />
  );
};

export default AssetsContainer;
