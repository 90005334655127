import React, { Fragment } from 'react';
import styled from 'styled-components';

import { IPeripheral } from '@/api/main-protected';

import arrowIcon from '@/assets/images/icons/sidebar/arrow-down.svg';

import { ISort, SortOrder } from '@/utils/sortUtil';

enum COLUMNS {
  TYPE = 'Type',
  DEPLOYED = 'Deployed',
  INSTOCK = 'In-stock',
}

interface IPeripheralsTable {
  peripherals: IPeripheral[]
  setSortColumn: (sort: ISort) => void;
  sortColumn: ISort | null;
}

const PERIPH_LABELS: { label: string, sortProp: keyof IPeripheral }[] = [
  { label: COLUMNS.TYPE, sortProp: 'type' },
  { label: COLUMNS.DEPLOYED, sortProp: 'deployed' },
  { label: COLUMNS.INSTOCK, sortProp: 'in_stock' },
];

const PeripheralsTable: React.FC<IPeripheralsTable> = ({
  peripherals,
  setSortColumn,
  sortColumn,
}) => {
  const reverseDirection = (direction: SortOrder) => (
    direction === SortOrder.ASCENDING
      ? SortOrder.DESCENDING
      : SortOrder.ASCENDING);

  const handleTopColumnCellClick = (sortBy: keyof IPeripheral) => {
    const sort = {
      sortBy,
      direction: sortColumn?.sortBy === sortBy
        ? reverseDirection(sortColumn?.direction)
        : SortOrder.ASCENDING,
    };

    setSortColumn(sort);
  };

  return (
    <>
      <Grid>
        <FixedTop
          className="fixed-left"
          onClick={() => handleTopColumnCellClick(PERIPH_LABELS[0].sortProp)}
        >
          <Text>{PERIPH_LABELS[0].label}</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === PERIPH_LABELS[0].sortProp
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop>
        {PERIPH_LABELS.slice(1).map((l, i) => (
          <FixedTop key={l.sortProp} onClick={() => handleTopColumnCellClick(l.sortProp)}>
            <Text>{l.label}</Text>
            <Arrow>
              <ArrowImg
                isAccending={
                  sortColumn?.sortBy === PERIPH_LABELS[i + 1].sortProp
                  && sortColumn?.direction === SortOrder.ASCENDING
                }
              >
                <img src={arrowIcon} alt="icon" />
              </ArrowImg>
            </Arrow>
          </FixedTop>
        ))}
        {peripherals.length > 0
          && peripherals.map((item) => (
            <Fragment key={item.type}>
              <GridCell className="fixed-left">{item.type || '-'}</GridCell>
              <GridCell>{item.deployed || '-'}</GridCell>
              <GridCell>{item.in_stock || '-'}</GridCell>
            </Fragment>
          ))}
      </Grid>
      {peripherals.length === 0 && (
        <EmptyList>No peripherals currently available for the selected time frame.</EmptyList>
      )}
    </>
  );
};

const Grid = styled.div`
  min-width: 495px;
  // width: 100%; // adds unnecessary scroll
  // height: 100%; // adds hiding effect
  position: relative;

  display: grid;
  grid-template-columns:
    minmax(26.88%, 26.88%) 
    minmax(33.33%, 33.33%)
    minmax(17.2%, auto);
  grid-auto-rows: min-content;

  font-weight: 400;
  font-size: 13px;
`;
const FixedTop = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;

  height: 28px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: 800;
  font-size: 12px;
  padding: 8px 10px 8px 0;

  background: #d9d9d9;

  cursor: pointer;

  &.fixed-left {
    position: sticky;
    left: 0;
    z-index: 2;

    padding-left: 15px;
  }
`;
const GridCell = styled.div<{ isBlue?: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ isBlue }) => (isBlue ? '#0066FF' : '#000000')};

  height: 33px;
  padding: 10px 10px 10px 0;
  background-color: white;
  word-break: break-word;
  
  &.fixed-left {
    position: sticky;
    left: 0;
    z-index: 1;

    padding-left: 15px;
  }
  &:nth-child(6n + 1),
  &:nth-child(6n + 2),
  &:nth-child(6n + 3) {
    background: #f6f6f6;
  }
`;
const Text = styled.div`
  margin-right: 10px;
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  min-width: 10px;
  height: 20px;
`;
const ArrowImg = styled.div<{ isAccending: boolean }>`
  display: flex;
  align-items: center;
  transform: rotate(${({ isAccending }) => (isAccending ? 180 : 0)}deg);
  transition: all 0.3s ease;

  & img {
    max-width: 100%;
  }
`;

const EmptyList = styled.div`
  min-height: 125px;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 34px;
  color: #868484;
  padding: 35px 50px 25px 35px;
  margin: 0 auto;
`;

export default PeripheralsTable;
