import HttpClientProtected from '@/api/http-client-protected';

export default class MainProtectedFile extends HttpClientProtected {
  private static instanceCached: MainProtectedFile;

  private constructor() {
    super(process.env.BASE_URL, 'application/zip');
  }

  static getInstance = () => {
    if (!MainProtectedFile.instanceCached) {
      MainProtectedFile.instanceCached = new MainProtectedFile();
    }

    return MainProtectedFile.instanceCached;
  };

  public exportCSV = (queries: string) => this.instance.get(`/requests/export/csv?date_range=${queries}`);
}
