import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { IPhoneType } from '@/api/main-protected';
import { ROLES } from '@/api/main';

import { AppDispatch } from '@/App';
import { PAGES } from '@/Components/Main/Main';
import { NAV_IDS, NAV_NAMES } from '@/Components/UserSettings/UserSettingsMainWrap';

import Input from '@/Components/common/Input';
import Btn from '@/Components/common/Signin/Btn';
import { IVariant } from '@/Components/common/DropDown';

import { getLocations } from '@/store/actions/utils';
import { selectLocations } from '@/store/selectors/utils';
import { selectUserInfo } from '@/store/selectors/user';

import useInput from '@/Components/hooks/useInput';

import {
  isLowerLetter,
  isNumericAlpha,
  isSpecialSymbol,
  isUpperLetter,
} from '@/utils/validation';
import { getUserRoleName } from '@/utils/user';
import getBrowserName from '@/utils/browsers';

interface ISetForm {
  topOffset: number;
}

const SettingsForm: React.FC<ISetForm> = ({ topOffset }) => {
  const user = useSelector(selectUserInfo);
  const [firstName, setFirstName] = useInput(user?.first_name || '');
  const [lastName, setLastName] = useInput(user?.last_name || '');
  const [mail, setMail] = useInput(user?.email || '');
  const [newMail, setNewMail] = useInput('');
  const [curPsw, setCurPsw] = useInput('');
  const [newPsw, setNewPsw] = useInput('');
  const [confNewPsw, setConfNewPsw] = useInput('');
  const [phone, setPhone] = useInput(user?.phone_numbers[0]?.phone || '');
  const [organization, setOrganization] = useInput(user?.business_unit || '');
  const [country, setCountry] = useInput(user?.country || '');
  const [role, setRole] = useInput(getUserRoleName(user ? user?.roles[0] : ROLES.USER));
  const [locationVariant, setLocationVariant] = useState<IVariant | null>({
    title: user?.country || '',
  });
  const [isBasicInfoUpdating, setIsBasicInfoUpdating] = useState(false);
  const [isPswUpdating, setIsPswUpdating] = useState(false);
  const [is10, setIs10] = useState(false);
  const [isUpLow, setIsUpLow] = useState(false);
  const [isA1, setIsA1] = useState(false);
  const [isSpecial, setIsSpecial] = useState(false);

  const locations = useSelector(selectLocations);

  const browser = getBrowserName();
  const dispatch = useDispatch<AppDispatch>();

  const ROLES_VARIANTS = [
    { title: getUserRoleName(ROLES.USER), role: ROLES.USER },
    { title: getUserRoleName(ROLES.SUPER_ADMIN), role: ROLES.SUPER_ADMIN },
    { title: getUserRoleName(ROLES.MANAGER), role: ROLES.MANAGER },
  ];

  useEffect(() => {
    if (!locations) {
      dispatch(getLocations());
    }
  }, []);

  useEffect(() => {
    setIs10(newPsw.length > 9);
    setIsUpLow(isUpperLetter(newPsw) && isLowerLetter(newPsw));
    setIsA1(isNumericAlpha(newPsw));
    setIsSpecial(isSpecialSymbol(newPsw));
  }, [newPsw]);

  const handleUpdateMe = () => ({
    first_name: firstName,
    last_name: lastName,
    phone_numbers: [
      {
        phone,
        type: IPhoneType.Mobile,
      },
    ],
    business_unit: organization,
    country: locationVariant ? locationVariant?.title : '',
    role: confNewPsw,
  });
  const handleErrorUpdateMe = () => {
    setFirstName(user?.first_name || '');
    setLastName(user?.last_name || '');
    setPhone(user?.phone_numbers[0]?.phone || '');
    setOrganization(user?.business_unit || '');
    setLocationVariant({ title: user?.country || '' });
  };

  // const handleUpdateMeRole = () => ({
  //   role: confNewPsw,
  // });
  const handleUpdateMePsw = () => ({
    password: confNewPsw,
  });

  const handleErrorUpdatePswMe = () => {
    setCurPsw('');
    setNewPsw('');
    setConfNewPsw('');
  };

  return (
    <Form id="user-settings">
      <Section id={NAV_IDS.BASIC_INFO} topOffset={topOffset}>
        <TitleBlock>{NAV_NAMES.BASIC_INFO}</TitleBlock>
        <SettingsBlock>
          <FormItem>
            <Label>Full name</Label>
            <InputsBox>
              <InputBox>
                <Input
                  id="first-name"
                  name="first-name"
                  value={firstName}
                  placeholder="First name"
                  isNoMargin
                  onChange={setFirstName}
                  disabled
                />
              </InputBox>
              <InputBox>
                <Input
                  id="last-name"
                  name="last-name"
                  value={lastName}
                  placeholder="Last name"
                  isNoMargin
                  onChange={setLastName}
                  disabled
                />
              </InputBox>
            </InputsBox>
          </FormItem>
          {/* <FormItem>
            <Label>Email</Label>
            <InputBox>
              <Input
                id='user-email'
                name='user-email'
                value={mail}
                placeholder='Email'
                isNoMargin
                onChange={setMail}
              />
            </InputBox>
          </FormItem> */}
          <FormItem>
            <Label>
              Phone
              {' '}
              <Span>{' (Optional)'}</Span>
            </Label>
            <InputBox>
              <Input
                id="phone"
                name="phone"
                // type='number' // add number checks
                value={phone}
                placeholder="+1(609)972-2222"
                isNoMargin
                onChange={setPhone}
                disabled
              />
            </InputBox>
          </FormItem>
          <FormItem>
            <Label>Organization</Label>
            <InputBox>
              <Input
                id="organization"
                name="organization"
                value={organization}
                placeholder="Organization"
                isNoMargin
                onChange={setOrganization}
                disabled
              />
            </InputBox>
          </FormItem>
          <FormItem>
            <Label>Location</Label>
            <InputBox>
              <Input
                id="locations"
                name="locations"
                value={locationVariant?.title || ''}
                placeholder="Locations"
                isNoMargin
                onChange={() => {}}
                disabled
              />
              {/* <DropDown
                variants={locations}
                chosenOption={locationVariant}
                onChoose={setLocationVariant}
                placeholder="Country"
                isSub
                isGreyArrow
                minHeight={30}
                height={145}
              /> */}
            </InputBox>
          </FormItem>
          {/* <FormItem>
            <Label>Account type</Label>
            <CheckboxesBox>
              <InputBox>
                <Checkbox
                  isChecked={!isGroupAccountType}
                  onCheck={() => setIsGroupAccountType(!isGroupAccountType)}
                  noMargins
                  isRound
                >
                  Individual
                </Checkbox>
              </InputBox>
              <InputBox>
                <Checkbox
                  isChecked={isGroupAccountType}
                  onCheck={() => setIsGroupAccountType(!isGroupAccountType)}
                  noMargins
                  isRound
                >
                  Group
                </Checkbox>
              </InputBox>
            </CheckboxesBox>
          </FormItem> */}
          <FormItem>
            <Label>Role</Label>
            <InputBox>
              <Input
                id="role"
                name="role"
                value={role}
                placeholder="Role"
                isNoMargin
                onChange={setRole}
                disabled
              />
            </InputBox>
          </FormItem>
          {/* <BtnWrap>
            <Btn isActive={!isBasicInfoUpdating} onClick={handleSaveBasicInfo}>
              {isBasicInfoUpdating ? <LoaderDots /> : 'Save changes'}
            </Btn>
          </BtnWrap> */}
        </SettingsBlock>
      </Section>
      <Section id={NAV_IDS.EMAIL} topOffset={topOffset}>
        <TitleBlock>{NAV_NAMES.EMAIL}</TitleBlock>
        <SettingsBlock>
          <FormItem>
            <Text>Your current email address is</Text>
            <Bold>{user?.email || ''}</Bold>
          </FormItem>
          {/* <FormItem>
            <Label>New email address</Label>
            <InputBox>
              <Input
                id='user-new-email'
                name='user-new-email'
                value={newMail}
                placeholder='New email'
                isNoMargin
                onChange={setNewMail}
              />
            </InputBox>
          </FormItem>
          <BtnWrap>
            <Btn isActive={isValidEmail(newMail)}>Save changes</Btn>
          </BtnWrap> */}
        </SettingsBlock>
      </Section>
      <Section id={NAV_IDS.PSW} topOffset={topOffset}>
        <TitleBlock>Change your password</TitleBlock>
        <SettingsBlock>
          {/* <FormItem>
            <Label>Current password</Label>
            <InputBox isValue={!!curPsw}>
              <Input
                id={`user-new-${NAV_NAMES.PSW.replaceAll(' ', '-')}`}
                name={`user-new-${NAV_NAMES.PSW.replaceAll(' ', '-')}`}
                value={curPsw}
                placeholder={`Enter current password`}
                isNoMargin
                onChange={setCurPsw}
                isHideMode
                height={30}
                browser={browser}
              />
            </InputBox>
          </FormItem>
          <FormItem>
            <Label>{NAV_NAMES.NEW_PSW}</Label>
            <InputBox isValue={!!newPsw}>
              <Input
                id={`user-new-${NAV_NAMES.NEW_PSW.replaceAll(' ', '-')}`}
                name={`user-new-${NAV_NAMES.NEW_PSW.replaceAll(' ', '-')}`}
                value={newPsw}
                placeholder={`Enter ${NAV_NAMES.NEW_PSW.toLowerCase()}`}
                isNoMargin
                onChange={setNewPsw}
                isHideMode
                height={30}
                browser={browser}
              />
            </InputBox>
          </FormItem>
          <FormItem>
            <Label>{NAV_NAMES.CONF_PSW}</Label>
            <InputBox isValue={!!confNewPsw}>
              <Input
                id={`user-new-${NAV_NAMES.CONF_PSW.replaceAll(' ', '-')}`}
                name={`user-new-${NAV_NAMES.CONF_PSW.replaceAll(' ', '-')}`}
                value={confNewPsw}
                placeholder='Confirm your new password'
                onChange={setConfNewPsw}
                isNoMargin
                isHideMode
                height={30}
                browser={browser}
              />
            </InputBox>
          </FormItem>
          <FormItem>
            <Label></Label>
            <InputBox>
              <ChecksTitle>Password requirements:</ChecksTitle>
              <SubText>Ensure that this requirements are met</SubText>
              <Condition>
                <Dot />
                <Text>At least 10 characters</Text>
              </Condition>
              <Condition>
                <Dot />
                <Text>A mixture of both uppercase and lowercase letters</Text>
              </Condition>
              <Condition>
                <Dot />
                <Text>A mixture of letters and numbers</Text>
              </Condition>
              <Condition>
                <Dot />
                <Text>{`Inclusion of at least one special character, e.g., ! @ # ? ]`}</Text>
              </Condition>
            </InputBox>
          </FormItem>
          <BtnWrap>
            <Btn
              isActive={
                !!curPsw &&
                !!newPsw &&
                !!confNewPsw &&
                newPsw === confNewPsw &&
                is10 &&
                isA1 &&
                isUpLow &&
                isSpecial
              }
              onClick={handleSavePsw}
            >
              {isPswUpdating ? <LoaderDots /> : 'Save changes'}
            </Btn>
          </BtnWrap> */}
          <BtnLinkWrap>
            <Link to={PAGES.RESET_PSW}>
              <Btn isActive width={175}>
                Change password
              </Btn>
            </Link>
          </BtnLinkWrap>
        </SettingsBlock>
      </Section>
      {/* <Section id={NAV_IDS.PREFERENCES} topOffset={topOffset}>
        <TitleBlock>{NAV_NAMES.PREFERENCES}</TitleBlock>
        <SettingsBlock>
          <FormItem>
            <Label>{NAV_NAMES.PREFERENCES}</Label>
            <InputBox>
              <Input
                id={`user-new-${NAV_NAMES.PREFERENCES.replaceAll(' ', '-')}`}
                name={`user-new-${NAV_NAMES.PREFERENCES.replaceAll(' ', '-')}`}
                value={newMail}
                placeholder={NAV_NAMES.PREFERENCES}
                isNoMargin
                onChange={setNewMail}
              />
            </InputBox>
          </FormItem>
          <BtnWrap>
            <Btn isActive={isValidEmail(newMail)}>Save changes</Btn>
          </BtnWrap>
        </SettingsBlock>
      </Section> */}
      {/* <Section id={NAV_IDS.TWOSTEPVER} topOffset={topOffset}>
        <TitleBlock>{NAV_NAMES.TWOSTEPVER}</TitleBlock>
        <SettingsBlock>
          <FormItem>
            <Label>{NAV_NAMES.TWOSTEPVER}</Label>
            <InputBox>
              <Input
                id={`user-new-${NAV_NAMES.TWOSTEPVER.replaceAll(' ', '-')}`}
                name={`user-new-${NAV_NAMES.TWOSTEPVER.replaceAll(' ', '-')}`}
                value={newMail}
                placeholder={NAV_NAMES.TWOSTEPVER}
                isNoMargin
                onChange={setNewMail}
              />
            </InputBox>
          </FormItem>
          <BtnWrap>
            <Btn isActive={isValidEmail(newMail)}>Save changes</Btn>
          </BtnWrap>
        </SettingsBlock>
      </Section> */}
      {/* <Section id={NAV_IDS.RECENT_DEVICES} topOffset={topOffset}>
        <TitleBlock>{NAV_NAMES.RECENT_DEVICES}</TitleBlock>
        <SettingsBlock>
          <FormItem>
            <Label>{NAV_NAMES.RECENT_DEVICES}</Label>
            <InputBox>
              <Input
                id={`user-new-${NAV_NAMES.RECENT_DEVICES.replaceAll(' ', '-')}`}
                name={`user-new-${NAV_NAMES.RECENT_DEVICES.replaceAll(' ', '-')}`}
                value={newMail}
                placeholder={NAV_NAMES.RECENT_DEVICES}
                isNoMargin
                onChange={setNewMail}
              />
            </InputBox>
          </FormItem>
          <BtnWrap>
            <Btn isActive={isValidEmail(newMail)}>Save changes</Btn>
          </BtnWrap>
        </SettingsBlock>
      </Section> */}
      {/* <Section id={NAV_IDS.NOTIFICATIONS} topOffset={topOffset}>
        <TitleBlock>{NAV_NAMES.NOTIFICATIONS}</TitleBlock>
        <SettingsBlock>
          <FormItem>
            <Label>{NAV_NAMES.NOTIFICATIONS}</Label>
            <InputBox>
              <Input
                id={`user-new-${NAV_NAMES.NOTIFICATIONS.replaceAll(' ', '-')}`}
                name={`user-new-${NAV_NAMES.NOTIFICATIONS.replaceAll(' ', '-')}`}
                value={newMail}
                placeholder={NAV_NAMES.NOTIFICATIONS}
                isNoMargin
                onChange={setNewMail}
              />
            </InputBox>
          </FormItem>
          <BtnWrap>
            <Btn isActive={isValidEmail(newMail)}>Save changes</Btn>
          </BtnWrap>
        </SettingsBlock>
      </Section> */}
      {/* <FormItem>
        <Label />
        <InputBox>
          <Input
            id='city'
            name='country'
            value={city}
            placeholder='City'
            isNoMargin
            onChange={setCity}
          />
        </InputBox>
      </FormItem>
      <FormItem>
        <Label />
        <InputBox>
          <Input
            id='state'
            name='state'
            value={state}
            placeholder='State'
            isNoMargin
            onChange={setState}
          />
        </InputBox>
      </FormItem>
      <FormItem>
        <Label>Address line 1</Label>
        <InputBox>
          <Input
            id='address'
            name='address'
            value={address}
            placeholder='Your address'
            isNoMargin
            onChange={setAddress}
          />
        </InputBox>
      </FormItem>
      <FormItem>
        <Label>Address line 2</Label>
        <InputBox>
          <Input
            id='secondAddress'
            name='secondAddress'
            value={secondAddress}
            placeholder='Your address'
            isNoMargin
            onChange={setSecondAddress}
          />
        </InputBox>
      </FormItem>
      <FormItem>
        <Label>Zip code</Label>
        <InputBox>
          <Input
            id='zip-code'
            name='zip-code'
            value={zipCode}
            placeholder='Zip code'
            isNoMargin
            onChange={setZipCode}
          />
        </InputBox>
      </FormItem> */}
    </Form>
  );
};

const Form = styled.div`
  flex: 0 0 100%;
  width: 100%;
  margin-bottom: 60vh;
`;

const Section = styled.section<{ topOffset: number }>`
  width: 100%;
  // min-height: 200px;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-bottom: 24px;

  scroll-margin-top: ${({ topOffset }) => topOffset}px;
  // scroll-margin-top: 30vh;

  // /* Safari-only */
  // @supports (-webkit-hyphens: none) {
  //   padding-top: 120px;
  //   margin-top: -120px;
  // }
`;

const TitleBlock = styled.div`
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #000000;
  background: #ffffff;
  padding: 20px 20px 20px 36px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.7);
  text-transform: capitalize;
`;

const SettingsBlock = styled.div`
  padding: 9px 20px 15px 36px;
`;
const FormItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 12px 0;
`;
const BtnWrap = styled.div`
  width: 110px;
  display: flex;
  align-items: center;
  margin: 30px 0 0 auto;

  & button {
    height: 30px;
    font-weight: 600;
    font-size: 12px;
  }
`;

const BtnLinkWrap = styled.div`
  flex: 0 0 175px;
  width: 175px;
  display: flex;
  align-items: center;
  margin: 10px 0 30px 0;

  & a {
    width: 100%;
  }

  & button {
    font-weight: 500;
    font-size: 14px;
  }
`;

const Label = styled.p`
  flex: 0 0 160px;
  width: 160px;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-right: 6px;
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #4a4949;
  margin-bottom: 8px;
`;

const SubText = styled(Text)`
  color: #868484;
`;

const Bold = styled(Text)`
  font-weight: 600;
  margin-left: 5px;
`;

const Span = styled.span`
  color: #949393;
`;

const InputsBox = styled.div`
  width: 100%;
  max-width: 475px;
  display: flex;

  & [name='first-name'] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  & [name='last-name'] {
    border-left: 1px solid transparent;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
// const CheckboxesBox = styled.div`
//   width: 100%;
//   max-width: 475px;
//   display: flex;

//   & > div:first-child {
//     width: 100%;
//     background: #ffffff;
//     border: 1px solid #c4c4c4;
//     border-top-right-radius: 0;
//     border-bottom-right-radius: 0;
//     border-top-left-radius: 4px;
//     border-bottom-left-radius: 4px;
//   }
//   & > div:last-child {
//     background: #ffffff;
//     border: 1px solid #c4c4c4;
//     border-left: 1px solid transparent;
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
//     border-top-right-radius: 4px;
//     border-bottom-right-radius: 4px;
//   }
// `
const InputBox = styled.div<{ isValue?: boolean }>`
  width: 100%;
  max-width: 475px;

  & input {
    height: 30px;
    padding: 7px 10px 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #868484;

    & ~ img {
      width: 20px;
      height: 20px;
      top: 5px;
    }
  }
  & [type='password'] {
    // font-size: ${({ isValue }) => (isValue ? 'xx-large' : '16px')};
    letter-spacing: ${({ isValue }) => (isValue ? '1px' : '0')};
    color: ${({ isValue }) => (isValue ? '#000000' : '#868484')};

    & ~ div {
      height: 5px;
    }

    &::-webkit-input-placeholder {
      font-family: 'Jost';
      font-weight: 400;
      font-size: 16px !important;
      line-height: 1px;
      color: ${({ isValue }) => (isValue ? '#000000' : '#868484')};
      height: 10px;
    }
  }

  & [type='text'] {
    letter-spacing: 0px;
    color: #000000;

    &::-webkit-input-placeholder {
      font-family: 'Jost';
      font-weight: 400;
      font-size: 16px !important;
      line-height: 1px;
      color: #868484;
      height: 10px;
    }
  }
`;

// const ChecksTitle = styled(Text)`
//   font-weight: 600;
// `

// const Condition = styled.div`
//   display: flex;

//   & p {
//     color: #868484;
//   }
// `
// const Dot = styled.div`
//   flex: 0 0 5px;
//   width: 5px;
//   height: 5px;
//   border-radius: 50%;
//   background: #868484;
//   margin: 5px 7px 0 3px;
// `

export default SettingsForm;
