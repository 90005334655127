import { THEMES } from '@/store/reducers/theme';
import { lastMonthDays } from '@/utils/date';

export enum Filter {
  DAY_1 = 1,
  LAST_7_DAYS = 7,
  LAST_2_WEEK = 14,
  LAST_MONTH = lastMonthDays(),
  LAST_QUARTER = 91,
  ALL = -1,
}

enum LOCALS {
  THEME = 'theme',
  DOCU_SIGNED_ID = 'docuSignedId',
  FILTER = 'filter',
  SEARCH = 'search',
  EMAIL = 'email',
}

interface IStorage {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}

abstract class LocalStorage<T extends string> {
  private readonly storage: IStorage;

  public constructor(getStorage = (): IStorage => window.localStorage) {
    this.storage = getStorage();
  }

  protected get(key: T): string | null {
    return this.storage.getItem(key);
  }

  protected set(key: T, value: string): void {
    this.storage.setItem(key, value);
  }

  protected clearItem(key: T): void {
    this.storage.removeItem(key);
  }

  protected clearItems(keys: T[]): void {
    keys.forEach((key) => this.clearItem(key));
  }

  static setTheme(theme: THEMES) {
    window.localStorage.setItem(LOCALS.THEME, theme);
  }

  static getTheme() {
    return window.localStorage.getItem(LOCALS.THEME);
  }

  static setDocuSignedId(docuSignedId: string) {
    window.localStorage.setItem(LOCALS.DOCU_SIGNED_ID, docuSignedId);
  }

  static getDocuSignedId() {
    return window.localStorage.getItem(LOCALS.DOCU_SIGNED_ID);
  }

  static setFilter(filter: string) {
    window.localStorage.setItem(LOCALS.FILTER, filter);
  }

  static getFilter() {
    return window.localStorage.getItem(LOCALS.FILTER);
  }

  static setEmail(email: string) {
    window.localStorage.setItem(LOCALS.EMAIL, email);
  }

  static getEmail() {
    return window.localStorage.getItem(LOCALS.EMAIL);
  }

  static setRecentSearch(search: string) {
    window.localStorage.setItem(LOCALS.SEARCH, search);
  }

  static getRecentSearch() {
    return window.localStorage.getItem(LOCALS.SEARCH);
  }

  static clear() {
    window.localStorage.clear();
  }
}

export default LocalStorage;
