import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';

import { IAsset } from '@/api/main-protected';

import arrowIcon from '@/assets/images/icons/sidebar/arrow-down.svg';

import convertFromStringToDate, { FormatDate } from '@/utils/convertFromStringToDate';
import { ISort, SortOrder } from '@/utils/sortUtil';

interface IAssetsTable {
  assets: IAsset[]
  setSortColumn: (sort: ISort) => void;
  sortColumn: ISort | null;
}

enum COLUMNS {
  SERIAL_NUMBER = 'Serial Number',
  TYPE = 'Asset Type',
  MODEL = 'Make/Model',
  STATUS = 'Status',
  WARRANTY_DATE = 'Warranty Date',
  REQUESTS = 'Request(s)',
}

const ASSETS_LABELS: { label: string, sortProp: keyof IAsset, isDisable?: boolean }[] = [
  { label: COLUMNS.SERIAL_NUMBER, sortProp: 'serial_number' },
  { label: COLUMNS.TYPE, sortProp: 'asset_type' },
  { label: COLUMNS.MODEL, sortProp: 'model' },
  { label: COLUMNS.STATUS, sortProp: 'status' },
  { label: COLUMNS.WARRANTY_DATE, sortProp: 'warranty_date' },
  { label: COLUMNS.REQUESTS, sortProp: 'requests', isDisable: true },
];

// use for All items on items page
const AssetsTable: React.FC<IAssetsTable> = ({ assets, sortColumn, setSortColumn }) => {
  const reverseDirection = (direction: SortOrder) => (
    direction === SortOrder.ASCENDING
      ? SortOrder.DESCENDING
      : SortOrder.ASCENDING);

  const handleTopColumnCellClick = (sortBy: keyof IAsset) => {
    const sort = {
      sortBy,
      direction: sortColumn?.sortBy === sortBy
        ? reverseDirection(sortColumn?.direction)
        : SortOrder.ASCENDING,
    };

    setSortColumn(sort);
  };

  return (
    <>
      <Grid>
        <FixedTop
          className="fixed-left"
          onClick={() => handleTopColumnCellClick(ASSETS_LABELS[0].sortProp)}
        >
          <Text>{ASSETS_LABELS[0].label}</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === ASSETS_LABELS[0].sortProp
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <Image src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop>
        {ASSETS_LABELS.slice(1).map((l, i) => (
          <FixedTop
            key={l.sortProp}
            onClick={() => !l.isDisable && handleTopColumnCellClick(l.sortProp)}
            isActive={!l.isDisable}
          >
            {' '}
            <Text>{l.label}</Text>
            {!l.isDisable && (
              <Arrow>
                <ArrowImg
                  isAccending={
                    sortColumn?.sortBy === ASSETS_LABELS[i + 1].sortProp
                    && sortColumn?.direction === SortOrder.ASCENDING
                  }
                >
                  <Image src={arrowIcon} alt="icon" />
                </ArrowImg>
              </Arrow>
            )}
          </FixedTop>
        ))}
        {assets.length > 0
          && assets.map((item) => (
            <Fragment key={item.asset_tag}>
              <GridCell className="fixed-left">{item.serial_number || '-'}</GridCell>
              <GridCell>{item.asset_type || '-'}</GridCell>
              <GridCell>{item.model || '-'}</GridCell>
              <GridCell isCapitalize>{item.status || '-'}</GridCell>
              <GridCell>{convertFromStringToDate(item.warranty_date, FormatDate.ShortFormat) || '-'}</GridCell>
              <GridCell isBlue isWrap>
                {item.requests.length ? item.requests.map((req, i) => (
                  <Text key={req.request_id}>
                    {i !== item.requests.length - 1 ? `${req.itsm_id},` : req.itsm_id}
                  </Text>
                )) : '-'}
              </GridCell>
            </Fragment>
          ))}
      </Grid>
      {assets.length === 0 && (
        <EmptyList>No assets currently available for the selected time frame.</EmptyList>
      )}
    </>
  );
};

const Image = styled.img`
  fill: red;
`;

const Grid = styled.div`
  min-width: 1100px;
  // width: 100%; // adds unnecessary scroll
  // height: 100%; // adds hiding effect
  position: relative;

  display: grid;

  grid-template-columns:
    minmax(200px, auto)
    minmax(100px, auto)
    minmax(200px, auto)
    minmax(200px, auto)
    minmax(150px, auto)
    minmax(250px, auto);

  grid-auto-rows: min-content;

  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
`;
const FixedTop = styled.div<{ isActive?: boolean }>`
  position: sticky;
  top: 0;
  z-index: 1;

  height: 28px;
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 12px;
  padding: 8px 10px 8px 0;

  background: #d9d9d9;

  cursor: ${({ isActive }) => isActive && 'pointer'};

  &.fixed-left {
    position: sticky;
    left: 0;
    z-index: 2;

    padding-left: 10px;
  }
`;
const GridCell = styled.div<{ isBlue?: boolean, isCapitalize?: boolean, isWrap?: boolean }>`
  display: flex;
  align-items: center;
  word-break: break-word;

  ${({ isWrap }) => isWrap && css`
    flex-wrap: wrap;
    & p:not(:last-child) {
      margin-bottom: 8px;
    }
  `}
  color: ${({ isBlue }) => (isBlue ? '#0066FF' : '#000000')};
  ${({ isCapitalize }) => isCapitalize && css` 
    text-transform: capitalize;
  `};

  min-height: 33px;
  padding: 10px 10px 10px 0;
  background-color: white;

  &.fixed-left {
    position: sticky;
    left: 0;
    z-index: 1;

    padding-left: 15px;
  }
  &:nth-child(12n + 1),
  &:nth-child(12n + 2),
  &:nth-child(12n + 3),
  &:nth-child(12n + 4),
  &:nth-child(12n + 5),
  &:nth-child(12n + 6) {
    background: #f6f6f6;
  }
`;
const Text = styled.p`
  margin-right: 6px;
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  min-width: 10px;
  height: 20px;
`;
const ArrowImg = styled.div<{ isAccending: boolean }>`
  display: flex;
  align-items: center;
  transform: rotate(${({ isAccending }) => (isAccending ? 180 : 0)}deg);
  transition: all 0.3s ease;

  & img {
    max-width: 100%;
  }
`;
const EmptyList = styled.div`
  min-height: 125px;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 34px;
  color: #868484;
  padding: 35px 50px 25px 35px;
  margin: 0 auto;
`;

export default AssetsTable;
