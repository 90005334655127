import { createSelector, Selector } from 'reselect';

import { State } from '@/store';

import { RequestState } from '@/store/reducers/common';

const selectLogin = (state: State) => state.LoginReducer;

export const selectIsLoggedIn: Selector<State, boolean> = createSelector(
  selectLogin,
  ({ isLoggedIn }) => isLoggedIn,
);

export const selectLoginState: Selector<State, RequestState> = createSelector(
  selectLogin,
  ({ loginState }) => loginState,
);

export const selectIsLoading: Selector<State, boolean> = createSelector(
  selectLogin,
  ({ isLoading }) => isLoading,
);

export const selectIsLocalLoading: Selector<State, boolean> = createSelector(
  selectLogin,
  ({ isLocalLoading }) => isLocalLoading,
);

export const selectIsImpactedUserLoading: Selector<State, boolean> = createSelector(
  selectLogin,
  ({ isImpactedUserLoading }) => isImpactedUserLoading,
);

export const selectIsDocuSigned: Selector<State, boolean> = createSelector(
  selectLogin,
  ({ isDocuSigned }) => isDocuSigned,
);
