import { createActionCreators } from 'immer-reducer';

import { AsyncAction } from '@/store/actions/common';
import { GlobalReducer } from '@/store/reducers/global';
import { RequestsSearchReducer } from '@/store/reducers/requestsSearch';
import { AssetsSearchReducer } from '@/store/reducers/assetsSearch';
import { EventsSearchReducer } from '@/store/reducers/eventsSearch';
import { RequestState } from '@/store/reducers/common';

export const globalActions = createActionCreators(GlobalReducer);
export const requestsSearchActions = createActionCreators(RequestsSearchReducer);
export const assetsSearchActions = createActionCreators(AssetsSearchReducer);
export const eventsSearchActions = createActionCreators(EventsSearchReducer);

export type GlobalActionsType =
  | ReturnType<typeof globalActions.setGlobalSearch>
  | ReturnType<typeof globalActions.setGlobalSearchState>;

export type RequestsSearchActionsType =
  | ReturnType<typeof requestsSearchActions.setRequestsSearch>
  | ReturnType<typeof requestsSearchActions.setRequestsSearchState>;

export type AssetsSearchActionsType =
  | ReturnType<typeof assetsSearchActions.setAssetsSearch>
  | ReturnType<typeof assetsSearchActions.setAssetsSearchState>;

export type EventsSearchActionsType =
  | ReturnType<typeof eventsSearchActions.setEventsSearch>
  | ReturnType<typeof eventsSearchActions.setEventsSearchState>;

export const getGlobalSearch = (query: string): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(globalActions.setGlobalSearchState(RequestState.LOADING));
    dispatch(requestsSearchActions.setRequestsSearchState(RequestState.LOADING));
    dispatch(assetsSearchActions.setAssetsSearchState(RequestState.LOADING));
    dispatch(eventsSearchActions.setEventsSearchState(RequestState.LOADING));
    dispatch(requestsSearchActions.setRequestsSearch({ requests: [] }));
    dispatch(assetsSearchActions.setAssetsSearch({ assets: [] }));
    dispatch(eventsSearchActions.setEventsSearch({ events: [] }));

    const requestsResponse = await mainProtectedApi.getGlobalSearch(`${query}&search_type=requests`);
    dispatch(requestsSearchActions.setRequestsSearch({ requests: requestsResponse.requests }));
    dispatch(requestsSearchActions.setRequestsSearchState(RequestState.LOADED));

    const assetsResponse = await mainProtectedApi.getGlobalSearch(`${query}&search_type=assets`);
    dispatch(assetsSearchActions.setAssetsSearch({ assets: assetsResponse.assets }));
    dispatch(assetsSearchActions.setAssetsSearchState(RequestState.LOADED));

    const eventsResponse = await mainProtectedApi.getGlobalSearch(`${query}&search_type=events`);
    dispatch(eventsSearchActions.setEventsSearch({ events: eventsResponse.events }));
    dispatch(eventsSearchActions.setEventsSearchState(RequestState.LOADED));
  } catch (err) {
    dispatch(globalActions.setGlobalSearchState(RequestState.ERROR));
    dispatch(requestsSearchActions.setRequestsSearchState(RequestState.ERROR));
    dispatch(assetsSearchActions.setAssetsSearchState(RequestState.ERROR));
    dispatch(eventsSearchActions.setEventsSearchState(RequestState.ERROR));
  }
};
