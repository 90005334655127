import { css } from 'styled-components';

export const singleLine = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const linebreak = css`
  white-space: pre-line;
  word-break: break-word;
`;

export const lineClamp = (maxLine: number = 2) => maxLine && css`
  word-wrap: white-space;
  word-break: break-word;

  @supports (-webkit-line-clamp: ${maxLine}) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: ${maxLine};
    -webkit-box-orient: vertical;
  }
`;

export const resetLineClamp = (maxLine: number = 2) => maxLine && css`
  word-wrap: initial;
  word-break: initial;

  @supports (-webkit-line-clamp: ${maxLine}) {
    overflow: initial;
    text-overflow: initial;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: initial;
    -webkit-box-orient: initial;
  }
`;
