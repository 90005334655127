import React from 'react';
import styled from 'styled-components';

import Logo from '@/Components/Sidebar/Logo';
import Pages from '@/Components/Sidebar/Pages';

import { HandleToggle } from '@/Components/hooks/useToggle';

interface ISideBar {
  isExpanded: boolean
  handleBtnClick: HandleToggle
  handleLogoClick: () => void
}

const Sidebar: React.FC<ISideBar> = ({ isExpanded, handleBtnClick, handleLogoClick }) => (
  <Wrap>
    <Logo
      isExpanded={isExpanded}
      handleBtnClick={handleBtnClick}
      handleLogoClick={handleLogoClick}
    />
    <Pages isExpanded={isExpanded} />
    {/* <Info>
        <Link to={PAGES.SUPPORT}>
          <IconComponent icon={supportIcon} alt='support' />
        </Link>
      </Info> */}
  </Wrap>
);

const Wrap = styled.div`
  width: auto;
  height: 100vh;
  padding: 41px 21px 125px;
  border-right: 1px solid #e5e5e5;
  transition: width 0.3s ease;

  display: flex;
  flex-direction: column;

  & > div:nth-child(2) {
    flex: 1 0 auto;
  }
`;
// const Info = styled.div`
//   & img {
//     cursor: pointer;
//   }
// `
export default Sidebar;
