import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@/Components/common/Signin/Btn';
import useOnClickOutside from '@/Components/hooks/useOnClickOutside';
import LoaderDots from '@/Components/LoaderDots';

import { AppDispatch } from '@/App';
import { IEvent, Request } from '@/api/main-protected';

import { selectUserInfo } from '@/store/selectors/user';
import { selectOverrideEventState } from '@/store/selectors/events';
import { RequestState } from '@/store/reducers/common';
import { eventsActions, overrideEvent } from '@/store/actions/events';

import convertFromStringToDate from '@/utils/convertFromStringToDate';
import { getPermissions, Permissions } from '@/utils/permissions';

interface IComponent {
  event: IEvent;
  request: Request;
  onCloseModal: () => void;
}

const EventInfoWindowComponent: React.FC<IComponent> = ({
  event,
  request,
  onCloseModal,
}) => {
  const [ev, setEv] = useState<IEvent>(event);
  const modalClickOutsideRef = useOnClickOutside(onCloseModal);
  const user = useSelector(selectUserInfo);
  const overrideEventState = useSelector(selectOverrideEventState);

  const dispatch = useDispatch<AppDispatch>();

  const todayDate = new Date();

  const today = convertFromStringToDate(`${todayDate}`);

  useEffect(() => {
    if (overrideEventState !== RequestState.LOADED) return;

    setEv({
      ...ev,
      overriden: {
        user: `${user?.first_name} ${user?.last_name}`,
        time: today,
      },
    });

    dispatch(eventsActions.setOverrideEventState(RequestState.IDLE));
  }, [overrideEventState]);

  const handleOverride = () => {
    dispatch(
      overrideEvent({
        work_order_id: request.request_id,
        itsm_id: request.itsm_id,
        override_type: 'override',
        xp_event_id: event.xp_event_id,
        user: `${user?.first_name} ${user?.last_name}`,
      }),
    );
  };

  return (
    <Container ref={modalClickOutsideRef}>
      <Title>{request.category || ''}</Title>
      <SubTitle>{ev.long_desc || ''}</SubTitle>
      {ev.user_response && (
      <Bold>
        User response:
        {' '}
        <Text>{` ${ev.user_response}`}</Text>
      </Bold>
      )}
      {/* {ev.user_response && <Text>{`User response: ${ev.user_response}`}</Text>} */}
      {getPermissions(user!.roles[0], Permissions.Override)
      && (
      <Row>
        <Btn>
          <Button onClick={handleOverride} isActive={!ev.overriden}>
            {overrideEventState === RequestState.LOADING ? <LoaderDots size={7} /> : 'Override'}
          </Button>
        </Btn>
        {ev.overriden && (
        <OverrideBox>
          <Text>{`Event overridden by ${ev.overriden.user}`}</Text>
          <Text>{ev.overriden.time}</Text>
        </OverrideBox>
        )}
      </Row>
      )}
    </Container>
  );
};

const fadein = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 45px;
  width: 330px;
  height: auto;
  transform: translateY(-50%);

  background: #ffffff;
  padding: 15px 15px 15px 25px;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // transition: 0.3s ease;
  z-index: 2;

  animation-duration: 0.3s;
  animation-name: ${fadein};

  &:hover {
    opacity: 1;
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-bottom: -5px;
    bottom: 50%;
    left: 0px;
    box-sizing: border-box;
    border: 10px solid black;
    border-color: transparent transparent #ffffff #ffffff;

    transform-origin: 0 0;
    transform: rotate(45deg);
  }

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-bottom: -5.5px;
    bottom: 50%;
    left: -1px;
    box-sizing: border-box;
    border: 11px solid black;
    border-color: transparent transparent #c4c4c4 #c4c4c4;

    transform-origin: 0 0;
    transform: rotate(45deg);

    box-shadow: -2px 3px 3px 0px rgba(0, 0, 0, 0.2);
  }
`;

const Title = styled.h4`
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
`;

const SubTitle = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  margin: 10px 0;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const OverrideBox = styled.div``;

const Btn = styled.div`
  flex: 0 0 80px;
  width: 80px;
  margin-right: 10px;

  & button {
    height: 30px;
    font-weight: 600;
    font-size: 12px;
  }
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
`;

const Bold = styled(Text)`
  display: flex;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;

  & > div {
    margin-left: 6px;
  }
`;

export default EventInfoWindowComponent;
