import React from 'react';
import styled, { css } from 'styled-components';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
  fullWidth?: boolean
  isActive?: boolean
  width?: number
  isTransparent?: boolean
  borderRadius?: number
  isLogInBtn?: boolean
  btnRef?: React.RefObject<HTMLButtonElement>
}

const Button: React.FC<IProps> = ({
  isLoading = false,
  fullWidth = false,
  isActive = true,
  children,
  width = 184,
  isTransparent = false,
  borderRadius = 4,
  isLogInBtn = false,
  btnRef = null,
  onClick,
}) => (
  <ButtonStyled
    onClick={onClick}
    fullWidth={fullWidth}
    isActive={isActive}
    width={width}
    isTransparent={isTransparent}
    borderRadius={borderRadius}
    isLogInBtn={isLogInBtn}
    isLoading={isLoading}
    ref={btnRef}
  >
    {/* {isLoading ? 'Loading...' : children} */}
    {children}
  </ButtonStyled>
);

interface IButton {
  fullWidth: boolean
  isActive: boolean
  width: number
  isTransparent: boolean
  borderRadius: number
  isLogInBtn: boolean
  isLoading: boolean
}

const ButtonStyled = styled.button<IButton>`
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: linear-gradient(0deg, #0066ff 0%, #3888ff 78.65%, #0066ff 100%);
  border-radius: 4px;
  padding: 10px;

  // font-weight: 700;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  text-transform: capitalize;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease-in;

  &:hover {
    // background: red;
  }

  ${({ isActive, isLoading }) => (!isActive || isLoading)
    && css`
      background: linear-gradient(
        0deg,
        rgba(0, 102, 255, 0.5) 0%,
        rgba(56, 136, 255, 0.5) 78.65%,
        rgba(0, 102, 255, 0.5) 100%
      );
      cursor: not-allowed;
      pointer-events: none;
    `};

  ${({ isTransparent }) => isTransparent
    && css`
      background: #ffffff;
      border: 1px solid #c4c4c4;
      color: #4a4949;
      font-weight: 500;
      font-size: 14px;
      transition: 0.3s ease-in;

      &:hover {
        background: ${({ theme }) => theme.palette.lightGray};
        border: ${({ theme }) => `1px solid ${theme.palette.main}`};
        color: ${({ theme }) => theme.palette.main};
        transition: none;
      }
    `};

    ${({ isTransparent, isActive }) => isTransparent && isActive
    && css`
      border: 1px solid #0066FF;
      color: #0066FF;
    `};
`;

export default Button;
