import React from 'react';
import styled, { css } from 'styled-components';
import { Moment } from 'moment';

import { isSameDayWithToday } from '@/utils/date';

interface Props {
  date: Moment;
  selectedDate: null | Moment;
  currentDate: Moment;
  minFromDate?: Moment;
  onClick: (date: Moment) => void;
  isFromDate: boolean;
  tempFromDate: Moment | null;
  tempTillDate: Moment | null;
  todayDate: Moment;
  isTillToday?: boolean;
  isFromToday?: boolean;
  isDisableNextMonth?: boolean;
}

const Day: React.FC<Props> = ({
  currentDate,
  date,
  selectedDate,
  minFromDate,
  onClick,
  isFromDate,
  tempFromDate,
  tempTillDate,
  todayDate,
  isTillToday,
  isFromToday,
  isDisableNextMonth = false,
}) => {
  const disableDays = React.useCallback((): boolean => {
    const formatDate = date;

    if (isTillToday) {
      if (todayDate.utc().unix() < formatDate.unix()) {
        return true;
      }
    }

    if (isFromToday) {
      if (todayDate.utc().unix() > formatDate.unix()) {
        return true;
      }
    }

    if (isFromDate) {
      if ((tempTillDate && (tempTillDate.unix() < formatDate.unix()))
        || (tempFromDate && minFromDate && (formatDate.unix() < minFromDate.unix()))
      ) {
        return true;
      }
    }

    if (!isFromDate) {
      if (tempFromDate && (tempFromDate.unix() > formatDate.unix())) {
        return true;
      }
    }
    return false;
  }, [date, isFromDate, isTillToday, isFromToday]);

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    if (!disableDays()) onClick(date);
  };
  return (
    <StyledDay onClick={handleClick} disabled={isDisableNextMonth && !date.isSame(currentDate, 'month')}>
      <Text
        isDisabled={disableDays()}
        active={isSameDayWithToday(date)}
        muted={isDisableNextMonth ? !date.isSame(currentDate, 'month') : disableDays()}
        selected={selectedDate !== null && date.isSame(selectedDate, 'day')}
      >
        {date.date()}
      </Text>
    </StyledDay>
  );
};

const StyledDay = styled.button`
  text-align: center;
  cursor: pointer;
  padding: 11px 0;
  position: relative;
  outline: none;
  user-select: none;
  height: 36px;
  width: calc(100% / 7);
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    cursor: default;
  }
`;

const Text = styled.p<{ active: boolean; muted: boolean; selected: boolean; isDisabled: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  width: 100%;
  outline: none;
  user-select: none;

  :hover {
      height: 32px;
      max-width: 32px;
      min-width: 32px;
      width: 100%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
    }

  ${({ active }) => active
    && css`
      color: #FF474D;
    `};

  ${({ muted }) => muted
    && css`
      color: #BDBDBD;

      :hover { 
        background-color: transparent;
      }
    `};

    ${({ selected }) => selected
    && css`
      height: 32px;
      max-width: 32px;
      min-width: 32px;
      width: 100%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #0074ffb0;

      :hover { 
        background-color: #0074ffb0;
      }
    `};
`;

export default Day;
