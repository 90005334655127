import React from 'react';

interface Props {
  width?: number | string;
  height?: number | string;
}

const ArrowFullIcon: React.FC<Props> = ({
  width = '12',
  height = '6',
}) => (
  <svg width={width} height={height} viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.99951 5.99951L0.80336 -0.000488281L11.1957 -0.000488281L5.99951 5.99951Z" fill="#D9D9D9" />
  </svg>
);

export default ArrowFullIcon;
