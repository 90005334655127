import { createActionCreators } from 'immer-reducer';

// import TokensLocalStorage from '@/local-storage/TokensLocalStorage';
import { AsyncAction } from '@/store/actions/common';
import { UserReducer } from '@/store/reducers/user';
import { loginActions, logout } from '@/store/actions/login';
import { RequestState } from '@/store/reducers/common';

import {
  IAddUser, IUser,
} from '@/api/main-protected';
import { createMessageToast } from '@/utils/toast';
import { ToastIcon } from '@/interfaces/toast';
import { toastActions } from './toast';

export const userActions = createActionCreators(UserReducer);

export type UserActionsType =
    | ReturnType<typeof userActions.setUserInfo>
    | ReturnType<typeof userActions.setUsers>
    | ReturnType<typeof userActions.setUsersStatic>
    | ReturnType<typeof userActions.setAddUserState>
    | ReturnType<typeof userActions.setDeleteUserState>
    | ReturnType<typeof userActions.setDepartments>
    | ReturnType<typeof userActions.setDepartmentsState>
    | ReturnType<typeof loginActions.setLoginState>
    | ReturnType<typeof loginActions.setIsLoading>;

export const getUserInfo = (): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(loginActions.setLoginState(RequestState.LOADING));
    // const storage = TokensLocalStorage.getInstance();
    // const refreshToken = storage.getRefreshToken();

    // if (!refreshToken) {
    //   dispatch(logout());
    //   return;
    // }

    // dispatch(loginActions.setIsLoggedIn(true));
    // dispatch(loginActions.setIsLoading(true));

    const response = await mainProtectedApi.getUserInfo();

    // // for test role=user
    // response.user.roles[0] = ROLES.USER;

    dispatch(userActions.setUserInfo(response.user));
    dispatch(loginActions.setLoginState(RequestState.LOADED));
  } catch (e) {
    dispatch(loginActions.setLoginState(RequestState.ERROR));
    dispatch(logout());
  }
};

export const updateUserInfo = (updatedUser: IUser): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(loginActions.setIsLocalLoading(true));

    await mainProtectedApi.updateUserInfo(updatedUser);

    dispatch(userActions.setUserInfo(updatedUser));
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(loginActions.setIsLocalLoading(false));
  }
};

export const getUsers = (): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    const response = await mainProtectedApi.getUsers();

    dispatch(userActions.setUsers(response.users));
    dispatch(userActions.setUsersStatic(response.statistic));
  } catch (e) {
    console.error(e);
  }
};

export const addUser = (body: IAddUser): AsyncAction => async (
  dispatch,
  getState,
  { mainProtectedApi },
) => {
  try {
    dispatch(userActions.setAddUserState(RequestState.LOADING));

    await mainProtectedApi.addUser(body);

    dispatch(userActions.setAddUserState(RequestState.LOADED));
  } catch (e: any) {
    dispatch(userActions.setAddUserState(RequestState.ERROR));
    const toast = createMessageToast({
      icon: ToastIcon.Error,
      header: e.response.data.data.message,
    });
    dispatch(toastActions.addToast(toast));
    throw new Error(e.response.data.data.message);
  }
};

export const deleteUser = (id: string): AsyncAction => async (
  dispatch,
  getState,
  { mainProtectedApi },
) => {
  try {
    dispatch(userActions.setDeleteUserState(RequestState.LOADING));

    await mainProtectedApi.deleteUser(id);

    const { UserReducer: { users } } = getState();

    const updatedUsers = users!.filter((user) => user.user_id !== id);

    dispatch(userActions.setUsers(updatedUsers));

    dispatch(userActions.setDeleteUserState(RequestState.LOADED));
  } catch (e) {
    dispatch(userActions.setDeleteUserState(RequestState.ERROR));
  }
};

export const getUserDepartments = (): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(userActions.setDepartmentsState(RequestState.LOADING));

    const response = await mainProtectedApi.getUserDepartments();

    dispatch(userActions.setDepartments(response.departments));
    dispatch(userActions.setDepartmentsState(RequestState.LOADED));
  } catch (e) {
    dispatch(userActions.setDepartmentsState(RequestState.ERROR));
  }
};
