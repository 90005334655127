import auth0 from 'auth0-js'

export const AUTH0_DOMAIN=process.env.A0_DOMAIN
export const AUTH0_CLIENT_ID=process.env.A0_CLIENT_ID
export const AUTH0_LOGIN_REDIRECT_URI=process.env.A0_LOGIN_REDIRECT_URI
export const AUTH0_LOGOUT_URI=process.env.A0_LOGOUT_URI

export const AUTH0_REALM='Username-Password-Authentication' // set custom database
export const AUTH0_USER_SCOPE='openid email profile'
export const AUTH0_LOGIN_RESPONSE_TYPE='token'

export const auth = new auth0.WebAuth({
  domain: AUTH0_DOMAIN!,
  clientID: AUTH0_CLIENT_ID!,
  scope: AUTH0_USER_SCOPE
})
