import React from 'react';
import styled from 'styled-components';

import useOnClickOutside from '@/Components/hooks/useOnClickOutside';
import SuccessIcon from '@/Components/icons/SuccessIcon';
import IconComponent from '@/Components/common/IconComponent';
import Btn from '@/Components/common/Signin/Btn';

import closeIcon from '@/assets/images/icons/close-black-icon.svg';

import customScrollCss from '@/utils/custom-scroll-css';

interface IModal {
  title?: string,
  message?: string,
  onCloseModal: () => void
}

const defaultTitle = 'Add a User Confirmation';
const defaultMessage = 'New user successfully created!';

const ModalSuccessfuly: React.FC<IModal> = ({
  title = defaultTitle,
  message = defaultMessage,
  onCloseModal,
}) => {
  const modalRef = useOnClickOutside(onCloseModal);

  return (
    <Layout>
      <ModalWrap ref={modalRef}>
        <CloseIconBox onClick={onCloseModal}>
          <IconComponent icon={closeIcon} width={20} />
        </CloseIconBox>
        <Title>{title}</Title>
        <SuccessIcon />
        <SubTitle>{message}</SubTitle>
        <BtnWrap>
          <Btn onClick={onCloseModal} isTransparent isActive isLogInBtn>
            Close
          </Btn>
        </BtnWrap>
      </ModalWrap>
    </Layout>
  );
};

const Layout = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
const ModalWrap = styled.div`
  width: 513px;
  height: 386px;
  background: #ffffff;
  border-radius: 10px;
  padding: 35px 40px 30px;
  // margin: 90px auto 30px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;

  position: relative;

  @media (max-width: 725px) {
    min-width: 100%;
  }

  ${customScrollCss}
`;

const BtnWrap = styled.div`
  margin-top: auto;
  width: 95px;
`;

const Title = styled.div`
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  color: #000000;
`;

const SubTitle = styled.div`
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #000000;
`;

const CloseIconBox = styled.div`
  position: absolute;
  top: 35px;
  right: 35px;
  // width: 20px;
  // height: 20px;
  cursor: pointer;
`;

export default ModalSuccessfuly;
