import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import Sidebar from '@/Components/Sidebar/Sidebar';
import Main, { PAGES } from '@/Components/Main/Main';
import Modals from '@/Components/common/Modals';

import useToggle from '@/Components/hooks/useToggle';

const AdminPage: React.FC = () => {
  const [isExpanded, setIsExpanded] = useToggle(true);
  const history = useHistory();

  const handleLogoClick = () => {
    history.push(PAGES.CONTROL_CENTER);
  };

  return (
    <Container>
      <Sidebar
        isExpanded={isExpanded}
        handleLogoClick={handleLogoClick}
        handleBtnClick={setIsExpanded}
      />
      <Main isExpanded={isExpanded} />
      <Modals />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  // max-width: 1440px; // !!! with container
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  padding-right: 55px;

  @media (max-width: 1430px) {
    padding-right: 0;
  }
`;

export default AdminPage;
