import { createSelector, Selector } from 'reselect';
import { State } from '@/store';
import { IThemeState, THEMES } from '@/store/reducers/theme';

const selectTheme = (state: State) => state.themeReducer;

export const selectThemeState: Selector<State, IThemeState> = createSelector(
  selectTheme,
  (themeState) => themeState,
);

export const selectCurrentTheme: Selector<State, THEMES> = createSelector(
  selectTheme,
  ({ theme }) => theme,
);
