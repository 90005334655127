import { createReducerFunction, ImmerReducer } from 'immer-reducer';
import { IItemsFilters, ITicket, ITicketBody } from '@/api/main-protected';
import { RequestState } from '@/store/reducers/common';

export interface InitState {
  tickets: ITicket[]
  ticket: ITicketBody | null
  ticketsTotal: number
  ticketsFilters: IItemsFilters
  ticketsState: RequestState
  ticketState: RequestState
  ticketsTotalState: RequestState
  ticketsFiltersState: RequestState
  ticketsPaginationState: RequestState
}

const initialState: InitState = {
  tickets: [],
  ticket: null,
  ticketsTotal: 0,
  ticketsFilters: {},
  ticketsFiltersState: RequestState.IDLE,
  ticketsState: RequestState.IDLE,
  ticketState: RequestState.IDLE,
  ticketsTotalState: RequestState.IDLE,
  ticketsPaginationState: RequestState.IDLE,
};

export class SupportReducer extends ImmerReducer<InitState> {
  setTickets(tickets: ITicket[]) {
    this.draftState.tickets = tickets;
  }

  setTicketsState(ticketsState: RequestState) {
    this.draftState.ticketsState = ticketsState;
  }

  setTicket(ticket: ITicketBody | null) {
    this.draftState.ticket = ticket;
  }

  setTicketState(ticketState: RequestState) {
    this.draftState.ticketState = ticketState;
  }

  setTicketsFilters(ticketsFilters: IItemsFilters) {
    this.draftState.ticketsFilters = ticketsFilters;
  }

  setTicketsFiltersState(ticketsFiltersState: RequestState) {
    this.draftState.ticketsFiltersState = ticketsFiltersState;
  }

  setTicketsTotal(ticketsTotal: number) {
    this.draftState.ticketsTotal = ticketsTotal;
  }

  setTicketsTotalState(ticketsTotalState: RequestState) {
    this.draftState.ticketsTotalState = ticketsTotalState;
  }

  setTicketsPaginationState(ticketsPaginationState: RequestState) {
    this.draftState.ticketsPaginationState = ticketsPaginationState;
  }
}

export default createReducerFunction(SupportReducer, initialState);
