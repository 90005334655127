import {
  defaultToastOptions,
} from '@/store/reducers/toast';

import {
  IActionToast,
  IMessageToast,
  IStatusToast,
  IToastOptions,
  ToastType,
} from '@/interfaces/toast';

export const createActionToast = (context: IActionToast, options?: IToastOptions) => {
  const { duration, theme } = { ...defaultToastOptions, ...options };
  return {
    type: ToastType.Action,
    context,
    duration,
    theme,
  };
};

export const createMessageToast = (context: IMessageToast, options?: IToastOptions) => {
  const { duration, theme } = { ...defaultToastOptions, ...options };
  return {
    type: ToastType.Message,
    context,
    duration,
    theme,
  };
};

export const createStatusToast = (context: IStatusToast, options?: IToastOptions) => {
  const { duration, theme } = { ...defaultToastOptions, ...options };
  return {
    type: ToastType.Status,
    context,
    duration,
    theme,
  };
};
