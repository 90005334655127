import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import customScrollCss from '@/utils/custom-scroll-css';

import useOnClickOutside from '@/Components/hooks/useOnClickOutside';
import { IAsset, Request } from '@/api/main-protected';
import { selectIsLocalLoading } from '@/store/selectors/login';

import closeIcon from '@/assets/images/icons/close-black-icon.svg';

import IconComponent from '@/Components/common/IconComponent';
import LoaderDots from '@/Components/LoaderDots';

interface IModal {
  // item: IAsset
  item: IAsset
  request?: Request
  onCloseModal: () => void
}

const ModalItem: React.FC<IModal> = ({ item, request, onCloseModal }) => {
  const editModalClickOutsideRef = useOnClickOutside(onCloseModal);
  const isLocalLoading = useSelector(selectIsLocalLoading);

  return (
    <Layout>
      {isLocalLoading ? (
        <LoaderDots />
      ) : (
        <ModalWrap ref={editModalClickOutsideRef}>
          <>
            <Title>{`${item.make_model.manufacturer} - ${item.serial_number}`}</Title>
            <Main>
              <>
                <Row>
                  <Column>
                    <Label>MAKE</Label>
                    <Text>{item.make_model.manufacturer || '-'}</Text>
                  </Column>
                  <Column>
                    <Label>MODEL</Label>
                    <Text>{item.make_model.model || '-'}</Text>
                  </Column>
                  <Column>
                    <Label>SERIAL NUMBER</Label>
                    <Text>{item.serial_number || '-'}</Text>
                  </Column>
                  <Column>
                    <Label>STATUS</Label>
                    <Text>{item.status || '-'}</Text>
                  </Column>
                  <Column>
                    <Label>ASSOCIATED REQUEST</Label>
                    {!item.requests.length && <Text>-</Text>}
                    {item.requests.map((req) => req?.itsm_id
                      && <Text key={req?.itsm_id} isBlue>{req?.itsm_id}</Text>)}
                  </Column>
                </Row>
              </>
            </Main>
            <CloseIconBox onClick={onCloseModal}>
              <IconComponent icon={closeIcon} width={20} />
            </CloseIconBox>
          </>
        </ModalWrap>
      )}
    </Layout>
  );
};

const Layout = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
const ModalWrap = styled.div`
  max-width: 975px;
  min-width: 675px;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding: 35px 40px 30px;
  // margin: 90px auto 30px;
  overflow-y: auto;

  position: relative;

  @media (max-width: 725px) {
    min-width: 100%;
  }

  ${customScrollCss}
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #000000;
  padding-right: 40px;
`;

const Main = styled.div`
  margin: 25px 0 0;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #c4c4c4;
  padding: 30px 0;
`;

const Column = styled.div`
  &:not(:last-child) {
    margin-right: 4.5vw;
  }
`;
const Label = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding: 0 0 20px 0;
`;
const Text = styled.p<{ isBlue?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ isBlue }) => (isBlue ? '#0066FF' : '#000000')};
  text-transform: capitalize;
`;

const CloseIconBox = styled.div`
  position: absolute;
  top: 35px;
  right: 35px;
  // width: 20px;
  // height: 20px;
  cursor: pointer;
`;

export default ModalItem;
