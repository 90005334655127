import React, {
  Fragment, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import axios from 'axios';

import ContentContainer from '@/Components/common/ContentContainer/ContentContainer';
import Btn from '@/Components/common/Signin/Btn';
import {
  Column, Columns, EmptyList, mainBlockHeight, TitleGroup,
} from '@/Components/ControlCenter/ControlCenter';
import NewIssueModal from '@/Components/Support/NewIssueModal';
import { IVariant } from '@/Components/common/DropDown';
import LoadingContainer from '@/Components/common/LoadingContainer';
import TicketCellComponent from '@/Components/Support/TicketCell';
import TicketComponent from '@/Components/Support/Ticket';
import PaginationSelect from '@/Components/Items/ItemsTable/PaginationSelect';
import Pagination from '@/Components/Items/ItemsTable/Pagination';
import FilterToggleComponent from '@/Components/common/FilterToggle';
import ModalSuccessfuly from '@/Components/Requests/ModalSuccessfuly';
// import SupportTabs from './SupportTabs';

import { IItemsFilters, ITicket, ITicketBody, ITicketComment, ITicketCreate, ITicketUpdate } from '@/api/main-protected';
import { selectRequestsInterval } from '@/store/selectors/events';
import getUpdated from '@/utils/getUpdated';
import groupedItemsByDate from '@/utils/groupedItemsByDate';
import formatTitleDate from '@/utils/formatTitleDate';
import customScrollCss from '@/utils/custom-scroll-css';
import { assetsPerPage } from '@/utils/api';
import { isEmptyObject } from '@/utils/validation';
// import { ITicket } from '@/api/main-protected';
import useToggle from '@/Components/hooks/useToggle';
import { IMultiItemsFiltersLabel } from '@/Components/Items/ItemsTable/TableContainer';

import attachmentImage from '@/assets/images/attachment.png';
import TableFilters, { IDateFilter, IDateFilters } from '../Items/ItemsTable/TableFilters';
import { RequestOptions, RequestState } from '@/store/reducers/common';
import { sort, SortOrder, SortType } from '@/utils/sortUtil';
import { ITableBody } from '@/interfaces/api';
import { AppDispatch } from '@/App';
import { addTicket, getTicket, getTickets, updateTicket } from '@/store/actions/support';
import TokensLocalStorage from '@/local-storage/TokensLocalStorage';
import useInput from '../hooks/useInput';
import { selectTickets, selectTicket, selectTicketState, selectTicketsState } from '@/store/selectors/support';

export const TABS = [
  {
    id: 'active-tab',
    tab: 1,
    title: 'Active',
    isActive: true,
  },
  {
    id: 'inactive-tab',
    tab: 2,
    title: 'Resolved',
    isActive: false,
  },
];

const fakeFilters: IItemsFilters = {
  phases: ['needs verifying', 'in deploy', 'in transit'],
};

const dateFilters: IDateFilters = {
  date_opened: {
    startDate: null,
    endDate: null,
    title: 'Date Opened',
  },
  date_closed: {
    startDate: null,
    endDate: null,
    title: 'Date Closed',
  },
  action_date: {
    startDate: null,
    endDate: null,
    title: 'Action Date',
  },
};

const paginDefaultPageNumber = 1;

interface ISuccessModel {
  title: string,
  message: string,
}

const Support: React.FC = () => {
  const [tabs, setTabs] = useState(TABS);
  const [activeTab, setActiveTab] = useState<number>(TABS[0].tab);
  const [activeTicket, setActiveTicket] = useState<ITicket | null>(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [type, setType] = useState<IVariant | null>(null);
  const [priority, setPriority] = useState<IVariant | null>(null);
  const [description, setDescription] = useState<string>('');
  const [attachments, setAttachments] = useState<any>([]);
  const [subject, setSubject] = useInput('');
  const [comment, setComment] = useState<string>('');
  const [isDisabled, setDisabled] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isAutoLoaded, setIsAutoLoaded] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [successModel, setSuccessModel] = useState<ISuccessModel | null>(null);
  const [itemsPerPage, setItemsPerPage] = useState<number>(assetsPerPage);
  const [currentPage, setCurrentPage] = useState<number>(paginDefaultPageNumber);
  const [isFilterActive, setIsFilterActive] = useToggle();
  const [savedFilters, setSavedFilters] = useState<{ [key: string]: IVariant }>({});
  const [choosenFilters, setChoosenFilters] = useState<{ [key: string]: IVariant }>({});
  const [
    choosenMultiItemsFilters,
    setChoosenMultiItemsFilters,
  ] = useState<{ [key: string]: string }>({});
  const [
    choosenDateFilters,
    setChoosenDateFilters,
  ] = useState<{ [key: string]: IDateFilter }>(dateFilters);

  const ticketsFilters = fakeFilters; // useSelector(selectRequestFilters);
  const itemsFiltersState = RequestState.LOADED; // useSelector(selectRequestFiltersState);
  const interval = useSelector(selectRequestsInterval);
  const ticketsTotal = 100;
  const isTicketsTotalLoading = false;
  const isTicketsPaginationLoading = false;
  const isItemsFilterLoading = itemsFiltersState !== RequestState.LOADED;

  const filtersLabel: IMultiItemsFiltersLabel = {
    phases: { title: 'Phases', isShowAll: true },
    statuses: { title: 'Status' },
    // locations: { title: 'Locations' },
    // employee_ids: { title: 'Employee ID' },
    // categories: { title: 'Category' },
    // departments: { title: 'Department' },
  };

  const dispatch = useDispatch<AppDispatch>();

  const getTicketss = (body: ITableBody, option?: RequestOptions) => {
    dispatch(getTickets({ ...body, ...{ interval } }, option));
  };

  // const setActiveTicketBody = (ticket: ITicket) => {
  //   dispatch(getTicket(ticket.id));
  // };

  useEffect(() => {
    if (activeTicket) {
      // console.log(tickets);
      setIsAutoLoaded(false);
      setAttachments([]);
      setComment('');
      dispatch(getTicket(activeTicket!.id));
    }
  }, [activeTicket]);

  const items = useSelector(selectTickets);
  const ticketsState = useSelector(selectTicketsState);
  const activeTicketBody = useSelector(selectTicket);
  const ticketState = useSelector(selectTicketState);

  useEffect(() => {
    getTicketss({});
  }, []);

  const tickets = useMemo(() => items!
    .filter((ticket) => (ticket.status === 'solved' || ticket.status === 'closed') === (TABS[1].tab === activeTab))
    .map((ticket) => ({
      date: (ticket.created_at).toString(),
      item: ticket,
    })), [activeTab, items]);

  useEffect(() => {
    if (!activeTicket && tickets && tickets!.length > 0) {
      // console.log(tickets);
      dispatch(getTicket(tickets![0].item.id));
      setIsAutoLoaded(true);
    }
  }, [tickets]);

  const sortedFilters = useMemo(() => {
    const arrFilters = Object.entries(ticketsFilters).map(
      ([key, value]) => [key, sort(
        value,
        (sortedValue) => sortedValue,
        SortOrder.ASCENDING,
        SortType.String,
      ).filter((item) => !!item)],
    );
    return Object.fromEntries(arrFilters);
  }, [ticketsFilters]);

  // useEffect(() => {
  //   if (!isSuccess) return;

  //   const timer = setTimeout(() => {
  //     setIsSuccess(false);
  //   }, 3000);

  //   return () => clearTimeout(timer);
  // }, [isSuccess]);

  const groupedTicketsByDate = groupedItemsByDate(tickets, interval);

  const appliedFiltersAmount = 0;
  // const appliedFiltersAmount = (
  //   Object.keys(savedFilters).length
  //   + Object.keys(savedMultiItemsFilters).length
  //   + Object.entries(savedDateFilters)
  //     .filter(([, { startDate, endDate }]) => !!startDate || !!endDate).length
  // );

  const isShowFilters = useMemo(() => !!Object.entries(ticketsFilters).length, [ticketsFilters]);
  // const isShowFilters = useMemo(() => !!Object.entries(itemsFilters).length
  // || !!Object.entries(multiItemsFilters).length
  // || !!Object.entries(dateFilters).length, [itemsFilters, dateFilters, multiItemsFilters]);

  const handleClick = (itemStep: number) => {
    const updatedTabs = tabs.map((tabItem) => ({
      ...tabItem,
      isActive: itemStep === tabItem.tab,
    }));

    setTabs(updatedTabs);
    setActiveTab(itemStep);
    setActiveTicket(null);
    setAttachments([]);
    setComment('');
  };

  const handleCurrentPage = (page: number) => {
    console.log('page # ', page);

    // handleGetItems(
    //   savedFilters,
    //   savedDateFilters,
    //   savedMultiItemsFilters,
    //   sortColumn,
    //   itemsPerPage,
    //   page,
    // );
  };

  const handleOnFilterClick = () => {
    setIsFilterActive(!isFilterActive);
    // setChoosenFilters(savedFilters);
    // setChoosenDateFilters(savedDateFilters);
    // setChoosenMultiItemsFilters(savedMultiItemsFilters);
  };

  const handleSetItemsPerPage = (updatedTicketsPerPage: number) => {
    setItemsPerPage(updatedTicketsPerPage);

    console.log(`new req for ${updatedTicketsPerPage} tickets was made`);

    // handleGetItems(
    //   savedFilters,
    //   savedDateFilters,
    //   savedMultiItemsFilters,
    //   sortColumn,
    //   updatedItemsPerPage,
    //   1,
    //   false,
    //   true,
    // );
  };

  // useEffect(() => {
  //   getTicketss({}); // TODO: this
  // });

  const handleSuccess = () => {
    setIsShowModal(false);
    setAttachments([]);
    setIsSuccess(true);
  };

  const handleCancelClick = () => {
    if (appliedFiltersAmount) {
      setSavedFilters({});
      // setSavedDateFilters(dateFilters);
      // setSavedMultiItemsFilters({});

      // handleGetItems({}, dateFilters, {}, sortColumn, itemsPerPage, 1, true);
    }

    setIsFilterActive(false);
    setAttachments([]);
  };

  const handleApplyClick = () => {
    console.log('apply was clicked');

    // const cleanMultiFilters = Object.fromEntries(
    //   Object.entries(choosenMultiItemsFilters)
    //     .map(([key, values]) => [key, values.split(', ')
    //       .filter((value) => multiItemsFilters[key]
    //         .some((filter) => value.replaceAll('٫', ',') === filter)).join(', ')])
    //     .filter(([, value]) => !!value),
    // );

    // const cleanFilters = Object.fromEntries(
    //   Object.entries(choosenFilters)
    //     .filter(([, { id }]) => id !== DefaultFilter.All),
    // );

    // setSavedFilters(cleanFilters);
    // setSavedDateFilters(choosenDateFilters);
    // setSavedMultiItemsFilters(cleanMultiFilters);

    // handleGetItems(
    //   cleanFilters,
    //   choosenDateFilters,
    //   cleanMultiFilters,
    //   sortColumn,
    //   itemsPerPage,
    //   1,
    //   true,
    // );

    setIsFilterActive(false);
  };

  const onChoose = (key: string) => (v: IVariant | null) => {
    console.log('onChoose');
    if (!v) {
      return;
    }

    setChoosenFilters((filter) => ({ ...filter, [key]: v }));
  };

  const onDateChoose = (key: string) => (dateFilter: IDateFilter) => {
    console.log('onDateChoose');

    setChoosenDateFilters((filter) => ({ ...filter, [key]: dateFilter }));
  };

  const onMultiItemsChoose = (key: string) => (value: string) => {
    console.log('onMultiItemsChoose', onMultiItemsChoose);
    // setChoosenMultiItemsFilters((filter) => ({ ...filter, [key]: value }));
  };

  // console.log('isFilterActive', isFilterActive);

  const uploadAttachment = async (file: File, callback: Function) => {
    try {
      const storage = TokensLocalStorage.getInstance();
      const token = storage.getAccessToken();
      const instance = axios.create();
      const formData = new FormData();
      formData.append('file', file);
      await instance.post(
        `${process.env.BASE_URL}/zendesk/attachment`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` } },
      )
        .then((res) => {
          callback(res.data);
        })
        .catch((error) => {
          console.warn('Upload Attachment error');
          console.error(error);
          return null;
        });
    } catch (error) {
      console.error(error);
      throw new Error('Attachment Upload Failed!');
    }
  };

  const handleAttachment = async (e: any) => {
    const filesArray = [...e.target.files];
    if (filesArray.length > 0) {
      setIsUploading(true);
      filesArray.forEach(async (file: any, idx: number) => {
        await uploadAttachment(file, (response: any) => {
          setAttachments((prevAttachments: any) => ([...prevAttachments, {
            src: URL.createObjectURL(file),
            alt: file.name,
            file,
            name: file.name,
            upload: response.data.upload,
          }]));
        });
        if (idx === filesArray.length - 1) setIsUploading(false);
      });
    }
  };

  const deleteAttachment = (e: any) => {
    const filtred = attachments.filter((_item: any, index: any) => index !== e);
    setAttachments(filtred);
  };

  const resetForm = () => {
    setType(null);
    setPriority(null);
    setSubject('');
    setDescription('');
    setAttachments([]);
  };

  const handleTicketSubmit = async () => {
    if (!type || !priority || !description || !subject) return;
    setDisabled(true);
    setSuccessModel({
      title: 'New Ticket Confirmation',
      message: 'Your ticket has been submitted! Processing this ticket may take a few minutes',
    });
    const uploads: string[] = [];
    attachments.forEach((attachment: { upload: { token: any; }; }) => {
      uploads.push(attachment.upload.token);
    });
    const body: ITicketCreate = {
      type: type!.title.toLowerCase() || 'problem',
      priority: priority!.title.toLowerCase() || 'normal',
      description,
      subject,
      comment: {
        body: description,
        uploads,
      },
    };
    try {
      await dispatch(addTicket(body));
      await dispatch(getTickets({}));
      setIsShowModal(false);
      resetForm();
      setIsSuccess(true);
    } catch (error) {
      console.error(error);
    }
    setDisabled(false);
  };

  const handleCommentSubmit = async (id: number) => {
    if (!comment) return;
    setDisabled(true);
    setSuccessModel({
      title: 'Comment Confirmation',
      message: 'You comment has been submitted!',
    });
    const uploads: string[] = [];
    attachments.forEach((attachment: { upload: { token: any; }; }) => {
      uploads.push(attachment.upload.token);
    });
    const body: ITicketUpdate = {
      priority: activeTicketBody!.priority.toLowerCase(),
      subject: activeTicketBody!.subject,
      comment: {
        body: comment,
        uploads,
      },
    };
    try {
      await dispatch(updateTicket(id, body));
      await dispatch(getTickets({}));
      setAttachments([]);
      setComment('');
      setIsSuccess(true);
      if (activeTicket) dispatch(getTicket(activeTicket!.id));
    } catch (error) {
      console.error(error);
    }
    setDisabled(false);
  };

  return (
    <>
      <ContentContainer title="Support" updated={getUpdated(window.Date())}>
        <Header>
          <Tabs>
            {tabs.map((tab) => (
              <TabWrap key={tab.tab} isActive={tab.isActive} onClick={() => handleClick(tab.tab)}>
                <Tab>{tab.title}</Tab>
              </TabWrap>
            ))}
          </Tabs>
          <NewIssueBtn>
            <Btn onClick={() => setIsShowModal(true)}>New Support Request</Btn>
          </NewIssueBtn>
        </Header>
        {/* <FilterWrap>
          <PaginationSelectWrap>
            <PaginationSelect
              value={itemsPerPage}
              onChange={handleSetItemsPerPage}
            />
          </PaginationSelectWrap>
          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            maxTotal={ticketsTotal}
            setCurrentPage={handleCurrentPage}
            isLoading={isTicketsTotalLoading}
            isEmpty={!tickets.length}
            isArrowLoading={isTicketsPaginationLoading}
          />
          {isShowFilters && (
          <FilterToggleComponent
            onFilterIconClick={handleOnFilterClick}
            isFilterActive={isFilterActive}
            isActiveFilter={isEmptyObject(savedFilters)}
            appliedFiltersAmount={appliedFiltersAmount}
          />
          )}
        </FilterWrap> */}
        {/* {isFilterActive && (
          <TableFilters
            isLoading={isItemsFilterLoading}
            handleCancelClick={handleCancelClick}
            handleApplyClick={handleApplyClick}
            filtersLabel={{}/* filtersLabel }
            itemsFilters={ticketsFilters}
            choosenFilters={choosenFilters}
            onChoose={onChoose}
            onDateChoose={onDateChoose}
            dateFilters={choosenDateFilters}
            multiItemsFiltersLabel={filtersLabel}
            multiItemsFilters={sortedFilters}
            onMultiItemsChoose={onMultiItemsChoose}
            choosenMultiItemsFilters={choosenMultiItemsFilters}
          />
        )} */}
        <Columns>
          <Column width="450px">
            <LoadingContainer
              isLoading={
                ticketsState !== RequestState.LOADED
              }
            >
              {tickets && tickets.length > 0 ? (
                groupedTicketsByDate!.map((group, k) => (
                  <Fragment key={group[0]}>
                    <TitleGroup>
                      <DateCell>
                        {formatTitleDate(group[0])}
                      </DateCell>
                      <DateCell>
                        Time
                      </DateCell>
                    </TitleGroup>
                    {group[1].map(({ item }, i) => (
                      <Fragment key={i}>
                        <TicketCellComponent
                          id={i}
                          isAutoLoaded={isAutoLoaded && k === 0}
                          ticket={item as ITicket}
                          activeTicket={activeTicket}
                          ticketState={ticketState}
                          setActiveTicket={setActiveTicket}
                        />
                      </Fragment>

                      // <EventComponent
                      //   key={`${incident.event.event_id}`}
                      //   incident={incident}
                      //   setActiveIncident={setActiveIncident}
                      // />
                    ))}
                  </Fragment>
                ))
              ) : (
                <EmptyList>
                  There are currently no
                  {' '}
                  {activeTab === TABS[0].tab ? 'active' : 'resolved'}
                  {' '}
                  tickets to display
                </EmptyList>
              )}
            </LoadingContainer>
          </Column>
          <TicketColumn>
            <LoadingContainer
              isLoading={
                ticketState === RequestState.LOADING
              }
            >
              {activeTicketBody && (
                <TicketComponent
                  ticket={activeTicketBody}
                  comment={comment}
                  setComment={setComment}
                  isDisabled={isDisabled}
                  deleteAttachment={deleteAttachment}
                  attachments={attachments}
                  handleAttachment={handleAttachment}
                  handleCommentSubmit={handleCommentSubmit}
                />
              )}
            </LoadingContainer>
          </TicketColumn>
          {/* <MapLeafletContainer
          isZoom={isZoom}
          incidents={mapIncidentsWithLatLong}
          activeIncident={activeIncident}
        /> */}
        </Columns>
      </ContentContainer>
      {isShowModal && (
        <NewIssueModal
          description={description}
          setDescription={setDescription}
          type={type!}
          setType={setType}
          priority={priority!}
          setPriority={setPriority}
          subject={subject}
          setSubject={setSubject}
          onCloseModal={() => { setIsShowModal(false); resetForm(); }}
          handleTicketSubmit={handleTicketSubmit}
          handleAttachment={handleAttachment}
          deleteAttachment={deleteAttachment}
          attachments={attachments}
          isDisabled={isDisabled}
          isUploading={isUploading}
        />
      )}
      {isSuccess && (
      <ModalSuccessfuly
        title={successModel!.title}
        message={successModel!.message}
        onCloseModal={() => setIsSuccess(false)}
      />
      )}
    </>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 9px 0 0;
  padding-bottom: 7px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #e5e5e5;
  }
`;
const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const NewIssueBtn = styled.div`
  flex: 0 0 147px;
  width: 147px;

  & button {
    height: 30px;
    font-weight: 600;
    font-size: 12px;
  }
`;
const TabWrap = styled.div<{ isActive: boolean }>`
  flex: 0 0 105px;
  width: 105px;
  height: 38px;
  font-weight: 500;
  color: ${({ isActive }) => (isActive ? '#0066FF' : '#868484')};
  font-size: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  z-index: 1;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 100%;
    height: 3px;
    background: ${({ isActive }) => (isActive ? '#0066FF' : 'transparent')};
  }
`;
const Tab = styled.p``;

const TicketColumn = styled.div<{ isChart?: boolean }>`
  flex: 1 0 ${({ isChart }) => (isChart ? '100%' : 'calc(100% - 450px)')};
  width: ${({ isChart }) => (isChart ? '100%' : 'calc(100% - 450px)')};
  height: ${mainBlockHeight};
  border-left: 1px solid rgba(196, 196, 196, 0.6);
  overflow-y: auto;

  @media (hover: hover) {
    ${customScrollCss}
  }
`;

const DateCell = styled.div`
  margin: 0 50px 0 10px;
`;

const FilterWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
  padding: 10px 0;
`;

const PaginationSelectWrap = styled.div`
  flex-grow: 1;

  & > div {
    display: inline-flex;
  }
`;

export default Support;
