import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import gravatar from 'gravatar';

import { ITicketBody } from '@/api/main-protected';
import convertFromStringToDate, { FormatDate } from '@/utils/convertFromStringToDate';

import addAttachmentIcon from '@/assets/images/add-attachment.svg';

import Btn from '@/Components/common/Signin/Btn';
import defaultProfile from '@/assets/images/icons/user-settings/user-profile-default-icon.svg';
import { selectUserInfo } from '@/store/selectors/user';

interface ITicketComponent {
  ticket: ITicketBody;
  comment: string;
  setComment: (text: string) => void;
  isDisabled: boolean;
  attachments: any[];
  deleteAttachment: (e: any) => void;
  handleAttachment: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCommentSubmit: (id: number) => void
}

const TicketComponent: React.FC<ITicketComponent> = ({
  ticket,
  comment,
  setComment,
  isDisabled,
  attachments,
  deleteAttachment,
  handleAttachment,
  handleCommentSubmit,
}) => {
  // console.log('ITicketBody: ', ticket);
  const user = useSelector(selectUserInfo);

  const hiddenFileInput = React.useRef<any>(null);

  const handleAttachmentUpload = () => {
    if (isDisabled) return;
    hiddenFileInput.current.click();
  };

  const textAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  return (
    <Wrap>
      <Header>
        <Title>{ticket.subject}</Title>
        <Type>{ticket.type}</Type>
        {ticket.priority ? <Priority isUrgent={ticket.priority === 'urgent' || ticket.priority === 'high'}><span>{ticket.priority || ''}</span></Priority> : '' }
      </Header>
      {ticket.comments.length > 0 && (
      <Inner>
        <SubWrap>
          <IconColumn>
            <ProfileBox>
              <img src={gravatar.url(ticket.requester.email, { s: '200', d: 'mp' })} alt={ticket.requester.name || ''} />
            </ProfileBox>
          </IconColumn>
          <UserBox>
            <Row>
              <UserName>{ticket.comments[0].author.name || ''}</UserName>
              <TimeBox>
                {convertFromStringToDate(
                  ticket.comments[0].created_at,
                  FormatDate.ShortMonthFormat,
                )}
                {' '}
                {convertFromStringToDate(
                  ticket.comments[0].created_at,
                  FormatDate.ViewAllEventsFormat,
                )}
              </TimeBox>
            </Row>
            <Block>
              <Box>{ticket.comments[0].body}</Box>
            </Block>
            {ticket.comments[0].attachments.length > 0 && (
            <>
              <Bold>
                Attachment(s)
                {' '}
                {ticket.comments[0].attachments.length}
                :
              </Bold>
              <AttachmentWrap>
                {ticket.comments[0].attachments.map((attach, i) => (
                  <Attachment key={i}>
                    <AttachmentInner>
                      <img 
                        src={attach.content_url}
                        alt={attach.file_name}
                        onClick={() => window.open(attach.content_url, "_blank")}
                      />
                    </AttachmentInner>
                  </Attachment>
                ))}
                {/* <AttachmentAdd>
                  <AttachmentInner>
                    <img src={addImageIcon} alt="add attachment" />
                  </AttachmentInner>
                </AttachmentAdd> */}
              </AttachmentWrap>
            </>
            )}
          </UserBox>
        </SubWrap>
      </Inner>
      )}
      {ticket.comments.length > 0
      && ticket.comments.slice(1).map((cmnt) => (
        <Fragment key={cmnt.created_at}>
          <SubWrap>
            <IconColumn />
            <Inner>
              <Row>
                <IconColumn>
                  <ProfileBox>
                    {/* <img src={cmnt.user.profile} alt={cmnt.user.name} /> TODO */}
                    <img
                      src={gravatar.url(cmnt.author.email, { s: '200', d: 'mp' })}
                      alt={cmnt.author.name || ''}
                    />
                  </ProfileBox>
                </IconColumn>
                <UserBox>
                  <Row>
                    <UserName>{cmnt.author.name || ''}</UserName>
                    <TimeBox>
                      {convertFromStringToDate(cmnt.created_at, FormatDate.ShortMonthFormat)}
                      {' '}
                      {convertFromStringToDate(cmnt.created_at, FormatDate.ViewAllEventsFormat)}
                    </TimeBox>
                  </Row>
                  <Block>
                    <Box>{cmnt.body}</Box>
                  </Block>
                  {cmnt.attachments.length > 0 && (
                  <>
                    <Bold>
                      Attachment(s)
                      {' '}
                      {cmnt.attachments.length}
                      :
                    </Bold>
                    <AttachmentWrap>
                      {cmnt.attachments.map((attach, i) => (
                        <Attachment key={i}>
                          <AttachmentInner>
                            <img
                              src={attach.content_url}
                              alt={attach.file_name}
                              onClick={() => window.open(attach.content_url, '_blank')}
                            />
                          </AttachmentInner>
                        </Attachment>
                      ))}
                      {/* <AttachmentAdd>
                        <AttachmentInner>
                          <img src={addAttachmentIcon} alt="add attachment" />
                        </AttachmentInner>
                      </AttachmentAdd> */}
                    </AttachmentWrap>
                  </>
                  )}
                </UserBox>
              </Row>
            </Inner>
          </SubWrap>
        </Fragment>
      ))}
      <SubWrap>
        <IconColumn />
        <Comment>
          <Row>
            <IconColumn>
              <ProfileBox>
                <img src={user?.picture || defaultProfile} alt={`${user?.first_name} ${user?.last_name}`} />
              </ProfileBox>
            </IconColumn>
            <InputBox>
              <TextArea
                id="comment"
                name="comment"
                value={comment}
                placeholder="Add a comment..."
                onChange={textAreaChange}
                disabled={isDisabled}
              />
            </InputBox>
            <AttachmentAdd isDisabled={isDisabled} onClick={() => handleAttachmentUpload()}>
              <AddIcon src={addAttachmentIcon} />
              <File type="file" multiple accept="image/png, image/jpeg" ref={hiddenFileInput} onChange={(e) => handleAttachment(e)} />
            </AttachmentAdd>
            <Btn disabled={isDisabled} onClick={() => handleCommentSubmit(ticket.id)}>Submit</Btn>
          </Row>
          <Section>
            <AttachmentPreview>
              {attachments.length > 0 ? (
                <AttachmentList>
                  {attachments.map((item, index) => (
                    <AttachmentItem key={item.src}>
                      <AttachmentLabel>{item.name}</AttachmentLabel>
                      <Delete onClick={() => deleteAttachment(index)}>X</Delete>
                    </AttachmentItem>
                  ))}
                </AttachmentList>
              ) : null }
            </AttachmentPreview>
          </Section>
        </Comment>
      </SubWrap>
    </Wrap>
  );
};

const Wrap = styled.div`
  padding: 20px;
`;
const SubWrap = styled.div`
  flex-grow: 1;
  display: flex;

`;
const Inner = styled.div`
  flex-grow: 1;
  display: flex;
  border-bottom: 1px solid rgba(196, 196, 196, 0.6);
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  width: 75%;
`;
const Type = styled.div`
  background-color: #868484; // gray
  border-radius: 4px;
  width: 89px;
  height: 25px;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #FFFFFF;
  margin-left: auto;
`;
const Priority = styled.div<{ isUrgent: boolean }>`
  ${({ isUrgent }) => (isUrgent ? 'background-color: #FF1A1A' : 'background-color: #868484;')};
  border-radius: 4px;
  width: 79px;
  height: 25px;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #FFFFFF;
  margin: 0 auto 0 10px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin: 20px 0 0;

  & button {
    width: 72px;
    margin: 10px 0;
  }
`;

const UserName = styled.p`
  font-weight: 600;
`;
const Block = styled.div`
  margin: 30px 0;
`;
const IconColumn = styled.div`
  flex: 0 0 50px;
  width: 50px;
  padding: 10px 10px 10px 0;
`;
const ProfileBox = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const UserBox = styled.div`
  flex-grow: 1;
`;

const Box = styled.div``;
const TimeBox = styled.div`
  color: #4A4949;
`;
const Bold = styled.div`
  font-weight: 600;
`;
const AttachmentWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Attachment = styled.div`
  flex: 0 0 60px;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px;
`;
// const AttachmentAdd = styled(Attachment)``;
const AddIcon = styled.img`
  width: 50%;
  margin: auto;
  filter: invert(34%) sepia(74%) saturate(6163%) hue-rotate(211deg) brightness(101%) contrast(108%);
`;

const AttachmentAdd = styled.div<{ isDisabled: boolean }>`
  width: 50px;
  height: 44px;
  margin: 10px 7px;
  align-items: center;
  display: flex;
  border: solid 1px #2E81FF;
  border-radius: 4px;
  cursor: pointer;
  pointer-events:${({ isDisabled }) => (isDisabled ? 'none' : null)};
`;

const AttachmentInner = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  position: relative;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (hover: hover) {
    cursor: pointer;

    &:hover {
      transform: scale(1.15);
    }
  }
`;

const InputBox = styled.div`
  flex-grow: 1;
  padding-top: 10px;
`;

const TextArea = styled.textarea`
  display: block;
  width: 100%;
  height: 45px;
  min-height: 45px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 10px;
  resize: vertical;
  background-color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;

  color: #000000;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #868484;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: #868484;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #868484;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: #868484;
  }
`;

const File = styled.input`
 display: none;
`;
const Comment = styled.div`
  flex-grow: 1;
  display: flex;
  /* border-bottom: 1px solid rgba(196, 196, 196, 0.6); */
  flex-direction: column;
`;
const Section = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 50px;
`;

const AttachmentPreview = styled.div`
  display: flex;
  align-items: center;
`;

const AttachmentList = styled.ul`
`;

const AttachmentItem = styled.li`
  margin: 7px 0;
`;

const AttachmentLabel = styled.label`
  font-style: italic;
`;
const Delete = styled.span`
  color : #FF1A1A;
  cursor: pointer;
  margin-left: 10px;
  font-weight: 700;
  font-style: italic;
`;

export default TicketComponent;
