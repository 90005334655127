import { createReducerFunction, ImmerReducer } from 'immer-reducer';
import { RequestState } from '@/store/reducers/common';
import { IGlobalSearch } from '@/api/main-protected';

export interface InitState {
  globalSearch: IGlobalSearch | null,
  globalSearchQuery: string,
  globalSearchState: RequestState,
}

const initialState: InitState = {
  globalSearch: null,
  globalSearchQuery: '',
  globalSearchState: RequestState.IDLE,
};

export class GlobalReducer extends ImmerReducer<InitState> {
  setGlobalSearch(globalSearch: IGlobalSearch | null) {
    this.draftState.globalSearch = globalSearch;
  }

  setGlobalSearchQuery(globalSearchQuery: string) {
    this.draftState.globalSearchQuery = globalSearchQuery;
  }

  setGlobalSearchState(globalSearchState: RequestState) {
    this.draftState.globalSearchState = globalSearchState;
  }
}

export default createReducerFunction(GlobalReducer, initialState);
