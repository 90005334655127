import React from 'react';
import styled from 'styled-components';
import { Moment } from 'moment';

import Calendar from '@/Components/common/calendar/Calendar';
import DateSelect from '@/Components/common/calendar/DateSelect';

import useToggle from '@/Components/hooks/useToggle';

export enum DatePickerAlign {
  Left = 'left: 0px',
  Right = 'right: 0px',
  Center = '',
}

interface Props {
  tempFromDate: Moment | null;
  tempTillDate: Moment | null;
  mainDate: Moment | null;
  minFromDate?: Moment;
  handleSetDate: (value: Moment | null) => void;
  align?: DatePickerAlign,
  placeholder?: string,
  isFromDate: boolean,
  isTillToday?: boolean,
}

const DatePicker: React.FC<Props> = ({
  tempFromDate,
  tempTillDate,
  mainDate,
  minFromDate,
  handleSetDate,
  isFromDate,
  isTillToday,
  placeholder = 'Select',
  align = DatePickerAlign.Center,
}) => {
  const [isCalendar, setIsCalendar] = useToggle();

  const handleDateSelect = () => {
    setIsCalendar(true);
  };

  const handleCloseCalendar = () => {
    setIsCalendar(false);
  };

  const handleDate = (value: Moment | null) => {
    handleSetDate(value !== null ? value.utc() : null);
    handleCloseCalendar();
  };

  return (
    <DateSelectStyled>
      <DateSelect
        isActive={isCalendar}
        onClick={handleDateSelect}
        text={mainDate}
        format="ddd, D MMM"
        placeholder={placeholder}
      />
      {isCalendar && (
        <CalendarWrapper align={align}>
          <Calendar
            tempFromDate={tempFromDate}
            tempTillDate={tempTillDate}
            minFromDate={minFromDate}
            setTempDate={(e) => handleDate(e)}
            isFromDate={isFromDate}
            isTillToday={isTillToday}
            setVisible={handleCloseCalendar}
          />
        </CalendarWrapper>
      )}
    </DateSelectStyled>
  );
};

const CalendarWrapper = styled.div<{ align: DatePickerAlign }>`
  border-radius: 10px;
  margin-top: 10px;
  position: absolute;
  top: 100%;
  z-index: 10;

  ${({ align }) => align}
`;

const DateSelectStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

export default DatePicker;
