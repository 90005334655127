import { createReducerFunction, ImmerReducer } from 'immer-reducer';
import { RequestState } from '@/store/reducers/common';
import { IAssetsSearch } from '@/api/main-protected';

export interface InitState {
  globalSearch: IAssetsSearch | null,
  globalSearchState: RequestState,
}

const initialState: InitState = {
  globalSearch: null,
  globalSearchState: RequestState.IDLE,
};

export class AssetsSearchReducer extends ImmerReducer<InitState> {
  setAssetsSearch(globalSearch: IAssetsSearch | null) {
    this.draftState.globalSearch = globalSearch;
  }

  setAssetsSearchState(globalSearchState: RequestState) {
    this.draftState.globalSearchState = globalSearchState;
  }
}

export default createReducerFunction(AssetsSearchReducer, initialState);
