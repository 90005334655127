import { createSelector, Selector } from 'reselect';

import { State } from '@/store';
import { RequestState } from '@/store/reducers/common';

import { IDepartment, IUser, IUsersStatic } from '@/api/main-protected';

const selectUser = (state: State) => state.UserReducer;

export const selectUserInfo: Selector<State, IUser | null> = createSelector(
  selectUser,
  ({ userInfo }) => userInfo,
);

export const selectUserError: Selector<State, boolean> = createSelector(
  selectUser,
  ({ userError }) => userError,
);

export const selectUsers: Selector<State, IUser[] | null> = createSelector(
  selectUser,
  ({ users }) => users,
);

export const selectUsersStatic: Selector<State, IUsersStatic | null> = createSelector(
  selectUser,
  ({ usersStatic }) => usersStatic,
);

export const selectAddUserState: Selector<State, RequestState> = createSelector(
  selectUser,
  ({ addUserState }) => addUserState,
);

export const selectDeleteUserState: Selector<State, RequestState> = createSelector(
  selectUser,
  ({ deleteUserState }) => deleteUserState,
);

export const selectDepartments: Selector<State, IDepartment[] | null> = createSelector(
  selectUser,
  ({ departments }) => departments,
);

export const selectDepartmentsState: Selector<State, RequestState> = createSelector(
  selectUser,
  ({ departmentsState }) => departmentsState,
);
