import React from 'react';
import styled from 'styled-components';

interface ITabLabels {
  tabs: string[];
  activeTab: string;
  setActiveTab: (label: string) => void;
}

const TabLabels: React.FC<ITabLabels> = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <Wrap>
      {tabs.map((tab) => (
        <Tab key={tab} isActive={tab === activeTab} onClick={() => setActiveTab(tab)}>
          {tab}
        </Tab>
      ))}
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-grow: 1;
`;
const Tab = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 95px;
  width: fit-content;
  height: 30px;
  padding: 5px 15px;

  white-space: nowrap;

  background: ${({ isActive }) => (isActive ? '#0066ff' : '#ffffff')};
  border-radius: 40px;
  border: 1px solid #0066ff;

  font-weight: 600;
  font-size: 14px;
  color: ${({ isActive }) => (isActive ? '#ffffff' : '#0066ff')};
  text-transform: capitalize;
  margin-right: 15px;
  cursor: pointer;

  @media (hover: hover) {
    transition: all 0.3s ease;
  }
`;

export default TabLabels;
