import React from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import { AppDispatch } from '@/App';

import { IEvent, Request } from '@/api/main-protected';

import { eventsActions } from '@/store/actions/events';

import { groupBy } from '@/utils/groupBy';
import convertFromStringToDate, { FormatDate } from '@/utils/convertFromStringToDate';

import IconComponent from '@/Components/common/IconComponent';

import yellowWarnIcon from '@/assets/images/icons/events/warn-yellow.svg';

interface IProps {
  req: Request;
  handleEventClick?: (item: IEvent, req: Request) => void;
  onCloseRequestModal?: () => void;
  handleAllEventsClick?: () => void;
}

const EventsViewAllComponent: React.FC<IProps> = ({
  req,
  handleEventClick,
  onCloseRequestModal,
  handleAllEventsClick,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleOpenALLViewEventsModal = (eventArr: IEvent[]) => {
    // to not close incidentRequest modal
    if (handleAllEventsClick) {
      handleAllEventsClick();
    }

    const grouped = groupBy(
      eventArr,
      (i) => convertFromStringToDate(i.event_date, FormatDate.GroupFormat),
    );

    // close opened before modal
    if (onCloseRequestModal) {
      onCloseRequestModal();
    }

    dispatch(
      eventsActions.setViewAllEvents({
        request: req,
        events: Object.entries(grouped),
      }),
    );
  };

  const onEventClick = (item: IEvent, request: Request) => {
    if (handleEventClick) {
      handleEventClick(item, request);
    }
  };

  return (
    <EventsColumn>
      <Title>
        EVENTS
        {req.events && req.events?.length > 0 && (
          <ViewAll onClick={() => handleOpenALLViewEventsModal(req.events as IEvent[])}>
            <Text>View All</Text>
          </ViewAll>
        )}
      </Title>
      {req.events && req.events?.length > 0 ? (
        req.events.slice(0, 1).map((item) => (
          <EventBox key={item.event_id}>
            <Text
              isBlue
              isLink={!!handleEventClick}
              onClick={() => onEventClick(item as IEvent, req)}
            >
              {item.event_id ? `${item.short_desc}` : ''}
            </Text>
            {req.incidents_count > 0 && item.incidents.filter((i) => i.incident_id).length > 0 && (
              <EventIcon>
                <IconComponent width={20} icon={yellowWarnIcon} />
              </EventIcon>
            )}
          </EventBox>
        ))
      ) : (
        <Text>-</Text>
      )}
    </EventsColumn>
  );
};

const EventsColumn = styled.div`
  flex: 0 0 220px;
  width: 220px;
`;

const Title = styled.h5`
  font-weight: 600;
  font-size: 14px;
  /* line-height: 37px; */
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding: 0 0 10px 0;
`;
const Text = styled.p<{ isBlue?: boolean; isLink?: boolean; isNoCapitalize?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: ${({ isBlue }) => (isBlue ? '#0066FF' : '#000000')};
  text-transform: ${({ isNoCapitalize }) => (isNoCapitalize ? 'none' : 'capitalize')};

  ${({ isLink }) => isLink
    && css`
    @media (hover: hover) {
      cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  `}
`;

const ViewAll = styled.div`
  display: inline-block;
  border: 1px solid #0066ff;
  border-radius: 15px;
  padding: 2px 9px;
  margin-left: 10px;
  cursor: pointer;
  & p {
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;
    color: #0066ff;
  }
`;

const EventBox = styled.div`
  display: flex;
  align-items: center;
`;

const EventIcon = styled.div`
  margin-left: 6px;
`;

export default EventsViewAllComponent;
