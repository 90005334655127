import { useEffect } from 'react';

const zendeskUrl = 'https://static.zdassets.com/ekr/snippet.js';
const zendeskKey = '9758c9cf-813b-4ade-906f-b527b6a41a85';
export const resourceUrl = `${zendeskUrl}?key=${zendeskKey}`;
const zendeskScriptId = 'ze-snippet';

const useImportZendeskScript = (isLoadZendesk: boolean) => {
  useEffect(() => {
    if(!isLoadZendesk) return

    const script = document.createElement('script');
    script.id = zendeskScriptId;
    script.src = resourceUrl;
    script.async = true;
    document.body.appendChild(script);

  return () => {
      document.body.removeChild(script);
    }
  }, [resourceUrl, isLoadZendesk]);
};

export default useImportZendeskScript;