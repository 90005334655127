import { createSelector, Selector } from 'reselect';

import { RequestState } from '@/store/reducers/common';
import { State } from '@/store';
import { IRequestsSearch } from '@/api/main-protected';

const requestsSearch = (state: State) => state.RequestsSearchReducer;

export const selectRequestsSearch: Selector<State, IRequestsSearch | null> = createSelector(
  requestsSearch,
  ({ globalSearch }) => globalSearch,
);

export const selectRequestsSearchState: Selector<State, RequestState> = createSelector(
  requestsSearch,
  ({ globalSearchState }) => globalSearchState,
);
