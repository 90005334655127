import React from 'react';

import { IIcon } from '@/interfaces/icon';

const ControlCenterIcon: React.FC<IIcon> = ({ width = '16', height = '16', color = 'black' }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4077_5433)">
      <path d="M15 4H1C0.448667 4 0 3.55133 0 3V1C0 0.448667 0.448667 0 1 0H15C15.5513 0 16 0.448667 16 1V3C16 3.55133 15.5513 4 15 4ZM1 0.666667C0.816 0.666667 0.666667 0.816 0.666667 1V3C0.666667 3.184 0.816 3.33333 1 3.33333H15C15.184 3.33333 15.3333 3.184 15.3333 3V1C15.3333 0.816 15.184 0.666667 15 0.666667H1Z" fill={color} />
      <path d="M6.33333 16H1C0.448667 16 0 15.5514 0 15V6.33337C0 5.78204 0.448667 5.33337 1 5.33337H6.33333C6.88467 5.33337 7.33333 5.78204 7.33333 6.33337V15C7.33333 15.5514 6.88467 16 6.33333 16ZM1 6.00004C0.816 6.00004 0.666667 6.14937 0.666667 6.33337V15C0.666667 15.184 0.816 15.3334 1 15.3334H6.33333C6.51733 15.3334 6.66667 15.184 6.66667 15V6.33337C6.66667 6.14937 6.51733 6.00004 6.33333 6.00004H1Z" fill={color} />
      <path d="M15.0003 10H9.66699C9.11566 10 8.66699 9.55137 8.66699 9.00004V6.33337C8.66699 5.78204 9.11566 5.33337 9.66699 5.33337H15.0003C15.5517 5.33337 16.0003 5.78204 16.0003 6.33337V9.00004C16.0003 9.55137 15.5517 10 15.0003 10ZM9.66699 6.00004C9.48299 6.00004 9.33366 6.14937 9.33366 6.33337V9.00004C9.33366 9.18404 9.48299 9.33337 9.66699 9.33337H15.0003C15.1843 9.33337 15.3337 9.18404 15.3337 9.00004V6.33337C15.3337 6.14937 15.1843 6.00004 15.0003 6.00004H9.66699Z" fill={color} />
      <path d="M15.0003 16H9.66699C9.11566 16 8.66699 15.5514 8.66699 15V12.3334C8.66699 11.782 9.11566 11.3334 9.66699 11.3334H15.0003C15.5517 11.3334 16.0003 11.782 16.0003 12.3334V15C16.0003 15.5514 15.5517 16 15.0003 16ZM9.66699 12C9.48299 12 9.33366 12.1494 9.33366 12.3334V15C9.33366 15.184 9.48299 15.3334 9.66699 15.3334H15.0003C15.1843 15.3334 15.3337 15.184 15.3337 15V12.3334C15.3337 12.1494 15.1843 12 15.0003 12H9.66699Z" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0_4077_5433">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ControlCenterIcon;
