import { createReducerFunction, ImmerReducer } from 'immer-reducer';
import { v4 as uuidv4 } from 'uuid';

import {
  ToastDuration,
  ToastTheme,
  IColorConfig,
  IToast,
} from '@/interfaces/toast';

export const colorConfig: { [key: string]: IColorConfig } = {
  [ToastTheme.Dark]: {
    background: '#0A090C',
    text: 'white',
    close: '#ECECEC',
  },
  [ToastTheme.Light]: {
    background: '#fafafa',
    text: '#212121',
    close: '#212121',
  },
};

export interface IToastState {
  queue: IToast[];
}

export const defaultToastOptions = {
  duration: ToastDuration.SHORT,
  theme: ToastTheme.Light,
};

const initialState: IToastState = {
  queue: [],
};

export class ToastReducer extends ImmerReducer<IToastState> {
  addToast(toast: IToast) {
    const toasty = { ...toast, id: uuidv4() };
    this.draftState.queue = [...this.draftState.queue, toasty];
  }

  removeToast(id?: string) {
    const toastQueue = this.draftState.queue;

    if (!id && toastQueue.length) {
      this.draftState.queue = toastQueue.slice(1, toastQueue.length);
      return;
    }

    this.draftState.queue = toastQueue.filter((toast) => toast.id !== id);
  }
}

export default createReducerFunction(ToastReducer, initialState);
