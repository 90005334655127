// // Opera 8.0+
// const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

// // Firefox 1.0+
// const isFirefox = typeof InstallTrigger !== 'undefined';

// // Safari 3.0+ "[object HTMLElementConstructor]"
// const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

// // Internet Explorer 6-11
// const isIE = /*@cc_on!@*/false || !!document.documentMode;

// // Edge 20+
// const isEdge = !isIE && !!window.StyleMedia;

// // Chrome 1 - 71
// const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// // Blink engine detection
// const isBlink = (isChrome || isOpera) && !!window.CSS;

// Detecting Browsers on Desktop and Mobile : Edge, Opera, Chrome, Safari, Firefox, IE

// I did some changes in @nimesh code now it is working for Edge also, and Opera issue fixed:

export enum BrowserNames {
  EDGE = 'Edge',
  OPERA = 'Opera',
  CHROME = 'Chrome',
  SAFARI = 'Safari',
  FIREFOX = 'Firefox',
  UNKNOWN = 'unknown',
}

const getBrowserName = (): BrowserNames => {
  if (navigator.userAgent.indexOf('Edge') > -1 && navigator.appVersion.indexOf('Edge') > -1) {
    return BrowserNames.EDGE;
  }
  if (navigator.userAgent.indexOf('Opera') !== -1 || navigator.userAgent.indexOf('OPR') !== -1) {
    return BrowserNames.OPERA;
  }
  if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return BrowserNames.CHROME;
  }
  if (navigator.userAgent.indexOf('Safari') !== -1) {
    return BrowserNames.SAFARI;
  }
  if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return BrowserNames.FIREFOX;
  }
  // else if( ( navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true ) ) //IF IE > 10
  // {
  //     return 'IE';
  // }

  return BrowserNames.UNKNOWN;
};

export default getBrowserName;
