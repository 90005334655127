import React, { useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { IIncident } from '@/api/main-protected';

import redWarnIcon from '@/assets/images/icons/events/warn-red.svg';
import yellowWarnIcon from '@/assets/images/icons/events/warn-yellow.svg';
import infoIcon from '@/assets/images/icons/events/i-icon.svg';

import IconComponent from '@/Components/common/IconComponent';
// import EventInfoWindowComponent from '@/Components/common/EventInfoWindow';
import ViewRequestComponent from '@/Components/common/ViewRequest';
import ModalIncidentRequests from '@/Components/Requests/ModalIncidentRequest';
import { AppDispatch } from '@/App';

import { RequestState } from '@/store/reducers/common';
import {
  selectIncidentRequest,
  selectIncidentRequestState,
} from '@/store/selectors/events';
import { eventsActions, getIncidentRequest } from '@/store/actions/events';

import useToggle from '@/Components/hooks/useToggle';

interface IEventComponent {
  incident: IIncident;
  setActiveIncident: (incident: IIncident | null) => void;
}

export enum IncidentAction {
  informational = 'informational',
  exception = 'exception',
  warning = 'warning',
}

const EventComponent: React.FC<IEventComponent> = ({
  incident,
  setActiveIncident,
}) => {
  const [isModal, setIsModal] = useToggle();

  const dispatch = useDispatch<AppDispatch>();

  const request = useSelector(selectIncidentRequest);
  const requestState = useSelector(selectIncidentRequestState);

  const eventRef = useRef(null) as React.RefObject<HTMLDivElement>;
  const {
    country,
    event: {
      event_id: eventId,
      action,
      short_desc: shortDesc,
      long_desc: longDesc,
    },
  } = incident;

  const setIcon = () => {
    if (action === IncidentAction.warning) {
      return redWarnIcon;
    }

    if (action === IncidentAction.exception) {
      return yellowWarnIcon;
    }

    return infoIcon;
  };

  const setAlt = () => {
    if (action === IncidentAction.warning) {
      return IncidentAction.warning;
    }

    if (action === IncidentAction.exception) {
      return IncidentAction.exception;
    }

    return IncidentAction.informational;
  };
  // const icon = action === IncidentAction.warning
  //   ? redWarnIcon
  //   : action === IncidentAction.exception
  //     ? yellowWarnIcon
  //     : infoIcon;

  // const alt = action === IncidentAction.warning
  //   ? IncidentAction.warning
  //   : action === IncidentAction.exception
  //     ? IncidentAction.exception
  //     : IncidentAction.informational;

  // useEffect(() => {
  //   if (!isMouseEnter) return;

  //   const rect = eventRef.current?.getBoundingClientRect();

  //   setTop(rect!.y - rect!.height / 2);
  //   setLeft(rect!.x + rect!.width + 5);
  // }, [scrolling, isMouseEnter]);

  const handleMouseEnter = () => {
    // setIsMouseEnter(true);
    setActiveIncident(incident);
  };

  const handleMouseLeave = () => {
    // setIsMouseEnter(false);
    setActiveIncident(null);
  };

  const handleOpenModal = () => {
    dispatch(getIncidentRequest(eventId, true));
    setIsModal(true);
  };

  const handleCloseModal = () => {
    setIsModal(false);
  };

  return (
    <Container
      ref={eventRef}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      <WarningIcon>
        <IconComponent icon={setIcon()} alt={setAlt()} />
      </WarningIcon>
      <Info>
        <Description>{shortDesc}</Description>
        <Address>{country}</Address>
        {longDesc && <Note>{longDesc}</Note>}
        <ViewBox>
          <ViewRequestComponent onClick={handleOpenModal} />
        </ViewBox>
      </Info>
      {/* {isMouseEnter
        && <EventInfoWindowComponent info={long_desc} top={top!} left={left!} />} */}
      {isModal && (
        <ModalIncidentRequests
          isEventsStatus
          incidentRequest={request!}
          onCloseModal={handleCloseModal}
          isLoading={requestState === RequestState.LOADING}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  flex: 0 0 290px;
  max-width: 290px;
  display: flex;
  padding: 12px 5px 4px;
  /* margin: 0 8px; */
  border-bottom: 1px solid rgba(182, 182, 182, 0.5);
  background: #ffffff;
`;
const WarningIcon = styled.div`
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-right: 5px; */
  margin: 2px 5px 0 0;
  /* margin-left: 10px; */
`;
const Info = styled.div`
  flex: 1 0 80%;
`;
const Description = styled.p`
  font-family: 'Jost';
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
`;
const Address = styled.p`
  font-family: 'Jost';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin: 8px 0;
`;
// const Notes = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `;
const ViewBox = styled.div`
  display: flex;
  justify-content: flex-end;

  & div {
    margin: 0;
  }
`;
const Note = styled.p`
  flex: 0 0 90%;
  width: 90%;
  max-width: 90%;
  font-family: 'Jost';
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

export default EventComponent;
