import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { ROLES } from '@/api/main';
import { IAddUser, ILocation } from '@/api/main-protected';

import { AppDispatch } from '@/App';

import prevIcon from '@/assets/images/icons/prev-arrow-icon.svg';
import closeIcon from '@/assets/images/icons/close-icon.svg';

import Input from '@/Components/common/Input';
import useInput from '@/Components/hooks/useInput';
import Btn from '@/Components/common/Signin/Btn';
import IconComponent from '@/Components/common/IconComponent';
import PasswordConditions from '@/Components/common/PasswordConditions';

import PhoneMultiInput, {
  IPhoneVariant,
  PHONE_TYPE_VARIANTS,
} from '@/Components/common/PhoneMultiInput';
import { TABS } from '@/Components/AddNewUser/AddNewUser';
import DropDown, { IVariant } from '@/Components/common/DropDown';
import useToggle from '@/Components/hooks/useToggle';
import ModalSuccessfuly from '@/Components/Requests/ModalSuccessfuly';
import { PAGES } from '@/Components/Main/Main';

import { addUser, getUserDepartments, getUsers } from '@/store/actions/user';
import {
  selectAddUserState,
  selectDepartments,
  selectDepartmentsState,
  selectUserInfo,
} from '@/store/selectors/user';
import { RequestState } from '@/store/reducers/common';
import { getLocations } from '@/store/actions/utils';

import {
  isLowerLetter,
  isNumericAlpha,
  isSpecialSymbol,
  isUpperLetter,
  isValidEmail,
  isValidPhone,
} from '@/utils/validation';
import getPhoto from '@/utils/getPhoto';
import getBrowserName from '@/utils/browsers';
import { getUserRoleName } from '@/utils/user';
import { accessLevel } from '@/utils/permissions';
import DropDownCheckBox from '../common/dropDown/DropDownCheckBox';

interface IAddNewUserForm {
  activeStep: number;
  handleClick: () => void;
  onNextBtnClick: () => void;
}

const MAX_PHONE_ADD_NUM = 2;

const AddNewUserForm: React.FC<IAddNewUserForm> = ({ handleClick, onNextBtnClick, activeStep }) => {
  const user = {
    photo: '',
    alt: '',
    file: '',
  };
  const [{ src, alt }] = useState({
    src: getPhoto(user!.photo),
    alt: 'Select Image',
    file: '',
  });

  const roleInfo = useSelector(selectUserInfo)!;
  const addUserState = useSelector(selectAddUserState);
  const departments = useSelector(selectDepartments);
  const departmentsState = useSelector(selectDepartmentsState);

  const history = useHistory();

  const ROLES_VARIANTS = useMemo(
    () => accessLevel[roleInfo.roles[0]].map((role) => ({ title: getUserRoleName(role), role })),
    [roleInfo],
  );

  const [firstName, setFirstName] = useInput('');
  const [lastName, setLastName] = useInput('');
  const [mail, setMail] = useInput('');
  const [password, setPassword] = useInput('');
  const [phone, setPhone] = useInput('');
  const [phone1, setPhone1] = useInput('');
  const [phone2, setPhone2] = useInput('');
  const [phoneVariant, setPhoneVariant] = useState<IPhoneVariant | null>(PHONE_TYPE_VARIANTS[0]);
  const [phoneVariant1, setPhoneVariant1] = useState<IPhoneVariant | null>(PHONE_TYPE_VARIANTS[0]);
  const [phoneVariant2, setPhoneVariant2] = useState<IPhoneVariant | null>(PHONE_TYPE_VARIANTS[0]);
  const [newBusinessUnit, setNewBusinessUnit] = useInput('');
  const [dropDownItems, setDropDownItems] = useState<string[] | null>(null);
  const [newLocationVariant, setNewLocationVariant] = useState<ILocation | null>({
    country_code: 'US',
    country_name: 'United States',
    title: 'United States',
  });
  const [newRoleVariant, setNewRoleVariant] = useState<IVariant | null>(ROLES_VARIANTS[0]);
  const [isMaxPhoneAdded, setIsMaxPhoneAdded] = useState(false);
  const [phonesAmountArr, setPhonesAmount] = useState<number[]>([1]);
  const [isErrorFields, setIsErrorFields] = useToggle();
  const [isModal, setIsModal] = useToggle();

  const isValidPassword = useMemo(
    () => password.length > 9
      && isNumericAlpha(password)
      && isSpecialSymbol(password)
      && isUpperLetter(password)
      && isLowerLetter(password),
    [password],
  );

  const isValidFields = useMemo(
    () => isValidPassword
      && isValidEmail(mail)
      && isValidPhone(phone)
      && !!firstName
      && !!lastName
      && !!newBusinessUnit
      && !!newLocationVariant?.country_name,
    [isValidPassword, mail, phone, firstName, lastName, newBusinessUnit, newLocationVariant],
  );

  const locationVariants = [
    {
      country_code: 'US',
      country_name: 'United States',
      title: 'United States',
    },
  ]; // useSelector(selectLocations);

  const browser = getBrowserName();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (locationVariants) return;

    dispatch(getLocations());
  }, []);

  useEffect(() => {
    if (departments) return;

    dispatch(getUserDepartments());
  }, []);

  useEffect(() => {
    if (!departments) return;

    if (departments && departments.length === 0) return;

    const deps = setDepartmentOptions();

    setDropDownItems(deps);
  }, [departments]);

  useEffect(() => {
    if (departments && departments.length > 0 && !!newRoleVariant) {
      setDepartments(newRoleVariant.title);
    }
  }, [newRoleVariant?.title]);

  const setDepartmentOptions = () => {
    const namedDepartments = departments!.map((d) => d.name);

    return [...namedDepartments]
      .sort((a, b) => a.localeCompare(b));
  };

  const setDepartments = (role: string) => {
    const departmentsData = setDepartmentOptions();

    // settings for user & manager
    // if (role !== ROLES_VARIANTS[2].title) {
    //   setNewBusinessUnit(departmentsData[0]);
    // }

    // settings for admin
    if (role === ROLES_VARIANTS[2].title) {
      const units = departmentsData.join(', ');
      setNewBusinessUnit(units);
    }
    
    setDropDownItems(departmentsData);
  };

  const handleAddPhoneClick = () => {
    if (!phonesAmountArr.includes(2)) {
      setPhonesAmount([...phonesAmountArr, 2]);
    } else if (!phonesAmountArr.includes(3)) {
      setPhonesAmount([...phonesAmountArr, 3]);
    }

    if (phonesAmountArr.length === MAX_PHONE_ADD_NUM) {
      setIsMaxPhoneAdded(true);
    }
  };

  const handleNextClick = () => {
    if (!isValidFields) {
      setIsErrorFields(true);
      return;
    }

    onNextBtnClick();
  };

  const handleClose = (num: number) => {
    const updatedAmount = phonesAmountArr.filter((numItem) => numItem !== num);
    setPhonesAmount(updatedAmount);

    if (num === 2) {
      setPhone1('');
      setPhoneVariant1(PHONE_TYPE_VARIANTS[0]);
    }

    if (num === 3) {
      setPhone2('');
      setPhoneVariant2(PHONE_TYPE_VARIANTS[0]);
    }

    setIsMaxPhoneAdded(false);
  };

  const onAddUserBtnClick = async () => {
    const body: IAddUser = {
      first_name: firstName,
      last_name: lastName,
      email: mail,
      phone_numbers: [
        ...(phone && phoneVariant
          ? [
            {
              type: phoneVariant.id,
              phone,
            },
          ]
          : []),
        ...(phone1 && phoneVariant1
          ? [
            {
              type: phoneVariant1.id,
              phone: phone1,
            },
          ]
          : []),
        ...(phone2 && phoneVariant2
          ? [
            {
              type: phoneVariant2.id,
              phone: phone2,
            },
          ]
          : []),
      ],
      country: newLocationVariant?.country_name || '',
      business_unit: newBusinessUnit,
      password,
      role: newRoleVariant?.role || ROLES.USER,
    };

    try {
      await dispatch(addUser(body));
      await dispatch(getUsers());

      setIsModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  // const onSaveDraftBtnClick = () => {};

  // const handlePhotoFile = (e: any) => {
  //   if (e.target.files[0]) {
  //     setPhotoFile({
  //       src: URL.createObjectURL(e.target.files[0]),
  //       alt: e.target.files[0].name,
  //       file: e.target.files[0],
  //     });
  //   }
  // };

  // const handleDeletePhotoFile = () => {
  //   if (src !== profileDefaultIcon) {
  //     setPhotoFile({
  //       src: profileDefaultIcon,
  //       alt: 'unknown',
  //       file: '',
  //     });
  //   }
  // };

  // const clearFields = () => {
  //   setFirstName('')
  //   setLastName('')
  //   setPassword('')
  //   setMail('')
  //   setPhone('')
  //   setPhone1('')
  //   setPhone2('')
  //   setNewBusinessUnit('')
  //   setNewLocationVariant(null)
  //   setNewRoleVariant(ROLES_VARIANTS[0])
  //   setPhonesAmount([1])
  //   setIsErrorFields(false)
  // }

  const handleOnClose = () => {
    // handleClick()
    // setIsModal(false)
    // clearFields()
    history.push(PAGES.USER_ADMIN);
  };

  return (
    <Form>
      {activeStep === TABS[0].step && (
        <Inner>
          <AvatarFormItem>
            <AvatarLabel>Avatar</AvatarLabel>
            <ImgBox>
              <IconComponent icon={src} alt={alt} />
              {/* <EditBtn>
                <InputFile getImages={(e) => handlePhotoFile(e)} />
              </EditBtn> */}
            </ImgBox>
            {/* <DeleteBtnBox>
              <Btn onClick={handleDeletePhotoFile} isTransparent>
                Delete
              </Btn>
            </DeleteBtnBox> */}
          </AvatarFormItem>
          <FormItem>
            <Label>Full name</Label>
            <InputsBox>
              <InputBox>
                <Input
                  id="new-first-name"
                  name="first-name"
                  value={firstName}
                  placeholder="First name"
                  isNoMargin
                  onChange={setFirstName}
                  isError={isErrorFields && !firstName}
                  errorText=""
                />
              </InputBox>
              <InputBox>
                <Input
                  id="new-last-name"
                  name="last-name"
                  value={lastName}
                  placeholder="Last name"
                  isNoMargin
                  onChange={setLastName}
                  isError={isErrorFields && !lastName}
                  errorText=""
                />
              </InputBox>
            </InputsBox>
          </FormItem>
          <FormItem>
            <Label>Email</Label>
            <InputBox>
              <Input
                id="new-user-email"
                name="new-user-email"
                value={mail}
                placeholder="Email"
                isNoMargin
                onChange={setMail}
                isError={isErrorFields && !isValidEmail(mail)}
                errorText=""
              />
            </InputBox>
          </FormItem>
          <FormItem>
            <Label>Password</Label>
            <InputPasswordBox isValue={!!password}>
              <Input
                id="new-user-password"
                name="new-user-password"
                value={password}
                placeholder="Password"
                isNoMargin
                onChange={setPassword}
                isHideMode
                height={30}
                browser={browser}
                isError={isErrorFields && !isValidPassword}
                errorText=""
              />
            </InputPasswordBox>
          </FormItem>
          {isErrorFields && !isValidPassword && (
            <FormItem>
              <Label />
              <InputPasswordBox isValue>
                <PasswordConditions
                  is10={password.length > 9}
                  isUpLow={isUpperLetter(password) && isLowerLetter(password)}
                  isA1={isNumericAlpha(password)}
                  isSpecial={isSpecialSymbol(password)}
                  noMargin
                />
              </InputPasswordBox>
            </FormItem>
          )}
          <FormItem>
            <Label>Phone</Label>
            <PhoneMultiInput
              id="new-phone"
              name="new-phone"
              type="number"
              value={phone}
              variant={phoneVariant!}
              setVariant={setPhoneVariant}
              placeholder="+1(609)972-2222"
              isError={isErrorFields && !isValidPhone(phone)}
              onChange={setPhone}
              errorText=""
            />
          </FormItem>
          {phonesAmountArr.includes(2) && (
            <FormItem>
              <Label />
              <InputBox>
                <PhoneMultiInput
                  id="new-phone-1"
                  name="new-phone-1"
                  type="number"
                  value={phone1}
                  variant={phoneVariant1!}
                  setVariant={setPhoneVariant1}
                  placeholder="+1(609)972-2222"
                  onChange={setPhone1}
                />
                <Close onClick={() => handleClose(2)}>
                  <IconComponent icon={closeIcon} alt="close" />
                </Close>
              </InputBox>
            </FormItem>
          )}
          {phonesAmountArr.includes(3) && (
            <FormItem>
              <Label />
              <InputBox isClose>
                <PhoneMultiInput
                  id="new-phone-2"
                  name="new-phone-2"
                  type="number"
                  value={phone2}
                  variant={phoneVariant2!}
                  setVariant={setPhoneVariant2}
                  placeholder="+1(609)972-2222"
                  onChange={setPhone2}
                />
                <Close onClick={() => handleClose(3)}>
                  <IconComponent icon={closeIcon} alt="close" />
                </Close>
              </InputBox>
            </FormItem>
          )}
          {!isMaxPhoneAdded && (
            <FormItem>
              <>
                <Label />
                <BlueText onClick={handleAddPhoneClick}>+ Add phone</BlueText>
              </>
            </FormItem>
          )}
          <FormItem>
            <Label>Role</Label>
            <InputBox>
              <DropDown
                variants={ROLES_VARIANTS}
                chosenOption={newRoleVariant}
                onChoose={setNewRoleVariant}
                placeholder="Role"
                isSub
                isGreyArrow
                minHeight={30}
                height={145}
              />
            </InputBox>
          </FormItem>
          <FormItem>
            <Label>Department</Label>
            <InputBox>
              {!!dropDownItems && departmentsState !== RequestState.LOADING && departments ? (
                <DropDownCheckBox
                  dropItems={dropDownItems!}
                  onChange={setNewBusinessUnit}
                  value={newBusinessUnit}
                  placeholder="Department"
                  maxHeight="190px"
                  isError={isErrorFields && !newBusinessUnit}
                  isForm
                />
              ) : (
                <DropdownLoader>{newBusinessUnit}</DropdownLoader>
              )}
            </InputBox>
          </FormItem>
          <FormItem>
            <Label>Location</Label>
            <InputBox>
              <DropDown
                variants={locationVariants}
                chosenOption={newLocationVariant}
                onChoose={setNewLocationVariant}
                placeholder="Location"
                isSub
                isGreyArrow
                minHeight={30}
                height={145}
                isError={isErrorFields && !newLocationVariant?.country_name}
              />
            </InputBox>
          </FormItem>
        </Inner>
      )}
      {activeStep === TABS[1].step && (
        <>
          <UserHeader>
            <UserBgImg />
            <ImgBox>
              <IconComponent icon={src} alt={alt} />
            </ImgBox>
          </UserHeader>
          <PaddingLeftBlock>
            <Inner>
              <DataItem>
                <Label>Full name</Label>
                <LabelBold>
                  {firstName}
                  {' '}
                  {lastName}
                </LabelBold>
              </DataItem>
              <DataItem>
                <Label>Email</Label>
                <LabelBold>{mail}</LabelBold>
              </DataItem>
              {phone && (
                <DataItem>
                  <Label>Phone</Label>
                  <LabelBold>{phone}</LabelBold>
                </DataItem>
              )}
              {phone1 && (
                <DataItem>
                  <Label>Phone 2</Label>
                  <LabelBold>{phone1}</LabelBold>
                </DataItem>
              )}
              {phone2 && (
                <DataItem>
                  <Label>Phone 3</Label>
                  <LabelBold>{phone2}</LabelBold>
                </DataItem>
              )}
              <DataItem>
                <Label>Department</Label>
                <LabelBold>{newBusinessUnit}</LabelBold>
              </DataItem>
              <DataItem>
                <Label>Role</Label>
                <LabelBold>{newRoleVariant?.title}</LabelBold>
              </DataItem>
              <DataItem>
                <Label>Location</Label>
                <LabelBold>{newLocationVariant?.title}</LabelBold>
              </DataItem>
            </Inner>
          </PaddingLeftBlock>
        </>
      )}

      <Btns>
        {activeStep === TABS[1].step && (
          <BtnBoxPrev>
            <Btn onClick={handleClick} isTransparent>
              <IconComponent icon={prevIcon} width={21} />
              <PrevText>Previous step</PrevText>
            </Btn>
          </BtnBoxPrev>
        )}

        <BtnsBox>
          {/* {activeStep === TABS[1].step && (
            <BtnBox width={110}>
              <Btn onClick={onSaveDraftBtnClick} isTransparent>
                Save in drafts
              </Btn>
            </BtnBox>
          )} */}
          <BtnBox>
            {activeStep === TABS[0].step && <Btn onClick={handleNextClick}>Next</Btn>}
            {activeStep === TABS[1].step && (
              <Btn isLoading={addUserState === RequestState.LOADING} onClick={onAddUserBtnClick}>
                Add user
              </Btn>
            )}
          </BtnBox>
        </BtnsBox>
      </Btns>
      {isModal && <ModalSuccessfuly onCloseModal={handleOnClose} />}
    </Form>
  );
};

const Form = styled.div`
  flex: 0 0 100%;
  width: 100%;
  padding: 0px 0px 30px 0px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  overflow: hidden;
`;
const Inner = styled.div`
  width: 100%;
  padding: 0px 125px 0px 85px;
`;
const FormItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 12px 0;
`;
const DataItem = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 12px 0;

  & > p {
    flex: 0 0 50%;
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
`;
const AvatarFormItem = styled(FormItem)`
  padding: 38px 0 45px;
`;

const UserHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  padding: 38px 0 42px;
`;
const UserBgImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110px;
  background: #d9d9d9;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Label = styled.p`
  flex: 0 0 135px;
  width: 135px;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  padding-right: 10px;
  margin-right: 6px;
  text-align: right;
`;

const LabelBold = styled(Label)`
  font-weight: 600;
  text-align: left;
`;

const AvatarLabel = styled(Label)`
  flex: 0 0 125px;
  width: 125px;
  margin-top: 20px;
`;

const ImgBox = styled.div`
  position: relative;
  flex: 0 0 88px;
  width: 88px;
  height: 88px;
  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 50%;

  & > div > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
`;

// const EditBtn = styled.div`
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   width: 24px;
//   height: 24px;
//   border-radius: 50%;
//   background: #ffffff;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   cursor: pointer;
// `;

// const DeleteBtnBox = styled.div`
//   width: 90px;
//   margin: 20px 30px;
// `;

const InputsBox = styled.div`
  width: 100%;
  max-width: 475px;
  display: flex;

  & [name='first-name'] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  & [name='last-name'] {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const BlueText = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: #0066ff;
  margin: 10px 0;
  cursor: pointer;
`;

// const CheckboxesBox = styled.div`
//   width: 100%;
//   max-width: 475px;
//   display: flex;

//   & > div:first-child {
//     width: 100%;
//     background: #ffffff;
//     border: 1px solid #c4c4c4;
//     border-top-right-radius: 0;
//     border-bottom-right-radius: 0;
//     border-top-left-radius: 4px;
//     border-bottom-left-radius: 4px;
//   }
//   & > div:last-child {
//     background: #ffffff;
//     border: 1px solid #c4c4c4;
//     border-left: 1px solid transparent;
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
//     border-top-right-radius: 4px;
//     border-bottom-right-radius: 4px;
//   }
// `;

const InputBox = styled.div<{ isClose?: boolean }>`
  position: relative;
  width: 100%;
  max-width: 475px;

  & input {
    height: 30px;
    padding: 7px 10px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  & label {
    padding: 0 7px 0 3px;
  }
`;

const InputPasswordBox = styled(InputBox)<{ isValue: boolean }>`
  input {
    height: 30px;
    padding: 7px 10px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    & ~ img {
      width: 20px;
      height: 20px;
      top: 5px;
    }
  }

  & [type='password'] {
    letter-spacing: ${({ isValue }) => (isValue ? '1px' : '0')};
    color: #000000;

    & ~ div {
      height: 5px;
    }

    ::placeholder {
      letter-spacing: 1px;
    }
  }
`;

const Btns = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 125px 0px 85px;
  margin: 65px 0 20px;
`;
const BtnsBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  & > div {
    margin-left: 20px;
  }
`;
const BtnBoxPrev = styled.div`
  width: 140px;
  display: flex;
  align-items: center;
  margin-left: 0;

  & > div {
    margin-left: 0;
  }

  & button {
    border: none;
  }

  & span {
    font-weight: 400;
    font-size: 16px;
    color: #4a4949;
  }
`;
const BtnBox = styled.div<{ width?: number }>`
  width: ${({ width }) => width || 130}px;
`;

const PrevText = styled.span`
  display: inline-block;
  color: #4a4949;
  margin-left: 5px;
`;
const PaddingLeftBlock = styled.div`
  padding-left: 40px;
`;

const Close = styled.div`
  position: absolute;
  top: 50%;
  right: -40px;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
  cursor: pointer;
`;

const DropdownLoader = styled.div`
  width: 100%;
  height: 32px;
  padding: 8px 13px 8px;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
`;

export default AddNewUserForm;
