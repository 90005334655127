import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { IXPI } from '@/interfaces/metrics';

import { singleLine } from '@/styles/mixins';
import InfoIcon from '@/Components/icons/InfoIcon';

interface Props extends IXPI {
  scrollLeft: number;
}

const nullStates = 'Insufficient data';

const XPICell: React.FC<Props> = ({
  name,
  value,
  description,
  scrollLeft,
  count,
}) => {
  const percentage = (count > 0) ? useMemo(() => (value || 0) * 100, [value]) : 0;
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  

  return (
    <CellStyled>
      <CellTitle>
        <Title>{name || '-'}</Title>
        {description && (
          <TooltipWrap>
            <IconWrap ref={(iconRef) => setRef(iconRef)}>
              <InfoIcon />
            </IconWrap>
            <Tooltip
              left={ref?.offsetLeft
                ? ref.offsetLeft - scrollLeft + (ref.clientWidth / 2) - 112
                : null}
            >
              <TooltipTitle>{name}</TooltipTitle>
              <TooltipSubTitle>{description}</TooltipSubTitle>
            </Tooltip>
          </TooltipWrap>
        )}
      </CellTitle>
      <CellInfo>
        {percentage >= 0 && count > 0 && (
          <PercentBlock>
            <NumOf>
              <NumOfAdd>{`${percentage.toFixed(0)}%`}</NumOfAdd>
            </NumOf>
          </PercentBlock>
        )}
        {(count === 0) && (
          <span>{`${nullStates}`}</span>
        )}
      </CellInfo>
    </CellStyled>
  );
};

const TooltipTitle = styled.div`
  font-size: 9px;
  font-weight: 600;
`;

const TooltipSubTitle = styled.div`
  font-size: 9px;
`;

const Tooltip = styled.div<{ left: number | null }>`
  padding: 12px;
  position: absolute;
  min-width: 224px;
  max-width: 224px;
  background-color: #fff;
  border: 1px solid rgba(182, 182, 182, 0.5);
  z-index: 3;
  display: none;
  flex-direction: column;
  border-radius: 4px;
  row-gap: 8px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
  margin-bottom: 100px;
  left: ${({ left }) => left && `${left}px`};

  ::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    background-color: #fff;
    bottom: -11px;
    left: 50%;
    translate: -50%;
    border-right: 1px solid rgba(182,182,182,0.5);
    border-bottom: 1px solid rgba(182,182,182,0.5);
    box-shadow: 3px 3px 3px rgb(0 0 0 / 12%);
  }
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const TooltipWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    ${Tooltip} {
      display: flex;
    };
  }
`;

const PercentBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const NumOf = styled.div`
  display: flex;
  align-items: flex-end;
  font-weight: 600;
  font-size: 22px;
`;

const NumOfAdd = styled.div`
  font-weight: 600;
  font-size: 22px;
`;

const CellStyled = styled.div`
  min-width: 270px;
  max-width: 370px;

  display: flex;
  flex-direction: column;
  row-gap: 14px;

  border: 1px solid rgba(182, 182, 182, 0.5);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 10px 15px;
`;

const CellTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;

  & img {
    cursor: pointer;
  }
`;

const Title = styled.h6`
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #4A4949;
  flex: 1;

  ${singleLine}
`;

const CellInfo = styled.div`
  width: 100%;
`;

export default XPICell;
