import React, { useState } from 'react';
import styled from 'styled-components';
import customScrollCss from '@/utils/custom-scroll-css';

import useOnClickOutside from '@/Components/hooks/useOnClickOutside';
import { IEvent, IViewAllEvents } from '@/api/main-protected';

import closeIcon from '@/assets/images/icons/close-black-icon.svg';

import SpeechBubbleIcon from '@/Components/icons/SpeechBubblesIcon';
import IconComponent from '@/Components/common/IconComponent';
import EventInfoPopup from '@/Components/Requests/EventInfoPopup';

import convertFromStringToDate, { FormatDate } from '@/utils/convertFromStringToDate';
// import LoaderDots from '@/Components/LoaderDots';

interface IModal {
  viewAllEvents: IViewAllEvents;
  onCloseModal: () => void;
}

const ModalViewAllEvents: React.FC<IModal> = ({ viewAllEvents, onCloseModal }) => {
  const [clickedEvent, setClickedEvent] = useState<IEvent | null>(null);
  const { request, events } = viewAllEvents;
  const editModalClickOutsideRef = useOnClickOutside(onCloseModal);

  const handleChartIconClick = (ev: IEvent) => {
    setClickedEvent(ev);
  };

  const handleCloseInfoWindow = () => {
    setClickedEvent(null);
  };

  return (
    <Layout>
      <ModalWrap ref={editModalClickOutsideRef}>
        <Title>
          {`${`${request?.category} - `}${request.itsm_id}`}
          <CloseIconBox onClick={onCloseModal}>
            <IconComponent icon={closeIcon} width={20} />
          </CloseIconBox>
        </Title>
        {events.map((eventsObj) => (
          <Main key={eventsObj[0]}>
            <Label>{eventsObj[0]}</Label>
            {eventsObj[1].map((event, i) => (
              <Column key={`${event.event_id}${i}`}>
                <Row>
                  <Decor />
                  <Description>{event.short_desc}</Description>
                  {!!event.allow_override && (
                    <IconWrap onClick={() => handleChartIconClick(event)}>
                      <SpeechBubbleIcon />
                      {clickedEvent?.event_id === event.event_id && (
                        <EventInfoPopup
                          event={event}
                          request={request}
                          onCloseModal={handleCloseInfoWindow}
                        />
                      )}
                    </IconWrap>
                  )}
                </Row>
                <Row>
                  <Decor />
                  <Text>
                    {convertFromStringToDate(event.event_date, FormatDate.ViewAllEventsFormat)}
                  </Text>
                </Row>
              </Column>
            ))}
          </Main>
        ))}
      </ModalWrap>
    </Layout>
  );
};

const Layout = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
const ModalWrap = styled.div`
  max-height: calc(100vh - 160px);
  max-width: 975px;
  min-width: 675px;
  width: 100%;
  max-height: 80vh;
  background: #ffffff;
  border-radius: 10px;
  padding: 0px 40px 55px;
  // margin: 90px auto 30px;
  overflow-y: auto;

  position: relative;

  @media (max-width: 725px) {
    min-width: 100%;
  }

  ${customScrollCss}
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #000000;
  background: #ffffff;
  padding: 45px 40px 30px 0;
  border-bottom: 1px solid #c4c4c4;

  position: sticky;
  top: 0;
  z-index: 10;
`;

const Main = styled.div`
  margin: 55px 0 0;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  // icon styles
  & span {
    margin: auto 0;
    cursor: pointer;
  }
`;

const Column = styled.div`
  max-width: 500px;

  @media (max-width: 1020px) {
    max-width: calc(100vw - 470px);
  }
`;

const Label = styled.p`
  font-weight: 800;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #868484;
  margin: 12px 0;
`;
const Description = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: -0.01em;
  color: #000000;
  border-left: 1px solid #c4c4c4;
  padding: 10px 10px 10px 30px;

  &:before {
    content: '';
    position: absolute;
    top: 8px;
    left: 15px;
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 50%;
  }
  &:after {
    content: '';
    position: absolute;
    top: 13px;
    left: 10.5px;
    width: 10px;
    height: 10px;
    background: #c4c4c4;
    border-radius: 50%;
  }
`;
const Text = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 7px;
  letter-spacing: -0.02em;
  color: #868484;
  padding: 0px 20px 30px 30px;
  border-left: 1px solid #c4c4c4;
`;

const CloseIconBox = styled.div`
  position: absolute;
  top: 45px;
  right: 0px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const Decor = styled.div`
  flex: 0 0 15px;
  width: 15px;
`;

const IconWrap = styled.div`
  display: flex;
  position: relative;
  height: 24px;
  margin: auto 0;
`;

export default ModalViewAllEvents;
