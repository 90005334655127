import React from 'react';
import styled from 'styled-components';

interface Props {
  width?: number | string;
  height?: number | string;
  color?: string;
}

const ShareIcon: React.FC<Props> = ({ width = '20', height = '20', color = '#212121' }) => (
  <Svg color={color} width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M11 16h2V7h3l-4-5-4 5h3z"/><path d="M5 22h14c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2h-4v2h4v9H5v-9h4V9H5c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2z"/></Svg>
);

const Svg = styled.svg<{ color: string }>`
  fill: ${({ color }) => color};
`;

export default ShareIcon;
