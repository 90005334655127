import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { IUser, IUserStatus } from '@/api/main-protected';

import editIcon from '@/assets/images/icons/edit-icon.svg';
import defaultProfile from '@/assets/images/icons/user-settings/user-profile-default-icon.svg';

import Btn from '@/Components/common/Signin/Btn';
import IconComponent from '@/Components/common/IconComponent';
import EditUserModal from '@/Components/UserAdmin/EditUserModal';
import { AppDispatch } from '@/App';

import { selectUserInfo } from '@/store/selectors/user';
import { userActions } from '@/store/actions/user';

import { getUserRoleName } from '@/utils/user';
import { getPermissions, getUserLevelPermission, Permissions } from '@/utils/permissions';
import { RequestState } from '@/store/reducers/common';

interface IUsersTable {
  users: IUser[]
}

const UsersTableBlock: React.FC<IUsersTable> = ({ users }) => {
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [editedUser, setEditedUser] = useState<IUser | null>(null);
  const { roles } = useSelector(selectUserInfo)!;

  const dispatch = useDispatch<AppDispatch>();

  const handleSelectAll = () => {
    const toggleAllCheckedUsers = users.map((user) => ({ ...user, isChecked: !isCheckedAll }));

    dispatch(userActions.setUsers(toggleAllCheckedUsers));
    setIsCheckedAll(!isCheckedAll);
  };

  const handleCheck = (user: IUser) => {
    const updateUsersOnUserCheck = users.map((userItem) => {
      if (userItem.user_id === user.user_id) {
        const updatedUser = {
          ...userItem,
          isChecked: !userItem.isChecked,
        };
        return updatedUser;
      }
      return userItem;
    }) as IUser[];

    dispatch(userActions.setUsers(updateUsersOnUserCheck));

    const checkedUsersAmount = updateUsersOnUserCheck.filter((u) => u.isChecked).length;

    setIsCheckedAll(users.length === checkedUsersAmount);
  };

  const getEditedUser = (id: string) => {
    const edited = [...users].find((user) => user.user_id === id);
    setEditedUser(edited!);
  };

  const handleCloseModal = () => {
    setEditedUser(null);
    dispatch(userActions.setDeleteUserState(RequestState.IDLE));
  };

  return (
    <Wrap>
      <Grid>
        {/* <FixedTop className='checkbox'>
          <Checkbox onCheck={handleSelectAll} noMargins isChecked={isCheckedAll} />
        </FixedTop> */}
        <FixedTop className="fixed-left">name</FixedTop>
        <FixedTop>Phone Number</FixedTop>
        <FixedTop>Role</FixedTop>
        {/* <FixedTop>Country</FixedTop> */}
        {/* <FixedTop>Status</FixedTop> */}
        {/* <FixedTop>Organization</FixedTop> */}
        <FixedTop />

        {users.map((user) => (user.roles ? (
          <Fragment key={user.user_id}>
            {/* <GridCell className='checkbox'>
                <Checkbox
                  onCheck={() => handleCheck(user)}
                  noMargins
                  isChecked={!!user.isChecked}
                />
              </GridCell> */}
            <GridCell className="fixed-left">
              <Icon>
                <IconComponent icon={user.picture || defaultProfile} />
              </Icon>
              <User>
                <Bold>
                  {`${user.first_name ? user.first_name : ''} ${
                    user.last_name ? user.last_name : ''
                  }`}
                </Bold>
                <Normal>{user.email}</Normal>
              </User>
            </GridCell>
            <GridCell>{user?.phone_numbers[0]?.phone || '-'}</GridCell>
            <GridCell>
              <Box>
                <Bold>{getUserRoleName(user.roles[0]) || '-'}</Bold>
              </Box>
            </GridCell>
            {/* <GridCell>{user.country}</GridCell> */}
            {/* <GridCell>
              <Dot isActive={user.status.toLowerCase() === IUserStatus.Active} />
              <Span>{user.status.toLowerCase() === IUserStatus.Active ? 'Active' : 'Inactive'}</Span>
            </GridCell> */}
            {/* <GridCell>{user.business_unit}</GridCell> */}
            <GridCell>
              {getPermissions(roles[0], Permissions.EditUser)
              && getUserLevelPermission(roles[0], user.roles[0])
               && (
               <BtnWrap>
                 <Btn isTransparent onClick={() => getEditedUser(user.user_id)}>
                   <IconComponent icon={editIcon} width={10} />
                   <SpanBtn>Edit</SpanBtn>
                 </Btn>
               </BtnWrap>
               )}
            </GridCell>
          </Fragment>
        ) : null))}
      </Grid>
      {editedUser && <EditUserModal editedUser={editedUser} onCloseModal={handleCloseModal} />}
    </Wrap>
  );
};

const Wrap = styled.div`
`;

const Grid = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  /* min-width: 1140px; */
  min-width: 1000px;
  // width: 100%;
  // height: 100%;
  // position: relative;

  display: grid;
  grid-template-columns:
    // minmax(3.94%, 3.94%)
    minmax(22.8%, auto)
    minmax(15.79%, auto)
    minmax(14.91%, auto)
    // minmax(13.15%, auto) // country
    /* minmax(10.08%, auto) // status */
    // minmax(12.28%, 12.28%) // business_unit column
    minmax(7.4%, 7.4%);
  grid-auto-rows: min-content;

  font-weight: 400;
  font-size: 16px;
  color: #4a4949;
`;
const FixedTop = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;

  height: 50px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 10px 10px 0;
  background: #f5f5f5;

  &.fixed-left {
    position: sticky;
    left: 0;
    z-index: 2;

    padding-left: 15px;
  }

  &.checkbox {
    padding-left: 18px;
  }
`;
const GridCell = styled.div`
  display: flex;
  align-items: center;

  max-height: 80px;
  padding: 15px 10px 15px 0;
  background-color: white;
  word-break: break-word;

  &.fixed-left {
    position: sticky;
    left: 0;

    padding-left: 15px;
  }

  &.checkbox {
    padding-left: 18px;
  }

  & button {
    height: 32px;
  }
`;

const Icon = styled.div`
  overflow: hidden;
  position: relative;
  background: #f5f5f5;
  flex: 0 0 43px;
  width: 43px;
  height: 43px;
  border-radius: 50%;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const User = styled.div`
  margin-left: 14px;
`;
const Bold = styled.div`
  font-weight: 600;
  margin: 4px 0;
`;
const Normal = styled.div`
  margin: 4px 0;
`;
const Box = styled.div``;
const Span = styled.div`
  text-transform: capitalize;
`;
const SpanBtn = styled(Span)`
  margin-left: 5px;
`;
const Dot = styled.div<{ isActive: boolean }>`
  flex: 0 0 10px;
  width: 10px;
  height: 10px;
  background-color: ${({ isActive }) => (isActive ? '#1EDB0E' : '#FFBF1A')};
  border-radius: 50%;
  margin-right: 10px;
`;

const BtnWrap = styled.div`
  flex: 0 0 70px;
  width: 70px;
`;

export default UsersTableBlock;
