import React from 'react';
import styled from 'styled-components';

interface Props<T> {
  tab: T;
  setTab: (tab: T) => void;
  selectedTab: string;
}

const Tab = <T extends string>({
  tab, setTab, selectedTab,
}: Props<T>) => {
  const handleSetTab = () => {
    setTab(tab);
  };

  return (
    <TabStyled isActive={selectedTab === tab} onClick={handleSetTab}>{tab}</TabStyled>
  );
};

const TabStyled = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 95px;
  width: fit-content;
  height: 30px;
  padding: 5px 15px;

  white-space: nowrap;

  border-bottom: 2px solid ${({ isActive }) => (isActive ? '#0066ff' : 'transparent')};

  font-weight: 600;
  font-size: 14px;
  color: ${({ isActive }) => (isActive ? '#0066ff' : '#868484')};
  text-transform: capitalize;
  cursor: pointer;

  @media (hover: hover) {
    transition: all 0.3s ease;
  }
`;

export default Tab;
