import React, { useState } from 'react';

import AllRequestContainer from '@/Components/Requests/RequestTable/AllRequestContainer';
import EventsContainer from '@/Components/Requests/RequestTable/EventsContainer';

export enum IEventTabs {
  ALL_REQUESTS = 'All Requests',
  EVENTS = 'Events',
}

interface Props {
  interval: number;
}

const RequestContainer: React.FC<Props> = ({ interval }) => {
  const [selectedTab, setSelectedTab] = useState(IEventTabs.ALL_REQUESTS);

  const showContent = () => {
    switch (selectedTab) {
      case IEventTabs.ALL_REQUESTS:
        return (
          <AllRequestContainer
            interval={interval}
            setSelectedTab={setSelectedTab}
            selectedTab={IEventTabs.ALL_REQUESTS}
            tabs={Object.values(IEventTabs)}
            title="All Requests"
          />
        );
      case IEventTabs.EVENTS:
        return (
          <EventsContainer
            interval={interval}
            setSelectedTab={setSelectedTab}
            selectedTab={IEventTabs.EVENTS}
            tabs={Object.values(IEventTabs)}
            title="All Requests"
          />
        );
      default:
        return <></>;
    }
  };

  return (
    showContent()
  );
};

export default RequestContainer;
