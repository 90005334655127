import React, { useState } from 'react';
import styled from 'styled-components';

import LocalStorage from '@/local-storage/LocalStorage';

import ContentContainer from '../common/ContentContainer/ContentContainer';
import EventComponent from '../common/Event';
import ScrollableContainer from '../common/ScrollableContainer';
import MapLeafletContainer from '../common/MapLeafletContainer';

import { EVENTS } from '@/mockData/events';
import cities from '@/mockData/cities';

const PERCENT_INFO_ITEMS = [
  {
    cellTitle: 'Warranty Repairs to Date', numOf: '232', numOfAdd: 'of 25,000 (CIs)', perOf: -12,
  },
  {
    cellTitle: 'Onboarding Kit Delivery', numOf: '76', numOfAdd: '', perOf: +2.1,
  },
  {
    cellTitle: 'End-of-Life Refresh Progress', numOf: '3', numOfAdd: '', perOf: +6,
  },
  {
    cellTitle: 'Device Upgrade Requests to Date', numOf: '2', numOfAdd: '', perOf: +11,
  },
  {
    cellTitle: 'Warranty Repairs', numOf: '232', numOfAdd: 'of 85,000', perOf: -12,
  },
];

const Events: React.FC = () => {
  const [isFiltersShow, setIsFiltersShow] = useState(false);
  const [eventsScrolling, setEventsScrolling] = useState<number | null>(null);

  return (
    <>
      <ContentContainer title="Events" isInfoBtn updated="Updated April 11, 2022  at  02:06PM" />
      <ContentContainer
        title="All Events"
        isSubContainer
        amount={EVENTS.length}
        // isViewAll
        isFiltersShow={isFiltersShow}
        setIsFiltersShow={() => setIsFiltersShow(!isFiltersShow)}
      >
        {/* <Columns>
          <ScrollableContainer padding='15px 0' setEventsScrolling={setEventsScrolling}>
            {EVENTS.map(event => (
              <EventComponent key={event.description} event={event} scrolling={eventsScrolling} />
            ))}
          </ScrollableContainer>
          <MapLeafletContainer data={cities} />
        </Columns> */}
      </ContentContainer>
    </>
  );
};

const Columns = styled.div`
  display: flex;
  border: 1px solid rgba(196, 196, 196, 0.6);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
`;

export default Events;
