import HttpClientProtected from '@/api/http-client-protected';
import { ROLES } from '@/api/main';

import { ITableBody } from '@/interfaces/api';
import { IMetrics } from '@/interfaces/metrics';

export enum IUserStatus {
  Active = 'active',
  NonActive = 'non-active',
}

export interface CommonUser {
  first_name: string;
  last_name: string;
  phone_numbers: IPhoneInfo[];
  status: IUserStatus;
  picture: string;
  position_title?: string;
  business_unit: string;
  email: string;
  country: string;
  roles: ROLES[];
  isChecked?: boolean;
  password?: string;
  joined_date?: string;
  isActive?: boolean | any; // need implementation on back
}

export interface IUser extends CommonUser {
  user_id: string;
}

export interface UserInfo {
  user: IUser;
}

export interface Users {
  users: IUser[];
  statistic: IUsersStatic;
}

export interface IUpdatePsw {
  password: string;
}

export interface IUpdateProfile {
  first_name: string;
  last_name: string;
  email?: string;
  phone_numbers: IPhoneInfo[];
  country: string;
  business_unit: string;
  role?: string;
}

export enum IPhoneType {
  Mobile = 'mobile',
  Home = 'home',
  Work = 'work',
  Fax = 'fax',
  Direct = 'direct',
}

export interface IPhoneInfo {
  phone: string;
  type: IPhoneType;
}

export interface IAddUser {
  first_name: string;
  last_name: string;
  email: string;
  phone_numbers: IPhoneInfo[];
  country: string;
  business_unit: string;
  password: string;
  role: ROLES
}

export interface IImpactedUser {
  first_name: string;
  last_name: string;
  address1: string;
  address2: string;
  city: string;
  state_or_province: string;
  postal_code: string;
  country: string;
  email: string;
  phone: string;
}

export interface ImpactUserResponse {
  impacted_user: IImpactedUser;
}

export interface IImpactedUserBody {
  itsm_id: string;
  employee_id: string;
}

export interface IRequestNote {
  user: string
  note: string
  created_date: string
}
export interface Request {
  request_id: number;
  event_id: number;
  // requestor: string
  notes: IRequestNote[] | [];
  date_opened?: string;
  date_closed: string;
  action_date: string;
  impacted_user_id: string; // employee_id
  impacted_user: IImpactedUser;
  itsm_id: string;
  location: string;
  status: string;
  category: string;
  serial_numbers: string[];
  incidents_count: number;
  incidents: number[];
  agency: string;
  tracking_number: string | null;
  return_tracking_number: string | null;
  delivery_date: string | null;
  estimated_delivery_dates: string | null;
  expected_delivery_date: string | null; // need to be added on back
  runbook: string;
  xp_event_id: number;
  priority: number; // check type for all project
  events: {
    event_id: number;
    priority: number;
    event_key: string;
    long_desc: string;
    event_date: string;
    event_type: string;
    short_desc: string;
    incidents: {
      incident_id: number;
      start_date: string;
      last_modified: string;
      description: string;
    }[];
  }[];
  items: IAsset[]; // added locally
  isModal?: boolean; // added locally to show modal from search
}

export interface Requests {
  requests: Request[];
}
export interface RequestsStatic {
  orders_count: number;
}
export interface EventsStatic {
  events: number;
}

export interface SeparateRequestInfo {
  work_order_id: number;
  requsetor: string;
  runbook: string;
  events: number[];
  incidents: number[];
  notes: INote[];
}

export interface INote {
  date: string;
  action: string;
  warn?: string;
}

interface Incident {
  incident: IIncident;
}

export interface SubRequest {
  impacted_user_id: string; // employee_id
  itsm_id: string;
  category: string;
  notes: any; // string - need fixing
  request_id: number;
  serial_numbers: string[];
  impacted_user: IImpactedUser;
  events: IEvent[];
}

export interface SubIncidentInSubEvent {
  start_date: string | null;
  description: string | null;
  incident_id: string | number | null;
  last_modified: string | null;
}

// add to IEvent and check for all project
export interface SubEvent {
  event_date: string;
  event_id: number;
  event_key: string;
  event_type: string;
  incidents: SubIncidentInSubEvent[];
  long_desc: string;
  priority: number;
  short_desc: string;
}

export interface IEvent {
  allow_override: number;
  xp_event_id: number;
  event_id: number;
  event_date: string;
  priority: number;
  event_key: string;
  // request: SubRequest;
  request: Request;
  status: string;
  long_desc: string;
  event_type: string;
  incident_count: number;
  short_desc: string;
  user_response: string;
  incidents: {
    start_date: string;
    incident_id: number;
    last_modified: string;
    response: string;
    status: string; // not added yet
    description: string; // not added yet
  }[];
  events?: IEvent[]; // change to SubEvent and removed from doc
  // events: {
  //   event_date: string;
  //   event_id: number;
  //   event_key: string;
  //   event_type: string;
  //   incidents: {
  //     start_date: string | null,
  //     description: string | null,
  //     incident_id: string | number | null,
  //     last_modified: string | null,
  //   }[]
  //   long_desc: string;
  //   priority: number;
  //   short_desc: string;
  // }[];
  request_id: string | number; // added locally for table sort
  items: IAsset[]; // added locally
  overriden: {
    user: string;
    time: string;
  }; // added locally, needs backend implementation
}

interface Events {
  events: IEvent[];
}

export interface IReturns {
  total: number;
  device_returns: IAssetsStatuses[];
}

export interface IAssetsStatuses {
  name: string;
  total: number;
}

export interface IAssetsByType {
  total: number;
  statuses: IAssetsStatuses[];
}

export interface IAssetsByMake {
  total: number;
  topAssets: { name: string; total: number }[];
}

export interface IAsset {
  asset_tag: string;
  asset_type: string;
  model: string;
  status: string;
  warranty_date: string;
  serial_number: string;
  make_model: {
    manufacturer: string;
    model: string;
  };
  requests: {
    request_id: number;
    itsm_id: string;
    impacted_user: {
      first_name: string;
      last_name: string;
      address1: string;
      address2: string;
      city: string;
      state_or_province: string;
      postal_code: string;
      country: string;
      email: string;
      phone: string;
    };
  }[];
}

export interface Assets {
  assets: IAsset[];
}

export interface IItemsFilters {
  [filter: string]: string[];
}

export interface AssetsTotal {
  total: number;
}

export interface IPeripheral {
  type: string;
  deployed: number;
  in_stock: number;
}

export interface Peripherals {
  peripherals: IPeripheral[];
}

export interface IIncident {
  latitude: number;
  longitude: number;
  country: string;
  response: string;
  incident_id: number;

  event: {
    event_type: string;
    event_id: number;
    short_desc: string;
    long_desc: string;
    action: string;
    event_date: string;
  };
  request: Request; // added localy for cc page request modal
}
export interface CCIncidents {
  incidents: IIncident[];
}

export interface IUsersStatic {
  total: number;
  active: number;
}

// export interface UsersStatic {
//   statistic: IUsersStatic;
// }

export interface ILocation {
  country_name: string;
  country_code: string;
  title: string;
}

export interface Locations {
  locations: ILocation[];
}

export interface IDepartment {
  name: string;
  department_id: number;
}
export interface Departments {
  departments: IDepartment[];
}

export interface IStatus {
  status: string;
}

export interface ICountChartItem {
  total: number;
  other: number;
}

export interface InnerChartItem {
  name: string | null;
  total: number;
}

export interface DynamicChartItem {
  [arrName: string]: InnerChartItem[];
}

export type RequestsChartItem = ICountChartItem & DynamicChartItem;

export interface RequestsChartByServiceItem {
  total: number;
  expedited: number;
  saturday_delivery: number;
  ground: number;
}

export interface NoteBody {
  itsm_id: string;
  note: string | any;
  work_order_id: number;
  user: string;
}

export interface OverrideBody {
  work_order_id: number
  itsm_id: string
  override_type: string
  xp_event_id: number
  user: string
}

export interface CancelRequestBody {
  override_type: string,
  itsm_id: string,
  xp_event_id: number,
  work_order_id: number,
  username: string
}

export interface RequestsByDateAndActivityStatus {
  [date: string]: { status: string; total: number }[];
}

export interface IGlobalSearch {
  assets: IAsset[];
  events: IEvent[];
  requests: Request[];
}

export interface IRequestsSearch {
  requests: Request[];
}

export interface IAssetsSearch {
  assets: IAsset[];
}

export interface IEventsSearch {
  events: IEvent[];
}

export interface IViewAllEvents {
  request: Request;
  events: [string, IEvent[]][];
}

interface FakeUser {
  name: string
  profile: string
}

export interface ITicket {
  id: number;
  status: string;
  description: string
  type: string
  priority: string
  subject: string
  comment: {
    body: string
    uploads: string[]
  }
  user: FakeUser
  created_at : string
  resolved: boolean
  responses: {
    user: FakeUser,
    description: string,
    date_created: string,
  }[]
}

export interface IAttachment {
  id: string
  file_name: string
  content_url: string
}

export interface ITicketComment {
  id: string
  body: string
  author: {
    id: number
    name: string
    email: string
  }
  attachments: IAttachment[]
  created_at: string
}

export interface ITicketCreate {
  description: string
  type: string
  priority: string
  subject: string
  comment: {
    body: string
    uploads: string[]
  }
}

export interface ITicketUpdate {
  priority: string
  subject: string
  comment: {
    body: string
    uploads: string[]
  }
}

export interface ITicketBody {
  id: number
  description: string
  type: string
  priority: string
  subject: string
  attachments: IAttachment[]
  requester: {
    id: number
    name: string
    profile: string
    email: string
    photo: string
  }
  comments: ITicketComment[]
  created_at: string
}

export enum RequestsFilter {
  ALL_REQUESTS = '',
  URGENT = 'urgent',
  NEEDS_VERIFYING = 'needs_verification',
  IN_DEPLOY = 'in_deploy',
  IN_TRANSIT = 'in_transit',
  // EVENTS = 'events',
}

export default class MainProtected extends HttpClientProtected {
  private static instanceCached: MainProtected;

  private constructor() {
    super(process.env.BASE_URL);
  }

  static getInstance = () => {
    if (!MainProtected.instanceCached) {
      MainProtected.instanceCached = new MainProtected();
    }

    return MainProtected.instanceCached;
  };

  public getUserInfo = () => this.instance.get<UserInfo>('/me');

  public updateUserInfo = (body: IUpdatePsw | IUpdateProfile) => this.instance.put('/me/update', body);

  public getUsers = () => this.instance.get<Users>('/users');

  public getImpactedUser = (body: IImpactedUserBody) => this.instance.post<ImpactUserResponse>('/users/impacted', body);

  public updateUser = (id: string, body: IUpdatePsw | IUpdateProfile) => this.instance.put(`/users/${id}`, body);

  public addUser = (body: IAddUser) => this.instance.post<{ user_id: string }>('/users', body);

  public deleteUser = (id: string) => this.instance.delete(`/users/${id}`);

  public getUserDepartments = () => this.instance.get<Departments>('/departments');

  public updateUserRole = (body: { role: ROLES }, id: string) => this.instance.put(`/users/role/${id}`, body);

  public getAuthReadme = () => this.instance.get<any>('/authReadme');

  public getCCIncidents = (queries: string) => this.instance.get<CCIncidents>(`/events/incidents${queries}`);

  public getRequests = (body: ITableBody) => this.instance.post<Requests>('/requests', body);

  public getRequestsStatic = (body: ITableBody) => this.instance.post<RequestsStatic>('/requests/statistic', body);

  public getEventsStatic = (body: ITableBody) => this.instance.post<EventsStatic>('/events/statistic', body);

  public getIncident = (id: number) => this.instance.get<Incident>(`/events/incidents/${id}`);

  public getIncidentRequest = (eventId: number) => this.instance.get<{ request: Request }>(`/requests/events/${eventId}`);

  public getEvents = (body: ITableBody) => this.instance.post<Events>('/events', body);

  public getEvent = (id: number) => this.instance.get<IEvent>(`/events/${id}`);

  public getTableItem = (serialNumber: string): Promise<{ items: IAsset[] }> => this.instance.get(`/items/${serialNumber}`);

  public getRequestsFilters = () => this.instance.get<IItemsFilters>('/requests/filters');

  public getEventsFilters = () => this.instance.get<IItemsFilters>('/events/filters');

  public getTasksByCategory = (interval: number) => this.instance.get<RequestsChartItem>(
    `/requests/by-category${interval && `?interval=${interval}`}`,
  );

  public getTasksByStatus = (interval: number) => this.instance.get<RequestsChartItem>(
    `/requests/by-status${interval && `?interval=${interval}`}`,
  );

  public getTasksByService = (interval: number) => this.instance.get<RequestsChartByServiceItem>(
    `/requests/by-service${interval && `?interval=${interval}`}`,
  );

  public getRequestsByActionDateAndStatus = (
    interval: number,
  ) => this.instance.get<RequestsByDateAndActivityStatus>(
    `/requests/by-action-date-and-status${interval && `?interval=${interval}`}`,
  );

  public getAdvancedReplacementByItemType = (
    interval: number,
  ) => this.instance.get<RequestsChartItem>(
    `/requests/advanced-replacements/by-item-type${interval && `?interval=${interval}`}`,
  );

  public getAdvancedReplacementByReasonCode = (
    interval: number,
  ) => this.instance.get<RequestsChartItem>(
    `/requests/advanced-replacements/by-reason-code${interval && `?interval=${interval}`}`,
  );

  public addNote = (body: NoteBody) => this.instance.post('/requests/notes', body);

  public overrideEvent = (body: OverrideBody) => this.instance.post('/requests/work_order', body);

  public cancelRequest = (body: CancelRequestBody) => this.instance.post('/requests/cancel', body);

  // items page
  public getReturns = (interval: number) => this.instance.get<RequestsChartItem>(`/items/returns${interval && `?interval=${interval}`}`);

  public getAssetsByType = (interval: number) => this.instance.get<RequestsChartItem>(`/items/assets/by-type${interval && `?interval=${interval}`}`);

  public getAssets = (body: ITableBody) => this.instance.post<Assets>('/items/assets', body);

  public exportCSVAssets = (body: ITableBody) => this.instance.post('/items/assets/export/csv', body);

  public getItemsFilters = () => this.instance.get<IItemsFilters>('/items/assets/filters');

  public getIncidentsFilters = () => this.instance.get<IItemsFilters>('/events/incidents/filters');

  public getAssetsTotal = (body: ITableBody) => this.instance.post<AssetsTotal>('/items/assets/total', body);

  public getPeripheralsTotal = (body: ITableBody) => this.instance.post<AssetsTotal>('/items/peripherals/total', body);

  public getIncidentsTotal = (queries: string) => this.instance.get<AssetsTotal>(`/events/incidents/statistic${queries}`);

  public getPeripherals = (body: ITableBody) => this.instance.post<{ peripherals: IPeripheral[] }>('/items/peripherals', body);

  public getAssestsByMake = (interval: number) => this.instance.get<IAssetsByMake>(`/items/assets/make${interval && `?interval=${interval}`}`);

  // support center page
  // preparetion for tickets start
  public addTicket = (body: ITicketCreate) => this.instance.post<any>('/zendesk/ticket', body);

  public getTickets = () => this.instance.get<any>('/zendesk/tickets');

  public getTicket = (id: number) => this.instance.get<ITicketBody>(`/zendesk/ticket/${id}`);

  public updateTicket = (id: number, body: ITicketUpdate) => this.instance.put(`/zendesk/ticket/${id}`, body);

  public getTicketsFilters = () => this.instance.get<IItemsFilters>('/zendesk/tickets/filters');

  public getTicketsTotal = (body: ITableBody) => this.instance.post<AssetsTotal>('/zendesk/tickets/total', body);
  // preparetion for tickets end

  // common
  public locations = () => this.instance.get<Locations>('/locations');

  public getMetrics = (interval: number) => this.instance.get<{ xlas: IMetrics[] }>(`/metrics?interval=${interval}`);

  public getGlobalSearch = (query: string) => this.instance.get<IGlobalSearch>(`/search?query=${query}`);
}
