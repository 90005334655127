import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Auth0Error } from 'auth0-js';
import customScrollCss from '@/utils/custom-scroll-css';

import {
  auth,
  AUTH0_LOGIN_REDIRECT_URI,
  AUTH0_LOGIN_RESPONSE_TYPE,
  AUTH0_REALM,
} from '@/services/auth0.service';

import { PAGES } from '@/Components/Main/Main';
import Input from '@/Components/common/Input';
import Button from '@/Components/common/Signin/Btn';
import Checkbox from '@/Components/common/Checkbox';
import IconComponent from '@/Components/common/IconComponent';
import LoaderDots from '@/Components/LoaderDots';

import loginLogo from '@/assets/images/logo_color_stacked.svg';
import bg from '@/assets/images/bg.jpeg';

import useInput from '@/Components/hooks/useInput';
import useToggle from '@/Components/hooks/useToggle';

import { isValidEmail } from '@/utils/validation';
import getBrowserName from '@/utils/browsers';
import LocalStorage from '@/local-storage/LocalStorage';

const LoginForm: React.FC = () => {
  const [mail, setMail] = useInput(LocalStorage.getEmail() || '');
  const [isMailError, setIsMailError] = useState(false);
  const [password, setPassword] = useInput('');
  const [isPswError, setIsPswError] = useState(false);
  const [rememberMe, setRememberMe] = useToggle(true);
  const [isSending, setIsSending] = useState(false);
  const [isAuthError, setIsAuthError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const browser = getBrowserName();

  const validatePsw = (psw: string) =>
    // return psw.toLowerCase() === 'lambdapass'
    psw.toLowerCase() !== '';
  const loginClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!isValidEmail(mail)) {
      setIsMailError(true);
      return;
    }

    if (!validatePsw(password)) {
      setIsPswError(true);
      return;
    }

    setIsSending(true);

    // dispatch(loginActions.setMail(mail))
    // dispatch(loginActions.setRememberUserData(rememberMe))
    // dispatch(login(data, rememberMe))
    // history.push('/')
    // history.push('/login-next-step')

    // LocalStorage.setEmail(mail)
    // auth.crossOriginVerification()

    auth.login(
      {
        username: mail,
        password,
        realm: AUTH0_REALM,
        redirectUri: AUTH0_LOGIN_REDIRECT_URI,
        responseType: AUTH0_LOGIN_RESPONSE_TYPE,
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (error: Auth0Error | null, res: any) => {
        // !!! check app behavior on error
        if (error) {
          console.error(`Something went wrong on login: ${error}`);
          // setErrorMessage(error.description)
          setIsAuthError(true);
          setIsSending(false);
          return;
        }

        // console.log('1) login res: ', res);
        // history.push('/login-next-step')
        console.info('success login!');
      },
    );
  };

  const handleEmailInputClick = () => {
    // if (errorMessage) {
    //   setErrorMessage(undefined)
    // }

    if (isMailError) {
      setIsMailError(false);
    }

    if (isAuthError) {
      setIsAuthError(false);
    }
  };

  const handlePswInputClick = () => {
    // if (errorMessage) {
    //   setErrorMessage(undefined)
    // }

    if (isPswError) {
      setIsPswError(false);
    }
    if (isAuthError) {
      setIsAuthError(false);
    }
  };

  const handleSetMail = (value: string) => {
    setMail(value);
    LocalStorage.setEmail(value);
  };

  return (
    <PageContainer>
      <BgImage>
        <Img src={bg} />
      </BgImage>
      <SigninContainer>
        <IconComponent icon={loginLogo} />
        <Title>Sign in</Title>

        <Form className="login-form">
          <Input
            id="Mail"
            name="Mail"
            onChange={handleSetMail}
            onClick={handleEmailInputClick}
            value={mail}
            placeholder="username@email.com"
            label="email"
            errorText="Please enter a valid email address"
            isError={isMailError || isAuthError}
          />
          <ControlsBlock>
            <Input
              id="Password"
              name="Password"
              onChange={setPassword}
              onClick={handlePswInputClick}
              value={password}
              label="Password"
              isHideMode
              errorText="Please enter a valid password"
              isError={isPswError || isAuthError}
              browser={browser}
            />
            <Link to={PAGES.RESET_PSW}>
              <ChangePassword>Forgot password?</ChangePassword>
            </Link>
          </ControlsBlock>
          {/* {errorMessage && <Error>{errorMessage}</Error>} */}

          <Checkbox onCheck={setRememberMe} isChecked={rememberMe} isRoundCorners>
            Remember me
          </Checkbox>
          <Button
            isLogInBtn
            fullWidth
            onClick={loginClickHandler}
            isActive={!isMailError && !!password && mail.length > 0}
            isLoading={isSending}
          >
            {isSending ? <LoaderDots /> : 'SIGN IN'}
          </Button>
        </Form>
      </SigninContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  position: relative;
  // width: 100vw;
  min-height: 100vh;
  padding: 14.8vmin 3.4vmin 3.2vmin;

  background-color: #e5e5e5;

  & > div > img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const BgImage = styled.div``;
const Img = styled.img``;
const SigninContainer = styled.div`
  position: relative;
  max-width: 490px;
  width: 100%;
  // max-height: calc(100vh - 21vmin);
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 0 auto;
  padding: 6.7vmin 35px;
  z-index: 2;
  // overflow-y: auto;

  & > div:first-child {
    max-width: 195px;
    width: 100%;
    margin: 0 auto;
  }

  @media (hover: hover) {
    ${customScrollCss}
  }
`;
const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;
  letter-spacing: -0.02em;
  padding: 22px 10px;
  margin-bottom: 42px;
  border-bottom: 1px solid #c4c4c4;
`;

const Form = styled.form``;

const ControlsBlock = styled.div`
  position: relative;
  width: 100%;
  margin: 17px 0 24px 0;

  & > a {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
`;

const ChangePassword = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #0066ff;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;

// const Error = styled.p`
//   color: #ff0505;
// `;

// const SignUpLink = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `

// const SignUpText = styled.p`
//   margin-right: 8px;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 22px;
//   color: ${({ theme }) => theme.palette.gray};
// `

// const LinkText = styled.p`
//   font-weight: 700;
//   font-size: 14px;
//   line-height: 17px;
//   color: ${({ theme }) => theme.palette.main};

//   &:hover {
//     text-decoration: underline;
//   }
// `

export default LoginForm;
