import React from 'react';
import styled from 'styled-components';

interface Props {
  width?: number | string;
  height?: number | string;
  color?: string;
  backgroundColor?: string;
}

const ArrowIcon: React.FC<Props> = ({
  width = '24',
  height = '24',
  backgroundColor = '#fff',
  color = '#212121',
}) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="24"
      y="24"
      width="24"
      height="24"
      rx="8"
      fill={backgroundColor}
    />
    <path
      d="M8 12C8 12.2151 8.08403 12.4301 8.25175 12.5941L13.532 17.7538C13.8679 18.0821 14.4124 18.0821 14.7482 17.7538C15.0839 17.4257 15.0839 16.8937 14.7482 16.5654L10.076 12L14.748 7.43459C15.0838 7.10636 15.0838 6.57436 14.748 6.24629C14.4123 5.9179 13.8677 5.9179 13.5318 6.24629L8.25158 11.406C8.08384 11.57 8 11.785 8 12Z"
      fill={color}
    />
  </Svg>
);

const Svg = styled.svg`
  transform: rotate(-90deg);
`;

export default ArrowIcon;
