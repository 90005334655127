/* eslint-disable no-useless-escape */
export const numericAlpha = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
export const upper = /[A-Z]/;
export const lower = /[a-z]/;
export const special = /[\s~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?()\._]/;
export const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const regexPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

export const isValidEmail = (email: string): boolean => regexEmail.test(email.toLowerCase());

export const isValidPhone = (phone: string): boolean => regexPhone.test(phone);

export const isSpecialSymbol = (value: string): boolean => special.test(value);

export const isNumericAlpha = (value: string): boolean => numericAlpha.test(value);

export const isUpperLetter = (value: string): boolean => upper.test(value);

export const isLowerLetter = (value: string): boolean => lower.test(value);

export const isEmptyObject = <T extends Object>(obj: T): boolean => !!Object.entries(obj).length;
