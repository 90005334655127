import React from 'react';
import styled from 'styled-components';

import ContentContainer from '@/Components/common/ContentContainer/ContentContainer';

const ReleaseNotes: React.FC = () => (
  <ContentContainer title="Changelog">
    <Container>
      <IFrame name="changelog" title="changelog" src="https://headwayapp.co/xperienc-changelog" />
    </Container>
  </ContentContainer>

);

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(100vh - 116px);

  overflow: hidden;
`;

const IFrame = styled.iframe`
  position: absolute;
  top: -80px;
  left: 0;
  width: 100%;
  height: 100%;

  & #body-cont {
    border: solid 1px red;
  }
  & header {
    display: none;
  }
`;

// const NoteBox = styled.div`
//   margin: 10px 0 20px;

//   &:not(:last-child) {
//     border-bottom: 1px solid rgba(196, 196, 196, 0.7);
//   }
// `;
// const Version = styled.div`
//   font-weight: 600;
//   font-size: 16px;
//   line-height: 23px;
// `;
// const TextDate = styled.p`
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 147.9%;
// `;
// const Notes = styled.div`
//   margin: 20px 0 20px 8px;
// `;

// const Text = styled.p`
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 150%;

//   padding-left: 10px;
//   position: relative;

//   &:before {
//     content: '';
//     position: absolute;
//     top: 7px;
//     left: 0;
//     width: 2px;
//     height: 2px;
//     background: #000000;
//     border-radius: 50%;
//   }
// `;

export default ReleaseNotes;
