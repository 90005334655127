import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { IIcon } from '@/interfaces/icon';

import useOnClickOutside from '@/Components/hooks/useOnClickOutside';

interface IPageItem {
  icon: React.FC<IIcon>
  text: string
  path: string
  subItems?: { text: string; path: string[] }[]
  isArr?: boolean
  isActive: boolean
  isExpanded?: boolean
}

const PageItem: React.FC<IPageItem> = ({
  icon,
  text,
  path,
  subItems = [],
  isArr = false,
  isActive,
  isExpanded,
}) => {
  const [isHidden, setIsHidden] = useState(true);

  const { pathname } = useLocation();

  const handleArrowClick = () => {
    if (isArr) {
      setIsHidden(!isHidden);
    }
  };

  const hiddenBlock = useOnClickOutside(() => setIsHidden(false));

  const SideBar = icon;

  return (
    <Link to={path}>
      <Inner>
        <Item onClick={handleArrowClick}>
          <Icon>
            {!!icon && (
              <SideBar color={isActive ? '#0066FF' : '#000000'} />
            )}
          </Icon>
          {text && (
            <TextBlock>
              <Text isActive={isActive}>{text}</Text>
              {/* {isCount && +number! > 0 && <Count>{number}</Count>} */}
            </TextBlock>
          )}
        </Item>
        {isArr && !isHidden && isExpanded && (
          <HiddenBlock ref={hiddenBlock}>
            {subItems.map((item) => (
              <Link key={item.path[0]} to={item.path[0]}>
                <Text isActive={item.path.includes(pathname)}>{item.text}</Text>
              </Link>
            ))}
          </HiddenBlock>
        )}
      </Inner>
    </Link>
  );
};

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 10px;
  width: 100%;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;

  & img {
    max-width: 100%;
  }
`;

const TextBlock = styled.div`
  cursor: pointer;
  flex: 1;
`;

const Text = styled.p<{ isActive: boolean }>`
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
  color: ${({ isActive }) => (isActive ? '#0066FF' : '#000000')};
`;

const HiddenBlock = styled.div`
  padding-left: 27px;
`;

export default PageItem;
