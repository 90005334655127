import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { IAsset, IEvent } from '@/api/main-protected';

import { AppDispatch } from '@/App';

import ExpandEventTableLine from '@/Components/Requests/ExpandEventTableLine';
import IconComponent from '@/Components/common/IconComponent';
import ModalIncidentRequests from '@/Components/Requests/ModalIncidentRequest';
import ModalEventEvents from '@/Components/Requests/ModalEventEvents';
import ModalNotesEvent from '@/Components/Requests/ModalNotesEvent';

import arrowIcon from '@/assets/images/icons/sidebar/arrow-down.svg';
import yellowWarnIcon from '@/assets/images/icons/events/warn-yellow.svg';

import { RequestState } from '@/store/reducers/common';
import { eventsActions, getIncidentRequest } from '@/store/actions/events';
import { selectIncidentRequest, selectIncidentRequestState } from '@/store/selectors/events';

import { ISort, SortOrder } from '@/utils/sortUtil';

enum COLUMNS {
  EVENTS_ID = 'event_id',
  DATE = 'event_date',
  REQUEST_ID = 'itsm_id',
  STATUS = 'status',
  PRIORITY = 'priority',
  INCIDENT_NUM = 'incident_count',
  CATEGORY = 'category',
}

interface ITable {
  events: IEvent[];
  setSortColumn: (sort: ISort) => void;
  sortColumn: ISort | null;
}

const EventsTable: React.FC<ITable> = ({
  events,
  setSortColumn,
  sortColumn,
}) => {
  // const [incidentIds, setIncidentIds] = useState<number[]>([]);
  const [isRequestModal, setIsRequestModal] = useState(false);
  const [isEventModal, setIsEventModal] = useState(false);
  const [isNotesModal, setIsNotesModal] = useState(false);
  const [event, setEvent] = useState<IEvent | null>(null);
  const request = useSelector(selectIncidentRequest);
  const requestState = useSelector(selectIncidentRequestState);
  const dispatch = useDispatch<AppDispatch>();

  const reverseDirection = (direction: SortOrder) => (
    direction === SortOrder.ASCENDING
      ? SortOrder.DESCENDING
      : SortOrder.ASCENDING);

  const handleTopColumnCellClick = (sortBy: keyof IEvent) => {
    const sort = {
      sortBy,
      direction: sortColumn?.sortBy === sortBy
        ? reverseDirection(sortColumn?.direction)
        : SortOrder.ASCENDING,
    };
    setSortColumn(sort);
  };

  const handleOpenRequestModal = (eventItem: IEvent) => {
    setEvent(eventItem);

    dispatch(getIncidentRequest(eventItem.event_id, true));

    setIsRequestModal(true);
  };

  const handleOpenEventModal = (eventItem: IEvent) => {
    setEvent(eventItem);

    dispatch(getIncidentRequest(eventItem.event_id, true));

    setIsEventModal(true);
  };

  const handleOpenNotesModal = (eventItem: IEvent) => {
    setEvent(eventItem);

    dispatch(getIncidentRequest(eventItem.event_id));

    setIsNotesModal(true);
  };

  const handleCloseEventModal = () => {
    setIsEventModal(false);
  };

  const handleCloseIncidentRequestModal = () => {
    setIsRequestModal(false);
    dispatch(eventsActions.setIncidentRequest(null));
  };

  const handleSetItemModal = (assset: IAsset) => {
    dispatch(eventsActions.setModalAsset(assset));
  };

  return (
    <>
      <Grid>
        <FixedTop />
        <FixedTop
          className="fixed-left"
          onClick={() => handleTopColumnCellClick(COLUMNS.EVENTS_ID)}
          isCursorPointer
        >
          <Text>Event ID</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === COLUMNS.EVENTS_ID
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop>
        <FixedTop isCursorPointer onClick={() => handleTopColumnCellClick(COLUMNS.DATE)}>
          <Text>Date</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === COLUMNS.DATE
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop>
        <FixedTop
          isCursorPointer
          onClick={() => handleTopColumnCellClick(COLUMNS.REQUEST_ID as keyof IEvent)}
        >
          <Text>Request ID</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === COLUMNS.REQUEST_ID
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop>
        <FixedTop isCursorPointer onClick={() => handleTopColumnCellClick(COLUMNS.STATUS)}>
          <Text>Status</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === COLUMNS.STATUS
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop>
        <FixedTop
          isCursorPointer
          onClick={
          () => handleTopColumnCellClick(COLUMNS.CATEGORY as keyof IEvent)
          }
        >
          <Text>Category</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === COLUMNS.CATEGORY
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop>
        {/* <FixedTop isCursorPointer onClick={() => handleTopColumnCellClick(COLUMNS.PRIORITY)}>
          <Text>Priority</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === COLUMNS.PRIORITY
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop> */}
        <FixedTop onClick={() => handleTopColumnCellClick(COLUMNS.INCIDENT_NUM)} isCursorPointer>
          <Text>Exceptions (#)</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === COLUMNS.INCIDENT_NUM
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop>

        {events?.length > 0
          && events.map((ev, i) => (
            <Fragment key={`${ev.event_id}${i}event-table`}>
              <GridCell isRelative>
                <ExpandEventTableLine
                  event={ev}
                  setIsNotesModal={() => handleOpenNotesModal(ev)}
                  handleOpenEventModal={handleOpenEventModal}
                  setItemModal={handleSetItemModal}
                />
              </GridCell>
              <GridCell
                isBlue
                isPointer
                className="fixed-left"
                onClick={() => handleOpenEventModal(ev)}
              >
                <EventBox>
                  {`E-${ev.event_id}`}
                  {ev.incident_count > 0 && ev.incidents.filter(
                    (i) => i.incident_id,
                  ).length > 0 && (
                  <EventIcon>
                    <IconComponent width={20} icon={yellowWarnIcon} />
                  </EventIcon>
                  )}
                </EventBox>
              </GridCell>
              <GridCell>{ev.event_date || 'N/A'}</GridCell>
              <GridCell
                isBlue
                isPointer
                onClick={() => handleOpenRequestModal(ev)}
              >
                {ev.request.itsm_id || 'N/A'}
              </GridCell>
              <GridCell>{ev.status.replace(/[_-]/g, ' ') || 'N/A'}</GridCell>
              <GridCell>{ev.request?.category || 'N/A'}</GridCell>
              {/* <GridCell>{ev.priority || 'N/A'}</GridCell> */}
              <GridCell>{ev.incident_count || 'N/A'}</GridCell>
            </Fragment>
          ))}
      </Grid>
      {events.length === 0 && (
        <EmptyList>No events currently available for the selected time frame.</EmptyList>
      )}
      {isRequestModal && event && (
        <ModalIncidentRequests
          incidentRequest={request!}
          // incidentIds={incidentIds}
          onCloseModal={handleCloseIncidentRequestModal}
          isLoading={
            requestState === RequestState.LOADING
          }
        />
      )}
      {isEventModal && (
        <ModalEventEvents
          incidentRequest={request!}
          event={event}
          onCloseModal={handleCloseEventModal}
          isLoading={
            requestState === RequestState.LOADING || !request
          }
        />
      )}
      {isNotesModal && event && (
        <ModalNotesEvent
          incidentRequest={request!}
          onCloseModal={() => setIsNotesModal(false)}
          isLoading={
            requestState === RequestState.LOADING || !request
          }
        />
      )}
    </>
  );
};

const Grid = styled.div`
  min-width: 1250px;
  // width: 100%; // adds unnecessary scroll
  // height: 100%; // adds hiding effect
  position: relative;

  display: grid;
  grid-template-columns:
    minmax(50px, 50px)
    minmax(150px, auto)
    minmax(200px, auto)
    minmax(250px, auto)
    minmax(150px, auto)
    minmax(200px, auto)
    /* minmax(100px, auto) */
    minmax(150px, auto);

  grid-auto-rows: min-content;

  font-weight: 400;
  font-size: 12px;
  color: #000000;
`;
const FixedTop = styled.div<{ isCursorPointer?: boolean }>`
  position: sticky;
  top: 0;
  z-index: 3;

  // height: 50px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: 800;
  font-size: 14px;
  padding: 8px 0px 8px 10px;

  background: #d9d9d9;

  cursor: ${({ isCursorPointer }) => (isCursorPointer ? 'pointer' : 'auto')};

  &.fixed-left {
    position: sticky;
    left: 0;
    z-index: 4;

    padding-left: 15px;
  }
`;
const GridCell = styled.div<{ isBlue?: boolean; isRelative?: boolean, isPointer?: boolean }>`
  // padding: 12px 10px 4px 15px; // with container
  padding: 8px 10px;
  background-color: white;
  color: ${({ isBlue }) => (isBlue ? '#0066FF' : '#000000')};
  position: ${({ isRelative }) => (isRelative ? 'relative' : 'static')};
  text-transform: capitalize;
  line-height: 17px;
  word-break: break-word;

  &.fixed-left {
    position: sticky;
    left: 0;
    z-index: 1;

    padding-left: 15px;

    cursor: ${({ isPointer }) => (isPointer ? 'pointer' : 'default')};

    @media (hover: hover) {
      &:hover {
        text-decoration: ${({ isPointer }) => (isPointer ? 'underline' : 'none')};
      }
    }
  }

  cursor: ${({ isPointer }) => (isPointer ? 'pointer' : 'default')};

  @media (hover: hover) {
    &:hover {
      text-decoration: ${({ isPointer }) => (isPointer ? 'underline' : 'none')};
    }
  }

  &:nth-child(14n + 1),
  &:nth-child(14n + 2),
  &:nth-child(14n + 3),
  &:nth-child(14n + 4),
  &:nth-child(14n + 5),
  &:nth-child(14n + 6),
  &:nth-child(14n + 7) {
    background: #f6f6f6;
  }
`;

const Text = styled.div`
  text-transform: capitalize;
  margin-right: 10px;
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  min-width: 10px;
  height: 20px;
`;
const ArrowImg = styled.div<{ isAccending: boolean }>`
  display: flex;
  align-items: center;
  transform: rotate(${({ isAccending }) => (isAccending ? 180 : 0)}deg);
  transition: all 0.3s ease;

  & img {
    max-width: 100%;
  }
`;
const EventBox = styled.div`
  display: flex;
  align-items: center;
`;
const EventIcon = styled.div`
  margin-left: 6px;
`;
const EmptyList = styled.div`
  min-height: 125px;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 34px;
  color: #868484;
  padding: 35px 50px 25px 35px;
  margin: 0 auto;
`;
export default EventsTable;
