import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import customScrollCss from '@/utils/custom-scroll-css';

import { AppDispatch } from '@/App';
import { IRequestNote, Request } from '@/api/main-protected';
import { ROLES } from '@/api/main';
import useInput from '@/Components/hooks/useInput';
import { addNote } from '@/store/actions/events';
import { selectUserInfo } from '@/store/selectors/user';
import { selectAddNoteState } from '@/store/selectors/events';
import { RequestState } from '@/store/reducers/common';

// import warnIcon from '@/assets/images/icons/events/exclamation-mark-icon.svg';

import Btn from '@/Components/common/Signin/Btn';
import Input from '@/Components/common/Input';
import LoaderDots from '@/Components/LoaderDots';
import convertFromStringToDate from '@/utils/convertFromStringToDate';

interface INotesComponent {
  isRequestModal?: boolean,
  incidentRequest: Request
}

const NotesIncidentrequestComponent: React.FC<INotesComponent> = ({
  isRequestModal,
  incidentRequest,
}) => {
  const [value, setValue] = useInput('');
  const user = useSelector(selectUserInfo);
  const userRole = user?.roles[0] || ROLES.USER;
  const { notes } = incidentRequest;

  const addNoteState = useSelector(selectAddNoteState);
  const dispatch = useDispatch<AppDispatch>();

  const onAddNote = async () => {
    if (!value) return;

    const body = {
      note: value,
      itsm_id: incidentRequest.itsm_id,
      work_order_id: incidentRequest.request_id,
      user: `${user?.first_name} ${user?.last_name}`,
    };

    dispatch(addNote(body, incidentRequest, isRequestModal));

    setValue('');
  };

  return (
    <Wrap>
      <Notes>
        {notes.length > 0
          ? notes.slice().map((note: IRequestNote, i) => (
            <Note key={note.created_date + i}>
              <Date>{convertFromStringToDate(note.created_date)}</Date>
              <Description>
                {!!note.user.trim() && <Bold>{`${note.user}:`}</Bold>}
                {note.note}
              </Description>
            </Note>
          ))
          : null}
        <GradientLine />
      </Notes>
      {userRole !== ROLES.USER && (
      <AddNotesBlock>
        <InputBox>
          <Input
            id="modal-input"
            name="modal-input"
            value={value}
            isNoMargin
            onChange={setValue}
          />
        </InputBox>
        <BtnBox>
          <Btn isTransparent onClick={onAddNote}>
            {addNoteState === RequestState.LOADING ? <LoaderDots /> : 'Add note'}
          </Btn>
        </BtnBox>
      </AddNotesBlock>
      )}
    </Wrap>
  );
};

const Wrap = styled.div``;

const Notes = styled.div`
  max-height: 300px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  row-gap: 23px;
  
  ${customScrollCss};
`;

const Note = styled.div`
`;

const Date = styled.div`
  font-weight: 400;
  font-size: 10px;
  margin-bottom: 12px;
`;
const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
`;
const Bold = styled.div`
  display: inline-block;
  font-weight: 600;
  margin-right: 6px;
`;

const AddNotesBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;

  button: {
    width: 95px;
    margin-left: 10px;
    font-weight: 500;
    font-size: 14px;
    color: #0066ff;
    border: 1px solid #c4c4c4;
  };
`;

const InputBox = styled.div`
  flex: 0 0 300px;
  width: 300px;
  margin-right: 10px;
`;
const BtnBox = styled.div`
  flex: 0 0 95px;
  width: 95px;

  & button {
    color: #0066ff;
    border: 1px solid #0066ff;
  }
`;

const GradientLine = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 50px;
  height: 50px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
`;

export default NotesIncidentrequestComponent;
