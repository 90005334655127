import React from 'react';

import ContentContainer from '@/Components/common/ContentContainer/ContentContainer';

import AllResultsTables from '@/Components/AllResults/AllResultsTables';

const AllResults: React.FC = () => (
  <ContentContainer title="Search Results">
    <AllResultsTables />
  </ContentContainer>
);

export default AllResults;
