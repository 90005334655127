import React from 'react';
import styled from 'styled-components';

import { RequestsChartItem } from '@/api/main-protected';

// import Chart from '@/Components/Requests/Chart';
import ChartWrap from '@/Components/Requests/ChartWrap';
import LoadingContainer from '@/Components/common/LoadingContainer';
import ChartOut from '@/Components/Requests/ChartOut';

interface IPieChartComponent {
  data: RequestsChartItem
  arrName: string
  title: string
  tableTitle: string
  isLoading: boolean
  colors?: string[]
  isWhitePieText?: boolean
  startAngle?: number
}

const PieChartComponent: React.FC<IPieChartComponent> = ({
  data,
  arrName,
  title, tableTitle,
  isLoading, colors,
  isWhitePieText = false,
  startAngle,
}) => (
  <ChartWrap title={title} isContentGrow>
    <LoadingContainer isLoading={isLoading}>
      {!isLoading && data ? (
        <ChartOut
          itemsBy={data[arrName]}
          total={data.total}
          tableTitle={tableTitle}
          colors={colors}
          isWhitePieText={isWhitePieText}
          startAngle={startAngle}
        />
      ) : <ErrorBlock>Something went wrong</ErrorBlock>}
    </LoadingContainer>
  </ChartWrap>
);

const ErrorBlock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: red;
`;

export default PieChartComponent;
