import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { ROLES } from '@/api/main';
import {
  IPhoneType, IUpdateProfile, IUpdatePsw, IUser,
} from '@/api/main-protected';

import { AppDispatch } from '@/App';

import profileDefaultIcon from '@/assets/images/icons/user-settings/user-profile-default-icon.svg';

import DropDown, { IVariant } from '@/Components/common/DropDown';
import Input from '@/Components/common/Input';
import IconComponent from '@/Components/common/IconComponent';
import ToggleSwitch from '@/Components/common/ToggleSwitch';
import DropDownCheckBox from '@/Components/common/dropDown/DropDownCheckBox';

import { EDIT_NAVS } from '@/Components/UserAdmin/EditUserModal';

import { getLocations } from '@/store/actions/utils';
import { selectLocations } from '@/store/selectors/utils';

import useToggle from '@/Components/hooks/useToggle';
import useInput from '@/Components/hooks/useInput';

import {
  isLowerLetter,
  isNumericAlpha,
  isSpecialSymbol,
  isUpperLetter,
} from '@/utils/validation';
import getBrowserName from '@/utils/browsers';
import { getUserRoleName } from '@/utils/user';
import { accessLevel } from '@/utils/permissions';
import { selectDepartments, selectDepartmentsState, selectUserInfo } from '@/store/selectors/user';
import { RequestState } from '@/store/reducers/common';
import { getUserDepartments } from '@/store/actions/user';
import { EmptyList } from '../ControlCenter/ControlCenter';

interface IEditSettingsForm {
  editedUser: IUser;
  activeNav: string;
  setUpdatedData: (data: IUpdateProfile | IUpdatePsw) => void;
  // handleSubmit: (data: IUpdateProfile | IUpdatePsw) => void
}

enum UserStatuses {
  ACTIVE = 'Active',
  NON_ACTIVE = 'Non-Active',
}

const EditSettingsForm: React.FC<IEditSettingsForm> = ({
  editedUser,
  activeNav,
  // handleSubmit,
  setUpdatedData,
}) => {
  const [firstName, setFirstName] = useInput(editedUser.first_name || '');
  const [lastName, setLastName] = useInput(editedUser.last_name || '');
  const [mail, setMail] = useInput(editedUser.email || '');
  const [phone, setPhone] = useInput(editedUser.phone_numbers[0]?.phone || '');
  const [businessUnit, setBusinessUnit] = useInput(editedUser.business_unit || '');
  // const [role, setRole] = useInput(editedUser.roles[0] || '');
  const [newRoleVariant, setNewRoleVariant] = useState<IVariant | null>({
    title: getUserRoleName(editedUser.roles[0]),
    role: editedUser.roles[0],
  });
  const [dropDownItems, setDropDownItems] = useState<string[] | null>(null);
  const [locationVariant, setLocationVariant] = useState<IVariant | null>({
    title: editedUser.country || '',
  });
  const [isActive, setIsActive] = useState(
    editedUser?.status.toLowerCase() === UserStatuses.ACTIVE.toLowerCase(),
  );

  const roleInfo = useSelector(selectUserInfo)!;
  const departments = useSelector(selectDepartments);
  const departmentsState = useSelector(selectDepartmentsState);

  const ROLES_VARIANTS = useMemo(
    () => accessLevel[roleInfo.roles[0]].map((role) => ({ title: getUserRoleName(role), role })),
    [roleInfo],
  );

  const [curPsw, setCurPsw] = useInput('');
  const [newPsw, setNewPsw] = useInput('');
  const [confNewPsw, setConfNewPsw] = useInput('');
  const [isShowPsw, setIsShowPsw] = useToggle(false);
  const [isConfPasswordError, setIsConfPasswordError] = useState(false);
  // const [isPswError, setIsPswError] = useState(false)
  const [is10, setIs10] = useState(false);
  const [isUpLow, setIsUpLow] = useState(false);
  const [isA1, setIsA1] = useState(false);
  const [isSpecial, setIsSpecial] = useState(false);
  const locations = useSelector(selectLocations);

  const [isErrorFields, setIsErrorFields] = useToggle();

  const dispatch = useDispatch<AppDispatch>();

  const browser = getBrowserName();

  useEffect(() => {
    if (locations) return;

    dispatch(getLocations());
  }, []);

  useEffect(() => {
    if (departments) return;

    dispatch(getUserDepartments());
  }, []);

  useEffect(() => {
    if (!departments) return;

    if (departments && departments.length === 0) return;

    if (!newRoleVariant!.role) return;

    setDepartments(newRoleVariant!.role);
  }, [departments, editedUser.email, newRoleVariant?.role]);

  const setDepartmentOptions = () => {
    const namedDepartments = departments!.map((d) => d.name);
    return [...namedDepartments]
      .sort((a, b) => a.localeCompare(b));
  };

  const setDepartments = (role: string) => {
    const departmentsData = setDepartmentOptions();

    // settings for admin - set all departments
    if (role === ROLES_VARIANTS[2].role) {
      const units = departmentsData.join(', ');
      setBusinessUnit(units);
    }else{
      setBusinessUnit(editedUser.business_unit);
    }


    setDropDownItems(departmentsData);
  };

  useEffect(() => {
    setUpdatedData(handleUserUpdateProfile());
  }, [firstName, lastName, mail, phone, locationVariant?.title, businessUnit, newRoleVariant]);

  useEffect(() => {
    setIs10(newPsw.length > 9);
    setIsUpLow(isUpperLetter(newPsw) && isLowerLetter(newPsw));
    setIsA1(isNumericAlpha(newPsw));
    setIsSpecial(isSpecialSymbol(newPsw));
  }, [newPsw]);

  useEffect(() => {
    setIsErrorFields(businessUnit.length == 0)
  }, [businessUnit]);

  useEffect(() => {
    setIsConfPasswordError(newPsw !== confNewPsw);

    setUpdatedData(handleUserUpdatePsw());
  }, [confNewPsw]);

  const handleUserUpdateProfile = () => ({
    first_name: firstName,
    last_name: lastName,
    email: mail,
    phone_numbers: [
      {
        phone,
        type: IPhoneType.Mobile,
      },
    ],
    country: locationVariant ? locationVariant?.title : '',
    status: isActive ? UserStatuses.ACTIVE : UserStatuses.NON_ACTIVE,
    business_unit: businessUnit,
    role: newRoleVariant ? newRoleVariant?.role : ROLES.USER,
  });

  const handleUserUpdatePsw = () => ({
    password: confNewPsw,
  });

  // const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault()

  //   if (!is10 || !isA1 || !isSpecial || !isUpLow || isConfPasswordError) return

  //   const profileData = handleUserUpdateProfile()
  //   const pswData = handleUserUpdatePsw()

  //   const data = activeNav === EDIT_NAVS.PROFILE ? profileData : pswData

  //   handleSubmit(data)
  // }

  // const handlePswInputClick = () => {
  //   if (isPswError) {
  //     setPassword('')
  //     setIsPswError(false)
  //   }
  // }

  return (
    <Form>
      {activeNav === EDIT_NAVS.PROFILE && (
        <>
          <UserHeader>
            <UserBgImg />
            <ProfImgBlock>
              <IconComponent icon={editedUser.picture || profileDefaultIcon} alt="userName" />
              {/* <EditBtn>
                <IconComponent icon={editIcon} width={17} />
              </EditBtn> */}
            </ProfImgBlock>
          </UserHeader>
          <FormItem>
            <Label>Full name</Label>
            <InputsBox>
              <InputBox>
                <Input
                  id="edit-first-name"
                  name="first-name"
                  value={firstName}
                  placeholder="First name"
                  isNoMargin
                  onChange={setFirstName}
                  disabled
                />
              </InputBox>
              <InputBox>
                <Input
                  id="edit-last-name"
                  name="last-name"
                  value={lastName}
                  placeholder="Last name"
                  isNoMargin
                  onChange={setLastName}
                  disabled
                />
              </InputBox>
            </InputsBox>
          </FormItem>
          <FormItem>
            <Label>Email</Label>
            <InputBox>
              <Input
                id="edit-user-email"
                name="user-email"
                value={mail}
                placeholder="Email"
                isNoMargin
                onChange={setMail}
                disabled
              />
            </InputBox>
          </FormItem>
          <FormItem>
            <Label>
              Phone
              {' '}
              <Span>{' (Optional)'}</Span>
            </Label>
            <InputBox>
              <Input
                id="edit-phone"
                name="phone"
                // type='number'
                value={phone}
                placeholder="+1(609)972-2222"
                isNoMargin
                onChange={setPhone}
              />
            </InputBox>
          </FormItem>
          <FormItem>
            <Label>Role</Label>
            <InputBox isFontSizeIncreased>
              <DropDown
                variants={ROLES_VARIANTS}
                chosenOption={newRoleVariant}
                onChoose={setNewRoleVariant}
                placeholder="Role"
                isSub
                isGreyArrow
                minHeight={30}
                height={145}
              />
            </InputBox>
          </FormItem>
          <FormItem>
            <Label>Department</Label>
            <InputBox isDepartment>
              {!!dropDownItems && departmentsState !== RequestState.LOADING && departments ? (
                <DropDownCheckBox
                  dropItems={dropDownItems!}
                  onChange={setBusinessUnit}
                  value={businessUnit}
                  placeholder="Department"
                  maxHeight="190px"
                  isForm
                  isError={isErrorFields && !businessUnit}
                />
              ) : (
                <DropdownLoader>{businessUnit}</DropdownLoader>
              )}
            </InputBox>
          </FormItem>
          <FormItem>
            <Label>Location</Label>
            <InputBox isFontSizeIncreased>
              <DropDown
                variants={locations}
                chosenOption={locationVariant}
                onChoose={setLocationVariant}
                placeholder="Location"
                isSub
                isGreyArrow
                minHeight={30}
                height={145}
              />
            </InputBox>
          </FormItem>
          <FormItem>
            <Label>Active/Inactive</Label>
            <InputBox>
              <ToggleSwitchBox>
                Active
                {' '}
                <ToggleSwitch setIsActive={setIsActive} isActive={isActive} isDisabled />
                {' '}
                Inactive
              </ToggleSwitchBox>
            </InputBox>
          </FormItem>
        </>
      )}
      {activeNav === EDIT_NAVS.CHANGE_PSW && (
        <InputsWrap>
          <FormItem>
            <Label isTextRight>Current password</Label>
            <InputBox isValue={!!curPsw}>
              <Input
                id="current"
                onChange={setCurPsw}
                // onClick={handlePswInputClick}
                value={curPsw}
                placeholder="Enter current password"
                isHideMode
                // errorText='Please enter a valid password'
                // isError={isPswError}
                isNoMargin
                height={30}
                // circleFontSize={40}
                // onFocus={() => setIsFocused(true)}
                browser={browser}
              />
            </InputBox>
          </FormItem>
          <FormItem>
            <Label isTextRight>New password</Label>
            <InputBox isValue={!!newPsw}>
              <Input
                id="new-psw"
                onChange={setNewPsw}
                // onClick={handlePswInputClick}
                value={newPsw}
                placeholder="Enter new password"
                isHideMode
                // errorText='Please enter a valid password'
                // isError={isPswError}
                isNoMargin
                height={30}
                browser={browser}
              />
            </InputBox>
          </FormItem>
          <FormItem>
            <Label isTextRight>Confirm new password</Label>
            <InputBox isValue={!!confNewPsw}>
              <Input
                id="conf-new-psw"
                onChange={setConfNewPsw}
                // onClick={handlePswInputClick}
                value={confNewPsw}
                placeholder="Confirm your new password"
                isHideMode
                // errorText='Please enter a valid password'
                // isError={isPswError}
                isNoMargin
                height={30}
                browser={browser}
              />
            </InputBox>
          </FormItem>
        </InputsWrap>
      )}
    </Form>
  );
};

const Form = styled.form`
  flex: 0 0 100%;
  width: 100%;
`;
const InputsWrap = styled.div`
  margin-top: 46px;
`;
const FormItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 12px 0;
`;
const UserHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 21px;
  background: #ffffff;
`;
const UserBgImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 51.5%;
  background: #d9d9d9;
  border-radius: 7px;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ProfImgBlock = styled.div`
  position: relative;
  width: 118px;
  height: 118px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  margin: 41px 0 21px;

  & > div > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`;

// const EditBtn = styled.div`
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   width: 30px;
//   height: 30px;
//   border-radius: 50%;
//   background: #ffffff;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   cursor: pointer;
// `;

const Label = styled.p<{ isTextRight?: boolean }>`
  flex: 0 0 160px;
  width: 160px;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  margin-right: 6px;
  ${({ isTextRight }) => isTextRight
    && css`
      text-align: right;
    `}
`;

const Span = styled.span`
  color: #949393;
`;

const InputsBox = styled.div`
  width: 100%;
  max-width: 475px;
  display: flex;

  & [name='first-name'] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  & [name='last-name'] {
    border-left: 1px solid transparent;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

// const CheckboxesBox = styled.div`
//   width: 100%;
//   max-width: 475px;
//   display: flex;

//   & > div:first-child {
//     width: 100%;
//     background: #ffffff;
//     border: 1px solid #c4c4c4;
//     border-top-right-radius: 0;
//     border-bottom-right-radius: 0;
//     border-top-left-radius: 4px;
//     border-bottom-left-radius: 4px;
//   }
//   & > div:last-child {
//     background: #ffffff;
//     border: 1px solid #c4c4c4;
//     border-left: 1px solid transparent;
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
//     border-top-right-radius: 4px;
//     border-bottom-right-radius: 4px;
//   }
// `;
const InputBox = styled.div<{ isValue?: boolean, isDepartment?: boolean, isFontSizeIncreased?: boolean }>`
  width: 100%;
  max-width: 475px;

  & input {
    height: 30px;
    padding: 7px 10px 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;

    & ~ img {
      width: 20px;
      height: 20px;
      top: 5px;
    }
  }
  & [type='password'] {
    // font-size: ${({ isValue }) => (isValue ? 'xx-large' : '16px')};
    letter-spacing: ${({ isValue }) => (isValue ? '1px' : '0')};
    color: ${({ isValue }) => (isValue ? '#000000' : '#868484')};

    & ~ div {
      height: 5px;
    }

    &::-webkit-input-placeholder {
      font-family: 'Jost';
      font-weight: 400;
      font-size: 16px !important;
      line-height: 1px;
      color: ${({ isValue }) => (isValue ? '#000000' : '#868484')};
      height: 10px;
    }
  }

  & [type='text'] {
    letter-spacing: 0px;
    color: #000000;

    &::-webkit-input-placeholder {
      font-family: 'Jost';
      font-weight: 400;
      font-size: 16px !important;
      line-height: 1px;
      color: #868484;
      height: 10px;
    }
  }

  ${({ isDepartment }) => isDepartment && css`
    & label {
      padding: 0 7px 0 0;
    };
  `}

  // set font-size for Roles & Locations dropdowns
  ${({ isFontSizeIncreased }) => isFontSizeIncreased && css`
    & > div {
      font-size: 16px;
    };
  `}
`;

const ToggleSwitchBox = styled.div`
  display: flex;
  align-items: center;
  min-height: 30px;
`;

const DropdownLoader = styled.div`
  width: 100%;
  height: 32px;
  padding: 8px 13px 8px;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
`;

export default EditSettingsForm;
