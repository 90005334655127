import React from 'react';

import { IIcon } from '@/interfaces/icon';

const RequestIcon: React.FC<IIcon> = ({ width = '16', height = '16', color = 'black' }) => (
  <svg width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.66976 13.5122C7.52249 13.5221 7.37262 13.5271 7.22434 13.5271C3.62422 13.5271 0.6953 10.5982 0.6953 6.99805C0.6953 3.39793 3.62422 0.468981 7.22437 0.468981C10.8245 0.468981 13.7534 3.3979 13.7534 6.99805C13.7534 7.59057 13.6741 8.17774 13.5176 8.74329C13.4831 8.86803 13.5563 8.99715 13.681 9.03168C13.8058 9.06621 13.9349 8.99306 13.9694 8.86828C14.1371 8.26205 14.2222 7.63282 14.2222 6.99805C14.2222 5.12888 13.4943 3.37156 12.1726 2.04988C10.8508 0.728162 9.09354 0.000244141 7.22437 0.000244141C5.3552 0.000244141 3.59788 0.728162 2.27616 2.04988C0.954449 3.37156 0.226562 5.12885 0.226562 6.99805C0.226562 8.86725 0.954449 10.6245 2.27616 11.9462C3.59784 13.2679 5.35517 13.9958 7.22434 13.9958C7.38308 13.9958 7.54355 13.9905 7.70129 13.9798C7.83045 13.9711 7.92807 13.8594 7.91938 13.7302C7.91066 13.6011 7.79935 13.5033 7.66976 13.5122Z" fill={color} />
    <path d="M12.8636 8.20678C12.9914 8.22869 13.1123 8.14285 13.1342 8.01529C13.1912 7.68155 13.2202 7.33927 13.2202 6.998C13.2202 3.69191 10.5304 1.0022 7.22435 1.0022C3.91825 1.0022 1.22852 3.69191 1.22852 6.998C1.22852 10.3041 3.91822 12.9938 7.22432 12.9938C7.59965 12.9938 7.97546 12.9588 8.34123 12.8899C8.46841 12.8659 8.5521 12.7434 8.52813 12.6162C8.50416 12.489 8.38169 12.4053 8.25442 12.4293C7.91718 12.4929 7.57062 12.5251 7.22432 12.5251C4.17669 12.5251 1.69725 10.0456 1.69725 6.998C1.69725 3.95037 4.17672 1.47093 7.22435 1.47093C10.272 1.47093 12.7514 3.95037 12.7514 6.998C12.7514 7.3129 12.7247 7.62858 12.6721 7.93629C12.6503 8.06388 12.736 8.185 12.8636 8.20678Z" fill={color} />
    <path d="M16.0939 14.5358L12.954 9.09724C12.7775 8.79169 12.4616 8.60925 12.1087 8.60925C11.7559 8.60925 11.4399 8.79169 11.2635 9.09724L8.12354 14.5358C7.9471 14.8414 7.9471 15.2063 8.12354 15.5118C8.29997 15.8174 8.61593 15.9998 8.96876 15.9998H15.2487C15.6016 15.9998 15.9175 15.8174 16.0939 15.5118C16.2704 15.2063 16.2704 14.8414 16.0939 14.5358ZM15.688 15.2775C15.5963 15.4363 15.4321 15.5311 15.2487 15.5311H8.96876C8.78536 15.5311 8.62115 15.4363 8.52946 15.2775C8.43778 15.1186 8.43778 14.929 8.52946 14.7702L11.6694 9.33161C11.7611 9.1728 11.9253 9.07799 12.1087 9.07799C12.2921 9.07799 12.4564 9.1728 12.548 9.33161L15.688 14.7702C15.7797 14.929 15.7797 15.1186 15.688 15.2775Z" fill={color} />
    <path d="M12.1025 10.3058C11.9731 10.3058 11.8682 10.4107 11.8682 10.5402V13.5461C11.8682 13.6756 11.9731 13.7805 12.1025 13.7805C12.232 13.7805 12.3369 13.6756 12.3369 13.5461V10.5402C12.3369 10.4107 12.232 10.3058 12.1025 10.3058Z" fill={color} />
    <path d="M7.92109 7.2324H11.9837C12.1131 7.2324 12.2181 7.12747 12.2181 6.99804C12.2181 6.8686 12.1131 6.76367 11.9837 6.76367H7.92109C7.823 6.4728 7.54779 6.26268 7.22423 6.26268C7.10683 6.26268 6.99583 6.29049 6.89727 6.33965L4.13347 3.57585C4.04194 3.48432 3.89354 3.48432 3.80204 3.57585C3.71052 3.66738 3.71052 3.81578 3.80204 3.90728L6.56584 6.67108C6.51669 6.76964 6.48888 6.88063 6.48888 6.99804C6.48888 7.40353 6.81877 7.73339 7.22423 7.73339C7.54779 7.73339 7.823 7.52327 7.92109 7.2324ZM7.22426 7.26465C7.07724 7.26465 6.95765 7.14503 6.95765 6.99804C6.95765 6.85104 7.07727 6.73142 7.22426 6.73142C7.37126 6.73142 7.49088 6.85104 7.49088 6.99804C7.49088 7.14503 7.37126 7.26465 7.22426 7.26465Z" fill={color} />
    <path d="M3.20022 6.99805C3.20022 6.86861 3.09528 6.76367 2.96584 6.76367H2.46484C2.33541 6.76367 2.23047 6.86861 2.23047 6.99805C2.23047 7.12748 2.33541 7.23242 2.46484 7.23242H2.96584C3.09528 7.23242 3.20022 7.12748 3.20022 6.99805Z" fill={color} />
    <path d="M7.45898 2.73965V2.23865C7.45898 2.10921 7.35405 2.00427 7.22461 2.00427C7.09517 2.00427 6.99023 2.10921 6.99023 2.23865V2.73965C6.99023 2.86908 7.09517 2.97402 7.22461 2.97402C7.35405 2.97402 7.45898 2.86908 7.45898 2.73965Z" fill={color} />
    <path d="M6.99023 11.2565V11.7575C6.99023 11.8869 7.09517 11.9918 7.22461 11.9918C7.35405 11.9918 7.45898 11.8869 7.45898 11.7575V11.2565C7.45898 11.127 7.35405 11.0221 7.22461 11.0221C7.09517 11.0221 6.99023 11.127 6.99023 11.2565Z" fill={color} />
    <path d="M4.76704 10.7857L4.64179 11.0026C4.57707 11.1147 4.61548 11.2581 4.72757 11.3228C4.76448 11.3441 4.80479 11.3542 4.84454 11.3542C4.92554 11.3542 5.00432 11.3122 5.04773 11.237L5.17298 11.0201C5.2377 10.908 5.19929 10.7646 5.0872 10.6999C4.97513 10.6351 4.83176 10.6736 4.76704 10.7857Z" fill={color} />
    <path d="M9.47833 3.32762C9.55933 3.32762 9.63811 3.28559 9.68152 3.2104L9.80677 2.99346C9.87149 2.88137 9.83308 2.73802 9.72099 2.67331C9.60889 2.60856 9.46555 2.64696 9.40083 2.75909L9.27558 2.97602C9.21086 3.08812 9.24927 3.23146 9.36136 3.29618C9.39827 3.31749 9.43855 3.32762 9.47833 3.32762Z" fill={color} />
    <path d="M3.4367 4.54062L3.21976 4.41537C3.10767 4.35062 2.96432 4.38903 2.8996 4.50115C2.83488 4.61325 2.87329 4.75659 2.98538 4.82131L3.20232 4.94656C3.23923 4.96787 3.27954 4.978 3.31929 4.978C3.40029 4.978 3.47907 4.93596 3.52248 4.86078C3.5872 4.74868 3.54879 4.60534 3.4367 4.54062Z" fill={color} />
    <path d="M4.97023 3.32762C5.00998 3.32762 5.05029 3.31749 5.0872 3.29618C5.19929 3.23146 5.2377 3.08812 5.17298 2.97602L5.04773 2.75909C4.98301 2.64696 4.83963 2.60856 4.72757 2.67331C4.61548 2.73802 4.57707 2.88137 4.64179 2.99346L4.76704 3.2104C4.81048 3.28559 4.88926 3.32762 4.97023 3.32762Z" fill={color} />
    <path d="M11.1292 4.97797C11.1689 4.97797 11.2092 4.96785 11.2461 4.94654L11.4631 4.82129C11.5752 4.75657 11.6136 4.61322 11.5488 4.50113C11.4841 4.38904 11.3408 4.35063 11.2287 4.41535L11.0118 4.5406C10.8997 4.60532 10.8613 4.74866 10.926 4.86076C10.9694 4.93594 11.0482 4.97797 11.1292 4.97797Z" fill={color} />
    <path d="M3.10279 9.61227C3.14254 9.61227 3.18285 9.60215 3.21976 9.58084L3.4367 9.45559C3.54879 9.39087 3.5872 9.24752 3.52248 9.13543C3.45776 9.0233 3.31435 8.9849 3.20232 9.04965L2.98538 9.1749C2.87329 9.23962 2.83488 9.38296 2.8996 9.49505C2.94301 9.57021 3.02179 9.61227 3.10279 9.61227Z" fill={color} />
    <path d="M12.1025 14.7823C12.232 14.7823 12.3369 14.6774 12.3369 14.548C12.3369 14.4185 12.232 14.3136 12.1025 14.3136C11.9731 14.3136 11.8682 14.4185 11.8682 14.548C11.8682 14.6774 11.9731 14.7823 12.1025 14.7823Z" fill={color} />
  </svg>
);

export default RequestIcon;
