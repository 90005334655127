import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ROLES } from '@/api/main';

import { PAGES } from '@/Components/Main/Main';
import PageItem from '@/Components/Sidebar/PageItem';

import ControlCenterIcon from '@/Components/icons/ControlCenterIcon';
import ItemsIcon from '@/Components/icons/ItemsIcon';
import RequestIcon from '@/Components/icons/RequestIcon';
import UserAdminIcon from '@/Components/icons/UserAdminIcon';

import { selectUserInfo } from '@/store/selectors/user';

// sync roles to protectRouter roles

const Pages: React.FC<{ isExpanded: boolean }> = ({ isExpanded }) => {
  const user = useSelector(selectUserInfo);
  const role = user?.roles[0];
  const { pathname } = useLocation();

  const ITEMS = useMemo(() => [
    {
      icon: ControlCenterIcon,
      text: 'Control Center',
      isArr: true,
      path: [PAGES.CONTROL_CENTER],
      roles: [ROLES.USER, ROLES.MANAGER, ROLES.SUPER_ADMIN],
    },
    {
      icon: RequestIcon,
      text: 'Requests',
      path: [PAGES.REQUESTS],
      roles: [ROLES.USER, ROLES.MANAGER, ROLES.SUPER_ADMIN],
    },
    {
      icon: ItemsIcon,
      text: 'Items',
      path: [PAGES.ITEMS],
      roles: [ROLES.USER, ROLES.MANAGER, ROLES.SUPER_ADMIN],
    },
    {
      icon: UserAdminIcon,
      text: 'Users',
      path: [PAGES.USER_ADMIN, PAGES.ADD_NEW_USER],
      roles: [ROLES.SUPER_ADMIN, ROLES.MANAGER],
    },
    // {
    //   icon: betaGroupIcon,
    //   text: 'Beta Group',
    //   isArr: true,
    //   subItems: [
    //     { text: 'Feedback', path: ['/'] },
    //     { text: 'Feature Requests', path: ['/'] },
    //     { text: 'Roadmap', path: ['/'] }
    //   ],
    //   path: [PAGES.BETA_GROUP]
    // },

    // {
    //   icon: playbooksIcon,
    //   text: 'Playbooks',
    //   path: [PAGES.PLAYBOOKS],
    //   roles: [ROLES.MANAGER, ROLES.SUPER_ADMIN]
    // },
    // {
    //   icon: personaManagerIcon,
    //   text: 'Persona Manager',
    //   path: [PAGES.PERSONA_MANAGER],
    //   roles: [ROLES.MANAGER, ROLES.SUPER_ADMIN]
    // },
    // {
    //   icon: '',
    //   text: 'Incidents',
    //   path: [PAGES.INCIDENTS],
    //   isCount: true
    // },
    // {
    //   icon: '',
    //   text: 'Events',
    //   path: [PAGES.EVENTS],
    //   isCount: true
    // },

    // {
    //   icon: activityLogIcon,
    //   text: 'Activity Log',
    //   path: [PAGES.ACTIVITY_LOG],
    //   roles: [ROLES.USER, ROLES.MANAGER, ROLES.SUPER_ADMIN]
    // }
  ], []);

  const sideBarItems = ITEMS.filter((item) => item?.roles.includes(role as ROLES));

  return (
    <Pagesbox>
      <Line />
      <PageItem
        key={ITEMS[0].text}
        icon={ITEMS[0].icon}
        text={isExpanded ? ITEMS[0].text : ''}
        // subItems={ITEMS[0].subItems}
        isActive={ITEMS[0].path.includes(pathname)}
        path={ITEMS[0].path[0]}
      />
      {/* {ITEMS.slice(1).map(item => ( */}
      {sideBarItems.slice(1).map((item) => (
        <PageItem
          key={item.text}
          icon={item.icon}
          text={isExpanded ? item.text : ''}
          isArr={item.isArr}
          // subItems={item.subItems}
          isActive={item.path.includes(pathname)}
          path={item.path[0]}
          isExpanded={isExpanded}
        />
      ))}
    </Pagesbox>
  );
};

const Pagesbox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #c4c4c4b3;
`;

const Line2 = styled.div`
  width: 100%;
  height: 1px;
  background: white;
`;


export default Pages;
