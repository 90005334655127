import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Spinner } from 'spin.js';

import '@/styles/spinner.css';

const SPINNER_OPT = {
  lines: 7, // The number of lines to draw
  length: 14, // The length of each line
  // length: 28, // The length of each line
  width: 9, // The line thickness
  // width: 17, // The line thickness
  radius: 19, // The radius of the inner circle
  // radius: 38, // The radius of the inner circle
  scale: 1, // Scales overall size of the spinner
  // scale: 0.55, // Scales overall size of the spinner
  corners: 1, // Corner roundness (0..1)
  speed: 1, // Rounds per second
  rotate: 12, // The rotation offset
  animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
  direction: 1, // 1: clockwise, -1: counterclockwise
  color: '#7b61ff', // CSS color or array of colors
  fadeColor: 'transparent', // CSS color or array of colors
  top: '50%', // Top position relative to parent
  left: '50%', // Left position relative to parent
  shadow: '0 0 1px transparent', // Box-shadow for the lines
  zIndex: 1000, // The z-index (defaults to 2e9)
  className: 'spinner', // The CSS class to assign to the spinner
  position: 'absolute', // Element positioning
};

const GlobalLoading: React.FC = () => {
  useEffect(() => {
    const spinner = document.getElementById('spinner') || undefined;

    new Spinner(SPINNER_OPT).spin(spinner);
  }, []);

  return <Loader id="spinner" />;
};

const Loader = styled.div``;

export default GlobalLoading;
