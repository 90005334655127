import React, { useState } from 'react';

import AssetsContainer from '@/Components/Items/ItemsTable/AssetsContainer';
import PeripheralsContainer from '@/Components/Items/ItemsTable/PeripheralsContainer';

export enum ItemsTabsNames {
  ASSETS = 'Assets',
  PERIPHERALS = 'Peripherals',
}

interface Props {
  interval: number;
}

const ItemsContainer: React.FC<Props> = ({ interval }) => {
  const [selectedTab, setSelectedTab] = useState(ItemsTabsNames.ASSETS);

  const showContent = () => {
    switch (selectedTab) {
      case ItemsTabsNames.ASSETS:
        return (
          <AssetsContainer
            interval={interval}
            setSelectedTab={setSelectedTab}
            selectedTab={ItemsTabsNames.ASSETS}
            tabs={Object.values(ItemsTabsNames)}
            title="All Items"
          />
        );
      case ItemsTabsNames.PERIPHERALS:
        return (
          <PeripheralsContainer
            interval={interval}
            setSelectedTab={setSelectedTab}
            selectedTab={ItemsTabsNames.PERIPHERALS}
            tabs={Object.values(ItemsTabsNames)}
            title="All Items"
          />
        );
      default:
        return <></>;
    }
  };

  return (
    showContent()
  );
};

export default ItemsContainer;
