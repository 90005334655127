import React, { useRef, RefObject, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

interface IHFit {
  isChangeHeight?: boolean
  isNoBorder?: boolean
  extraSpace?: number
  minHeight?: number
  children?: React.ReactNode
}

const PAGE_PADDING = 56

const HeightFitContainer: React.FC<IHFit> = ({
  isChangeHeight = false,
  isNoBorder = false,
  extraSpace = 0,
  minHeight = 350,
  children
}) => {
  const MIN_AVAIL_ELEM_H = minHeight + extraSpace
  const [height, setHeight] = useState(MIN_AVAIL_ELEM_H)

  const fitHRef = useRef(null) as RefObject<HTMLDivElement>

  const screenHeight = window.innerHeight
  useEffect(() => {
    const elementTopPosition = fitHRef.current?.offsetTop
    const calculatedAvailableHeight = screenHeight - elementTopPosition!

    if (calculatedAvailableHeight <= MIN_AVAIL_ELEM_H) {
      setHeight(MIN_AVAIL_ELEM_H)
      return
    }

    setHeight(calculatedAvailableHeight - PAGE_PADDING)
  }, [isChangeHeight])

  return (
    <Container ref={fitHRef} height={height - extraSpace} isNoBorder={isNoBorder}>
      {children}
    </Container>
  )
}

const Container = styled.div<{ height: number; isNoBorder: boolean }>`
  flex: 1 0 auto;
  width: 100%;
  height: ${({ height }) => height}px;

  display: flex;
  background: #ffffff;

  ${({ isNoBorder }) =>
    !isNoBorder &&
    css`
      border: 1px solid #e5e5e5;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
    `}
`

export default HeightFitContainer
