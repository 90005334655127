import React, { useMemo } from 'react';
import styled from 'styled-components';

import LoadingContainer from '@/Components/common/LoadingContainer';
import LoaderDots from '@/Components/common/LoaderDots';

interface Props {
  maxTotal: number;
  itemsPerPage: number;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  isLoading: boolean;
  isEmpty: boolean;
  isArrowLoading: boolean;
}

const Pagination: React.FC<Props> = ({
  maxTotal,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  isLoading,
  isEmpty,
  isArrowLoading,
}) => {
  const maxItems = useMemo(() => (
    itemsPerPage * currentPage > maxTotal ? maxTotal : itemsPerPage * currentPage
  ), [itemsPerPage, currentPage, maxTotal]);

  const total = useMemo(
    () => `${isEmpty ? 0 : itemsPerPage * (currentPage - 1) + 1}-${maxItems} of ${maxTotal}`,
    [maxTotal, itemsPerPage, currentPage, maxItems, isEmpty],
  );

  const maxPaginationPage = useMemo(
    () => Math.ceil(maxTotal / itemsPerPage),
    [maxTotal, itemsPerPage],
  );

  const isLeftArrowActive = useMemo(() => currentPage > 1 && !isLoading, [currentPage, isLoading]);
  const isRightArrowActive = useMemo(
    () => Math.ceil(maxTotal / itemsPerPage) > currentPage && !isLoading,
    [currentPage, itemsPerPage, maxTotal, isLoading],
  );

  const handleArrowLeftClick = () => {
    if (isLeftArrowActive) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleArrowRightClick = () => {
    if (isRightArrowActive) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDoubleArrowRightClick = () => {
    if (isRightArrowActive) {
      setCurrentPage(maxPaginationPage);
    }
  };

  const handleDoubleArrowLeftClick = () => {
    if (isLeftArrowActive) {
      setCurrentPage(1);
    }
  };

  return (
    <PaginationStyled>
      <LoadingContainer isLoading={isLoading}>
        <ArrowBox>
          {isArrowLoading
            ? (
              <LoadingBox>
                <LoaderDots />
              </LoadingBox>
            )
            : (
              <ArrowsWrap>
                <DoubleArrows onClick={handleDoubleArrowLeftClick}>
                  <ArrowLeftWrap
                    isActive={isLeftArrowActive}
                  />
                  <ArrowLeft
                    isActive={isLeftArrowActive}
                  />
                </DoubleArrows>
                <ArrowLeft
                  isActive={isLeftArrowActive}
                  onClick={handleArrowLeftClick}
                />
              </ArrowsWrap>
            )}
        </ArrowBox>
        <Wrap>
          <Text>{total}</Text>
        </Wrap>
        <ArrowBox>
          {isArrowLoading
            ? (
              <LoadingBox>
                <LoaderDots />
              </LoadingBox>
            )
            : (
              <ArrowsWrap>
                <ArrowRight
                  isActive={isRightArrowActive}
                  onClick={handleArrowRightClick}
                />
                <DoubleArrows onClick={handleDoubleArrowRightClick}>
                  <ArrowRight
                    isActive={isRightArrowActive}
                  />
                  <ArrowRightWrap
                    isActive={isRightArrowActive}
                  />
                </DoubleArrows>
              </ArrowsWrap>
            )}
        </ArrowBox>
      </LoadingContainer>
    </PaginationStyled>
  );
};

const DoubleArrows = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ArrowsWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const LoadingBox = styled.div`
  display: flex;
  align-items: center;

  & > div > div {
    width: 6px;
    height: 6px;
    margin: 1px;
  }
`;

const PaginationStyled = styled.div`
  display: flex;
  align-items: center;
  min-width: 128px;
`;

const ArrowBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
`;

const ArrowLeft = styled.span<{ isActive: boolean }>`
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;

  border-right: 11px solid ${({ isActive }) => (isActive ? '#0066FF' : '#D9D9D9')};
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'default')};
`;

const ArrowRight = styled.span<{ isActive: boolean }>`
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;

  border-left: 11px solid ${({ isActive }) => (isActive ? '#0066FF' : '#D9D9D9')};
  cursor: ${({ isActive }) => (isActive ? 'pointer' : 'default')};
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;

const Wrap = styled.div`
  padding: 4px 15px;
  min-width: 100px;
`;

const ArrowLeftWrap = styled(ArrowLeft)`
  margin-right: -3px;
`;

const ArrowRightWrap = styled(ArrowRight)`
  margin-left: -3px;
`;

export default Pagination;
