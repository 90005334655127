import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment, { DurationInputArg1, DurationInputArg2, Moment } from 'moment';

import styled from 'styled-components';

import Button from '@/Components/common/Signin/Btn';
import DatePicker, { DatePickerAlign } from '@/Components/common/calendar/DatePicker';
import ExportBtnElement from '@/Components/common/ExportCSV/ExportBtnElement';

import useOnClickOutside from '@/Components/hooks/useOnClickOutside';

import { AppDispatch } from '@/App';
import { exportCSVRequest } from '@/store/actions/events';
import { selectExportCSVRequestsState } from '@/store/selectors/events';
import { RequestState } from '@/store/reducers/common';
import { dateToISO, lastMonthDays } from '@/utils/date';
import LocalStorage, { Filter } from '@/local-storage/LocalStorage';

interface IMomentFilter {
  [filterName: string]: {
    momentPeriod: DurationInputArg1;
    momentUnit: DurationInputArg2;
  };
}

const extractedPeriod: IMomentFilter[] = [
  { [Filter.DAY_1]: { momentPeriod: 1, momentUnit: 'd' } },
  { [Filter.LAST_7_DAYS]: { momentPeriod: 7, momentUnit: 'd' } },
  { [Filter.LAST_2_WEEK]: { momentPeriod: 14, momentUnit: 'd' } },
  { [Filter.LAST_MONTH]: { momentPeriod: lastMonthDays(), momentUnit: 'd' } },
  { [Filter.LAST_QUARTER]: { momentPeriod: 3, momentUnit: 'months' } },
  { [Filter.ALL]: { momentPeriod: 12, momentUnit: 'months' } },
];

const ExportCSVComponent: React.FC = () => {
  const [isClicked, setIsClicked] = useState(false);
  const loadingRequests = useSelector(selectExportCSVRequestsState);
  const [fromDate, setFromDate] = useState<Moment | null>(null);
  const [toDate, setToDate] = useState<Moment | null>(moment().utc());
  const minDateFrom = moment().subtract(12, 'months').utc();
  const filters = LocalStorage.getFilter();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setToDate(moment().utc());

    if (filters) {
      const momentFromDate = extractedPeriod.find((f) => f[filters]);

      const substructPeriod = momentFromDate![filters].momentPeriod;
      const substructName = momentFromDate![filters].momentUnit;
      setFromDate(moment().utc().subtract(substructPeriod, substructName));
    }
  }, [filters]);

  const onClose = () => {
    setIsClicked(false);
  };

  const clickOutsideRef = useOnClickOutside(onClose);

  const handleClick = () => {
    setIsClicked(true);
  };

  const handleExport = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    const queries = `${dateToISO(fromDate)}:${dateToISO(toDate)}`;

    dispatch(exportCSVRequest(queries));

    onClose();
    setIsClicked(false);
  };

  return (
    <ExportBtnElement onClick={handleClick} isClicked={isClicked} loadingRequests={loadingRequests}>
      {loadingRequests !== RequestState.LOADING && (
        <Export ref={clickOutsideRef} isShow={isClicked}>
          <DatePickerWrapper>
            <Title>Max date range 12 months</Title>
            <DatePickerLabel />
            <DatePickerWrap>
              <DatePicker
                mainDate={fromDate}
                tempFromDate={fromDate}
                tempTillDate={toDate}
                handleSetDate={setFromDate}
                align={DatePickerAlign.Right}
                placeholder="From Date"
                isFromDate
                minFromDate={minDateFrom}
              />
            </DatePickerWrap>
            <DatePickerLabel />
            <DatePickerWrap>
              <DatePicker
                mainDate={toDate}
                tempFromDate={fromDate}
                tempTillDate={toDate}
                handleSetDate={setToDate}
                align={DatePickerAlign.Right}
                placeholder="To Date"
                isFromDate={false}
                isTillToday
              />
            </DatePickerWrap>
          </DatePickerWrapper>
          <Button isLogInBtn onClick={(e) => handleExport(e)}>
            Export to CSV
          </Button>
        </Export>
      )}
    </ExportBtnElement>
  );
};

const Export = styled.div<{ isShow: boolean }>`
  position: absolute;
  top: calc(100% + 7px);
  right: 0;
  display: ${({ isShow }) => (isShow ? 'block' : 'none')};
  font-weight: 400;
  font-size: 11px;
  background: #ffffff;
  border: 1px solid rgba(196, 196, 196, 0.7);
  box-shadow: 0px 2px 4px rgb(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 30px 20px;
  z-index: 1;

  & > button {
    height: 34px;
  }
`;

const DatePickerLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-transform: capitalize;
  text-align: left;
  font-weight: 500;
  letter-spacing: 0.02em;
  width: 100%;
`;

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 165px;
  margin-bottom: 15px;
`;

const Title = styled(DatePickerLabel)`
  font-weight: 600;
  text-transform: none;
`;
const DatePickerWrap = styled.div``;

export default ExportCSVComponent;
