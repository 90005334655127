const getUpdated = (responseDate: string | null) => {
  if (responseDate === null) return '-';

  const newDate = new Date(responseDate);

  const month = newDate.toLocaleString('default', { month: 'long' });
  const date = newDate.toLocaleString('default', { day: 'numeric' });
  const year = newDate.toLocaleString('default', { year: 'numeric' });
  const time = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  }).format(newDate);

  return `Updated ${month} ${date}, ${year} at ${time}`;
};

export default getUpdated;
