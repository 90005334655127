import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import DropDown, { IVariant } from '@/Components/common/DropDown';

interface IChartWrap {
  title: string
  isContentGrow?: boolean
  isDropDown?: boolean
  variant?: IVariant
  variants?: IVariant[]
  onChoose?: (varItem: IVariant | null) => void
  children: ReactNode
}

const ChartWrap: React.FC<IChartWrap> = ({
  title,
  isContentGrow = false,
  isDropDown = false,
  variant,
  variants,
  onChoose,
  children,
}) => (
  <Wrap>
    <TitleBlock>
      <Top>
        <Title>{title}</Title>
        {isDropDown && (
        <Options>
          <DropDown
            variants={variants!}
            chosenOption={variant!}
            onChoose={onChoose!}
            isSub={false}
                // isTextCenter
            minHeight={35}
          />
        </Options>
        )}
      </Top>
    </TitleBlock>
    <Content isContentGrow={isContentGrow}>{children}</Content>
  </Wrap>
);

const Wrap = styled.div`
  // flex: 0 0 calc(50% - 9px); // with container
  min-height: 291px;
  flex: 1 0 calc(50% - 9px);
  min-width: 505px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid rgba(196, 196, 196, 0.7);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;
const TitleBlock = styled.div`
  padding: 18px 22px 20px 25px;
`;
const Top = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.h3`
  flex: 1 0 auto;
  font-weight: 600;
  font-size: 16px;
`;

const Options = styled.div`
  flex: 0 0 125px;
  width: 125px;
  margin-left: 20px;
`;

const Content = styled.div<{ isContentGrow: boolean }>`
  width: 100%;
  height: 100%;
  ${({ isContentGrow }) => isContentGrow
    && css`
      flex-grow: 2;
    `}
`;

export default ChartWrap;
