import React, { useEffect } from 'react';
import styled from 'styled-components';

import useToggle from '@/Components/hooks/useToggle';

import checkIcon from '@/assets/images/icons/check.svg';
import checkGreyIcon from '@/assets/images/icons/check-grey-icon.svg';

interface IProps {
  onCheck: (e: React.MouseEvent<HTMLDivElement>) => void
  onClickInactive?: (e: React.MouseEvent<HTMLDivElement>) => void
  isChecked?: boolean
  isRound?: boolean
  noMargins?: boolean
  isRoundCorners?: boolean
  isGrey?: boolean
  inactive?: boolean
  onLabelClick?: () => void
  children?: React.ReactNode
}

const Checkbox: React.FC<IProps> = ({
  onCheck,
  onClickInactive = () => {},
  children,
  isChecked = false,
  isRound = false,
  noMargins = false,
  isRoundCorners = false,
  isGrey = false,
  inactive = false,
  onLabelClick = null,
}) => {
  const [checked, setChecked] = useToggle(isChecked);
  const handleCheck = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!inactive) {
      onCheck(e);
      setChecked(e);
      return;
    }
    onClickInactive(e);
  };

  useEffect(() => {
    if (checked !== isChecked) {
      setChecked(isChecked);
    }
  }, [isChecked]);

  return (
    <CheckboxLabel noMargins={noMargins}>
      <CheckboxContainer
        onClick={handleCheck}
        noMargins={noMargins}
        isRound={isRound}
        isGrey={isGrey}
      >
        <CustomCheckbox
          isChecked={checked}
          isRound={isRound}
          isInactive={inactive}
          isRoundCorners={isRoundCorners}
          isGrey={isGrey}
        >
          {!isRound && (
            <CheckIcon isGrey isChecked={checked} src={isGrey ? checkGreyIcon : checkIcon} />
          )}
        </CustomCheckbox>
      </CheckboxContainer>
      <Label
        isInactive={inactive}
        onClick={onLabelClick || handleCheck}
        isRound={isRound}
        isGrey={isGrey}
      >
        {children}
      </Label>
    </CheckboxLabel>
  );
};

const CheckboxLabel = styled.div<{ noMargins: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  margin: ${({ noMargins }) => (noMargins ? 0 : '37px 9px 15px 0')};
`;

const CheckboxContainer = styled.div<{ noMargins: boolean; isRound: boolean; isGrey: boolean }>`
  width: ${({ isRound }) => (isRound ? 30 : 32)}px;
  height: ${({ isRound }) => (isRound ? 30 : 32)}px;
  margin-left: ${({ noMargins, isGrey }) => (isGrey && noMargins ? '-6px' : noMargins ? 0 : '-5px')};

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
`;

const CustomCheckbox = styled.div<{
  isChecked: boolean
  isRound: boolean
  isInactive: boolean
  isRoundCorners: boolean
  isGrey: boolean
}>`
  width: ${({ isRound, isGrey }) => (isGrey ? 20 : isRound ? 12 : 24)}px;
  height: ${({ isRound, isGrey }) => (isGrey ? 20 : isRound ? 12 : 24)}px;
  border-radius: ${({ isRound, isRoundCorners }) => (isRound ? '50%' : isRoundCorners ? '4px' : 0)};
  border: ${({ isRound, isChecked }) => (isRound && isChecked ? '3px solid #0066FF' : '1px solid #c4c4c4')};
  background: #ffffff;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckIcon = styled.img<{ isChecked: boolean; isGrey: boolean }>`
  display: ${({ isChecked }) => !isChecked && 'none'};
  width: ${({ isGrey }) => (isGrey ? 13 : 16.62)}px;
  height: ${({ isGrey }) => (isGrey ? 16 : 17.62)}px;
  // z-index: 2;
`;

const Label = styled.div<{ isInactive: boolean; isRound: boolean; isGrey: boolean }>`
  width: ${({ isRound }) => (isRound ? '100%' : 'auto')};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: ${({ isGrey }) => (isGrey ? '2' : '9')}px;
  padding: 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #000000;
  cursor: pointer;
`;

export default Checkbox;
