import { createReducerFunction, ImmerReducer } from 'immer-reducer';
import { IDepartment, IUser, IUsersStatic } from '@/api/main-protected';

import { RequestState } from '@/store/reducers/common';

export interface InitState {
  userError: boolean
  userInfo: IUser | null
  updatedUser: IUser | null
  users: IUser[] | null
  departments: IDepartment[] | null
  usersStatic: IUsersStatic | null
  addUserState: RequestState
  deleteUserState: RequestState
  departmentsState: RequestState
}

const initialState: InitState = {
  userError: false,
  userInfo: null,
  updatedUser: null,
  users: null,
  usersStatic: null,
  departments: null,
  addUserState: RequestState.IDLE,
  deleteUserState: RequestState.IDLE,
  departmentsState: RequestState.IDLE,
};

export class UserReducer extends ImmerReducer<InitState> {
  setUserInfo(user: IUser) {
    this.draftState.userInfo = user;
  }

  updateUserInfo(updatedUser: IUser) {
    this.draftState.updatedUser = updatedUser;
  }

  setUserError() {
    this.draftState.userError = true;
  }

  setUsers(users: IUser[]) {
    this.draftState.users = users;
  }

  setUsersStatic(usersStatic: IUsersStatic) {
    this.draftState.usersStatic = usersStatic;
  }

  setAddUserState(state: RequestState) {
    this.draftState.addUserState = state;
  }

  setDeleteUserState(state: RequestState) {
    this.draftState.deleteUserState = state;
  }

  setDepartments(departments: IDepartment[]) {
    this.draftState.departments = departments;
  }

  setDepartmentsState(state: RequestState) {
    this.draftState.departmentsState = state;
  }
}

export default createReducerFunction(UserReducer, initialState);
