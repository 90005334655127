import React, { useState } from 'react';
import styled from 'styled-components';
import moment, { Moment } from 'moment';

import ArrowIcon from '@/Components/icons/ArrowIcon';

import Days from '@/Components/common/calendar/Days';

import useOnClickOutside from '@/Components/hooks/useOnClickOutside';

import { defaultLocale, startOf } from '@/utils/date';

interface Props {
  tempFromDate: Moment | null;
  tempTillDate: Moment | null;
  minFromDate?: Moment;
  setTempDate: (value: Moment | null) => void;
  isFromDate: boolean;
  isTillToday?: boolean;
  isFromToday?: boolean;
  setVisible?: (status: boolean) => void;
  keepSecondModalOpen?: () => void;
}

const Calendar: React.FC<Props> = ({
  tempFromDate,
  tempTillDate,
  minFromDate,
  setTempDate,
  isFromDate,
  isTillToday,
  isFromToday,
  setVisible,
  keepSecondModalOpen,
}) => {
  const selectRef = useOnClickOutside(() => {
    if (setVisible) {
      setVisible(false);
    }
    if (keepSecondModalOpen) {
      keepSecondModalOpen();
    }
  });

  const todayDate = startOf();

  const tempDate = React.useMemo(() => (
    isFromDate ? tempFromDate : tempTillDate
  ), [isFromDate]);

  const currentDate = React.useMemo(() => (
    tempDate ? moment(new Date(tempDate.toDate())) : null
  ), [tempDate]);

  const [date, setDate] = useState<Moment>(currentDate || startOf());

  const resetDate = () => setDate(moment().utc());

  const formatDate = () => date.locale(defaultLocale).format('MMMM YYYY');

  const changeMonth = (value: number, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setDate(moment(date).month(value));
  };

  const changeDate = (select: Moment) => {
    let tempSelectedDate = tempDate;

    if (tempDate === null) {
      tempSelectedDate = moment(select);
    } else if (select.isBefore(tempDate, 'day') || select.isAfter(tempDate, 'day')) {
      tempSelectedDate = moment(select);
    } else if (tempDate.isSame(tempDate, 'day')) {
      tempSelectedDate = null;
    }

    setTempDate(tempSelectedDate);
  };

  return (
    <CalendarStyled ref={selectRef}>
      <Heading>
        <IconLeftArrow onClick={(e) => changeMonth(date.month() - 1, e)}>
          <ArrowIcon width={20} height={20} />
        </IconLeftArrow>
        <Text onClick={resetDate}>{formatDate()}</Text>
        <IconRightArrow onClick={(e) => changeMonth(date.month() + 1, e)}>
          <ArrowIcon width={20} height={20} />
        </IconRightArrow>
      </Heading>

      <Days
        onClick={(data: Moment) => changeDate(data)}
        date={date}
        selectedDate={tempDate}
        minFromDate={minFromDate}
        isFromDate={isFromDate}
        tempFromDate={tempFromDate}
        tempTillDate={tempTillDate}
        todayDate={todayDate}
        isTillToday={isTillToday}
        isFromToday={isFromToday}
      />
    </CalendarStyled>
  );
};

const CalendarStyled = styled.div`
  width: 300px;
  min-height: 300px;
  border-radius: 16px;
  padding: 16px;
  border: 1px solid #d9d9d9;
  background-color: #f6f6f6;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
`;

const Text = styled.p`
  flex: 1;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  user-select: none;
`;

const IconArrow = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

const IconLeftArrow = styled(IconArrow)`
  margin-right: 9px;
`;

const IconRightArrow = styled(IconArrow)`
  transform: rotate(180deg);
  margin-left: 9px;
`;

export default Calendar;
