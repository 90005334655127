import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import useImportZendeskScript from 'Components/hooks/useZendeskScript';
import useZendeskCustomizationScript from '@/Components/hooks/useZendeskCustomizationScript';

import { selectUserInfo } from '@/store/selectors/user';
import { eventsActions } from '@/store/actions/events';

import customScrollCss from '@/utils/custom-scroll-css';

import { AppDispatch } from '@/App';

import LocalStorage from '@/local-storage/LocalStorage';

import Search from '@/Components/SearchBlock/Search';
import UserInfo from '@/Components/SearchBlock/UserInfo';
import ControlCenter from '@/Components/ControlCenter/ControlCenter';
import Requests, { DefaultFilter } from '@/Components/Requests/Requests';
import Events from '@/Components/Events/Events';
import UserAdmin from '@/Components/UserAdmin/UserAdmin';
import UserSettings from '@/Components/UserSettings/UserSettings';
import AddNewUser from '@/Components/AddNewUser/AddNewUser';
import Incidents from '@/Components/Incidents/Incidents';
import Items from '@/Components/Items/Items';
import PersonaManager from '@/Components/PersonaManager/PersonaManager';
import Support from '@/Components/Support/Support';
import ReleaseNotes from '@/Components/ReleaseNotes/ReleaseNotes';
import UserProfile from '@/Components/UserProfile/UserProfile';
import AllResults from '@/Components/AllResults/AllResults';

interface IMain {
  isExpanded: boolean;
}

export const PAGES = {
  LOGIN: '/login',
  REDIRECT: '/redirect',
  SIGN_UP: '/sign-up',
  RESET_PSW: '/reset-password',
  CONTROL_CENTER: '/',
  USER_ADMIN: '/admin',
  BETA_GROUP: '/group',
  FEEDBACK: '/feedback',
  FEATURE_REQUESTS: '/requests',
  ROADMAP: '/roadmap',
  ACTIVITY_LOG: '/activity-log',
  PLAYBOOKS: '/playbooks',
  PERSONA_MANAGER: '/manager',
  EVENTS: '/events',
  INCIDENTS: '/incidents',
  REQUESTS: '/requests',
  REQUESTS_ID: '/requests/:id',
  ITEMS: '/items',
  USER_SETTINGS: '/user-settings',
  USER_PROFILE: '/user-profile',
  ADD_NEW_USER: '/add-new-user',
  CHANGELOG: '/changelog',
  ALL_RESULTS: '/all-results',
  SUPPORT: '/support-center',
};

const MainComponent: React.FC<IMain> = ({ isExpanded }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const zendeskWidget = process.env.ZENDESK_WIDGET || 'false';

  if (zendeskWidget === 'true') {
    const user = useSelector(selectUserInfo);
    const isDocuSigned = LocalStorage.getDocuSignedId();
    const isLoadZendesk = !!user && isDocuSigned === user?.user_id;
    const [isZendeskForm, setIsZendeskForm] = useState<boolean>(false);

    // need to refactor by pasting into code directly
    useZendeskCustomizationScript(isLoadZendesk, user);
    useImportZendeskScript(isLoadZendesk); // should be called the last of zendesk scripts

    useEffect(() => {
      if (!isLoadZendesk) return;

      const timer = setInterval(() => {
        const launcher = document.querySelector('#launcher') as HTMLIFrameElement;

        if (!launcher) return;
        const launcherBtn = launcher.contentDocument?.body.querySelector(
          '[data-testid="launcher"]',
        ) as HTMLIFrameElement;

        launcherBtn.addEventListener('click', () => {
          // e.preventDefault()
          setIsZendeskForm(true);
        });

        clearInterval(timer);

        return () => launcherBtn.removeEventListener('click', () => {});
      }, 500);

      return () => clearInterval(timer);
    }, [isLoadZendesk]);

    useEffect(() => {
      if (!isZendeskForm) return;

      const timer = setInterval(() => {
        const form = document.querySelector('#webWidget') as HTMLIFrameElement;

        if (!form) return;

        const strongs = form.contentDocument?.body.querySelectorAll('label');
        const h1 = form.contentDocument?.body.querySelector('h1') as HTMLElement;
        const closeBtn = h1.nextElementSibling;
        const svg = closeBtn?.querySelector('svg');
        const path = closeBtn?.querySelector('path');
        const submitBtn = form.contentDocument?.body.querySelector('[type="submit"]');

        const addUp = () => {
          strongs?.forEach((s) => {
            s.style.textTransform = 'uppercase';
            s.style.fontFamily = '"Jost", sans-serif';
            s.style.fontWeight = '600';
            s.style.fontSize = '12px';
          });
        };
        addUp();

        h1.style.cssText = `
          color: #ffffff !important;
          font-family: 'Jost', sans-serif !important;
          font-weight: 600 !important;
          font-size: 14px !important;
        `;

        closeBtn?.setAttribute('style', 'fill: #ffffff !important');

        svg?.setAttribute('style', 'fill: #ffffff !important');
        svg?.setAttribute('style', 'color: #ffffff !important');

        path?.setAttribute('style', 'fill: #ffffff !important');
        path?.setAttribute('style', 'color: #ffffff !important');

        submitBtn?.setAttribute('style', 'color: #ffffff !important');

        setIsZendeskForm(true);

        closeBtn?.addEventListener('click', () => {
          // e.preventDefault()
          setIsZendeskForm(false);
        });

        clearInterval(timer);

        // eslint-disable-next-line consistent-return
        return () => closeBtn?.removeEventListener('click', () => {});
      }, 500);

      // eslint-disable-next-line consistent-return
      return () => {
        clearInterval(timer);
      };
    }, [isZendeskForm]);
  }

  useEffect(() => {
    if (pathname !== PAGES.REQUESTS) {
      dispatch(eventsActions.setRequestsPage(DefaultFilter.page));
      dispatch(eventsActions.setActiveRequestsPriority(DefaultFilter.priority));
      dispatch(eventsActions.setActiveRequestsStatus(DefaultFilter.status));
      dispatch(eventsActions.setActiveRequestsLabel(DefaultFilter.phase));
    }
  }, [pathname]);

  return (
    <Wrap noScroll={pathname === PAGES.CHANGELOG}>
      <SearchBlock>
        <Inner>
          <Search />
          <UserInfo />
        </Inner>
      </SearchBlock>
      <Content>
        {(() => {
          switch (pathname) {
            case PAGES.CONTROL_CENTER:
              return <ControlCenter />;

            case PAGES.USER_ADMIN:
              return <UserAdmin />;

            case PAGES.ADD_NEW_USER:
              return <AddNewUser />;

            case pathname.startsWith(PAGES.REQUESTS) && pathname:
              return <Requests isExpanded={isExpanded} />;

            case PAGES.INCIDENTS:
              return <Incidents />;

            case PAGES.EVENTS:
              return <Events />;

            case PAGES.USER_SETTINGS:
              return <UserSettings />;

            case PAGES.USER_PROFILE:
              return <UserProfile />;

            case PAGES.ITEMS:
              return <Items />;

            case PAGES.CHANGELOG:
              return <ReleaseNotes />;

            case PAGES.ALL_RESULTS:
              return <AllResults />;

            case PAGES.PERSONA_MANAGER:
              return <PersonaManager />;

            case PAGES.SUPPORT:
              return <Support />;

            default:
              return null;
          }
        })()}
      </Content>
    </Wrap>
  );
};

const Wrap = styled.div<{ noScroll?: boolean }>`
  flex: 1 1 auto;
  min-height: 100vh;
  padding: 0px 30px 56px;
  overflow-x: hidden;
  overflow-y: ${({ noScroll }) => (noScroll ? 'hidden' : 'auto')};

  ${customScrollCss}

  & #webWidget #Embed {
    color: red !important;
    text-transform: uppercase !important;
  }
`;

const SearchBlock = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: calc(100% + 20px);
  padding: 0px 10px;
  margin: 0 -10px;
  background: #ffffff;
  z-index: 20;
`;

const Inner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 36px 0px 20px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.7);
`;
const Content = styled.div`
  width: 100%;
`;

export default MainComponent;
