import React from 'react';
import styled from 'styled-components';

interface ISwitcher {
  setIsActive: (isActive: boolean) => void
  isActive: boolean
  isDisabled?: boolean
}

const ToggleSwitch: React.FC<ISwitcher> = ({ setIsActive, isActive, isDisabled = false }) => {
  const handleClick = () => {
    if (isDisabled) {
      return;
    }

    setIsActive(!isActive);
  };

  return (
    <Wrap isDisabled={isDisabled} onClick={handleClick} isActive={isActive}>
      <Knob isActive={isActive} />
    </Wrap>
  );
};

const Wrap = styled.div<{ isActive: boolean, isDisabled: boolean }>`
  background: ${({ isActive }) => (isActive ? '#0066FF' : '#DCDCDC')};
  border: 1px solid ${({ isActive }) => (isActive ? '#0066FF' : '#DCDCDC')};
  height: 21px;
  width: 44px;
  border-radius: 22px;
  margin: 0 6px;
  cursor: ${({ isDisabled }) => !isDisabled && 'pointer' };
`;

const Knob = styled.div<{ isActive: boolean }>`
  position: relative;
  width: 18px;
  height: 18px;
  background: #ffffff;
  border: 1px solid ${({ isActive }) => (isActive ? '#0066FF' : '#DCDCDC')};
  border-radius: 50%;
  left: ${({ isActive }) => (isActive ? 2 : 22)}px;
  transition: all 0.3s ease-out;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export default ToggleSwitch;
