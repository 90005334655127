import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectUserInfo } from '@/store/selectors/user';

import profileDefaultIcon from '@/assets/images/icons/user-settings/user-profile-default-icon.svg';
import startdateIcon from '@/assets/images/icons/user-profile/startdate.svg';
import organizationIcon from '@/assets/images/icons/user-profile/organization.svg';
import pinIcon from '@/assets/images/icons/user-profile/pin.svg';
import roleIcon from '@/assets/images/icons/user-profile/role.svg';
import mailIcon from '@/assets/images/icons/user-profile/mail.svg';
import phoneIcon from '@/assets/images/icons/user-profile/phone.svg';

import IconComponent from '@/Components/common/IconComponent';
import HeightFitContainer from '@/Components/common/HeightFitContainer';
import customScrollCss from '@/utils/custom-scroll-css';
import { getUserRoleName } from '@/utils/user';

const UserProfile: React.FC = () => {
  const user = useSelector(selectUserInfo);

  return (
    <Wrap>
      <UserData>
        <UserHeader>
          <UserBgImg />
          <TitleBlock>
            <ProfImgBlock>
              <ImgBlock>
                <IconComponent
                  icon={user?.picture || profileDefaultIcon}
                  alt={`${user?.first_name} ${user?.last_name}` || 'userName'}
                />
              </ImgBlock>
            </ProfImgBlock>
            <Name>{`${user?.first_name} ${user?.last_name}`}</Name>
            <LocationBox>
              {user?.business_unit && (
                <LocationItem>
                  <IconBox>
                    <IconComponent icon={organizationIcon} width={13} />
                  </IconBox>
                  <Text>{user?.business_unit}</Text>
                </LocationItem>
              )}
              {user?.country && (
                <LocationItem>
                  <IconBox>
                    <IconComponent icon={pinIcon} width={12} />
                  </IconBox>
                  <Text>{user?.country}</Text>
                </LocationItem>
              )}
              {user?.joined_date && (
                <LocationItem>
                  <IconBox>
                    <IconComponent icon={startdateIcon} width={16} />
                  </IconBox>
                  <Text>{user?.joined_date}</Text>
                </LocationItem>
              )}
            </LocationBox>
          </TitleBlock>
        </UserHeader>
        <HeightFitContainer extraSpace={130} isNoBorder>
          <Main>
            <Section topOffset={20}>
              <Title>Profile</Title>
              {user && (
                <Info>
                  <Label>About</Label>
                  <Item>
                    <IconBox>
                      <IconComponent icon={roleIcon} width={16} />
                    </IconBox>
                    <SectionText>{getUserRoleName(user.roles[0])}</SectionText>
                  </Item>
                </Info>
              )}
              <Info>
                <Label>CONTACT</Label>
                <Item>
                  <IconBox>
                    <IconComponent icon={mailIcon} width={16} />
                  </IconBox>
                  <SectionText>{user?.email}</SectionText>
                </Item>
                <Item>
                  <IconBox>
                    <IconComponent icon={phoneIcon} width={16} />
                  </IconBox>
                  <SectionText>{user?.phone_numbers[0]?.phone || ''}</SectionText>
                </Item>
              </Info>
            </Section>
            {false && (
            <Section topOffset={20}>
              <Title>Activity Stream</Title>
              <Activity>
                <ActivityItem>
                  <BoldText>Updated phone number</BoldText>
                  <DateText>Aug 15 - 2:30 PM</DateText>
                </ActivityItem>

                <ActivityItem>
                  <BoldText>Exported Requests - Events to CSV</BoldText>
                  <DateText>Aug 15 - 10:11 AM</DateText>
                </ActivityItem>

                <ActivityItem>
                  <BoldText>Updated phone number</BoldText>
                  <DateText>Aug 14 - 09:55 AM</DateText>
                </ActivityItem>

                <ActivityItem>
                  <BoldText>Joined Xperienc</BoldText>
                  <DateText>Aug 13 - 09:21 AM</DateText>
                </ActivityItem>
                <ActivityItem>
                  <BoldText>Joined Xperienc</BoldText>
                  <DateText>Aug 13 - 09:21 AM</DateText>
                </ActivityItem>
                <ActivityItem>
                  <BoldText>Joined Xperienc</BoldText>
                  <DateText>Aug 13 - 09:21 AM</DateText>
                </ActivityItem>
                <ActivityItem>
                  <BoldText>Joined Xperienc</BoldText>
                  <DateText>Aug 13 - 09:21 AM</DateText>
                </ActivityItem>
                <ActivityItem>
                  <BoldText>Joined Xperienc</BoldText>
                  <DateText>Aug 13 - 09:21 AM</DateText>
                </ActivityItem>
                <ActivityItem>
                  <BoldText>Joined Xperienc</BoldText>
                  <DateText>Aug 13 - 09:21 AM</DateText>
                </ActivityItem>
                <ActivityItem>
                  <BoldText>Joined Xperienc</BoldText>
                  <DateText>Aug 13 - 09:21 AM</DateText>
                </ActivityItem>
                <ActivityItem>
                  <BoldText>Joined Xperienc</BoldText>
                  <DateText>Aug 13 - 09:21 AM</DateText>
                </ActivityItem>
              </Activity>
            </Section>
            )}
          </Main>
        </HeightFitContainer>
      </UserData>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  margin-top: 27px;
  position: relative;
`;

const UserData = styled.div`
  flex: 1 1 100%;
  width: 100%;
`;
const UserHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid #e5e5e5;
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 21px;
  background: #ffffff;
`;

const Main = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 25px;
  width: 100%;
`;

const UserBgImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 145px;
  background: #e5e5e5;

  background: linear-gradient(180deg, rgba(183, 97, 255, 0.9) 0%, rgba(151, 71, 255, 0.9) 100%);
  border: 1px solid #e5e5e5;
  border-radius: 4px;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TitleBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #000000;
  background: #ffffff;
  padding: 20px 20px 20px 36px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.7);
  text-transform: capitalize;
`;
const Name = styled.h1`
  font-weight: 500;
  font-size: 20px;
`;

const LocationBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  margin: 20px;
`;
const LocationItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  margin: 10px;
`;
const Item = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  margin: 10px 0;
`;
const IconBox = styled.div`
  margin-right: 7px;
`;
const Text = styled.p`
  font-weight: 400;
  font-size: 15px;
`;
const ProfImgBlock = styled.div`
  position: relative;
  width: 118px;
  height: 118px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  margin: 48px 0 35px;
`;

const ImgBlock = styled.div`
  border-radius: 50%;
  overflow: hidden;
`;

const Section = styled.section<{ topOffset: number }>`
  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  &:nth-child(1) {
    min-width: 300px;
    width: 100%;
    height: fit-content;
  }

  &:nth-child(2) {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

const Info = styled.div`
  padding: 9px 20px 10px 36px;

  &:last-child {
    padding: 0px 20px 35px 36px;
  }
`;
const Label = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #4a4949;
  text-transform: uppercase;
  margin: 15px 0 20px;
`;
const SectionText = styled.p`
  font-weight: 400;
  font-size: 16px;
  margin: 5px;
`;

const BoldText = styled.p`
  font-weight: 500;
  font-size: 16px;
  padding: 0 0 10px 33px;

  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: #d9d9d9;
  }
`;
const DateText = styled.p`
  font-weight: 400;
  font-size: 14px;
  padding: 0 0 0 33px;
`;

const Activity = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
  overflow-y: auto;

  @media (hover: hover) {
    ${customScrollCss}
  }
`;
const ActivityItem = styled.div`
  margin: 0px 0px 0px 36px;
  padding: 0px 20px 45px 0px;

  &:first-child {
    margin: 35px 0px 0px 36px;
  }

  position: relative;

  &:not(:last-child):before {
    content: '';
    position: absolute;
    top: 5px;
    left: 8px;
    width: 1px;
    height: 100%;
    background: #868484;
  }
`;

export default UserProfile;
