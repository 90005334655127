import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import IconComponent from '@/Components/common/IconComponent';
import LoaderDots from '@/Components/LoaderDots';
import { eventsActions, getIncidentRequest } from '@/store/actions/events';

import { AppDispatch } from '@/App';
import { IAsset, IEvent } from '@/api/main-protected';
import convertFromStringToDate, { FormatDate } from '@/utils/convertFromStringToDate';
import { groupBy } from '@/utils/groupBy';

import playIcon from '@/assets/images/icons/play-arrow-icon.svg';
import notesIcon from '@/assets/images/icons/notes-icon.svg';
import yellowWarnIcon from '@/assets/images/icons/events/warn-yellow.svg';

interface IExpandEventTableLine {
  event: IEvent;
  setIsNotesModal: () => void;
  handleOpenEventModal: (ev: IEvent) => void;
  setItemModal?: (item: IAsset) => void;
}

const ExpandEventTableLine: React.FC<IExpandEventTableLine> = ({
  event,
  setIsNotesModal,
  handleOpenEventModal,
  setItemModal,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpening, setIsOpening] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const events = event?.request.events;

  useEffect(() => {
    if (isOpen) {
      setIsOpening(false);
    }
  }, [isOpen]);

  const handleArrowClick = async () => {
    if (isOpen) {
      setIsOpen(false);
      dispatch(eventsActions.setIncidentRequest(null));
      return;
    }

    if (event.items && event.events) {
      setIsOpen(true);
      return;
    }

    setIsOpening(true);
    await dispatch(getIncidentRequest(event.event_id, true));
    setIsOpen(true);
  };

  const handleNotesClick = () => {
    setIsNotesModal();
  };

  const handleItemClick = (item: IAsset) => {
    if (setItemModal) {
      setItemModal(item);
    }
  };

  const handleOpenALLViewEventsModal = (eventArr: IEvent[]) => {
    const grouped = groupBy(
      eventArr,
      (i) => convertFromStringToDate(i.event_date, FormatDate.GroupFormat),
    );

    dispatch(
      eventsActions.setViewAllEvents({
        request: event.request,
        events: Object.entries(grouped),
      }),
    );
  };

  return (
    <>
      <ArrowImg onClick={handleArrowClick} isOpen={isOpen}>
        {!isOpening ? <img src={playIcon} alt="play" /> : <LoaderDots />}
      </ArrowImg>

      <Wrap isOpen={isOpen}>
        <Row>
          {/* <Column>
            <Title>REQUESTOR</Title>
            <Text>-</Text>
            <Text>-</Text>
            <Text>-</Text>
          </Column> */}
          <UsersColumn>
            <Title>Impacted users</Title>
            {event.request.impacted_user
            && Object.values(event.request.impacted_user).filter((val) => !!val).length > 0 ? (
              <ImpactedUser>
                <Text>{`${event.request.impacted_user.first_name} ${event.request.impacted_user.last_name}`}</Text>
                <Text isNoCapitalize>{event.request.impacted_user.email}</Text>
                <Text>{event.request.impacted_user.phone}</Text>
              </ImpactedUser>
              ) : (
                <Text>N/A</Text>
              )}
          </UsersColumn>
          <Column>
            <Title>Action date</Title>
            <Text>
              {event.request?.action_date
                ? convertFromStringToDate(event.request?.action_date, FormatDate.ShortFormat)
                : 'N/A'}
            </Text>
          </Column>
          <Column>
            <Title>Department</Title>
            <Text>{event.request?.agency || 'N/A'}</Text>
          </Column>
          {/* <Column>
            <Title>ASSociated PlAYBOOKS</Title>
            <Text isBlue>-</Text>
          </Column> */}
          <ItemsColumn>
            <Title>ITEMS - Serial Number</Title>
            {event.items?.length > 0
              ? event.items.map((item, i) => (
                <Text key={`${item.asset_tag}${i}exp-ev`} isBlue isLink onClick={() => handleItemClick(item)}>
                  {`${item.model} - ${item.serial_number}`}
                </Text>
              ))
              : event.request.serial_numbers.map((num, i) => <Text key={`${num}${i}exp-ev-num`}>{num || 'N/A'}</Text>)}
          </ItemsColumn>
          {/* <Column>
            <Title>EXCEPTIONS</Title>
            {event?.incidents && event?.incidents.length > 0
              ? event.incidents.map((item) => (
                <Text key={item.incident_id} isBlue>
                  {item.incident_id ? `I-${item.incident_id}` : '-'}
                </Text>
              ))
              : <Text>-</Text>}
          </Column> */}
          <EventsColumn>
            <Title>
              EVENTS
              {events && events?.length > 0 && (
                <ViewAll onClick={() => handleOpenALLViewEventsModal(events as IEvent[])}>
                  <Text>View All</Text>
                </ViewAll>
              )}
            </Title>
            {events && events?.length > 0 ? (
              events.slice(0, 1).map((item, i) => (
                <EventBox key={`${item.event_id}${i}exp-events`}>
                  <Text isBlue isLink onClick={() => handleOpenEventModal(item as IEvent)}>
                    {item.event_id ? `${item.short_desc}` : ''}
                  </Text>
                  {event?.incident_count > 0
                    && item.incidents.filter((inc) => inc.incident_id).length > 0 && (
                      <EventIcon>
                        <IconComponent width={20} icon={yellowWarnIcon} />
                      </EventIcon>
                  )}
                </EventBox>
              ))
            ) : (
              <Text>N/A</Text>
            )}
          </EventsColumn>
          <Column>
            <Title>NOTES</Title>
            <NotesIcon>
              <IconBox onClick={handleNotesClick}>
                <IconComponent icon={notesIcon} width={23} />
              </IconBox>
            </NotesIcon>
          </Column>
        </Row>
      </Wrap>
    </>
  );
};

const Wrap = styled.div<{ isOpen: boolean; isExpanded?: boolean }>`
  position: relative;
  z-index: 2;

  // flex: 0 1 1075px; // with container
  // width: 1075px; // with container
  /* flex: 0 0 calc(100vw - 245px);
  width: calc(100vw - 245px); */
  flex: 0 0 ${({ isExpanded }) => (!isExpanded ? 'calc(100vw - 350px)' : 'calc(100vw - 245px)')};
  width: ${({ isExpanded }) => (!isExpanded ? 'calc(100vw - 350px)' : 'calc(100vw - 245px)')};

  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: ${({ isOpen }) => (isOpen ? 'all 0.3s ease' : 'none')};

  margin: ${({ isOpen }) => (isOpen ? '55px 0 28px 36px' : '8px 0 0px 36px')};
  padding: 10px 0 0;

  // border-top: 1px solid #e5e5e5; // with container
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #e5e5e5;
  }

  @media (max-width: 1440px) {
    flex: 0 0 ${({ isExpanded }) => (!isExpanded ? 1200 : 1185)}px; // with expanded
    width: ${({ isExpanded }) => (!isExpanded ? 1200 : 1185)}px; // with expanded
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Column = styled.div`
  &:last-child {
    flex: 0 0 90px;
    width: 90px;
  }
`;

const UsersColumn = styled.div`
  flex: 0 0 215px;
  width: 215px;
`;
const ItemsColumn = styled.div`
  flex: 0 0 185px;
  width: 185px;
`;

const Title = styled.h5`
  font-weight: 600;
  font-size: 14px;
  /* line-height: 37px; */
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding: 0 0 10px 0;
`;
const Text = styled.p<{ isBlue?: boolean; isLink?: boolean; isNoCapitalize?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: ${({ isBlue }) => (isBlue ? '#0066FF' : '#000000')};
  text-transform: ${({ isNoCapitalize }) => (isNoCapitalize ? 'none' : 'capitalize')};

  ${({ isLink }) => isLink
    && css`
      @media (hover: hover) {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    `}
`;
const ArrowImg = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 10px;
  left: 15px;
  display: flex;
  align-items: center;
  transform: rotate(${({ isOpen }) => (isOpen ? 90 : 0)}deg);
  transition: all 0.3s ease;
  cursor: pointer;

  & img {
    max-width: 100%;
  }

  // loading dots
  & > div {
    height: 13px;
  }
  & > div > div {
    width: 5px;
    height: 5px;
    margin: 1px;
  }
`;
const NotesIcon = styled.div<{ isDisabled?: boolean }>`
  padding-left: 15px;
  & img {
    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  }
`;

const IconBox = styled.div``;
const ImpactedUser = styled.div`
  margin-bottom: 10px;
`;

const EventsColumn = styled.div`
  flex: 0 0 220px;
  width: 220px;
`;

const EventBox = styled.div`
  display: flex;
  align-items: center;
`;
const EventIcon = styled.div`
  margin-left: 6px;
`;

const ViewAll = styled.div`
  display: inline-block;
  border: 1px solid #0066ff;
  border-radius: 15px;
  padding: 2px 9px;
  margin-left: 10px;
  cursor: pointer;
  & p {
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;
    color: #0066ff;
  }
`;
export default ExpandEventTableLine;
