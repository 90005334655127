import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '@/App';
import TableContainer, { IMultiItemsFiltersLabel, ITableTabs } from '@/Components/Items/ItemsTable/TableContainer';
import { IDateFilters } from '@/Components/Items/ItemsTable/TableFilters';

import { getEvents, getEventsFilters, getEventsStatic } from '@/store/actions/events';
import {
  selectEventsFilters,
  selectEventsFiltersState,
  selectEventsPaginationState,
  selectEventsStatic,
  selectEventsState,
  selectEventsStaticState,
  selectEventsArr,
} from '@/store/selectors/events';
import { RequestOptions, RequestState } from '@/store/reducers/common';

import { ITableBody } from '@/interfaces/api';

import { sort, SortOrder, SortType } from '@/utils/sortUtil';
import { eventsPerPage } from '@/utils/api';

interface Props<T> extends ITableTabs<T> {
}

const EventsContainer = <T extends string>({
  interval,
  selectedTab,
  setSelectedTab,
  tabs,
  title,
}: Props<T>) => {
  const dispatch = useDispatch<AppDispatch>();

  const itemsFilters = useSelector(selectEventsFilters);
  const events = useSelector(selectEventsArr);
  const eventsTotal = useSelector(selectEventsStatic);
  const eventsState = useSelector(selectEventsState);
  const eventsPaginationState = useSelector(selectEventsPaginationState);
  const eventsTotalState = useSelector(selectEventsStaticState);
  const itemsFiltersState = useSelector(selectEventsFiltersState);

  const filtersLabel: IMultiItemsFiltersLabel = {
    statuses: { title: 'Status' },
    categories: { title: 'Category' },
  };

  const filters = useMemo(() => {
    const arrFilters = Object.entries(itemsFilters).map(
      ([key, value]) => [key, sort(
        value,
        (sValue) => sValue,
        SortOrder.ASCENDING,
        SortType.String,
      ),
      ],
    );
    return Object.fromEntries(arrFilters);
  }, [itemsFilters]);

  const dateFilters: IDateFilters = {
    date: {
      startDate: null,
      endDate: null,
      title: 'Date',
    },
  };

  const getItems = (body: ITableBody, option?: RequestOptions) => {
    dispatch(getEvents({ ...body, ...{ interval } }, option));
  };

  const getItemsTotal = (body: ITableBody) => {
    dispatch(getEventsStatic({ ...body, ...{ interval } }));
  };

  const getItemsFilter = () => {
    dispatch(getEventsFilters());
  };

  return (
    <TableContainer
      defaultSort={{ sortBy: 'event_date', direction: SortOrder.DESCENDING }}
      title={title}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      getItems={getItems}
      getItemsTotal={getItemsTotal}
      getItemsFilter={getItemsFilter}
      interval={interval}
      filtersLabel={{}}
      itemsFilters={{}}
      multiItemsFilters={filters}
      multiItemsFiltersLabel={filtersLabel}
      dateFilters={dateFilters}
      items={events}
      itemsTotal={eventsTotal}
      isItemsLoading={eventsState !== RequestState.LOADED}
      isItemsTotalLoading={eventsTotalState !== RequestState.LOADED}
      isItemsFilterLoading={itemsFiltersState !== RequestState.LOADED}
      isItemsPaginationLoading={eventsPaginationState !== RequestState.LOADED}
      initialItemsPerPage={eventsPerPage}
    />
  );
};

export default EventsContainer;
