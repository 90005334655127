/* eslint-disable no-param-reassign */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import styled, { css } from 'styled-components';

import Input from '@/Components/common/dropDown/Input';
import CheckBox from '@/Components/common/dropDown/CheckBox';

import ArrowIcon from '@/Components/icons/ArrowIcon';

import useInput from '@/Components/hooks/useInput';
import useKeyPress from '@/Components/hooks/useKeyPress';
import useOnClickOutside from '@/Components/hooks/useOnClickOutside';

import customScrollCss from '@/utils/custom-scroll-css';
import search from '@/utils/search';
import ArrowFullIcon from '@/Components/icons/ArrowFullIcon';

export enum DropDownState {
  AllList, SearchList, None,
}

interface Props {
  onChange: (value: string) => void;
  value: string;
  dropItems: string[];
  chooseAll?: string;
  placeholder?: string;
  maxHeight?: string;
  isForm?: boolean;
  isOptionDisabled?: boolean;
  isOneWord?: boolean;
  isError?: boolean,
}

const DropDownCheckBox: React.FC<Props> = ({
  dropItems,
  chooseAll = '',
  placeholder = '',
  onChange,
  value,
  maxHeight = '258px',
  isForm = false,
  isOptionDisabled = false,
  isOneWord = false,
  isError = false,
}) => {
  const tabPress = useKeyPress('Tab');

  const cardRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [dropDownState, setDropDownState] = useState<DropDownState>(DropDownState.None);
  const [text, setText] = useInput('');

  const replacedItems = useMemo(() => dropItems.map((item) => item.replaceAll(',', '٫')), [dropItems]);

  const allDropItems = useMemo(
    () => [...(chooseAll ? [chooseAll] : []), ...replacedItems],
    [replacedItems],
  );

  const items = search(allDropItems, (name: string) => name, text);

  const dropDownRef = useOnClickOutside(() => {
    closeDropDown();
  });

  const [chooseAllItemsOp, setChooseAllItemsOp] = useState(false);
  const isActiveItem = React.useMemo(() => (name: string) => {
    if (name === chooseAll) {
      return !!replacedItems
        .every((valueItem) => value.split(', ').some((dropItem) => valueItem.trim() === dropItem.trim()));
    }

    return !!value
      .split(', ')
      .find((valueItem) => valueItem.trim().replace(',', '') === name);
  }, [value]);

  useEffect(() => {
    if (tabPress) {
      setDropDownState(DropDownState.None);
    }
  }, [tabPress]);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.scrollBy({ behavior: 'auto', left: inputRef.current.scrollWidth });
    }
  }, [value]);

  const handleOnBlur = () => {
    if (inputRef?.current) {
      inputRef.current.scrollBy({ behavior: 'auto', left: inputRef.current.scrollWidth });
    }
  };

  const isShowDropDown = useMemo(
    () => dropDownState === DropDownState.AllList
      || (dropDownState === DropDownState.SearchList && !!items.length),
    [dropDownState, items, value],
  );

  const closeDropDown = () => {
    setDropDownState(DropDownState.None);
  };

  const openDropDown = () => {
    if (dropDownState === DropDownState.AllList) {
      return;
    }
    setDropDownState(DropDownState.SearchList);
  };

  const chooseAllItem = (choose: boolean) => () => {
    setChooseAllItemsOp(choose);
    if(choose){ 
    const allWords = allDropItems
        .filter((item) => item.toLowerCase() !== chooseAll.toLowerCase())
        .reduce((acc, item, i) => {
          if (i === 0) {
            acc += item;
          } else {
            acc += `, ${item}`;
          }
          return acc;
        }, '');
      onChange(allWords);
    }else{
      onChange('')
    }
      
      return;
  }
  const chooseItem = (name: string) => () => {
    if (isOptionDisabled) return;

    inputRef.current?.focus();

    if (isOneWord) {
      onChange(name);
      return;
    }

    const lastWord = `${text}$`;
    const regExp = new RegExp(lastWord);
    const textValue = value.replace(regExp, '').trim();
    setText('');

    const itemIndex = value.split(', ').findIndex((valueItem) => valueItem.trim() === name);

    if (itemIndex !== -1) {
      const formatValue = value.split(', ').filter((itemName) => itemName.trim() !== name.trim()).join(', ');
      setChooseAllItemsOp(false)
      onChange(formatValue);
      return;
    }

    if (textValue.length && textValue[textValue.length - 1] === ',') {
      const val = `${textValue} ${name}`;
      onChange(val);
      return;
    }

    const val = `${textValue ? `${textValue}, ` : ''}${name}`;
    if (val.split(', ').length == items.length) {
      setChooseAllItemsOp(true)
    }
    onChange(val);
  };

  const onChangeHandler = (inputValue: string) => {
    const comaIndex = inputValue.split('').reduce((acc: number, letter, i) => {
      if (letter === ',') {
        // eslint-disable-next-line no-param-reassign
        acc = i;
        return i;
      }
      return acc;
    }, 0);
    const inputSearch = inputValue.slice(comaIndex ? comaIndex + 1 : 0);
    setText(inputSearch.trimLeft());
    onChange(inputValue);

    if (inputValue) {
      setDropDownState(DropDownState.SearchList);
      return;
    }
    setDropDownState(DropDownState.None);
  };

  const getAllItems = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();

    const isExpanded = dropDownState === DropDownState.AllList
      || dropDownState === DropDownState.SearchList;

    setDropDownState(isExpanded ? DropDownState.None : DropDownState.AllList);
  };

  return (
    <DropDownInputStyled ref={dropDownRef}>
      <Input
        inputRef={inputRef}
        onChange={onChangeHandler}
        value={value}
        onFocus={openDropDown}
        onBlur={handleOnBlur}
        placeholder={placeholder}
        isCapitalize
        icon={(
          <ArrowImg isAccending={isShowDropDown} isForm={isForm}>
            {isForm ? <ArrowFullIcon />
              : <ArrowIcon width={20} height={20} backgroundColor="transparent" color="#c4c4c4" />}
          </ArrowImg>
        )}
        onIconClick={getAllItems}
        isForm={isForm}
        isError={isError}
      />
      {isShowDropDown && (
        <DropDown ref={cardRef} maxHeight={maxHeight} isForm={isForm}>
          <DropDownItem
            key='all'
            onClick={chooseAllItem(!chooseAllItemsOp)}
            isForm={isForm}
            isDisabled={isOptionDisabled}
            >
              <DropDownWrap>
                <CheckBoxWrap>
                  <CheckBox
                      isActive={chooseAllItemsOp}
                      isForm={isForm}
                    />
                </CheckBoxWrap>
                <DropDownText>All</DropDownText>
              </DropDownWrap>
          </DropDownItem>
          <Divider></Divider>
          {
            items.map((name) => (
              <DropDownItem
                key={name}
                onClick={chooseItem(name)}
                isForm={isForm}
                isDisabled={isOptionDisabled}
              >
                <DropDownWrap>
                  <CheckBoxWrap>
                    <CheckBox
                      isActive={isActiveItem(name)}
                      isForm={isForm}
                    />
                  </CheckBoxWrap>
                  <DropDownText>{name}</DropDownText>
                </DropDownWrap>
              </DropDownItem>
            ))
          }
        </DropDown>
      )}
    </DropDownInputStyled>
  );
};

const Divider = styled.div`
  width: 98%;
  height: 1px;
  background: #c4c4c4;
  margin: 0 1%;
`;

const ArrowImg = styled.div<{ isAccending: boolean, isForm: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transform: 
   rotate(${({ isAccending }) => (isAccending
    ? 180 : 0)}deg);
  transition: all 0.3s ease;
`;

const CheckBoxWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-left: 10px;
`;

const DropDownWrap = styled.div`
  display: flex;
  align-items: center;
`;

const DropDownItem = styled.div<{ isForm: boolean, isDisabled: boolean }>`
  padding: ${({ isForm }) => (isForm ? '5px 16px 5px 0' : '10px 16px 10px 0')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'no-drop' : 'pointer')};

  :hover {
    background-color: ${({ isForm }) => (isForm ? '#f6f6f6' : '#ffffff')};
  }
`;

const DropDownText = styled.div`
  margin-left: 11px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: capitalize;
`;

const DropDown = styled.div<{ maxHeight: string, isForm: boolean }>`
  margin-top: ${({ isForm }) => (isForm ? '2' : '8')}px;
  padding: ${({ isForm }) => (isForm ? '0' : '10')}px 0px;
  width: 100%;
  max-height: ${({ maxHeight }) => maxHeight};
  position: absolute;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.22);
  background-color: ${({ isForm }) => (isForm ? '#ffffff' : '#f6f6f6')};
  overflow: auto;
  z-index: 10;
  border: 1px solid #d9d9d9;

  ${({ isForm }) => isForm && css`
    border-top-right-radius: 1px;
    border-top-left-radius: 1px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  `}

  ${customScrollCss};
`;

const DropDownInputStyled = styled.div`
  position: relative;
  width: 100%;
  
`;

export default DropDownCheckBox;
