import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { THEMES } from '@/store/reducers/theme';
import { selectCurrentTheme } from '@/store/selectors/theme';
import LocalStorage from '@/local-storage/LocalStorage';

const lightTheme = {
  palette: {
    blue: '#0066FF',
    violet: '#983fd0',
    black: '000000',
  },
};

const darkTheme = {
  palette: {},
};

interface Props {
  children?: React.ReactNode
}

const ThemeProviderComponent: React.FC<Props> = ({ children }) => {
  const localTheme = LocalStorage.getTheme();
  const [theme, setTheme] = useState(localTheme || THEMES.LIGHT);
  const storedTheme = useSelector(selectCurrentTheme);

  useEffect(() => {
    setTheme(LocalStorage.getTheme() || storedTheme);
  }, [storedTheme]);

  return (
    <ThemeProvider theme={theme === THEMES.LIGHT ? lightTheme : darkTheme}>
      {children}
    </ThemeProvider>
  );
};

export default ThemeProviderComponent;
