/* eslint-disable @typescript-eslint/naming-convention */
import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { ITicket } from '@/api/main-protected';
import convertFromStringToDate, { FormatDate } from '@/utils/convertFromStringToDate';
import { RequestState } from '@/store/reducers/common';

interface ITicketCellComponent {
  id: number;
  isAutoLoaded: boolean;
  ticket: ITicket;
  activeTicket: ITicket | null;
  setActiveTicket: (issue: ITicket | null) => void;
  ticketState: RequestState;
}

const TicketCellComponent: React.FC<ITicketCellComponent> = ({
  id,
  isAutoLoaded = false,
  ticket,
  activeTicket = null,
  setActiveTicket,
  ticketState,
}) => {
  const ticketRef = useRef(null) as React.RefObject<HTMLDivElement>;
  const {
    created_at,
    subject,
  } = ticket;

  const handleTicketClick = () => {
    if (ticketState === RequestState.LOADING) return;
    setActiveTicket(ticket);
  };

  return (
    <Container
      ref={ticketRef}
      isActive={(activeTicket?.id === ticket.id) || (isAutoLoaded && id === 0)}
      isLoading={ticketState === RequestState.LOADING}
      onClick={handleTicketClick}
    >
      <Description>{subject}</Description>
      {/* {ticket.priority && (ticket.priority === 'urgent' || ticket.priority === 'high') ?
          <Priority isUrgent><span>{ticket.priority}</span></Priority> : '' } */}
      <Time>
        {convertFromStringToDate(created_at, FormatDate.ViewAllEventsFormat)}
      </Time>
    </Container>
  );
};

const Container = styled.div<{ isActive: boolean, isLoading: boolean }>`
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  padding: 20px 10px;
  ${({ isActive }) => (isActive ? css`
    border: 1px solid #0066FF;
  ` : css`
      border-bottom: 1px solid rgba(182, 182, 182, 0.5);
      border-top: 1px solid #FFF;
      border-left: 1px solid #FFF;
  `)}
  background: #ffffff;
  cursor: ${({ isLoading }) => (isLoading ? 'wait' : 'pointer')};
`;

const Description = styled.p`
  flex-grow: 1;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

`;

const Priority = styled.div<{ isUrgent: boolean }>`
  ${({ isUrgent }) => (isUrgent ? 'background-color: #FF1A1A' : 'background-color: #868484;')};
  border-radius: 4px;
  width: 79px;
  height: 25px;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #FFFFFF;
  margin: 0 auto 0 10px;
`;

const Time = styled.div`
  flex: 0 0 90px;
  width: 90px;
  padding: 0 5px 0 10px;
  font-size: 14px;
  line-height: 21px;
  color: #4A4949;
`;

export default TicketCellComponent;
