import React, { useEffect, useState } from 'react';
import { CircleMarker, TileLayer, MapContainer } from 'react-leaflet';
import styled from 'styled-components';
import 'leaflet/dist/leaflet.css';

import useWindowSize from '@/Components/hooks/useWindowSize';

import { IIncident } from '@/api/main-protected';

import bluePinIcon from '@/assets/images/icons/pin-blue.svg';

import { IncidentAction } from '@/Components/common/Event';
import { mainBlockHeight } from '@/Components/ControlCenter/ControlCenter';

import IconComponent from '@/Components/common/IconComponent';
import LoaderDots from '@/Components/common/LoaderDots';
import MapMemoComponent from '@/Components/common/MapMemoComponent';
import MapNullComponent from '@/Components/common/MapNullComponent';

interface IMap {
  incidents?: IIncident[];
  isNoPinsBox?: boolean;
  isChart?: boolean;
  isZoom: boolean;
  activeIncident: IIncident | null;
}

// const map = L.map('map');

// const pinBlue = L.icon({
//   iconUrl: bluePinIcon,
//   iconSize: [10, 12],
//   iconAnchor: [3, 4],
//   popupAnchor: [0, 0],
// });

// const pinViolet = L.icon({
//   iconUrl: violetPinIcon,
//   iconSize: [10, 12],
//   iconAnchor: [3, 4],
//   popupAnchor: [0, 0],
// });

export const START_ZOOM = 2; // 1 -10
export const START_ZOOM_1600 = 3; // 1 -10
const ZOOMED = 8;
const MAP_BG = '#d4dadc';
export const DEFAULT_CENTER = { lat: 40.511521, lng: -98.031755 };
export const SCREEN_1600 = 1600;
const MIN_CIRCLE_RADIUS = 5;
const MAX_CIRCLE_RADIUS = 40;

const COLORS = {
  red: '#FF1A1A',
  yellow: '#FFBF1A',
  turquoise: '#15bbc6',
};

const MapLeafletContainer: React.FC<IMap> = ({
  incidents,
  isNoPinsBox = false,
  isChart = false,
  isZoom,
  activeIncident,
}) => {
  const [lastCenter, setLastCenter] = useState<[number, number]>([
    DEFAULT_CENTER.lat,
    DEFAULT_CENTER.lng]);
  const { width } = useWindowSize();
  const mapZoom = width > SCREEN_1600 ? START_ZOOM_1600 : START_ZOOM;

  useEffect(() => {
    const lats = incidents && incidents?.length > 0
      ? incidents?.map((incident) => incident.latitude)
      : null;
    const longs = incidents && incidents?.length > 0
      ? incidents?.map((incident) => incident.longitude)
      : null;

    const minLat = lats ? Math.min(...lats) : null;
    const minLong = longs && Math.min(...longs);
    const maxLat = lats && Math.max(...lats);
    const maxLong = longs && Math.max(...longs);

    const centerLat = minLat && maxLat ? (minLat + maxLat) / 2 : DEFAULT_CENTER.lat;
    const centerLong = minLong && maxLong ? (minLong + maxLong) / 2 : DEFAULT_CENTER.lng;

    setLastCenter([centerLat, centerLong]);
  }, []);

  const setRadius = (raduis: number) => {
    if (raduis > MAX_CIRCLE_RADIUS) {
      return MAX_CIRCLE_RADIUS;
    }

    if (raduis < MIN_CIRCLE_RADIUS) {
      return MIN_CIRCLE_RADIUS;
    }

    return raduis;
  };

  const setCircleRadius = (items: IIncident[], item: IIncident): number => {
    const currentCircle = (20 * items.filter(
      (it) => it.country === item.country,
    ).length) / items.length;

    return setRadius(currentCircle);
  };

  const setColor = (incidentAction: string) => {
    switch (incidentAction) {
      case IncidentAction.warning:
        return COLORS.red;

      case IncidentAction.exception:
        return COLORS.yellow;

      default:
        return COLORS.turquoise;
    }
  };

  return (
    <Container isChart={isChart}>
      {width === 0 ? (
        <LoaderDots />
      ) : (
        <MapContainer
          id="map"
          center={[lastCenter[0], lastCenter[1]]}
          zoom={isZoom && incidents && incidents?.length > 0 ? ZOOMED : mapZoom}
          minZoom={2}
          zoomControl={false}
          attributionControl={false}
          scrollWheelZoom
          style={{
            background: MAP_BG,
          }}
        >
          {/* <TileLayer url='http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' /> */}
          {/* <TileLayer url='https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}.png' /> */}
          {/* <TileLayer url='https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png' /> */}
          <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />
          {lastCenter && incidents && incidents.length > 0 ? (
            <MapMemoComponent
              incidents={incidents}
              isZoom={isZoom}
            />
          ) : null}
          {lastCenter && (!incidents || incidents.length === 0) && (
            <MapNullComponent center={[lastCenter[0], lastCenter[1]]} zoom={mapZoom * 2} />
          )}
          {incidents
            && incidents.length > 0
            && incidents.map((inc) => (
              <CircleMarker
                key={inc.incident_id}
                center={[inc.latitude, inc.longitude]}
                radius={
                  activeIncident?.incident_id === inc.incident_id
                    ? MAX_CIRCLE_RADIUS * 1.1
                    : setCircleRadius(incidents, inc)
                }
                fillOpacity={0.4}
                fillColor={setColor(inc.event.action)}
                color={setColor(inc.event.action)}
                weight={1}
              >
                {/* <Tooltip
                className='tooltip'
                direction='top'
                offset={[0, -15 * Math.log(city['population'] / 10000000)]}
                opacity={1}
                interactive={true}
              > */}
                {/* <span>{city['name'] + ': ' + 'Population' + ' ' + city['population']}</span> */}
                {/* <MapTooltip /> */}
                {/* </Tooltip> */}
              </CircleMarker>
            ))}
          {/* <Marker position={[centerLat + 10, centerLong + 10]} icon={pinBlue}></Marker> */}
          {/* <Marker position={[centerLat + 20, centerLong + 15]} icon={pinViolet}></Marker> */}
        </MapContainer>
      )}

      {!isNoPinsBox && (
        <Pins>
          <Pin>
            <Icon>
              <IconComponent icon={bluePinIcon} alt="Annex Locations" />
            </Icon>
            <Text>Annex Locations</Text>
          </Pin>
          {/* <Pin>
            <Icon>
              <IconComponent icon={violetPinIcon} alt='Warehouse Locations' />
            </Icon>
            <Text>Warehouse Locations</Text>
          </Pin> */}
        </Pins>
      )}
    </Container>
  );
};

const Container = styled.div<{ isChart: boolean }>`
  position: relative;
  flex: 1 0 ${({ isChart }) => (isChart ? '100%' : 'calc(100% - 290px)')};
  width: ${({ isChart }) => (isChart ? '100%' : 'calc(100% - 290px)')};
  padding: 0 0 ${mainBlockHeight};
  border-left: 1px solid rgba(196, 196, 196, 0.6);

  & > div:first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  & .tooltip {
    width: 135px;
    padding: 11px 5px 11px 11px;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  & .leaflet-interactive {
    @media (hover: hover) {
      transition: width 0.3s ease;
      &:hover {
        z-index: 1;
      }
    }
  }
`;

const Pins = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 0 0 17px;

  z-index: 1;
`;
const Pin = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;
const Icon = styled.div`
  margin-right: 2px;
`;
const Text = styled.p`
  font-weight: 500;
  font-size: 9px;
  line-height: 18px;
`;
export default MapLeafletContainer;
