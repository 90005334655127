import React, { FocusEventHandler } from 'react';
import styled from 'styled-components';

interface Props {
  onChange: (value: string) => void,
  onFocus: () => void,
  onBlur?: FocusEventHandler<HTMLInputElement>,
  onIconClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  value: string,
  placeholder?: string,
  icon: React.ReactNode,
  isCapitalize?: boolean,
  inputRef: React.RefObject<HTMLInputElement> | null,
  isForm: boolean,
  isError: boolean
}

const Input: React.FC<Props> = ({
  value,
  onChange,
  placeholder = '',
  icon,
  onFocus,
  onIconClick,
  isCapitalize,
  inputRef,
  onBlur,
  isForm,
  isError,
}) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <InputStyled isForm={isForm} isError={isError || false}>
      <InputComponent
        ref={inputRef}
        value={value}
        onChange={handleOnChange}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        isCapitalize={isCapitalize}
      />
      {icon && (
        <IconWrap onClick={onIconClick}>{icon}</IconWrap>
      )}
    </InputStyled>
  );
};

const IconWrap = styled.div`
  right: 0;
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const InputComponent = styled.input<{ isCapitalize?: boolean }>`
  width: 100%;
  outline: none;
  border: none;
  text-transform: ${({ isCapitalize }) => isCapitalize && 'capitalize'};

  ::placeholder {
    color: #c4c4c4;
  }
`;

const InputStyled = styled.label<{ isForm: boolean, isError: boolean }>`
  border-radius: 4px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: ${({ isForm }) => (isForm ? 0 : 11)}px 10px;
  border: 1px solid ${({ isError }) => (isError ? "#ff0505" : "#c4c4c4")};
  font-size: 14px;
`;

export default Input;
