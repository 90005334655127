import React from 'react';

import ContentContainer from '../common/ContentContainer/ContentContainer';
import UserSettingsMainWrap from './UserSettingsMainWrap';

const UserSettings: React.FC = () => (
  <>
    <ContentContainer title="Settings" />
    <UserSettingsMainWrap />
  </>
);

export default UserSettings;
