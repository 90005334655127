import { createReducerFunction, ImmerReducer } from 'immer-reducer';

import { RequestState } from '@/store/reducers/common';

export interface InitState {
  isLoggedIn: boolean,
  isLoading: boolean,
  isLocalLoading: boolean,
  isImpactedUserLoading: boolean,
  loginState: RequestState,
  isDocuSigned: boolean,
}

const initialState: InitState = {
  isLoggedIn: false,
  isLoading: true,
  isLocalLoading: false,
  isImpactedUserLoading: false,
  loginState: RequestState.IDLE,
  isDocuSigned: true, // @TODO HOTFIX due to a docusign error, move back to false when fixed  - 20221207
};

export class LoginReducer extends ImmerReducer<InitState> {
  setIsLoggedIn(isLoggedIn: boolean) {
    this.draftState.isLoggedIn = isLoggedIn;
  }

  setLoginState(loginState: RequestState) {
    this.draftState.loginState = loginState;
  }

  setIsLoading(isLoading: boolean) {
    this.draftState.isLoading = isLoading;
  }

  setIsLocalLoading(isLocalLoading: boolean) {
    this.draftState.isLocalLoading = isLocalLoading;
  }

  setIsImpactedUserLoading(isImpactedUserLoading: boolean) {
    this.draftState.isImpactedUserLoading = isImpactedUserLoading;
  }

  setDocuSignedId(isDocuSigned: boolean) {
    this.draftState.isDocuSigned = isDocuSigned;
  }
}

export default createReducerFunction(LoginReducer, initialState);
