import { createReducerFunction, ImmerReducer } from 'immer-reducer';

import { ILocation } from '@/api/main-protected';

import { IMetrics } from '@/interfaces/metrics';

import { RequestState } from '@/store/reducers/common';

export interface InitState {
  locations: ILocation[] | null,
  filterApliedItemsAmount: number,
  metrics: IMetrics[],
  metricsState: RequestState,
}

const initialState: InitState = {
  locations: null,
  filterApliedItemsAmount: 0,
  metrics: [],
  metricsState: RequestState.IDLE,
};

export class UtilsReducer extends ImmerReducer<InitState> {
  setMetricsState(metricsState: RequestState) {
    this.draftState.metricsState = metricsState;
  }

  setMetrics(metrics: IMetrics[]) {
    this.draftState.metrics = metrics;
  }

  setLocations(locations: ILocation[]) {
    this.draftState.locations = locations;
  }

  setFilterApliedItemsAmount(filterApliedItemsAmount: number) {
    this.draftState.filterApliedItemsAmount = filterApliedItemsAmount;
  }
}

export default createReducerFunction(UtilsReducer, initialState);
