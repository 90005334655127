import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';

import { DefaultFilter } from '@/Components/Requests/Requests';
import DropDown from '@/Components/common/DropDown';
import Btn from '@/Components/common/Signin/Btn';
import IconComponent from '@/Components/common/IconComponent';
import ViewAllComponent from '@/Components/common/ViewAll';
import FilterToggleComponent from '@/Components/common/FilterToggle';
import TabLabels from '@/Components/Requests/TabsLabels';
import ExportCSVComponent from '@/Components/common/ExportCSV/ExportCSV';

import { AppDispatch } from '@/App';

import { PAGES } from '@/Components/Main/Main';

import { eventsActions } from '@/store/actions/events';

import LocalStorage, { Filter } from '@/local-storage/LocalStorage';

import addUserIcon from '@/assets/images/icons/edit-user/edit-user-icon.svg';
import myProfileIcon from '@/assets/images/icons/user-settings/profile-icon.svg';
import infoIcon from '@/assets/images/icons/info-icon.svg';

interface IVariant {
  id?: number
  title: string
  icon?: string
  storage?: number
}

interface ITitleBlock {
  title: string
  updated: string
  total: number
  totalItems: string
  amount: number
  isSubContainer: boolean
  isViewAll: boolean
  isFiltersShow: boolean
  isInfoBtn: boolean
  isHelpCenter: boolean
  onFilterIconClick: () => void
  isTabs?: boolean
  tabs?: string[]
  activeTab?: string
  setActiveTab?: (tabName: string) => void
  appliedFiltersAmount: number
  isExportCSV: boolean
  isIntervalFilter: boolean
}

const VARIANTS: IVariant[] = [
  { id: 1, title: '1 day', storage: Filter.DAY_1 },
  { id: 2, title: 'Last 7 days', storage: Filter.LAST_7_DAYS },
  { id: 3, title: 'Last 2 weeks', storage: Filter.LAST_2_WEEK },
  { id: 4, title: 'Last month', storage: Filter.LAST_MONTH },
  { id: 5, title: 'Last quarter', storage: Filter.LAST_QUARTER },
  { id: 6, title: 'All time', storage: Filter.ALL },
];

const TitleBlock: React.FC<ITitleBlock> = ({
  title,
  updated,
  total,
  totalItems,
  amount,
  isSubContainer,
  isViewAll,
  isFiltersShow,
  isInfoBtn,
  isHelpCenter,
  onFilterIconClick,
  isTabs,
  tabs,
  activeTab,
  setActiveTab,
  appliedFiltersAmount,
  isExportCSV,
  isIntervalFilter,
}) => {
  const filters = LocalStorage.getFilter();
  const currentFilter = filters ? +filters : null;
  const curVariant = VARIANTS.find((variant) => variant.storage === currentFilter);

  const [variant, setVariant] = useState<IVariant | null>(curVariant || VARIANTS[0]);
  const { pathname } = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const isAddUserButton = pathname === PAGES.USER_ADMIN;
  const isMyProfileButton = false; // pathname === PAGES.USER_SETTINGS // disable temp
  const isItems = pathname === PAGES.ITEMS;

  const isJustTitleText = pathname === PAGES.ADD_NEW_USER
    || pathname === PAGES.PERSONA_MANAGER
    || pathname === PAGES.CHANGELOG
    || pathname === PAGES.USER_SETTINGS; // when isMyProfileButton = false

  const onChoose: (varItem: IVariant | null) => void = (e) => {
    setVariant(e);
  };

  const handleLast = (last: number) => {
    dispatch(eventsActions.setRequestsLast(last));
    dispatch(eventsActions.setRequestsPage(DefaultFilter.page));
  };

  return (
    <TitleWrap isSubContainer={isSubContainer}>
      <Title isSubContainer={isSubContainer}>{title}</Title>
      {isTabs && <TabLabels tabs={tabs!} activeTab={activeTab!} setActiveTab={setActiveTab!} />}

      {!isJustTitleText && (
        <>
          {isInfoBtn && (
            <InfoBtn>
              <IconComponent icon={infoIcon} />
            </InfoBtn>
          )}
          {!!amount && <Amount>{amount}</Amount>}
          {isViewAll && <ViewAllComponent />}
          <InfoBlock>
            {!!updated && <Updated>{updated}</Updated>}
            {totalItems && <TotalItems>{`Total: ${totalItems}`}</TotalItems>}
            {!isSubContainer && (isAddUserButton || isMyProfileButton) && (
              <BtnWrap isMyProfileButton={isMyProfileButton}>
                <Link to={isAddUserButton ? PAGES.ADD_NEW_USER : '/'}>
                  <Btn isLogInBtn isTransparent={isMyProfileButton} fullWidth>
                    <IconComponent icon={isAddUserButton ? addUserIcon : myProfileIcon} />
                    <AditUserBntText>{isAddUserButton ? 'Add User' : 'My Profile'}</AditUserBntText>
                  </Btn>
                </Link>
              </BtnWrap>
            )}
            {isIntervalFilter && (
            <Options>
              <DropDown
                variants={VARIANTS}
                chosenOption={variant}
                onChoose={onChoose}
                isSub={isSubContainer}
                isSemiBold={!isSubContainer}
                minHeight={35}
                dispatch={handleLast}
              />
            </Options>
            )}
            {isSubContainer && (
              <FilterToggleComponent
                appliedFiltersAmount={appliedFiltersAmount}
                onFilterIconClick={onFilterIconClick}
                isFilterActive={isFiltersShow}
                isActiveFilter={false}
              />

            )}
            {isExportCSV && (
              (
              <ExportCSVComponent />
              )
            )}
          </InfoBlock>
        </>
      )}
      {isHelpCenter && (
        <HelpCenterBtnWrap>
          <Btn width={105} isTransparent>
            Help Center
          </Btn>
        </HelpCenterBtnWrap>
      )}
    </TitleWrap>
  );
};

const TitleWrap = styled.div<{ isSubContainer: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ isSubContainer }) => (isSubContainer ? 'center' : 'flex-end')};
  padding: 25px 0 15px;

  min-height: 100px;
`;
const Title = styled.h2<{ isSubContainer: boolean }>`
  ${({ isSubContainer }) => isSubContainer
    && css`
      // font-family: 'Jost';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
    `}
  ${({ isSubContainer }) => !isSubContainer
    && css`
      font-weight: 600;
      font-size: 24px;
    `}
`;
const InfoBlock = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const BtnWrap = styled.div<{ isMyProfileButton?: boolean }>`
  width: 120px;
  margin-left: 25px;

  ${({ isMyProfileButton }) => isMyProfileButton
    && css`
      & button {
        color: #0066ff;
        background: #ffffff;
        border: 1px solid #0066ff;
        border-radius: 3px;
      }
    `}
`;

const AditUserBntText = styled.div`
  margin-left: 5px;
`;

const Updated = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  color: #1e1e1e;
  margin-left: 18px;
`;

const Total = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 5px 25px 3px 18px;
`;

const TotalItems = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  margin: 5px 25px 3px 18px;
`;

const Options = styled.div`
  width: 125px;
  margin-left: 18px;
`;

const InfoBtn = styled.div`
  flex: 0 0 14px;
  width: 14px;
  height: 14px;
  background: #ffffff;
  border-radius: 50%;
  margin-left: 12px;
  margin-bottom: 5px;
  cursor: pointer;
`;
const Amount = styled.div`
  flex: 0 0 21px;
  width: 21px;
  height: 21px;
  background: #ff1a1a;
  border-radius: 50%;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: -0.04em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;

  // margin: auto 7px 3px;
  margin-left: 8px;
`;

const HelpCenterBtnWrap = styled.div`
  & button {
    height: 38px;
    border: 1px solid transparent;
    color: #0066ff;
    font-weight: 500;
    font-size: 16px;

    @media (hover: hover) {
      transition: all 0.3s ease;
      &:hover {
        border: 1px solid #0066ff;
      }
    }
  }
`;

export default TitleBlock;
