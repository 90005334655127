import convertFromStringToDate, { FormatDate } from '@/utils/convertFromStringToDate';

const todayDate = new Date();

export const today = convertFromStringToDate(todayDate.toDateString(), FormatDate.ShortMonthFormat);
export const yesterday = convertFromStringToDate(
  new Date(todayDate.setDate(todayDate.getDate() - 1)).toDateString(),
  FormatDate.ShortMonthFormat,
);

const formatTitleDate = (date: string) => {
  switch (date) {
    case today:
      return 'Today';
    case yesterday:
      return 'Yesterday';
    default:
      return convertFromStringToDate(date, FormatDate.ShortMonthFormat);
  }
};

export default formatTitleDate;
