import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '@/App';
import TableContainer, { ITableTabs } from '@/Components/Items/ItemsTable/TableContainer';

import { getPeripherals, getPeripheralsTotal } from '@/store/actions/items';
import { RequestOptions, RequestState } from '@/store/reducers/common';
import {
  selectPeripherals,
  selectPeripheralsPaginationState,
  selectPeripheralsState,
  selectPeripheralsTotal,
  selectPeripheralsTotalState,
} from '@/store/selectors/items';

import { peripheralsPerPage } from '@/utils/api';
import { SortOrder } from '@/utils/sortUtil';
import { ITableBody } from '@/interfaces/api';

interface Props<T> extends ITableTabs<T> {
}

const PeripheralsContainer = <T extends string>({
  interval,
  selectedTab,
  setSelectedTab,
  tabs,
  title,
}: Props<T>) => {
  const dispatch = useDispatch<AppDispatch>();

  const peripherals = useSelector(selectPeripherals);
  const peripheralsTotal = useSelector(selectPeripheralsTotal);
  const peripheralsState = useSelector(selectPeripheralsState);
  const peripheralsPaginationState = useSelector(selectPeripheralsPaginationState);
  const peripheralsTotalState = useSelector(selectPeripheralsTotalState);

  const getItems = (body: ITableBody, option?: RequestOptions) => {
    dispatch(getPeripherals({ ...body, ...{ interval } }, option));
  };

  const getItemsTotal = (body: ITableBody) => {
    dispatch(getPeripheralsTotal({ ...body, ...{ interval } }));
  };

  const getItemsFilter = () => {};

  return (
    <TableContainer
      defaultSort={{ sortBy: 'type', direction: SortOrder.ASCENDING }}
      title={title}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      getItems={getItems}
      getItemsTotal={getItemsTotal}
      getItemsFilter={getItemsFilter}
      interval={interval}
      multiItemsFilters={{}}
      multiItemsFiltersLabel={{}}
      filtersLabel={{}}
      itemsFilters={{}}
      dateFilters={{}}
      items={peripherals}
      initialItemsPerPage={peripheralsPerPage}
      itemsTotal={peripheralsTotal}
      isItemsLoading={peripheralsState !== RequestState.LOADED}
      isItemsTotalLoading={peripheralsTotalState !== RequestState.LOADED}
      isItemsFilterLoading={false}
      isItemsPaginationLoading={peripheralsPaginationState !== RequestState.LOADED}
    />
  );
};

export default PeripheralsContainer;
