import base64 from 'base-64';

export const assetsPerPage = 20;
export const incidentsPerPage = 20;
export const peripheralsPerPage = 20;
export const requestsPerPage = 20;
export const eventsPerPage = 20;

export const encode = <T>(value: T): string => base64.encode(JSON.stringify(value));

export const getQueries = (data: { [key: string]: string | number }) => Object.entries(data)
  .reduce((acc, [key, value]) => [...acc, `${key}=${value}`], [] as string[])
  .join('&');

export const fakeDelay = async <T>(data: T, delay = 500): Promise<T> => {
  const res = await new Promise<T>((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, delay);
  });

  return res;
};
