export enum FormatDate {
  ShortFormat = 'shortFormat',
  ShortMonthFormat = 'shortMonthFormat',
  GroupFormat = 'groupFormat',
  ViewAllEventsFormat = 'viewAllEventsFormat',
}

const convertFromStringToDate = (
  responseDate: string | null,
  format?: string,
) => {
  if (responseDate === null) return 'N/A';

  const date = new Date(responseDate);

  switch (format) {
    case FormatDate.ShortMonthFormat:
      return new Intl.DateTimeFormat('en-US', {
        timeZone: 'utc',
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      }).format(date);

    case FormatDate.ShortFormat:
      return new Intl.DateTimeFormat('en-US', {
        timeZone: 'utc',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      }).format(date);

    case FormatDate.GroupFormat:
      return new Intl.DateTimeFormat('en-US', {
        timeZone: 'utc',
        weekday: 'long',
        month: 'short',
        day: '2-digit',
      }).format(date);

    case FormatDate.ViewAllEventsFormat:
      return new Intl.DateTimeFormat('en-US', {
        timeZone: 'utc',
        hour: '2-digit',
        minute: '2-digit',
      }).format(date);

    default:
      return new Intl.DateTimeFormat('en-US', {
        timeZone: 'utc',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
        .format(date)
        .replace(',', ' ');
  }
};

export default convertFromStringToDate;
