import React from 'react';
import styled from 'styled-components';

import CheckBoxOffIcon from '@/Components/icons/CheckBoxOffIcon';
import CheckBoxOnIcon from '@/Components/icons/CheckBoxOnIcon';
import CheckBoxFormOnIcon from '@/Components/icons/CheckBoxFormOnIcon';

interface Props {
  isActive: boolean;
  isForm?: boolean;
  setIsActive?: (isActive: boolean) => void;
}

const CheckBox: React.FC<Props> = ({
  isActive, setIsActive, isForm = false,
}) => {
  const handleSetIsActive = () => {
    if (setIsActive) {
      setIsActive(!isActive);
    }
  };

  return (
    <>
      <CheckBoxWrapper isActive={isActive} onClick={handleSetIsActive}>
        {isForm ? <CheckBoxFormOnIcon /> : <CheckBoxOnIcon />}
      </CheckBoxWrapper>
      <CheckBoxWrapper isActive={!isActive} isForm={isForm} onClick={handleSetIsActive}>
        {isForm ? null : <CheckBoxOffIcon />}
      </CheckBoxWrapper>
    </>
  );
};

const CheckBoxWrapper = styled.div<{ isActive: boolean, isForm?: boolean }>`
  width: 100%;
  height: 100%;
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  background-color: ${({ isForm }) => (isForm ? '#cecece' : 'transparent')};
`;

export default CheckBox;
