import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import ExpandTableLine from '@/Components/Requests/ExpandTableLine';

import { Request, IAsset } from '@/api/main-protected';
import { ISort, SortOrder } from '@/utils/sortUtil';

import arrowIcon from '@/assets/images/icons/sidebar/arrow-down.svg';
import { getIncidentRequest, eventsActions } from '@/store/actions/events';
import { AppDispatch } from '@/App';
import { PAGES } from '@/Components/Main/Main';

interface ITable {
  setSortColumn: (sort: ISort) => void;
  sortColumn: ISort | null;
  requests: Request[];
}

interface IParams {
  id: string;
}

export enum COLUMNS {
  WORK_ORDER_ID = 'work_order_id',
  TYPE = 'type',
  REQUEST_ID = 'request_id',
  DATE_OPENED = 'date_opened',
  STATUS = 'status',
  DATE_CLOSED = 'date_closed',
  CATEGORY = 'category',
  LOCATION = 'location',
  INCIDENT = 'incidents_count',
  PRIORITY = 'priority',
  ACTION_DATE = 'action_date',
  AGENCY = 'agency',
  EMPLOYEE_ID = 'itsm_id',
  IMPACTED_USER_ID = 'impacted_user_id',
}

const RequestsTable: React.FC<ITable> = ({
  requests,
  setSortColumn,
  sortColumn,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const { id } = useParams<IParams>();
  const { pathname } = useLocation();

  const reverseDirection = (direction: SortOrder) => (
    direction === SortOrder.ASCENDING
      ? SortOrder.DESCENDING
      : SortOrder.ASCENDING);

  const handleTopColumnCellClick = (sortBy: keyof Request) => {
    const sort = {
      sortBy,
      direction: sortColumn?.sortBy === sortBy
        ? reverseDirection(sortColumn?.direction)
        : SortOrder.ASCENDING,
    };

    setSortColumn(sort);
  };

  // prev logic for full request
  // const handleRequestClick = (req: Request) => {
  //   dispatch(eventsActions.setIncidentRequest({ ...req, isModal: true }));

  //   if (!req.items) {
  //     dispatch(getIncidentRequest(1, !req.items, { ...req, isModal: true }));
  //   }
  // };
  // prev logic for full request

  const openModal = (req: Request) => {
    if (!req.events) {
      // set given request for loading
      dispatch(eventsActions.setIncidentRequest({ ...req, isModal: true }));
    }

    // fetch full request info
    dispatch(getIncidentRequest(req.event_id, true, { ...req, isModal: true }));
  };

  const handleOpenRequestModal = (req: Request) => {
    openModal(req);

    // add request_id to url
    history.push(`/requests/${req.request_id}`);
  };

  const handleSetItemModal = (assset: IAsset) => {
    dispatch(eventsActions.setModalAsset(assset));
  };

  const handleSetNotesModal = (request: Request) => {
    dispatch(eventsActions.setModalNotes(request));
  };

  /** *********************************************
   open modal on page mount
   * ******************************************** */
  useEffect(() => {
    const request = requests.find((element) => element.request_id.toString() === id);
    if (!request) {
      history.push(PAGES.REQUESTS);
      return;
    }
    openModal(request);
  }, [pathname]);

  return (
    <>
      <Grid>
        <FixedTop />
        <FixedTop
          className="fixed-left"
          onClick={() => handleTopColumnCellClick(COLUMNS.EMPLOYEE_ID)}
          isCursorPointer
        >
          <Text>Request ID</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === COLUMNS.EMPLOYEE_ID
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop>
        <FixedTop isCursorPointer onClick={() => handleTopColumnCellClick(COLUMNS.DATE_OPENED)}>
          <Text>Date Opened</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === COLUMNS.DATE_OPENED
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop>
        <FixedTop isCursorPointer onClick={() => handleTopColumnCellClick(COLUMNS.STATUS)}>
          <Text>Status</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === COLUMNS.STATUS
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop>
        <FixedTop isCursorPointer onClick={() => handleTopColumnCellClick(COLUMNS.AGENCY)}>
          <Text>Department</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === COLUMNS.AGENCY
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop>
        <FixedTop isCursorPointer onClick={() => handleTopColumnCellClick(COLUMNS.ACTION_DATE)}>
          <Text>Action Date</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === COLUMNS.ACTION_DATE
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop>
        <FixedTop
          isCursorPointer
          onClick={() => handleTopColumnCellClick(COLUMNS.IMPACTED_USER_ID)}
        >
          <Text>{'Employee ID/ \n Master Work ID'}</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === COLUMNS.IMPACTED_USER_ID
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop>
        {/* <FixedTop isCursorPointer onClick={() => handleTopColumnCellClick(COLUMNS.PRIORITY)}>
          <Text>Priority</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === COLUMNS.PRIORITY
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop> */}
        <FixedTop isCursorPointer onClick={() => handleTopColumnCellClick(COLUMNS.DATE_CLOSED)}>
          <Text>Date Closed</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === COLUMNS.DATE_CLOSED
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop>
        <FixedTop isCursorPointer onClick={() => handleTopColumnCellClick(COLUMNS.CATEGORY)}>
          <Text>Category</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === COLUMNS.CATEGORY
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop>
        <FixedTop onClick={() => handleTopColumnCellClick(COLUMNS.LOCATION)} isCursorPointer>
          <Text>Location</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === COLUMNS.LOCATION
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop>
        <FixedTop onClick={() => handleTopColumnCellClick(COLUMNS.INCIDENT)} isCursorPointer>
          <Text>Exceptions (#)</Text>
          <Arrow>
            <ArrowImg
              isAccending={
                sortColumn?.sortBy === COLUMNS.INCIDENT
                && sortColumn?.direction === SortOrder.ASCENDING
              }
            >
              <img src={arrowIcon} alt="icon" />
            </ArrowImg>
          </Arrow>
        </FixedTop>

        {requests.length > 0
          && requests.map((req, i) => (
            <Fragment key={`${req.itsm_id}${i}req-table`}>
              <GridCell isRelative>
                <ExpandTableLine
                  req={req}
                  setItemModal={handleSetItemModal}
                  setNotesModal={handleSetNotesModal}
                />
              </GridCell>
              <GridCell
                isBlue
                className="fixed-left"
                onClick={() => handleOpenRequestModal(req)}
                isPointer
              >
                {req.itsm_id}
              </GridCell>
              <GridCell>{req.date_opened || 'N/A'}</GridCell>
              <GridCell>{req.status.replace(/[_-]/g, ' ') || 'N/A'}</GridCell>
              <GridCell>{req.agency || 'N/A'}</GridCell>
              <GridCell>{req.action_date || 'N/A'}</GridCell>
              <GridCell>{req.impacted_user_id || 'N/A'}</GridCell>
              {/* <GridCell>{req.priority || 'N/A'}</GridCell> */}
              <GridCell>{req.date_closed || 'N/A'}</GridCell>
              <GridCell>{ req.category && req.category.replace(/[_-]/g, ' ') || 'N/A'}</GridCell>
              <GridCell>{req.location || 'N/A'}</GridCell>
              <GridCell>{req.incidents_count || 'N/A'}</GridCell>
            </Fragment>
          ))}
      </Grid>
      {requests.length === 0 && (
        <EmptyList>No requests currently available for the selected time frame.</EmptyList>
      )}
    </>
  );
};

const Grid = styled.div`
  min-width: 1550px;
  // width: 100%; // adds unnecessary scroll
  // height: 100%; // adds hiding effect
  position: relative;

  display: grid;
  grid-template-columns:
    minmax(50px, 50px)
    minmax(150px, auto)
    minmax(150px, auto)
    minmax(150px, auto)
    minmax(150px, auto)
    minmax(150px, auto)
    minmax(150px, auto)
    /* minmax(100px, auto) */
    minmax(150px, auto)
    minmax(150px, auto)
    minmax(150px, auto)
    minmax(150px, auto);

  grid-auto-rows: min-content;

  font-weight: 400;
  font-size: 12px;
  color: #000000;
`;
const FixedTop = styled.div<{ isCursorPointer?: boolean }>`
  position: sticky;
  top: 0;
  z-index: 3;

  // height: 50px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: 800;
  font-size: 14px;
  padding: 8px 0px 8px 10px;

  background: #d9d9d9;

  cursor: ${({ isCursorPointer }) => (isCursorPointer ? 'pointer' : 'auto')};

  &.fixed-left {
    position: sticky;
    left: 0;
    z-index: 4;

    padding-left: 15px;
  }
`;

const GridCell = styled.div<{ isBlue?: boolean; isRelative?: boolean; isPointer?: boolean }>`
  // padding: 12px 10px 4px 15px; // with container
  padding: 8px 10px;
  background-color: white;
  color: ${({ isBlue }) => (isBlue ? '#0066FF' : '#000000')};
  position: ${({ isRelative }) => (isRelative ? 'relative' : 'static')};
  text-transform: capitalize;
  line-height: 17px;
  word-break: break-word;

  &.fixed-left {
    position: sticky;
    left: 0;
    z-index: 1;

    padding-left: 15px;

    cursor: ${({ isPointer }) => (isPointer ? 'pointer' : 'default')};

    @media (hover: hover) {
      &:hover {
        text-decoration: ${({ isPointer }) => (isPointer ? 'underline' : 'none')};
      }
    }
  }

  &:nth-child(22n + 1),
  &:nth-child(22n + 2),
  &:nth-child(22n + 3),
  &:nth-child(22n + 4),
  &:nth-child(22n + 5),
  &:nth-child(22n + 6),
  &:nth-child(22n + 7),
  &:nth-child(22n + 8),
  &:nth-child(22n + 9),
  &:nth-child(22n + 10),
  &:nth-child(22n + 11) {
    background: #f6f6f6;
  }
`;

const Text = styled.div`
  text-transform: capitalize;
  margin-right: 10px;
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  min-width: 10px;
  height: 20px;
`;
const ArrowImg = styled.div<{ isAccending: boolean }>`
  display: flex;
  align-items: center;
  transform: rotate(${({ isAccending }) => (isAccending ? 180 : 0)}deg);
  transition: all 0.3s ease;

  & img {
    max-width: 100%;
  }
`;

const EmptyList = styled.div`
  min-height: 125px;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 34px;
  color: #868484;
  padding: 35px 50px 25px 35px;
  margin: 0 auto;
`;

export default RequestsTable;
