import { createActionCreators } from 'immer-reducer';

import { AsyncAction } from '@/store/actions/common';

import { UtilsReducer } from '@/store/reducers/utils';
import { RequestState } from '@/store/reducers/common';

export const utilsActions = createActionCreators(UtilsReducer);

export type UtilsActionsType =
  | ReturnType<typeof utilsActions.setMetrics>
  | ReturnType<typeof utilsActions.setMetricsState>
  | ReturnType<typeof utilsActions.setLocations>
  | ReturnType<typeof utilsActions.setFilterApliedItemsAmount>;

export const getLocations = (): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    const { locations } = await mainProtectedApi.locations();

    const locationVariants = locations.map((loc) => {
      loc.title = loc.country_name;
      return loc;
    });

    dispatch(utilsActions.setLocations(locationVariants));
  } catch (err) {
    console.error(err);
  }
};

export const getMetrics = (interval: number): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(utilsActions.setMetricsState(RequestState.LOADING));

    const { xlas } = await mainProtectedApi.getMetrics(interval);

    dispatch(utilsActions.setMetrics(xlas));

    dispatch(utilsActions.setMetricsState(RequestState.LOADED));
  } catch (err) {
    dispatch(utilsActions.setMetricsState(RequestState.ERROR));
  }
};
