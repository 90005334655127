import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { AppDispatch } from '@/App';
import { exportCSVAssets } from '@/store/actions/events';
import { selectExportCSVRequestsState } from '@/store/selectors/events';
import { RequestState } from '@/store/reducers/common';

import arrowIcon from '@/assets/images/icons/sidebar/arrow-down.svg';
import ShareIcon from '@/Components/icons/ShareIcon';
import LoaderDots from '@/Components/LoaderDots';

const ExportCSVAssetsComponent = ({ filteredData }: any) => {
  const [isClicked, setIsClicked] = useState(false);
  const loadingRequests = useSelector(selectExportCSVRequestsState);

  const dispatch = useDispatch<AppDispatch>();

  const handleExport = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsClicked(true);
    dispatch(exportCSVAssets({ ...filteredData }));

    setIsClicked(false);
  };

  return (
    <Inner>
      <ExportBtn onClick={handleExport} disabled={loadingRequests === RequestState.LOADING}>
        <Icon>
          <ShareIcon />
          {/* <Image src={isActive ? filterIconBlue : filterIcon} alt="icon" /> */}
        </Icon>
        <Text>{loadingRequests === RequestState.LOADING ? <LoaderDots size={6} /> : 'Export'}</Text>
      </ExportBtn>
    </Inner>
  );
};

const Image = styled.img``;

const Inner = styled.div`
  height: 35px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  margin-left: 16px;
  transition: all 0.3s ease;
  position: relative;
  /* cursor: pointer; */
`;

const ExportBtn = styled.button`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 11px;
  background: #ffffff;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  width: 18px;
  height: 18px;
  margin: 0px 5px 0px 0px;

  & img {
    max-width: 100%;
  }
`;

const Text = styled.div`
  flex: 1 1 auto;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color: #4a4949;
  font-size: 14px;
  text-transform: capitalize;
  padding-right: 10px;
  cursor: pointer;
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  min-width: 10px;
  height: 20px;
`;

const ArrowImg = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  transform: rotate(${({ isOpen }) => (isOpen ? 180 : 0)}deg);

  & img {
    max-width: 100%;
  }
`;

export default ExportCSVAssetsComponent;
