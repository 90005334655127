import React, { Fragment } from 'react';
import styled from 'styled-components';

import { IEvent } from '@/api/main-protected';

import customScrollCss from '@/utils/custom-scroll-css';
import convertFromStringToDate from '@/utils/convertFromStringToDate';

interface ITable {
  events: IEvent[];
  handleEventClick: (asset: IEvent) => void;
}

const SearchedEventsTable: React.FC<ITable> = ({
  events,
  handleEventClick,
}) => (
  <Container>
    <Grid>
      <FixedTop
        className="fixed-left"
      >
        <Text>Event ID</Text>
      </FixedTop>
      <FixedTop>
        <Text>Date</Text>
      </FixedTop>
      <FixedTop>
        <Text>Status</Text>
      </FixedTop>
      <FixedTop>
        <Text>Category</Text>
      </FixedTop>
      <FixedTop>
        <Text>Priority</Text>
      </FixedTop>
      <FixedTop>
        <Text>Impacted user</Text>
      </FixedTop>

      {events.map((item) => (
        <Fragment key={item.event_id}>
          <GridCell
            className="fixed-left"
            isBlue
            isPointer
            onClick={() => handleEventClick(item)}
          >
            {item.event_id}
          </GridCell>
          <GridCell>{item.event_date ? convertFromStringToDate(item.event_date) : '-'}</GridCell>
          <GridCell>{item.status || '-'}</GridCell>
          <GridCell>{item.request?.category || '-'}</GridCell>
          <GridCell>{item.priority || '-'}</GridCell>
          <GridCell>
            {`${item.request.impacted_user?.first_name || ''} ${item.request.impacted_user?.last_name || ''}`}
          </GridCell>
        </Fragment>
      ))}
    </Grid>
  </Container>

);

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: 40px;

  ${customScrollCss}
`;

const Grid = styled.div`
  min-width: 900px;
  // width: 100%; // adds unnecessary scroll
  // height: 100%; // adds hiding effect
  position: relative;

  display: grid;
  grid-template-columns:
    minmax(100px, auto)
    minmax(150px, auto)
    minmax(150px, auto)
    minmax(150px, auto)
    minmax(150px, auto)
    minmax(200px, auto);

  grid-auto-rows: min-content;

  font-weight: 400;
  font-size: 12px;
  color: #000000;
`;
const FixedTop = styled.div<{ isCursorPointer?: boolean }>`
  position: sticky;
  top: 0;
  z-index: 3;

  // height: 50px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-weight: 800;
  font-size: 14px;
  padding: 8px 0px 8px 10px;

  background: #d9d9d9;

  &.fixed-left {
    position: sticky;
    left: 0;
    z-index: 4;

    padding-left: 15px;
  }
`;

const GridCell = styled.div<{ isBlue?: boolean; isRelative?: boolean; isPointer?: boolean }>`
  // padding: 12px 10px 4px 15px; // with container
  padding: 8px 10px;
  background-color: white;
  color: ${({ isBlue }) => (isBlue ? '#0066FF' : '#000000')};
  position: ${({ isRelative }) => (isRelative ? 'relative' : 'static')};
  text-transform: capitalize;
  line-height: 17px;
  word-break: break-word;

  &.fixed-left {
    position: sticky;
    left: 0;
    z-index: 1;

    padding-left: 15px;

    cursor: ${({ isPointer }) => (isPointer ? 'pointer' : 'default')};

    @media (hover: hover) {
      &:hover {
        text-decoration: ${({ isPointer }) => (isPointer ? 'underline' : 'none')};
      }
    }
  }

  &:nth-child(12n + 1),
  &:nth-child(12n + 2),
  &:nth-child(12n + 3),
  &:nth-child(12n + 4),
  &:nth-child(12n + 5),
  &:nth-child(12n + 6) {
    background: #f6f6f6;
  }
`;

const Text = styled.div`
  text-transform: capitalize;
  margin-right: 10px;
`;

export default SearchedEventsTable;
