import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteProps, Redirect, useHistory } from 'react-router';

import { ROLES } from '@/api/main';

import { PAGES } from '@/Components/Main/Main';
import GlobalLoading from '@/Components/common/GlobalLoading';
import DocuSign from '@/Components/common/DocuSign';
import { AppDispatch } from '@/App';

import { selectIsDocuSigned, selectLoginState } from '@/store/selectors/login';
import { selectUserInfo } from '@/store/selectors/user';
import { getUserInfo } from '@/store/actions/user';
import { RequestState } from '@/store/reducers/common';
import { logout } from '@/store/actions/login';

import TokensLocalStorage from '@/local-storage/TokensLocalStorage';
import LocalStorage from '@/local-storage/LocalStorage';

import getBrowserName, { BrowserNames } from '@/utils/browsers';
import MainProtected from '@/api/main-protected';

interface IProps extends RouteProps {
  roles: ROLES[];
}

const ProtectedRoute: React.FC<IProps> = ({ roles = [], ...rest }) => {
  const mainProtectedApi = MainProtected.getInstance();
  const history = useHistory();

  const user = useSelector(selectUserInfo);
  const isDocuSigned = useSelector(selectIsDocuSigned);
  const loginState = useSelector(selectLoginState);

  const browser = getBrowserName();

  const dispatch = useDispatch<AppDispatch>();

  const [isDocuSignFirefox, setIsDocuSignFirefox] = useState(false);

  const role = user?.roles[0] || ROLES.USER;

  const isLoggedIn = useMemo(
    () => !!user && loginState === RequestState.LOADED,
    [loginState, user],
  );

  useEffect(() => {
    if (loginState === RequestState.ERROR || !TokensLocalStorage.getInstance().getAccessToken()) {
      dispatch(logout());
      history.push(PAGES.LOGIN);
      return;
    }

    if (loginState === RequestState.IDLE) {
      dispatch(getUserInfo());
    }
  }, [loginState]);

  useEffect(() => {
    if (role === 'developer' || role === 'partner') {
      (async () => {
        try {
          const response = await mainProtectedApi.getAuthReadme();
          window.location.replace(response.redirect);
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [role]);

  if (!isLoggedIn) return <GlobalLoading />;

  if (!isDocuSigned && LocalStorage.getDocuSignedId() !== user?.user_id && !isDocuSignFirefox) {
    if (browser === BrowserNames.FIREFOX) {
      setTimeout(() => {
        if (!document.getElementById('ds-clickwrap')?.children.length) {
          LocalStorage.setDocuSignedId(user!.user_id);
          setIsDocuSignFirefox(true);
        }
      }, 10000);
    }
    return <DocuSign userId={user?.user_id!} />;
  }

  if (!roles.includes(role as ROLES)) {
    return <Redirect to={PAGES.CONTROL_CENTER} />;
  }

  return isLoggedIn && roles.includes(role as ROLES) ? (
    <>
      <Route {...rest} />
      {!isDocuSigned && <DocuSign userId={user?.user_id!} isLoading={false} />}
    </>
  ) : (
    <Redirect to={PAGES.LOGIN} />
  );
};

export default ProtectedRoute;
