import React, { useState } from 'react';
import styled from 'styled-components';
import { Auth0Error, ChangePasswordOptions } from 'auth0-js';

import { auth, AUTH0_REALM } from '@/services/auth0.service';
import LocalStorage from '@/local-storage/LocalStorage';
import TokensLocalStorage from '@/local-storage/TokensLocalStorage';

import Input from '@/Components/common/Input';
import Button from '@/Components/common/Signin/Btn';
import IconComponent from '@/Components/common/IconComponent';
import LoaderDots from '@/Components/LoaderDots';
import AuthInfoComponent from '@/Components/common/AuthInfoBlock';

import useInput from '@/Components/hooks/useInput';

import { isValidEmail } from '@/utils/validation';
import customScrollCss from '@/utils/custom-scroll-css';

import loginLogo from '@/assets/images/logo_color_stacked.svg';
import bg from '@/assets/images/bg.jpeg';

interface ClientId extends ChangePasswordOptions {
  client_id: string | undefined;
}

const ResetPswForm: React.FC = () => {
  const [mail, setMail] = useInput(LocalStorage.getEmail() || '');
  const [isMailError, setIsMailError] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const loginClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!isValidEmail(mail)) {
      setIsMailError(true);
      return;
    }

    setIsSending(true);

    auth.changePassword(
      {
        // client_id: AUTH0_CLIENT_ID!, // add to show back to my app btn
        email: mail,
        connection: AUTH0_REALM,
      } as ClientId,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (err: Auth0Error | null, res: any) => {
        if (err) {
          console.log(`Something went wrong: ${err}`);

          setErrorMessage(err.description);
          setIsSending(false);
          return;
        }

        const storage = TokensLocalStorage.getInstance();

        storage.clear();
        LocalStorage.clear();

        setIsSent(true);
        setIsSending(false);
      },
    );
  };

  const handleEmailInputClick = () => {
    if (isMailError) {
      setMail('');
      setIsMailError(false);
    }
  };

  return (
    <PageContainer>
      <BgImage>
        <Img src={bg} />
      </BgImage>
      <SigninContainer>
        <IconComponent icon={loginLogo} />
        <Title>Reset password</Title>

        {!isSent ? (
          <Form>
            <Input
              id="Mail"
              onChange={setMail}
              onClick={handleEmailInputClick}
              value={mail}
              placeholder="username@email.com"
              label="email"
              errorText="Please enter a valid email address"
              isError={isMailError}
            />
            {errorMessage && <Error>{errorMessage}</Error>}
            <Button
              isLogInBtn
              fullWidth
              onClick={loginClickHandler}
              isActive={!isMailError && mail.length > 0}
              isLoading={isSending}
            >
              {isSending ? <LoaderDots /> : 'Reset'}
            </Button>
          </Form>
        ) : (
          <AuthInfoComponent
            info={`We've sent a verification email to ${mail}. Follow the instructions in the email to reset your password.`}
          />
        )}
      </SigninContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  padding: 14.8vmin 3.4vmin 3.2vmin;
  background: #e5e5e5;

  & > div > img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const BgImage = styled.div``;
const Img = styled.img``;

const SigninContainer = styled.div`
  position: relative;
  max-width: 490px;
  width: 100%;
  max-height: calc(100vh - 21vmin);
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 0 auto;
  padding: 6.7vmin 35px;
  z-index: 2;
  overflow-y: auto;

  & > div:first-child {
    max-width: 195px;
    width: 100%;
    margin: 0 auto;
  }

  @media (hover: hover) {
    ${customScrollCss}
  }
`;
const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;
  letter-spacing: -0.02em;
  padding: 22px 10px;
  margin-bottom: 42px;
  border-bottom: 1px solid #c4c4c4;
`;

const Form = styled.form`
  & button {
    text-transform: uppercase;
  }
`;

const Error = styled.p`
  color: #ff0505;
  padding: 20px 0;
`;

export default ResetPswForm;
