import { createReducerFunction, ImmerReducer } from 'immer-reducer';
import {
  IAsset, IAssetsByMake, IItemsFilters, IPeripheral, RequestsChartItem,
} from '@/api/main-protected';
import { RequestState } from '@/store/reducers/common';

export interface InitState {
  returns: RequestsChartItem | null
  assetsByType: RequestsChartItem | null
  assetsByMake: IAssetsByMake | null
  assets: IAsset[]
  globalSearchItems: IAsset[]
  assetsTotal: number
  peripherals: IPeripheral[]
  itemsFilters: IItemsFilters
  modalItem: IAsset | null
  returnsState: RequestState
  assetsByTypeState: RequestState
  assetsByMakeState: RequestState
  itemsFiltersState: RequestState
  assetsState: RequestState
  assetsTotalState: RequestState
  assetsPaginationState: RequestState
  globalSearchItemsState: RequestState
  peripheralsTotalState: RequestState
  peripheralsPaginationState: RequestState
  peripheralsTotal: number
  peripheralsState: RequestState
}

const initialState: InitState = {
  returns: null,
  assetsByType: null,
  assetsByMake: null,
  assets: [],
  globalSearchItems: [],
  assetsTotal: 0,
  peripherals: [],
  itemsFilters: {},
  modalItem: null,
  returnsState: RequestState.IDLE,
  assetsByTypeState: RequestState.IDLE,
  assetsByMakeState: RequestState.IDLE,
  itemsFiltersState: RequestState.IDLE,
  assetsState: RequestState.IDLE,
  assetsTotalState: RequestState.IDLE,
  assetsPaginationState: RequestState.IDLE,
  globalSearchItemsState: RequestState.IDLE,
  peripheralsTotalState: RequestState.IDLE,
  peripheralsPaginationState: RequestState.IDLE,
  peripheralsTotal: 0,
  peripheralsState: RequestState.IDLE,
};

export class ItemsReducer extends ImmerReducer<InitState> {
  setReturns(returns: RequestsChartItem) {
    this.draftState.returns = returns;
  }

  setReturnsState(returnsState: RequestState) {
    this.draftState.returnsState = returnsState;
  }

  setAssetsByType(assetsByType: RequestsChartItem | null) {
    this.draftState.assetsByType = assetsByType;
  }

  setAssetsByTypeState(assetsByTypeState: RequestState) {
    this.draftState.assetsByTypeState = assetsByTypeState;
  }

  setAssetsByMake(assetsByMake: IAssetsByMake) {
    this.draftState.assetsByMake = assetsByMake;
  }

  setAssetsByMakeState(assetsByMakeState: RequestState) {
    this.draftState.assetsByMakeState = assetsByMakeState;
  }

  setAssets(assets: IAsset[]) {
    this.draftState.assets = assets;
  }

  setGlobalSearchItems(globalSearchItems: IAsset[]) {
    this.draftState.globalSearchItems = globalSearchItems;
  }

  setAssetsState(assetsState: RequestState) {
    this.draftState.assetsState = assetsState;
  }

  setAssetsPaginationState(assetsPaginationState: RequestState) {
    this.draftState.assetsPaginationState = assetsPaginationState;
  }

  setAssetsTotal(assetsTotal: number) {
    this.draftState.assetsTotal = assetsTotal;
  }

  setPeripherals(peripherals: IPeripheral[]) {
    this.draftState.peripherals = peripherals;
  }

  setPeripheralsState(peripheralsState: RequestState) {
    this.draftState.peripheralsState = peripheralsState;
  }

  setPeripheralsTotalState(peripheralsTotalState: RequestState) {
    this.draftState.peripheralsTotalState = peripheralsTotalState;
  }

  setPeripheralsTotal(peripheralsTotal: number) {
    this.draftState.peripheralsTotal = peripheralsTotal;
  }

  setPeripheralsPaginationState(peripheralsPaginationState: RequestState) {
    this.draftState.peripheralsPaginationState = peripheralsPaginationState;
  }

  setItemsFilters(itemsFilters: IItemsFilters) {
    this.draftState.itemsFilters = itemsFilters;
  }

  setItemsFiltersState(itemsFiltersState: RequestState) {
    this.draftState.itemsFiltersState = itemsFiltersState;
  }

  setAssetsTotalState(assetsTotalState: RequestState) {
    this.draftState.assetsTotalState = assetsTotalState;
  }

  setGlobalSearchItemsState(globalSearchItemsState: RequestState) {
    this.draftState.globalSearchItemsState = globalSearchItemsState;
  }

  setModalItem(modalItem: IAsset | null) {
    this.draftState.modalItem = modalItem;
  }
}

export default createReducerFunction(ItemsReducer, initialState);
