import { createActionCreators } from 'immer-reducer';

import {
  NoteBody,
  Request, RequestsChartItem, IAsset, OverrideBody,
} from '@/api/main-protected';

import { loginActions } from '@/store/actions/login';
import { RequestOptions, RequestState } from '@/store/reducers/common';
import { AsyncAction } from '@/store/actions/common';
import { EventsReducer } from '@/store/reducers/events';

import convertFromStringToDate, { FormatDate } from '@/utils/convertFromStringToDate';
import createCSVFileName from '@/utils/createCSVFileName';
import { encode } from '@/utils/api';
import { ITableBody } from '@/interfaces/api';
// import { IExportBody } from '@/api/main-protected-file';

export const eventsActions = createActionCreators(EventsReducer);

export type EventsActionsType =
  | ReturnType<typeof eventsActions.setCCIncidents>
  | ReturnType<typeof eventsActions.setRequests>
  | ReturnType<typeof eventsActions.setGlobalSearchRequests>
  | ReturnType<typeof eventsActions.setUrgentRequests>
  | ReturnType<typeof eventsActions.setNeedsVerifyingRequests>
  | ReturnType<typeof eventsActions.setInDeployRequests>
  | ReturnType<typeof eventsActions.setInTransitRequests>
  | ReturnType<typeof eventsActions.setRequestsStatic>
  | ReturnType<typeof eventsActions.setEventsStatic>
  | ReturnType<typeof eventsActions.setIncident>
  | ReturnType<typeof eventsActions.setEvents>
  | ReturnType<typeof eventsActions.setEvent>
  | ReturnType<typeof eventsActions.setTasksByCategory>
  | ReturnType<typeof eventsActions.setTasksByStatus>
  | ReturnType<typeof eventsActions.setTasksByService>
  | ReturnType<typeof eventsActions.setRequestsByActionDateAndStatus>
  | ReturnType<typeof eventsActions.setAdvancedReplacementByItemType>
  | ReturnType<typeof eventsActions.setRequestsLast>
  | ReturnType<typeof eventsActions.setRequestsPage>
  | ReturnType<typeof eventsActions.setRequestsPerPage>
  | ReturnType<typeof eventsActions.setIncidentRequest>
  | ReturnType<typeof eventsActions.setIsIncidentRequestState>
  | ReturnType<typeof eventsActions.setActiveRequestsLabel>
  | ReturnType<typeof eventsActions.setAdvancedReplacementByReasonCode>
  | ReturnType<typeof eventsActions.setActiveRequestsPriority>
  | ReturnType<typeof eventsActions.setCCIncidentsState>
  | ReturnType<typeof eventsActions.setCCIncidentsPaginationState>
  | ReturnType<typeof eventsActions.setUrgentRequestsState>
  | ReturnType<typeof eventsActions.setNeedsVerifyingRequestsState>
  | ReturnType<typeof eventsActions.setInDeployRequestsState>
  | ReturnType<typeof eventsActions.setInTransitRequestsState>
  | ReturnType<typeof eventsActions.setRequestsStaticState>
  | ReturnType<typeof eventsActions.setEventsStaticState>
  | ReturnType<typeof eventsActions.setIncidentState>
  | ReturnType<typeof eventsActions.setEventsState>
  | ReturnType<typeof eventsActions.setEventState>
  | ReturnType<typeof eventsActions.setRequestState>
  | ReturnType<typeof eventsActions.setTasksByCategoryState>
  | ReturnType<typeof eventsActions.setTableEventsItemsState>
  | ReturnType<typeof eventsActions.setTasksByStatusState>
  | ReturnType<typeof eventsActions.setAdvancedReplacementByItemTypeState>
  | ReturnType<typeof eventsActions.setAdvancedReplacementByReasonCodeState>
  | ReturnType<typeof eventsActions.setTasksByServiceState>
  | ReturnType<typeof eventsActions.setNoteState>
  | ReturnType<typeof eventsActions.setAddNoteState>
  | ReturnType<typeof eventsActions.setRequestsByActionDateAndStatusState>
  | ReturnType<typeof eventsActions.setTableRequestItemsState>
  | ReturnType<typeof eventsActions.setIncidentsFilters>
  | ReturnType<typeof eventsActions.setIncidentsFiltersState>
  | ReturnType<typeof eventsActions.setIncidentsTotal>
  | ReturnType<typeof eventsActions.setIncidentsTotalState>
  | ReturnType<typeof eventsActions.setEventsFilters>
  | ReturnType<typeof eventsActions.setEventsFiltersState>
  | ReturnType<typeof eventsActions.setRequestPaginationState>
  | ReturnType<typeof eventsActions.setEventsPaginationState>
  | ReturnType<typeof eventsActions.setExportCSVState>
  | ReturnType<typeof eventsActions.setRequestsFiltersState>
  | ReturnType<typeof eventsActions.setRequestsFilters>
  | ReturnType<typeof eventsActions.setModalAsset>
  | ReturnType<typeof eventsActions.setModalNotes>
  | ReturnType<typeof eventsActions.setItemsState>
  | ReturnType<typeof eventsActions.setCancelRequestState>
  | ReturnType<typeof eventsActions.setOverrideEventState>
  | ReturnType<typeof eventsActions.setGlobalSearchRequestsState>;

export const getCCIncidents = (
  interval: number,
  queries: string = '',
  option?: RequestOptions,
): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    if (!option?.silent) {
      dispatch(eventsActions.setCCIncidentsState(RequestState.LOADING));
    }
    dispatch(eventsActions.setCCIncidentsPaginationState(RequestState.LOADING));

    const incidentsQueries = `?interval=${interval}${queries ? `&${queries}` : ''}`;

    const response = await mainProtectedApi.getCCIncidents(incidentsQueries);

    dispatch(eventsActions.setCCIncidents(response.incidents));

    if (!option?.silent) {
      dispatch(eventsActions.setCCIncidentsState(RequestState.LOADED));
    }
    dispatch(eventsActions.setCCIncidentsPaginationState(RequestState.LOADED));
  } catch (e) {
    if (!option?.silent) {
      dispatch(eventsActions.setCCIncidentsState(RequestState.ERROR));
      dispatch(eventsActions.setCCIncidentsPaginationState(RequestState.ERROR));
    }
  }
};

export const getRequests = (
  body: ITableBody,
  option?: RequestOptions,
): AsyncAction => async (dispatch, _, { mainProtectedApi }) => {
  try {
    if (!option?.silent) {
      dispatch(eventsActions.setRequestState(RequestState.LOADING));
    }
    dispatch(eventsActions.setRequestPaginationState(RequestState.LOADING));

    const response = await (await mainProtectedApi.getRequests(body)).requests.map(
      (res) => ({
        ...res,
        date_opened: convertFromStringToDate(res?.date_opened || null),
        date_closed: convertFromStringToDate(res.date_closed),
        action_date: convertFromStringToDate(res.action_date, FormatDate.ShortFormat),
      }),
    );

    dispatch(eventsActions.setRequests(response));

    if (!option?.silent) {
      dispatch(eventsActions.setRequestState(RequestState.LOADED));
    }
    dispatch(eventsActions.setRequestPaginationState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setRequestState(RequestState.ERROR));
    dispatch(eventsActions.setRequestPaginationState(RequestState.ERROR));
  }
};

export const getIncidentRequest = (
  eventId: number,
  // incident: IIncident,
  // incidents: IIncident[],
  isGetItems = false,
  requestsTableRequest: Request | null = null,
): AsyncAction => async (dispatch, getState, { mainProtectedApi }) => {
  try {
    dispatch(eventsActions.setIsIncidentRequestState(RequestState.LOADING));

    let request: Request;

    // check if request is updated (no events initially)
    if (requestsTableRequest?.events && requestsTableRequest.events.length > 0) {
      request = { ...requestsTableRequest };
    } else {
      const response = await mainProtectedApi.getIncidentRequest(eventId);

      request = { ...response.request, ...requestsTableRequest };

      // set assotiated events to events
      const { EventsReducer: { events } } = getState();

      if (events) {
        const updatedEvents = events.map((ev) => {
          if (
            ev.request_id === request.request_id
            && request.events.length > 0
          ) {
            const newEvent = { ...ev, request };

            return newEvent;
          }
          return ev;
        });

        dispatch(eventsActions.setEvents(updatedEvents));
      }
    }

    const items: IAsset[] = [];

    const serialNumbers = request.serial_numbers.filter((item) => !!item);

    if (isGetItems && serialNumbers.some((num) => !!num)) {
      try {
        const response = await mainProtectedApi.getTableItem(encode(serialNumbers));
        items.push(...response.items);
      } catch (e) {
        console.error(e);
      }
    }

    const dateOpened = requestsTableRequest
      ? request.date_opened
      : convertFromStringToDate(request?.date_opened || null);

    const dateClosed = requestsTableRequest
      ? request.date_closed
      : convertFromStringToDate(request?.date_closed || null);

    const actionDate = requestsTableRequest
      ? request.action_date
      : convertFromStringToDate(request?.action_date || null, FormatDate.ShortFormat);

    const incidentRequest = {
      ...request,
      date_opened: dateOpened,
      date_closed: dateClosed,
      action_date: actionDate,
      items,
    };

    const { EventsReducer: { requests } } = getState();

    // set updated requests to prevent refetching on click the same request on table
    if (requests.length > 0) {
      const updatedRequests = requests.map((req) => {
        if (req.request_id === incidentRequest.request_id) {
          const updatedRequest = {
            ...incidentRequest,
          };
          return updatedRequest;
        }
        return req;
      });

      dispatch(eventsActions.setRequests(updatedRequests));
    }

    dispatch(eventsActions.setIncidentRequest(incidentRequest));

    dispatch(eventsActions.setIsIncidentRequestState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setIsIncidentRequestState(RequestState.ERROR));
  }
};

export const getRequestsStatic = (
  body: ITableBody,
): AsyncAction => async (dispatch, _, { mainProtectedApi }) => {
  try {
    dispatch(eventsActions.setRequestsStaticState(RequestState.LOADING));

    const response = await mainProtectedApi.getRequestsStatic(body);

    dispatch(eventsActions.setRequestsStatic(response.orders_count));
    dispatch(eventsActions.setRequestsStaticState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setRequestsStaticState(RequestState.ERROR));
  }
};

export const getEventsStatic = (body: ITableBody): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(eventsActions.setEventsStaticState(RequestState.LOADING));

    const response = await mainProtectedApi.getEventsStatic(body);

    dispatch(eventsActions.setEventsStatic(response.events));
    dispatch(eventsActions.setEventsStaticState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setEventsStaticState(RequestState.ERROR));
  }
};

export const getIncident = (id: number): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(eventsActions.setIncidentState(RequestState.LOADING));

    const response = await mainProtectedApi.getIncident(id);

    dispatch(eventsActions.setIncident(response.incident));
    dispatch(eventsActions.setIncidentState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setIncidentState(RequestState.ERROR));
  }
};

export const getEvents = (
  body: ITableBody,
  option?: RequestOptions,
): AsyncAction => async (dispatch, _, { mainProtectedApi }) => {
  try {
    if (!option?.silent) {
      dispatch(eventsActions.setEventsState(RequestState.LOADING));
    }
    dispatch(eventsActions.setEventsPaginationState(RequestState.LOADING));

    const { events } = await mainProtectedApi.getEvents(body);

    const updatedEvent = events.map((ev) => {
      ev.event_date = convertFromStringToDate(ev.event_date);
      ev.request_id = ev.request.request_id; // for sorting by locally
      return ev;
    });

    dispatch(eventsActions.setEvents(updatedEvent));

    if (!option?.silent) {
      dispatch(eventsActions.setEventsState(RequestState.LOADED));
    }
    dispatch(eventsActions.setEventsPaginationState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setEventsState(RequestState.ERROR));
    dispatch(eventsActions.setEventsPaginationState(RequestState.ERROR));
  }
};

export const getEvent = (id: number): AsyncAction => async (dispatch, _, { mainProtectedApi }) => {
  try {
    dispatch(eventsActions.setEventState(RequestState.LOADING));
    dispatch(loginActions.setIsLocalLoading(true));

    const response = await mainProtectedApi.getEvent(id);
    const updatedEvent = {
      ...response,
      event_date: convertFromStringToDate(response.event_date),
    };

    dispatch(eventsActions.setEvent(updatedEvent));
    dispatch(eventsActions.setEventState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setEventState(RequestState.ERROR));
    dispatch(eventsActions.setEvent(null));
  } finally {
    dispatch(loginActions.setIsLocalLoading(false));
  }
};

export const getItems = (
  request: Request,
): AsyncAction => async (dispatch, getState, { mainProtectedApi }) => {
  try {
    dispatch(eventsActions.setItemsState(RequestState.LOADING));

    const reqItems: IAsset[] = [];

    const { EventsReducer: { requests } } = getState();

    if (request.serial_numbers.some((num) => !!num)) {
      const { items } = await mainProtectedApi.getTableItem(encode(request.serial_numbers));
      reqItems.push(...items);
    }

    const updatedRequests = requests.map((req) => {
      if (req.request_id === request.request_id) {
        const updatedRequest = {
          ...req,
          items: reqItems,
        };
        return updatedRequest;
      }
      return req;
    });

    dispatch(eventsActions.setRequests(updatedRequests));

    dispatch(eventsActions.setItemsState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setItemsState(RequestState.ERROR));
  }
};

export const getTasksByCategory = (interval: number): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(eventsActions.setTasksByCategoryState(RequestState.LOADING));

    const response = await mainProtectedApi.getTasksByCategory(interval);

    dispatch(eventsActions.setTasksByCategory(response));
    dispatch(eventsActions.setTasksByCategoryState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setTasksByCategoryState(RequestState.ERROR));
  }
};

export const getTasksByStatus = (interval: number): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(eventsActions.setTasksByStatusState(RequestState.LOADING));

    const response = await mainProtectedApi.getTasksByStatus(interval);

    dispatch(eventsActions.setTasksByStatus(response));
    dispatch(eventsActions.setTasksByStatusState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setTasksByStatusState(RequestState.ERROR));
  }
};

export const getTasksByService = (interval: number): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(eventsActions.setTasksByServiceState(RequestState.LOADING));
    const response = await mainProtectedApi.getTasksByService(interval);

    const requestsChartItem = Object.entries(response).reduce((chartItem: any, entry) => {
      if (entry[0] === 'total') {
        chartItem.total = entry[1];
        return chartItem;
      }

      return {
        ...chartItem,
        top_services: chartItem.top_services
          ? [...chartItem.top_services, { name: entry[0], total: entry[1] }]
          : [{ name: entry[0], total: entry[1] }],
      };
    }, {} as RequestsChartItem);

    dispatch(eventsActions.setTasksByService(requestsChartItem));
    dispatch(eventsActions.setTasksByServiceState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setTasksByServiceState(RequestState.ERROR));
  }
};

export const getRequestsByActionDateAndStatus = (interval: number): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(eventsActions.setRequestsByActionDateAndStatusState(RequestState.LOADING));

    const response = await mainProtectedApi.getRequestsByActionDateAndStatus(interval);

    dispatch(eventsActions.setRequestsByActionDateAndStatus(response));
    dispatch(eventsActions.setRequestsByActionDateAndStatusState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setRequestsByActionDateAndStatusState(RequestState.ERROR));
  }
};

export const getAdvancedReplacementByItemType = (interval: number): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(eventsActions.setAdvancedReplacementByItemTypeState(RequestState.LOADING));

    const response = await mainProtectedApi.getAdvancedReplacementByItemType(interval);

    dispatch(eventsActions.setAdvancedReplacementByItemType(response));
    dispatch(eventsActions.setAdvancedReplacementByItemTypeState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setAdvancedReplacementByItemTypeState(RequestState.ERROR));
  }
};

export const getAdvancedReplacementByReasonCode = (interval: number): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(eventsActions.setAdvancedReplacementByReasonCodeState(RequestState.LOADING));

    const response = await mainProtectedApi.getAdvancedReplacementByReasonCode(interval);

    dispatch(eventsActions.setAdvancedReplacementByReasonCode(response));
    dispatch(eventsActions.setAdvancedReplacementByReasonCodeState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setAdvancedReplacementByReasonCodeState(RequestState.ERROR));
  }
};

export const addNote = (
  body: NoteBody,
  request: Request,
  isModal?: boolean,
): AsyncAction => async (dispatch, getState, { mainProtectedApi }) => {
  try {
    dispatch(eventsActions.setAddNoteState(RequestState.LOADING));

    // comment for tests
    await mainProtectedApi.addNote(body);

    const {
      EventsReducer: { incidentRequest, modalNotes, requests },
    } = getState();

    const response = await mainProtectedApi.getIncidentRequest(request.event_id);

    const updatedRequest = {
      ...response.request,
      date_opened: convertFromStringToDate(response.request?.date_opened || null),
      date_closed: convertFromStringToDate(response.request.date_closed),
      action_date: convertFromStringToDate(response.request.action_date, FormatDate.ShortFormat),

    };

    if (incidentRequest) {
      const updatedRequests = requests
        .map((req) => {
          if (req.request_id === incidentRequest.request_id) {
            return updatedRequest;
          }
          return req;
        });

      dispatch(eventsActions.setRequests(updatedRequests));
      dispatch(eventsActions.setIncidentRequest({
        ...updatedRequest,
        isModal,
      }));
    }

    if (modalNotes) {
      dispatch(eventsActions.setModalNotes(updatedRequest));
    }

    dispatch(eventsActions.setAddNoteState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setAddNoteState(RequestState.ERROR));
  }
};

export const getRequestsFilters = (): AsyncAction => async (dispatch, _, { mainProtectedApi }) => {
  try {
    dispatch(eventsActions.setRequestsFiltersState(RequestState.LOADING));

    const filters = await mainProtectedApi.getRequestsFilters();

    dispatch(eventsActions.setRequestsFilters(filters));
    dispatch(eventsActions.setRequestsFiltersState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setRequestsFiltersState(RequestState.ERROR));
  }
};

export const getEventsFilters = (): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(eventsActions.setEventsFiltersState(RequestState.LOADING));

    const filters = await mainProtectedApi.getEventsFilters();

    dispatch(eventsActions.setEventsFilters(filters));

    dispatch(eventsActions.setEventsFiltersState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setEventsFiltersState(RequestState.ERROR));
  }
};

export const getIncidentsFilters = (): AsyncAction => async (dispatch, _, { mainProtectedApi }) => {
  try {
    dispatch(eventsActions.setIncidentsFiltersState(RequestState.LOADING));

    const filters = await mainProtectedApi.getIncidentsFilters();

    dispatch(eventsActions.setIncidentsFilters(filters));
    dispatch(eventsActions.setIncidentsFiltersState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setIncidentsFiltersState(RequestState.ERROR));
  }
};

export const getIncidentsTotal = (
  interval: number,
  queries: string,
): AsyncAction => async (dispatch, _, { mainProtectedApi }) => {
  try {
    dispatch(eventsActions.setIncidentsTotalState(RequestState.LOADING));

    const incidentsQueries = `?interval=${interval}${queries ? `&${queries}` : ''}`;

    const { total } = await mainProtectedApi.getIncidentsTotal(incidentsQueries);

    dispatch(eventsActions.setIncidentsTotal(total));
    dispatch(eventsActions.setIncidentsTotalState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setIncidentsTotalState(RequestState.ERROR));
  }
};

export const exportCSVRequest = (queries: string): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedFile },
) => {
  try {
    dispatch(eventsActions.setExportCSVState(RequestState.LOADING));

    const response = await mainProtectedFile.exportCSV(queries);

    const fileName = createCSVFileName();

    // const blobUrl = window.URL.createObjectURL(new Blob([response.csv], { type: 'application/zip' }));
    const link = document.createElement('a');
    link.href = response.csv;
    link.setAttribute('download', `${fileName}`);
    document.body.appendChild(link);
    link.click();
    link.remove();

    dispatch(eventsActions.setExportCSVState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setExportCSVState(RequestState.ERROR));
  }
};

export const exportCSVAssets = (body: any): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(eventsActions.setExportCSVState(RequestState.LOADING));

    const response = await mainProtectedApi.exportCSVAssets(body);
    const fileName = createCSVFileName();

    const link = document.createElement('a');
    link.href = response.csv;
    link.setAttribute('download', `${fileName}`);
    document.body.appendChild(link);
    link.click();
    link.remove();

    dispatch(eventsActions.setExportCSVState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setExportCSVState(RequestState.ERROR));
  }
};

export const cancelRequest = (request: Request): AsyncAction => async (
  dispatch,
  getState,
  { mainProtectedApi },
) => {
  try {
    dispatch(eventsActions.setCancelRequestState(RequestState.LOADING));

    const {
      EventsReducer: { requests },
      UserReducer: { userInfo },
    } = getState();

    const body = {
      override_type: 'cancel',
      itsm_id: request.itsm_id,
      xp_event_id: request.xp_event_id,
      work_order_id: request.request_id,
      username: `${userInfo?.first_name} ${userInfo?.last_name}`,
    };

    await mainProtectedApi.cancelRequest(body);

    const canceledRequest = {
      ...request,
      status: 'Request cancelled',
    };

    if (requests.length > 0) {
      const updatedRequests = requests.map((req) => {
        if (req.request_id === canceledRequest.request_id) {
          const updatedRequest = {
            ...canceledRequest,
          };
          return updatedRequest;
        }
        return req;
      });

      dispatch(eventsActions.setRequests(updatedRequests));
    }

    dispatch(eventsActions.setIncidentRequest(canceledRequest));
    dispatch(eventsActions.setCancelRequestState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setCancelRequestState(RequestState.ERROR));
  }
};

export const overrideEvent = (body: OverrideBody): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(eventsActions.setOverrideEventState(RequestState.LOADING));

    await mainProtectedApi.overrideEvent(body);

    dispatch(eventsActions.setOverrideEventState(RequestState.LOADED));
  } catch (e) {
    dispatch(eventsActions.setOverrideEventState(RequestState.ERROR));
  }
};
