/* eslint-disable no-case-declarations */
import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import MessageToast from '@/Components/common/Toast/MessageToast';

import { toastActions } from '@/store/actions/toast';
import { selectToastQueue } from '@/store/selectors/toast';

import {
  IMessageToast,
  IToast,
  ToastType,
} from '@/interfaces/toast';

interface Props {
}

const Toasty: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const queue = useSelector(selectToastQueue);

  const [topRightQueue, setTopRightQueue] = React.useState<IToast[]>([]);
  const [bottomRightQueue, setBottomRightQueue] = React.useState<IToast[]>([]);

  React.useEffect(() => {
    if (!queue.length) {
      return;
    }
    queueToast(queue[0]);
  }, [queue]);

  const queueToast = React.useCallback((toast: IToast) => {
    const isTopRight = topRight.includes(toast.type);
    const isBottomRight = bottomRight.includes(toast.type);

    if (isTopRight) {
      setTopRightQueue((current) => [...current, toast]);
    } else if (isBottomRight) {
      setBottomRightQueue((current) => [...current, toast]);
    }

    const timer = setTimeout(() => {
      handleToastClose(toast);
      clearTimeout(timer);
    }, toast.duration) as unknown as number;

    dispatch(toastActions.removeToast(toast.id));
  }, []);

  const handleToastClose = React.useCallback((toast: IToast) => {
    setTopRightQueue((toasts) => toasts.filter((current) => current.id !== toast.id));
    setBottomRightQueue((toasts) => toasts.filter((current) => current.id !== toast.id));
  }, []);

  const getToastView = (toast: IToast) => {
    switch (toast.type) {
      case ToastType.Message:
        const messageContext = toast.context as IMessageToast;
        return (
          <MessageToast
            key={`toast-${toast.id}`}
            theme={toast.theme}
            icon={messageContext.icon}
            header={messageContext.header}
            text={messageContext.text}
            toast={toast}
            onClose={handleToastClose}
          />
        );
      default:
        return (null);
    }
  };

  return (
    <ToastyStyled>
      <TopRightWrap>
        {topRightQueue.map((toast) => getToastView(toast))}
      </TopRightWrap>
      <BottomRightWrap>
        {bottomRightQueue.map((toast) => getToastView(toast))}
      </BottomRightWrap>
    </ToastyStyled>
  );
};

const topRight: ToastType[] = [];
const bottomRight: ToastType[] = [ToastType.Action, ToastType.Message, ToastType.Status];

const ToastyStyled = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  right: 0px;
  z-index: 300;
`;

const TopRightWrap = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 20px;
  right: 20px;

  & > * {
    margin-left: auto;
    margin-bottom: 10px;
  }
`;

const BottomRightWrap = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 20px;
  right: 20px;

  & > * {
    margin-left: auto;
    margin-top: 10px;
  }
`;

export default Toasty;
