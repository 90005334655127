import { createSelector, Selector } from 'reselect';

import { State } from '@/store';

import {
  IAsset,
  IEvent,
  IIncident,
  IItemsFilters,
  IViewAllEvents,
  Request,
  RequestsByDateAndActivityStatus,
  RequestsChartItem,
} from '@/api/main-protected';

import { RequestState } from '@/store/reducers/common';

import LocalStorage from '@/local-storage/LocalStorage';

const selectEvents = (state: State) => state.EventsReducer;

export const selectCCIncidents: Selector<State, IIncident[]> = createSelector(
  selectEvents,
  ({ incidents }) => incidents,
);

export const selectModalAsset: Selector<State, IAsset | null> = createSelector(
  selectEvents,
  ({ modalAsset }) => modalAsset,
);

export const selectIncidentsFilters: Selector<State, IItemsFilters> = createSelector(
  selectEvents,
  ({ incidentsFilters }) => incidentsFilters,
);

export const selectIncidentsFiltersState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ incidentsFiltersState }) => incidentsFiltersState,
);

export const selectIncidentsTotal: Selector<State, number> = createSelector(
  selectEvents,
  ({ incidentsTotal }) => incidentsTotal,
);

export const selectIncidentsTotalState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ incidentsTotalState }) => incidentsTotalState,
);

export const selectCCIncidentsState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ ccIncidentsState }) => ccIncidentsState,
);

export const selectCCIncidentsPaginationState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ ccIncidentsPaginationState }) => ccIncidentsPaginationState,
);

export const selectRequestState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ requestState }) => requestState,
);

export const selectUrgentRequestState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ urgentRequestsState }) => urgentRequestsState,
);

export const selectRequests: Selector<State, Request[]> = createSelector(
  selectEvents,
  ({ requests }) => requests,
);

export const selectGlobalSearchRequests: Selector<State, Request[]> = createSelector(
  selectEvents,
  ({ globalSearchRequests }) => globalSearchRequests,
);

export const selectUrgentRequests: Selector<State, Request[] | null> = createSelector(
  selectEvents,
  ({ urgentRequests }) => urgentRequests,
);

export const selectNeedsVerifyingRequests: Selector<State, Request[] | null> = createSelector(
  selectEvents,
  ({ needsVerifyingRequests }) => needsVerifyingRequests,
);

export const selectInDeployRequests: Selector<State, Request[] | null> = createSelector(
  selectEvents,
  ({ inDeployRequests }) => inDeployRequests,
);

export const selectInTransitRequests: Selector<State, Request[] | null> = createSelector(
  selectEvents,
  ({ inTransitRequests }) => inTransitRequests,
);

export const selectRequestsStatic: Selector<State, number> = createSelector(
  selectEvents,
  ({ requestsStatic }) => requestsStatic,
);

export const selectEventsStatic: Selector<State, number> = createSelector(
  selectEvents,
  ({ eventsStatic }) => eventsStatic,
);

export const selectIncident: Selector<State, IIncident | null> = createSelector(
  selectEvents,
  ({ incident }) => incident,
);

export const selectEventsArr: Selector<State, IEvent[] | null> = createSelector(
  selectEvents,
  ({ events }) => events,
);

export const selectIncidentRequest: Selector<State, Request | null> = createSelector(
  selectEvents,
  ({ incidentRequest }) => incidentRequest,
);

export const selectIncidentEvent: Selector<State, IEvent | null> = createSelector(
  selectEvents,
  ({ incidentEvent }) => incidentEvent,
);

export const selectEvent: Selector<State, IEvent | null> = createSelector(
  selectEvents,
  ({ event }) => event,
);

export const selectTasksByCategory: Selector<State, RequestsChartItem | null> = createSelector(
  selectEvents,
  ({ tasksByCategory }) => tasksByCategory,
);

export const selectTasksByStatus: Selector<State, RequestsChartItem | null> = createSelector(
  selectEvents,
  ({ tasksByStatus }) => tasksByStatus,
);

export const selectTasksByService: Selector<State, RequestsChartItem | null> = createSelector(
  selectEvents,
  ({ tasksByService }) => tasksByService,
);

export const selectRequestsByActionDateAndStatus: Selector<
State,
RequestsByDateAndActivityStatus | null
> = createSelector(
  selectEvents,
  ({ requestsByActionDateAndStatus }) => requestsByActionDateAndStatus,
);

export const selectAdvancedReplacementByItemType: Selector<
State,
RequestsChartItem | null
> = createSelector(selectEvents, ({ replacementByItemType }) => replacementByItemType);

export const selectAdvancedReplacementByReasonCode: Selector<
State,
RequestsChartItem | null
> = createSelector(selectEvents, ({ replacementByReasonCode }) => replacementByReasonCode);

export const selectRequestsInterval: Selector<State, number> = createSelector(
  selectEvents,
  ({ requestsInterval }) => {
    const filters = LocalStorage.getFilter();

    return filters ? +filters : requestsInterval;
  },
);

export const selectRequestsPage: Selector<State, string> = createSelector(
  selectEvents,
  ({ requestsPage }) => requestsPage,
);

export const selectRequestsPerPage: Selector<State, string> = createSelector(
  selectEvents,
  ({ requestsPerPage }) => requestsPerPage,
);

export const selectActiveRequestsLabel: Selector<State, string> = createSelector(
  selectEvents,
  ({ activeRequestsLabel }) => activeRequestsLabel,
);

export const selectActiveRequestsPriority: Selector<State, string> = createSelector(
  selectEvents,
  ({ activeRequestsPriority }) => activeRequestsPriority,
);

export const selectActiveRequestsStatus: Selector<State, string> = createSelector(
  selectEvents,
  ({ activeRequestsStatus }) => activeRequestsStatus,
);

export const selectIncidentRequestState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ incidentRequestState }) => incidentRequestState,
);

export const selectExportCSVRequestsState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ exportCSVState }) => exportCSVState,
);

export const selectGlobalSearchRequestsState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ globalSearchRequestsState }) => globalSearchRequestsState,
);

export const selectRequestStaticState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ requestsStaticState }) => requestsStaticState,
);

export const selectRequestPaginationState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ requestPaginationState }) => requestPaginationState,
);

export const selectRequestFilters: Selector<State, IItemsFilters> = createSelector(
  selectEvents,
  ({ requestFilters }) => requestFilters,
);

export const selectRequestFiltersState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ requestFiltersState }) => requestFiltersState,
);

export const selectEventsFilters: Selector<State, IItemsFilters> = createSelector(
  selectEvents,
  ({ eventsFilters }) => eventsFilters,
);

export const selectEventsFiltersState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ eventsFiltersState }) => eventsFiltersState,
);

export const selectEventsPaginationState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ eventPaginationState }) => eventPaginationState,
);

export const selectEventsState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ eventsState }) => eventsState,
);

export const selectEventsStaticState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ eventsStaticState }) => eventsStaticState,
);

export const selectModalNotes: Selector<State, Request | null> = createSelector(
  selectEvents,
  ({ modalNotes }) => modalNotes,
);

export const selectItemsState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ itemsState }) => itemsState,
);

export const selectAddNoteState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ addNoteState }) => addNoteState,
);

export const selectViewAllEvents: Selector<State, IViewAllEvents | null> = createSelector(
  selectEvents,
  ({ viewAllEvents }) => viewAllEvents,
);

export const selectCancelRequestState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ cancelRequestState }) => cancelRequestState,
);

export const selectOverrideEventState: Selector<State, RequestState> = createSelector(
  selectEvents,
  ({ overrideEventState }) => overrideEventState,
);
