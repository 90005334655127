import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import styled from 'styled-components';

import LoadingContainer from '@/Components/common/LoadingContainer';
import { IVariant } from '@/Components/common/DropDown';
import FilterToggleComponent from '@/Components/common/FilterToggle';

import AssetsTable from '@/Components/Items/ItemsTable/AssetsTable';
import Tab from '@/Components/Items/ItemsTable/Tab';
import TableFilters, { IDateFilter, IDateFilters } from '@/Components/Items/ItemsTable/TableFilters';
import Pagination from '@/Components/Items/ItemsTable/Pagination';
import { ItemsTabsNames } from '@/Components/Items/ItemsTable/ItemsContainer';
import PeripheralsTable from '@/Components/Items/ItemsTable/PeripheralsTable';
import PaginationSelect from '@/Components/Items/ItemsTable/PaginationSelect';
import { IEventTabs } from '@/Components/Requests/RequestTable/RequestContainer';
import EventsTable from '@/Components/Requests/RequestTable/EventsTable';
import RequestsTable from '@/Components/Requests/RequestTable/RequestsTable';

import { RequestOptions } from '@/store/reducers/common';

import { ITableBody } from '@/interfaces/api';

import useToggle from '@/Components/hooks/useToggle';

import { ISort } from '@/utils/sortUtil';
import { isEmptyObject } from '@/utils/validation';
import customScrollCss from '@/utils/custom-scroll-css';
import { dateToISO } from '@/utils/date';
import ExportCSVAssetsComponent from '@/Components/common/ExportCSV/ExportCSVAssets';

export enum DefaultFilter {
  All = 'All',
}

export interface ITableTabs<T> {
  selectedTab: T;
  setSelectedTab: React.Dispatch<React.SetStateAction<T>>;
  tabs: T[]
  interval: number;
  title: string,
}

export interface IMultiItemsFiltersLabel {
  [key: string]: {
    title: string,
    isShowAll?: boolean
  }
}

interface Props<T> extends ITableTabs<T> {
  getItems: (body: ITableBody, option?: RequestOptions) => void,
  getItemsTotal: (body: ITableBody) => void,
  getItemsFilter: () => void,
  filtersLabel: { [key: string]: string },
  itemsFilters: { [filter: string]: string[] },
  multiItemsFilters: { [filter: string]: string[] },
  multiItemsFiltersLabel: IMultiItemsFiltersLabel,
  dateFilters: IDateFilters,
  items: any,
  itemsTotal: number,
  initialItemsPerPage: number,
  isItemsLoading: boolean,
  isItemsTotalLoading: boolean,
  isItemsFilterLoading: boolean,
  isItemsPaginationLoading: boolean,
  defaultSort: ISort | null,
  exportCSVAssets?: boolean,
}

const TableContainer = <T extends string>({
  selectedTab,
  setSelectedTab,
  tabs,
  interval,
  getItemsTotal,
  getItems,
  getItemsFilter,
  filtersLabel,
  itemsFilters,
  multiItemsFilters,
  multiItemsFiltersLabel,
  items,
  initialItemsPerPage,
  itemsTotal,
  isItemsLoading,
  isItemsTotalLoading,
  isItemsFilterLoading,
  isItemsPaginationLoading,
  dateFilters,
  title,
  defaultSort,
  exportCSVAssets = false,
}: Props<T>) => {
  const [isFilterActive, setIsFilterActive] = useToggle();
  const [choosenFilters, setChoosenFilters] = useState<{ [key: string]: IVariant }>({});
  const [
    choosenMultiItemsFilters,
    setChoosenMultiItemsFilters,
  ] = useState<{ [key: string]: string }>({});
  const [
    savedMultiItemsFilters,
    setSavedMultiItemsFilters,
  ] = useState<{ [key: string]: string }>({});
  const [
    choosenDateFilters,
    setChoosenDateFilters,
  ] = useState<{ [key: string]: IDateFilter }>(dateFilters);
  const [
    savedDateFilters,
    setSavedDateFilters,
  ] = useState<{ [key: string]: IDateFilter }>(dateFilters);
  const [savedFilters, setSavedFilters] = useState<{ [key: string]: IVariant }>({});
  const [sortColumn, setSortColumn] = useState<ISort | null>(defaultSort);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(initialItemsPerPage);
  const [exportCSVAssetsBody, setExportCSVAssetsBody] = useState<any>({});
  const containerRef = useRef<HTMLDivElement | null>(null);

  const appliedFiltersAmount = (
    Object.keys(savedFilters).length
    + Object.keys(savedMultiItemsFilters).length
    + Object.entries(savedDateFilters)
      .filter(([, { startDate, endDate }]) => !!startDate || !!endDate).length
  );

  useEffect(() => {
    getItemsFilter();
  }, []);

  useEffect(() => {
    handleGetItems(
      savedFilters,
      savedDateFilters,
      savedMultiItemsFilters,
      sortColumn,
      itemsPerPage,
      1,
      true,
      false,
    );
  }, [interval]);

  const isShowFilters = useMemo(() => !!Object.entries(itemsFilters).length
   || !!Object.entries(multiItemsFilters).length
   || !!Object.entries(dateFilters).length, [itemsFilters, dateFilters, multiItemsFilters]);

  const handleOnFilterClick = () => {
    setIsFilterActive(!isFilterActive);
    setChoosenFilters(savedFilters);
    setChoosenDateFilters(savedDateFilters);
    setChoosenMultiItemsFilters(savedMultiItemsFilters);
  };

  const onChoose = (key: string) => (variant: IVariant | null) => {
    if (!variant) {
      return;
    }

    setChoosenFilters((filter) => ({ ...filter, [key]: variant }));
  };

  const onMultiItemsChoose = (key: string) => (value: string) => {
    setChoosenMultiItemsFilters((filter) => ({ ...filter, [key]: value }));
  };

  const onDateChoose = (key: string) => (dateFilter: IDateFilter) => {
    setChoosenDateFilters((filter) => ({ ...filter, [key]: dateFilter }));
  };

  const handleCancelClick = () => {
    if (appliedFiltersAmount) {
      setSavedFilters({});
      setSavedDateFilters(dateFilters);
      setSavedMultiItemsFilters({});

      handleGetItems({}, dateFilters, {}, sortColumn, itemsPerPage, 1, true);
    }

    setIsFilterActive(false);
  };

  const handleApplyClick = () => {
    const cleanMultiFilters = Object.fromEntries(
      Object.entries(choosenMultiItemsFilters)
        .map(([key, values]) => [key, values.split(', ')
          .filter((value) => multiItemsFilters[key]
            .some((filter) => value.replaceAll('٫', ',') === filter)).join(', ')])
        .filter(([, value]) => !!value),
    );

    const cleanFilters = Object.fromEntries(
      Object.entries(choosenFilters)
        .filter(([, { id }]) => id !== DefaultFilter.All),
    );

    setSavedFilters(cleanFilters);
    setSavedDateFilters(choosenDateFilters);
    setSavedMultiItemsFilters(cleanMultiFilters);

    handleGetItems(
      cleanFilters,
      choosenDateFilters,
      cleanMultiFilters,
      sortColumn,
      itemsPerPage,
      1,
      true,
    );

    setIsFilterActive(false);
  };

  const handleSetItemsPerPage = (updatedItemsPerPage: number) => {
    handleGetItems(
      savedFilters,
      savedDateFilters,
      savedMultiItemsFilters,
      sortColumn,
      updatedItemsPerPage,
      1,
      false,
      true,
    );
  };

  const handleGetItems = (
    selectedFilters: { [key: string]: IVariant },
    selectedDateFilters: { [key: string]: IDateFilter },
    selectedMultiItemsFilters: { [key: string]: string },
    sort: ISort | null,
    updatedItemsPerPage: number,
    page: number = 1,
    isGetAssetTotal = false,
    isSilent = true,
  ) => {
    const formatedFilters: { [key: string]: string } = Object.fromEntries(
      Object.entries(selectedFilters)
        .map(([key, { id }]) => [key, id])
        .filter(([, id]) => id !== DefaultFilter.All),
    );

    const formatedMultiItemsFilters: { [key: string]: string[] } = Object.fromEntries(
      Object.entries(selectedMultiItemsFilters)
        .reduce(
          (acc, [key, value]) => {
            const splitedValue = value.split(', ').map((item) => item.replaceAll('٫', ','));
            const cleanedSplitedValue = (!multiItemsFiltersLabel[key].isShowAll && splitedValue.length === multiItemsFilters[key].length) ? '' : splitedValue;
            return cleanedSplitedValue ? [...acc, [key, cleanedSplitedValue]] : acc;
          },
          [] as [string, string[]][],
        ),
    );

    const formatedDateFilters: { [key: string]: string } = Object.fromEntries(
      Object.entries(selectedDateFilters)
        .reduce((acc, [key, { startDate, endDate }]) => {
          if (!startDate && !endDate) {
            return acc;
          }
          if (!endDate) {
            return [...acc, [key, `${dateToISO(startDate)}`]];
          }
          if (!startDate) {
            return [...acc, [key, `:${dateToISO(endDate)}`]];
          }
          return [...acc, [key, `${dateToISO(startDate)}:${dateToISO(endDate)}`]];
        }, [] as [string, string][]),
    );

    const body: ITableBody = {
      ...(page ? { page } : {}),
      ...(updatedItemsPerPage ? { perPage: updatedItemsPerPage } : {}),
      ...(sort ? { sort } : {}),
      ...(Object.entries(formatedFilters).length ? { single_filters: formatedFilters } : {}),
      ...(Object.entries(formatedDateFilters).length ? { dates_filters: formatedDateFilters } : {}),
      ...(Object.entries(formatedMultiItemsFilters).length
        ? { multiple_filters: formatedMultiItemsFilters } : {}),
    };

    if (exportCSVAssets) {
      const bodyAssets: ITableBody = {
        ...(sort ? { sort } : {}),
        ...(Object.entries(formatedFilters).length ? { single_filters: formatedFilters } : {}),
        ...(Object.entries(formatedDateFilters).length ? { dates_filters: formatedDateFilters } : {}),
        ...(Object.entries(formatedMultiItemsFilters).length
          ? { multiple_filters: formatedMultiItemsFilters } : {}),
      };
      setExportCSVAssetsBody(bodyAssets);
    }

    setCurrentPage(page);
    setItemsPerPage(updatedItemsPerPage);

    getItems(body, { silent: isSilent });

    if (isGetAssetTotal) {
      const totalBody: ITableBody = {
        ...(Object.entries(formatedFilters).length ? { single_filters: formatedFilters } : {}),
        ...(Object.entries(formatedDateFilters).length
          ? { dates_filters: formatedDateFilters } : {}),
        ...(Object.entries(formatedMultiItemsFilters).length
          ? { multiple_filters: formatedMultiItemsFilters } : {}),
      };
      getItemsTotal(totalBody);
    }
  };

  const handleSetSortColumn = (sort: ISort) => {
    setSortColumn(sort);
    handleGetItems(savedFilters, savedDateFilters, savedMultiItemsFilters, sort, itemsPerPage);
  };

  const handleCurrentPage = (page: number) => {
    handleGetItems(
      savedFilters,
      savedDateFilters,
      savedMultiItemsFilters,
      sortColumn,
      itemsPerPage,
      page,
    );
  };

  const handleBottomCurrentPage = (page: number) => {
    handleCurrentPage(page);
    containerRef!.current!.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const showTable = () => {
    switch (selectedTab) {
      case ItemsTabsNames.ASSETS:
        return (
          <AssetsTable
            assets={items}
            setSortColumn={handleSetSortColumn}
            sortColumn={sortColumn}
          />
        );
      case ItemsTabsNames.PERIPHERALS:
        return (
          <PeripheralsTable
            peripherals={items}
            setSortColumn={handleSetSortColumn}
            sortColumn={sortColumn}
          />
        );
      case IEventTabs.ALL_REQUESTS:
        return (
          <RequestsTable
            requests={items}
            setSortColumn={handleSetSortColumn}
            sortColumn={sortColumn}
          />
        );
      case IEventTabs.EVENTS:
        return (
          <EventsTable
            events={items}
            setSortColumn={handleSetSortColumn}
            sortColumn={sortColumn}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <ItemsContainerStyled>
      <SubTitle>{title}</SubTitle>
      <OptionContainer ref={containerRef}>
        <Wrap>
          <TabsWrap>
            {tabs.map((tab) => (
              <Tab
                key={tab}
                selectedTab={selectedTab}
                setTab={setSelectedTab}
                tab={tab}
              />
            ))}
          </TabsWrap>
          <FilterWrap>
            <PaginationSelect
              value={itemsPerPage}
              onChange={handleSetItemsPerPage}
            />
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              maxTotal={itemsTotal}
              setCurrentPage={handleCurrentPage}
              isLoading={isItemsTotalLoading}
              isEmpty={!items.length}
              isArrowLoading={isItemsPaginationLoading}
            />
            {isShowFilters && (
              <FilterToggleComponent
                onFilterIconClick={handleOnFilterClick}
                isFilterActive={isFilterActive}
                isActiveFilter={isEmptyObject(savedFilters)}
                appliedFiltersAmount={appliedFiltersAmount}
              />
            )}
            {exportCSVAssets && <ExportCSVAssetsComponent filteredData={exportCSVAssetsBody} />}
          </FilterWrap>
        </Wrap>
        {isFilterActive && (
          <TableFilters
            isLoading={isItemsFilterLoading}
            handleCancelClick={handleCancelClick}
            handleApplyClick={handleApplyClick}
            filtersLabel={filtersLabel}
            itemsFilters={itemsFilters}
            choosenFilters={choosenFilters}
            onChoose={onChoose}
            onDateChoose={onDateChoose}
            dateFilters={choosenDateFilters}
            multiItemsFiltersLabel={multiItemsFiltersLabel}
            multiItemsFilters={multiItemsFilters}
            onMultiItemsChoose={onMultiItemsChoose}
            choosenMultiItemsFilters={choosenMultiItemsFilters}
          />
        )}
      </OptionContainer>
      <Container>
        <LoadingContainer isLoading={isItemsLoading}>
          {showTable()}
        </LoadingContainer>
      </Container>
      <BottomWrap>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          maxTotal={itemsTotal}
          setCurrentPage={handleBottomCurrentPage}
          isLoading={isItemsTotalLoading}
          isEmpty={!items.length}
          isArrowLoading={isItemsPaginationLoading}
        />
      </BottomWrap>
    </ItemsContainerStyled>
  );
};

const FilterWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
  padding: 30px 0 25px;
`;

const BottomWrap = styled(Wrap)`
  justify-content: flex-end;
  padding-right: 20px;
`;

const TabsWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;

  // overflow-x: auto; // with container

  border: 1px solid rgba(196, 196, 196, 0.7);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding-bottom: 9px;

  overflow-x: auto;
  overflow-y: hidden;
  ${customScrollCss}
`;

const SubTitle = styled.h2`
  font-weight: 700;
  font-size: 18px;
  margin: 40px 0 0;
`;

const ItemsContainerStyled = styled.div`
`;

export default TableContainer;
