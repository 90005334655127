import { createSelector, Selector } from 'reselect';

import { IItemsFilters, ITicket, ITicketBody } from '@/api/main-protected';

import { State } from '@/store';
import { RequestState } from '@/store/reducers/common';

const selectSupport = (state: State) => state.SupportReducer;

export const selectTickets: Selector<State, ITicket[] | null> = createSelector(
  selectSupport,
  ({ tickets }) => tickets,
);

export const selectTicketsState: Selector<State, RequestState> = createSelector(
  selectSupport,
  ({ ticketsState }) => ticketsState,
);

export const selectTicket: Selector<State, ITicketBody | null> = createSelector(
  selectSupport,
  ({ ticket }) => ticket,
);

export const selectTicketState: Selector<State, RequestState> = createSelector(
  selectSupport,
  ({ ticketState }) => ticketState,
);

export const selectTicketsTotal: Selector<State, number> = createSelector(
  selectSupport,
  ({ ticketsTotal }) => ticketsTotal,
);

export const selectTicketsTotalState: Selector<State, RequestState> = createSelector(
  selectSupport,
  ({ ticketsTotalState }) => ticketsTotalState,
);

export const selectTicketsFilters: Selector<State, IItemsFilters> = createSelector(
  selectSupport,
  ({ ticketsFilters }) => ticketsFilters,
);

export const selectTicketsFiltersState: Selector<State, RequestState> = createSelector(
  selectSupport,
  ({ ticketsFiltersState }) => ticketsFiltersState,
);

export const selectTicketsPaginationState: Selector<State, RequestState> = createSelector(
  selectSupport,
  ({ ticketsPaginationState }) => ticketsPaginationState,
);
