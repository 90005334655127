import React from 'react';
import styled from 'styled-components';

import arrowDownIcon from '@/assets/images/icons/arrow-down-grey.svg';

import useToggle from '@/Components/hooks/useToggle';
import useOnClickOutside from '@/Components/hooks/useOnClickOutside';

import customScrollCss from '@/utils/custom-scroll-css';

export const paginationValueOptions = [20, 50, 100];

interface Props {
  value: number,
  onChange: (key: number) => void;
}

const PaginationSelect: React.FC<Props> = ({
  value,
  onChange,
}) => {
  const [isActive, setIsActive] = useToggle();

  const handleCloseDropDown = () => {
    setIsActive(false);
  };

  const handleOpenDropDown = () => {
    setIsActive(true);
  };

  const handleOnChange = (item: number) => () => {
    onChange(item);
    handleCloseDropDown();
  };

  const dropDownRef = useOnClickOutside(handleCloseDropDown);

  return (
    <PaginationSelectStyled ref={dropDownRef}>
      <Text>Showing:</Text>
      <DropDownResult
        onClick={handleOpenDropDown}
      >
        {value}
        <IconDropDown src={arrowDownIcon} />
      </DropDownResult>
      {isActive && (
        <DropDownContainer>
          {paginationValueOptions.map((item) => (
            <DropDownValue
              key={item}
              onClick={handleOnChange(item)}
            >
              {item}
            </DropDownValue>
          ))}
        </DropDownContainer>
      )}
    </PaginationSelectStyled>
  );
};

const IconDropDown = styled.img`
`;

const DropDownValue = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #4A4949;
  text-align: center;
  cursor: pointer;
  padding: 0 4px;
  min-width: 40px;

  :hover {
    background-color: #ffffff;
  }
`;

const DropDownContainer = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 4;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  background-color: #f6f6f6;
  padding: 6px 0;

  ${customScrollCss};
`;

const DropDownResult = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #4A4949;
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #4A4949;
`;

const PaginationSelectStyled = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  position: relative;
`;

export default PaginationSelect;
