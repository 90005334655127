import { createReducerFunction, ImmerReducer } from 'immer-reducer';

export enum THEMES {
  DARK = 'dark',
  LIGHT = 'light'
}

export interface IThemeState {
  theme: THEMES;
}

const initialState: IThemeState = {
  theme: THEMES.LIGHT,
};

export class ThemeReducer extends ImmerReducer<IThemeState> {
  toggleTheme() {
    if (this.draftState.theme === THEMES.LIGHT) {
      this.draftState.theme = THEMES.DARK;
    } else {
      this.draftState.theme = THEMES.LIGHT;
    }
  }
}

export default createReducerFunction(ThemeReducer, initialState);
