import React, { useRef } from 'react';
import styled from 'styled-components';
import customScrollCss from '@/utils/custom-scroll-css';

import useOnClickOutside from '@/Components/hooks/useOnClickOutside';

import Btn from '@/Components/common/Signin/Btn';
import DropDown, { IVariant } from '@/Components/common/DropDown';
import Input from '../common/Input';

interface INewIssueModal {
  // editedUser: IUser
  type: IVariant
  priority: IVariant
  subject: string
  description: string
  setType: (varItem: IVariant | null) => void
  setPriority: (varItem: IVariant | null) => void
  setSubject: (text: string) => void
  setDescription: (text: string) => void
  handleAttachment: (e: React.ChangeEvent<HTMLInputElement>) => void
  deleteAttachment: (e: any) => void
  attachments: any[]
  isDisabled: boolean
  isUploading: boolean
  onCloseModal: () => void
  handleTicketSubmit: () => void
}

export const EDIT_NAVS = {
  PROFILE: 'Profile',
  CHANGE_PSW: 'Change password',
  // NOTIFICATIONS: 'Notifications'
};

export const ISSUE_VARIANTS: IVariant[] = [
  // { title: 'Issue' },
  // { title: 'support' },
  // { title: 'feedback' },
  { title: 'Problem' },
  { title: 'Incident' },
  { title: 'Question' },
  { title: 'Task' },
];

export const PRIORITY_VARIANTS: IVariant[] = [
  { title: 'Low' },
  { title: 'Normal' },
  { title: 'High' },
  { title: 'Urgent' },
];

const NewIssueModal: React.FC<INewIssueModal> = ({
  type,
  priority,
  subject,
  description,
  setType,
  setPriority,
  setSubject,
  setDescription,
  handleAttachment,
  deleteAttachment,
  attachments,
  isDisabled,
  isUploading,
  onCloseModal,
  handleTicketSubmit,
}) => {
  const modalClickOutsideRef = useOnClickOutside(onCloseModal);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  // useEffect(() => {
  //   if (textareaRef && textareaRef.current) {
  //     textareaRef.current.style.height = '0px'
  //     const scrollHeight = textareaRef.current.scrollHeight
  //     textareaRef.current.style.height = scrollHeight + 'px'
  //   }
  // }, [value])

  const onChooseType: (varItem: IVariant | null) => void = (e) => {
    setType(e);
  };

  const onChoosePriority: (varItem: IVariant | null) => void = (e) => {
    setPriority(e);
  };

  const hiddenFileInput = React.useRef<any>(null);
  const handleAttachmentUpload = () => {
    hiddenFileInput.current.click();
  };

  const textAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  return (
    <Layout>
      <ModalWrap ref={modalClickOutsideRef}>
        <Title>Create New Support Request</Title>
        <Main>
          <Section>
            <Type>
              <Label>Request type</Label>
              <InputBox isPlaceholder={!description}>
                <DropDown
                  variants={ISSUE_VARIANTS}
                  chosenOption={type}
                  onChoose={onChooseType}
                  isSub
                  // isTextCenter
                  minHeight={44}
                  placeholder="Select"
                />
              </InputBox>
            </Type>
            <Priority isSupport>
              <Label>Priority</Label>
              <InputBox isPlaceholder={!description}>
                <DropDown
                  variants={PRIORITY_VARIANTS}
                  chosenOption={priority}
                  onChoose={onChoosePriority}
                  isSub
                  // isTextCenter
                  minHeight={44}
                  placeholder="Select"
                />
              </InputBox>
            </Priority>
          </Section>
          <Subject>
            <Label>Subject</Label>
            <InputBox isPlaceholder={!description}>
              <Input
                isNoMargin
                id="subject"
                name="subject"
                value={subject}
                placeholder="Enter subject here"
                onChange={setSubject}
              />
            </InputBox>
          </Subject>
          <Label>Description</Label>
          <TextArea
            ref={textareaRef}
            placeholder="Enter your message here"
            onChange={textAreaChange}
            value={description}
          />
          <Label>Attachment(s)</Label>
          <AttachmentBtnBox isDisabled={isDisabled || isUploading}>
            <Btn onClick={() => handleAttachmentUpload()}>{isUploading ? ('Uploading...') : ('Browse')}</Btn>
            <File type="file" multiple accept="image/png, image/jpeg" ref={hiddenFileInput} onChange={(e) => handleAttachment(e)} />
          </AttachmentBtnBox>
          <AttachmentPreview>
            {attachments.length > 0 ? (
              <AttachmentList>
                {attachments.map((item, index) => (
                  <AttachmentItem key={item.src}>
                    <AttachmentLabel>{item.name}</AttachmentLabel>
                    <Delete onClick={() => deleteAttachment(index)}>X</Delete>
                  </AttachmentItem>
                ))}
              </AttachmentList>
            ) : null }
          </AttachmentPreview>
        </Main>
        <Btns>
          <Btn isTransparent onClick={onCloseModal}>
            Cancel
          </Btn>
          <Btn onClick={handleTicketSubmit}>Submit</Btn>
        </Btns>
      </ModalWrap>
    </Layout>
  );
};

const Layout = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  z-index: 100;

  padding: 20px;
`;
const ModalWrap = styled.div`
  max-width: 770px;
  width: 100%;
  max-height: calc(100vh - 160px);
  background: #ffffff;
  border-radius: 10px;
  padding: 36px 33px 30px 36px;
  margin: 90px auto 30px;
  overflow-y: auto;

  ${customScrollCss}
`;
const Title = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;

  color: #000000;
`;
const Main = styled.div`
  margin: 20px 0 30px;
`;
const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const Type = styled.div`
  width: 47%;
`;
const Priority = styled.div<{ isSupport: boolean }>`
  width: 47%;
  margin-left: auto;
   ${({ isSupport }) => (isSupport ? 'display: block' : 'display: none')};
`;
const Subject = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const InputBox = styled.div<{ isPlaceholder: boolean }>`
  // max-width: 420px;
  // width: 80%;

  & .placeholder {
    color: ${({ isPlaceholder }) => (isPlaceholder ? '#868484' : '#000000')};
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
  }
  & .option {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
  }

  & input[type="text"] {
    letter-spacing: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      letter-spacing: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #868484;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      letter-spacing: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #868484;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      letter-spacing: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #868484;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      letter-spacing: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #868484;
    }
  }
  
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin: 20px 0 8px;
`;
const TextArea = styled.textarea`
  display: block;
  width: 100%;
  height: 120px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 12px;
  resize: none;
  background-color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;

  color: #000000;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #868484;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #868484;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #868484;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #868484;
  }
`;

const Btns = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // margin-top: 55px;

  & button:first-child {
    width: 90px;
    margin: 0 16px;
  }
  & button:last-child {
    width: 130px;
  }
`;

const File = styled.input`
 display: none;
`;

const AttachmentList = styled.ul`
`;

const AttachmentItem = styled.li`
  margin: 7px 0;
`;

const AttachmentLabel = styled.label`
  font-style: italic;
`;
const Delete = styled.span`
  color : #FF1A1A;
  cursor: pointer;
  margin-left: 10px;
  font-weight: 700;
  font-style: italic;
`;

const AttachmentBtnBox = styled.div<{ isDisabled: boolean }>`
  width: 82px;

  & button {
    height: 32px;
    width: 100px;
    cursor:${({ isDisabled }) => (isDisabled ? 'wait' : 'pointer')};
    pointer-events:${({ isDisabled }) => (isDisabled ? 'none' : null)};
    background: ${({ isDisabled }) => (isDisabled ? 'gray' : null)};  
  }
`;

const AttachmentPreview = styled.div`
  display: flex;
  align-items: center;
`;
export default NewIssueModal;
