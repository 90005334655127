import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import useSectionsData from '@/Components/hooks/useSectionsData';
import useIntersectionObserver from '@/Components/hooks/useIntersectionObserver';
import { selectUserInfo } from '@/store/selectors/user';

import eventsIcon from '@/assets/images/icons/sidebar/events.svg';
import profileDefaultIcon from '@/assets/images/icons/user-settings/user-profile-default-icon.svg';
import editIcon from '@/assets/images/icons/edit-icon.svg';

import IconComponent from '@/Components/common/IconComponent';
import SettingsForm from '@/Components/UserSettings/SettingsForm';

export const NAV_NAMES = {
  BASIC_INFO: 'Basic information',
  EMAIL: 'Email',
  PSW: 'Password',
  NEW_PSW: 'New password',
  CONF_PSW: 'Confirm new password',
  PREFERENCES: 'Preferences',
  TWOSTEPVER: 'Two-step verification',
  RECENT_DEVICES: 'Recent devices',
  NOTIFICATIONS: 'Notifications',
};
export const NAV_IDS = {
  BASIC_INFO: 'us-basic-information',
  EMAIL: 'us-email',
  PSW: 'us-password',
  PREFERENCES: 'us-preferences',
  TWOSTEPVER: 'us-two-step-verification',
  RECENT_DEVICES: 'us-recent-devices',
  NOTIFICATIONS: 'us-notifications',
};

const MENU = [
  {
    id: NAV_IDS.BASIC_INFO,
    name: NAV_NAMES.BASIC_INFO,
    icon: eventsIcon,
  },
  {
    id: NAV_IDS.EMAIL,
    name: NAV_NAMES.EMAIL,
    icon: eventsIcon,
  },
  {
    id: NAV_IDS.PSW,
    name: NAV_NAMES.PSW,
    icon: eventsIcon,
  },
  // {
  //   id: NAV_IDS.PREFERENCES,
  //   name: NAV_NAMES.PREFERENCES,
  //   icon: eventsIcon
  // },
  // {
  //   id: NAV_IDS.TWOSTEPVER,
  //   name: NAV_NAMES.TWOSTEPVER,
  //   icon: eventsIcon
  // }
  // {
  //   id: NAV_IDS.RECENT_DEVICES,
  //   name: NAV_NAMES.RECENT_DEVICES,
  //   icon: eventsIcon
  // },
  // {
  //   id: NAV_IDS.NOTIFICATIONS,
  //   name: NAV_NAMES.NOTIFICATIONS,
  //   icon: eventsIcon
  // }
];

const SCROLL_BLOCK_NAME = '#user-settings';
const TOP_OFFSET = 260; // sync top position for scroll and intersectionObserver

const UserSettingsMainWrap: React.FC = () => {
  const [activeNavId, setActiveNavId] = useState(MENU[0].id);
  const { nestedSections } = useSectionsData(SCROLL_BLOCK_NAME);
  const user = useSelector(selectUserInfo);

  useIntersectionObserver(setActiveNavId, SCROLL_BLOCK_NAME, TOP_OFFSET);

  const navMenu = MENU.map((v) => ({
    ...v,
    ...nestedSections.find((sp) => sp.id === v.id),
  }));

  return (
    <Wrap>
      <Menu>
        {navMenu.map((menuItem) => (
          <a
            href={`#${menuItem.id}`}
            key={menuItem.id}
            onClick={(e) => {
              e.preventDefault();
              document.querySelector(`#${menuItem.id}`)!.scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            <Item isActive={menuItem.id === activeNavId}>
              <IconComponent icon={menuItem.icon} width={12} />
              <ItemText isActive={menuItem.id === activeNavId}>{menuItem.name}</ItemText>
            </Item>
          </a>
        ))}
      </Menu>
      <UserData>
        <UserHeader>
          <UserBgImg />
          <ProfImgBlock>
            <ImgBlock>
              <IconComponent
                icon={user?.picture || profileDefaultIcon}
                alt={`${user?.first_name} ${user?.last_name}` || 'userName'}
              />
            </ImgBlock>
            {/* <EditBtn>
              <IconComponent icon={editIcon} width={17} />
            </EditBtn> */}
          </ProfImgBlock>
        </UserHeader>
        <SettingsWrap>
          <SettingsForm topOffset={TOP_OFFSET} />
        </SettingsWrap>
      </UserData>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  margin-top: 27px;
  position: relative;
`;
const Menu = styled.nav`
  position: sticky;
  top: 120px;
  left: 0;

  flex: 0 0 260px;
  width: 260px;
  height: fit-content;

  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 14px 0;
  margin-right: 32px;
`;
const UserData = styled.div`
  flex: 1 1 100%;
  width: 100%;
`;
const UserHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 21px;
  background: #ffffff;
`;

const SettingsWrap = styled.div``;

const UserBgImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 64%;
  background: #e5e5e5;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ProfImgBlock = styled.div`
  position: relative;
  width: 118px;
  height: 118px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  margin: 48px 0 60px;
`;

const ImgBlock = styled.div`
  border-radius: 50%;
  overflow: hidden;
`;

const EditBtn = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

const Item = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  padding: 6px 0;
  margin: 0 0 0 -1px;
  border-left: 3px solid ${({ isActive }) => (isActive ? '#0066ff' : 'transparent')};
  cursor: pointer;

  & > div:first-child {
    margin: 0 20px;
  }

  & img {
    height: 10px;
  }
`;
const ItemText = styled.div<{ isActive: boolean }>`
  font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
  font-size: 14px;
  line-height: 20px;
  color: ${({ isActive }) => (isActive ? '#0066ff' : '#000000')};
`;

export default UserSettingsMainWrap;
