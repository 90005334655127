import { createReducerFunction, ImmerReducer } from 'immer-reducer';

import {
  IAsset,
  IEvent,
  IIncident,
  IItemsFilters,
  IViewAllEvents,
  Request,
  RequestsByDateAndActivityStatus,
  RequestsChartItem,
} from '@/api/main-protected';

import { Filter } from '@/local-storage/LocalStorage';

import { RequestState } from '@/store/reducers/common';

export interface InitState {
  incidents: IIncident[];
  requests: Request[];
  urgentRequests: Request[];
  needsVerifyingRequests: Request[];
  inDeployRequests: Request[];
  inTransitRequests: Request[];
  requestFilters: IItemsFilters;
  requestPaginationState: RequestState;
  requestFiltersState: RequestState;
  requestsStatic: number;
  eventsStatic: number;
  incident: IIncident | null;
  events: IEvent[];
  event: IEvent | null;
  eventPaginationState: RequestState;
  eventsFilters: IItemsFilters;
  eventsFiltersState: RequestState;
  tasksByCategory: RequestsChartItem | null;
  tasksByStatus: RequestsChartItem | null;
  tasksByService: RequestsChartItem | null;
  replacementByItemType: RequestsChartItem | null;
  replacementByReasonCode: RequestsChartItem | null;
  requestsByActionDateAndStatus: RequestsByDateAndActivityStatus | null;
  requestsInterval: number;
  requestsPage: string;
  requestsPerPage: string;
  activeRequestsLabel: string;
  activeRequestsPriority: string;
  activeRequestsStatus: string;
  incidentRequest: Request | null;
  incidentRequestState: RequestState;
  incidentEvent: IEvent | null;
  associateEvents: IEvent[];
  globalSearchRequests: Request[];
  associateEventsState: RequestState;
  ccIncidentsState: RequestState;
  requestState: RequestState;
  urgentRequestsState: RequestState;
  needsVerifyingRequestsState: RequestState;
  inDeployRequestsState: RequestState;
  inTransitRequestsState: RequestState;
  requestsStaticState: RequestState;
  eventsStaticState: RequestState;
  incidentState: RequestState;
  eventsState: RequestState;
  eventState: RequestState;
  tableRequestItemsState: RequestState;
  tableEventsItemsState: RequestState;
  tasksByCategoryState: RequestState;
  tasksByStatusState: RequestState;
  tasksByServiceState: RequestState;
  requestsByActionDateAndStatusState: RequestState;
  replacementByItemTypeState: RequestState;
  replacementByReasonCodeState: RequestState;
  noteState: RequestState;
  addNoteState: RequestState;
  incidentsFilters: IItemsFilters;
  incidentsFiltersState: RequestState;
  incidentsTotal: number;
  incidentsTotalState: RequestState;
  exportCSVState: RequestState;
  ccIncidentsPaginationState: RequestState;
  globalSearchRequestsState: RequestState;
  modalAsset: IAsset | null;
  modalNotes: Request | null;
  itemsState: RequestState;
  cancelRequestState: RequestState;
  overrideEventState: RequestState;
  viewAllEvents: IViewAllEvents | null;
}

const initialState: InitState = {
  incidents: [],
  requests: [],
  urgentRequests: [],
  needsVerifyingRequests: [],
  inDeployRequests: [],
  inTransitRequests: [],
  requestFilters: {},
  requestsStatic: 0,
  eventsStatic: 0,
  incident: null,
  events: [],
  event: null,
  tasksByCategory: null,
  tasksByStatus: null,
  requestsByActionDateAndStatus: null,
  tasksByService: null,
  replacementByItemType: null,
  replacementByReasonCode: null,
  requestsInterval: Filter.LAST_7_DAYS,
  requestsPage: '1',
  requestsPerPage: '100',
  activeRequestsLabel: 'all requests',
  activeRequestsPriority: '',
  activeRequestsStatus: '',
  incidentRequest: null,
  incidentRequestState: RequestState.IDLE,
  incidentEvent: null,
  associateEvents: [],
  globalSearchRequests: [],
  associateEventsState: RequestState.IDLE,
  ccIncidentsState: RequestState.IDLE,
  requestState: RequestState.IDLE,
  urgentRequestsState: RequestState.IDLE,
  needsVerifyingRequestsState: RequestState.IDLE,
  inDeployRequestsState: RequestState.IDLE,
  inTransitRequestsState: RequestState.IDLE,
  requestsStaticState: RequestState.IDLE,
  eventsStaticState: RequestState.IDLE,
  incidentState: RequestState.IDLE,
  eventsState: RequestState.IDLE,
  eventState: RequestState.IDLE,
  requestPaginationState: RequestState.IDLE,
  tableRequestItemsState: RequestState.IDLE,
  tableEventsItemsState: RequestState.IDLE,
  tasksByCategoryState: RequestState.IDLE,
  tasksByStatusState: RequestState.IDLE,
  tasksByServiceState: RequestState.IDLE,
  requestsByActionDateAndStatusState: RequestState.IDLE,
  replacementByItemTypeState: RequestState.IDLE,
  replacementByReasonCodeState: RequestState.IDLE,
  noteState: RequestState.IDLE,
  addNoteState: RequestState.IDLE,
  requestFiltersState: RequestState.IDLE,
  exportCSVState: RequestState.IDLE,
  incidentsFilters: {},
  incidentsFiltersState: RequestState.IDLE,
  incidentsTotal: 0,
  incidentsTotalState: RequestState.IDLE,
  ccIncidentsPaginationState: RequestState.IDLE,
  globalSearchRequestsState: RequestState.IDLE,
  eventPaginationState: RequestState.IDLE,
  eventsFilters: {},
  eventsFiltersState: RequestState.IDLE,
  itemsState: RequestState.IDLE,
  cancelRequestState: RequestState.IDLE,
  overrideEventState: RequestState.IDLE,
  modalAsset: null,
  modalNotes: null,
  viewAllEvents: null,
};

export class EventsReducer extends ImmerReducer<InitState> {
  setCCIncidents(ccIncidents: IIncident[]) {
    this.draftState.incidents = ccIncidents;
  }

  setModalAsset(modalAsset: IAsset | null) {
    this.draftState.modalAsset = modalAsset;
  }

  setModalNotes(modalNotes: Request | null) {
    this.draftState.modalNotes = modalNotes;
  }

  setIncidentsFilters(incidentsFilters: IItemsFilters) {
    this.draftState.incidentsFilters = incidentsFilters;
  }

  setIncidentsFiltersState(incidentsFiltersState: RequestState) {
    this.draftState.incidentsFiltersState = incidentsFiltersState;
  }

  setIncidentsTotal(incidentsTotal: number) {
    this.draftState.incidentsTotal = incidentsTotal;
  }

  setIncidentsTotalState(incidentsTotalState: RequestState) {
    this.draftState.incidentsTotalState = incidentsTotalState;
  }

  setCCIncidentsState(ccIncidentsState: RequestState) {
    this.draftState.ccIncidentsState = ccIncidentsState;
  }

  setCCIncidentsPaginationState(ccIncidentsPaginationState: RequestState) {
    this.draftState.ccIncidentsPaginationState = ccIncidentsPaginationState;
  }

  setNoteState(noteState: RequestState) {
    this.draftState.noteState = noteState;
  }

  setAddNoteState(addNoteState: RequestState) {
    this.draftState.addNoteState = addNoteState;
  }

  setTableRequestItemsState(tableRequestItemsState: RequestState) {
    this.draftState.tableRequestItemsState = tableRequestItemsState;
  }

  setTableEventsItemsState(tableEventsItemsState: RequestState) {
    this.draftState.tableEventsItemsState = tableEventsItemsState;
  }

  setIsIncidentRequestState(incidentRequestState: RequestState) {
    this.draftState.incidentRequestState = incidentRequestState;
  }

  setRequests(requests: Request[]) {
    this.draftState.requests = requests;
  }

  setGlobalSearchRequests(globalSearchRequests: Request[]) {
    this.draftState.globalSearchRequests = globalSearchRequests;
  }

  setRequestState(requestState: RequestState) {
    this.draftState.requestState = requestState;
  }

  setGlobalSearchRequestsState(globalSearchRequestsState: RequestState) {
    this.draftState.globalSearchRequestsState = globalSearchRequestsState;
  }

  setIncidentRequest(incidentRequest: Request | null) {
    this.draftState.incidentRequest = incidentRequest;
  }

  setIncidentEvent(incidentEvent: IEvent | null) {
    this.draftState.incidentEvent = incidentEvent;
  }

  setUrgentRequests(urgentRequests: Request[]) {
    this.draftState.urgentRequests = urgentRequests;
  }

  setUrgentRequestsState(urgentRequestsState: RequestState) {
    this.draftState.urgentRequestsState = urgentRequestsState;
  }

  setNeedsVerifyingRequests(needsVerifyingRequests: Request[]) {
    this.draftState.needsVerifyingRequests = needsVerifyingRequests;
  }

  setNeedsVerifyingRequestsState(needsVerifyingRequestsState: RequestState) {
    this.draftState.needsVerifyingRequestsState = needsVerifyingRequestsState;
  }

  setInDeployRequests(inDeployRequests: Request[]) {
    this.draftState.inDeployRequests = inDeployRequests;
  }

  setInDeployRequestsState(inDeployRequestsState: RequestState) {
    this.draftState.inDeployRequestsState = inDeployRequestsState;
  }

  setInTransitRequests(inTransitRequests: Request[]) {
    this.draftState.inTransitRequests = inTransitRequests;
  }

  setInTransitRequestsState(inTransitRequestsState: RequestState) {
    this.draftState.inTransitRequestsState = inTransitRequestsState;
  }

  setRequestsStatic(requestsStatic: number) {
    this.draftState.requestsStatic = requestsStatic;
  }

  setRequestsStaticState(requestsStaticState: RequestState) {
    this.draftState.requestsStaticState = requestsStaticState;
  }

  setEventsStatic(eventsStatic: number) {
    this.draftState.eventsStatic = eventsStatic;
  }

  setEventsStaticState(eventsStaticState: RequestState) {
    this.draftState.eventsStaticState = eventsStaticState;
  }

  setIncident(incident: IIncident | null) {
    this.draftState.incident = incident;
  }

  setIncidentState(incidentState: RequestState) {
    this.draftState.incidentState = incidentState;
  }

  setEvents(events: IEvent[]) {
    this.draftState.events = events;
  }

  setEventsState(eventsState: RequestState) {
    this.draftState.eventsState = eventsState;
  }

  setEvent(event: IEvent | null) {
    this.draftState.event = event;
  }

  setEventState(eventState: RequestState) {
    this.draftState.eventState = eventState;
  }

  setTasksByCategory(tasksByCategory: RequestsChartItem) {
    this.draftState.tasksByCategory = tasksByCategory;
  }

  setTasksByCategoryState(tasksByCategoryState: RequestState) {
    this.draftState.tasksByCategoryState = tasksByCategoryState;
  }

  setTasksByStatus(tasksByStatus: RequestsChartItem) {
    this.draftState.tasksByStatus = tasksByStatus;
  }

  setTasksByStatusState(tasksByStatusState: RequestState) {
    this.draftState.tasksByStatusState = tasksByStatusState;
  }

  setTasksByService(tasksByService: RequestsChartItem) {
    this.draftState.tasksByService = tasksByService;
  }

  setTasksByServiceState(tasksByServiceState: RequestState) {
    this.draftState.tasksByServiceState = tasksByServiceState;
  }

  setRequestsByActionDateAndStatus(requestsByActionDateAndStatus: RequestsByDateAndActivityStatus) {
    this.draftState.requestsByActionDateAndStatus = requestsByActionDateAndStatus;
  }

  setRequestsByActionDateAndStatusState(requestsByActionDateAndStatusState: RequestState) {
    this.draftState.requestsByActionDateAndStatusState = requestsByActionDateAndStatusState;
  }

  setAdvancedReplacementByItemType(replacementByItemType: RequestsChartItem) {
    this.draftState.replacementByItemType = replacementByItemType;
  }

  setAdvancedReplacementByItemTypeState(replacementByItemTypeState: RequestState) {
    this.draftState.replacementByItemTypeState = replacementByItemTypeState;
  }

  setAdvancedReplacementByReasonCode(replacementByReasonCode: RequestsChartItem) {
    this.draftState.replacementByReasonCode = replacementByReasonCode;
  }

  setAdvancedReplacementByReasonCodeState(replacementByReasonCodeState: RequestState) {
    this.draftState.replacementByReasonCodeState = replacementByReasonCodeState;
  }

  setRequestsLast(reqLast: number) {
    this.draftState.requestsInterval = reqLast;
  }

  setRequestsPage(requestsPage: string) {
    this.draftState.requestsPage = requestsPage;
  }

  setRequestsPerPage(reqPerPage: string) {
    this.draftState.requestsPerPage = reqPerPage;
  }

  setActiveRequestsLabel(activeRequestsLabel: string) {
    this.draftState.activeRequestsLabel = activeRequestsLabel;
  }

  setActiveRequestsPriority(activeRequestsPriority: string) {
    this.draftState.activeRequestsPriority = activeRequestsPriority;
  }

  setActiveRequestsStatus(activeRequestsStatus: string) {
    this.draftState.activeRequestsStatus = activeRequestsStatus;
  }

  setExportCSVState(state: RequestState) {
    this.draftState.exportCSVState = state;
  }

  setRequestsFiltersState(requestFiltersState: RequestState) {
    this.draftState.requestFiltersState = requestFiltersState;
  }

  setEventsPaginationState(eventPaginationState: RequestState) {
    this.draftState.eventPaginationState = eventPaginationState;
  }

  setRequestPaginationState(requestPaginationState: RequestState) {
    this.draftState.requestPaginationState = requestPaginationState;
  }

  setEventPaginationState(eventPaginationState: RequestState) {
    this.draftState.eventPaginationState = eventPaginationState;
  }

  setRequestsFilters(requestFilters: IItemsFilters) {
    this.draftState.requestFilters = requestFilters;
  }

  setEventsFilters(eventsFilters: IItemsFilters) {
    this.draftState.eventsFilters = eventsFilters;
  }

  setEventsFiltersState(eventsFiltersState: RequestState) {
    this.draftState.eventsFiltersState = eventsFiltersState;
  }

  setItemsState(itemsState: RequestState) {
    this.draftState.itemsState = itemsState;
  }

  setViewAllEvents(viewAllEvents: IViewAllEvents | null) {
    this.draftState.viewAllEvents = viewAllEvents;
  }

  setCancelRequestState(cancelRequestState: RequestState) {
    this.draftState.cancelRequestState = cancelRequestState;
  }

  setOverrideEventState(overrideEventState: RequestState) {
    this.draftState.overrideEventState = overrideEventState;
  }
}

export default createReducerFunction(EventsReducer, initialState);
