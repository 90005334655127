import React from 'react';
import styled from 'styled-components';

import BtnIcon from '@/Components/Sidebar/BtnIcon';

import logoCircle from '@/assets/images/logo_color_left.svg';
import logoText from '@/assets/images/logo_color_right.png';

import { HandleToggle } from '@/Components/hooks/useToggle';

interface ILogo {
  isExpanded: boolean
  handleBtnClick: HandleToggle
  handleLogoClick: () => void
}

const Logo: React.FC<ILogo> = ({ isExpanded, handleBtnClick, handleLogoClick }) => (
  <Logobox>
    <Wrap onClick={handleLogoClick}>
      <Image id="round" src={logoCircle} alt="logo sign" />
      {isExpanded && (
      <LogoWrap>
        {/* <LogoTextIcon /> */}
        <img src={logoText} alt="xperience" />
      </LogoWrap>
      )}
    </Wrap>
    <Btn onClick={handleBtnClick}>
      <BtnIcon isExpanded={isExpanded} />
    </Btn>
  </Logobox>
);

const LogoWrap = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  & img {
    max-width: 101px;
  }
`;

const Wrap = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  cursor: pointer;
  column-gap: 5px;
  width: 100%;
`;

const Image = styled.img`
  max-width: 20px;
`;

const Logobox = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 34px 0;
  width: 100%;
  position: relative;

  & > img {
    max-width: 17px;
  }
`;

const Btn = styled.div`
  position: absolute;
  right: -32px; // block padding
  width: 25px;
  height: 25px;
  background: #ffffff;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
  cursor: pointer;
`;

export default Logo;
