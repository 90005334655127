/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import styled from 'styled-components';
import { Moment } from 'moment';

import CalendarIcon from '@/Components/icons/CalendarIcon';

import { defaultLocale } from '@/utils/date';

interface Props {
  classTracking?: string;
  label?: string;
  text?: Moment | null;
  onClick: () => void;
  isActive: boolean;
  format?: string;
  isIconBorder?: boolean;
  fontSize?: number;
  iconSize?: number;
  backgroundColor?: string;
  placeholder: string;
}

export interface IFilterDate {
  from: Moment | null;
  till: Moment | null;
}

export enum DateSelects {
  From = 'From',
  Till = 'Till',
}

const DateSelect: React.FC<Props> = ({
  classTracking,
  onClick,
  text,
  isActive,
  format = 'DD / MM / YY',
  backgroundColor,
  placeholder,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    onClick();
  };

  return (
    <DateSelectStyled
      className={classTracking}
      isActive={isActive}
      onClick={handleClick}
      backgroundColor={backgroundColor}
    >
      {text ? (
        <Text>
          {text.locale(defaultLocale).format(format)}
        </Text>
      ) : (
        <GreyText>{placeholder}</GreyText>
      )}
      <IconWrap>
        <CalendarIcon color={isActive ? '#0066FF' : undefined} />
      </IconWrap>
    </DateSelectStyled>
  );
};

const DateSelectStyled = styled.div<{ isActive: boolean, backgroundColor: string | undefined }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  padding: 12px 10px;
  cursor: pointer;
  border: 1px solid #c4c4c4;
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Text = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
`;

const GreyText = styled.p`
  font-size: 14px;
  color: #c4c4c4;
`;

export default DateSelect;
