import { createActionCreators } from 'immer-reducer';

import TokensLocalStorage from '@/local-storage/TokensLocalStorage';
import LocalStorage from '@/local-storage/LocalStorage';

import { RequestState } from '@/store/reducers/common';
import { AsyncAction } from '@/store/actions/common';
import { LoginReducer } from '@/store/reducers/login';

export const loginActions = createActionCreators(LoginReducer);

export type InitialActions =
  | ReturnType<typeof loginActions.setIsLoggedIn>
  | ReturnType<typeof loginActions.setIsLocalLoading>
  | ReturnType<typeof loginActions.setDocuSignedId>
  | ReturnType<typeof loginActions.setIsImpactedUserLoading>;

export const logout = (): AsyncAction => async (dispatch) => {
  try {
    const storage = TokensLocalStorage.getInstance();

    storage.clear();
    LocalStorage.clear();
    dispatch(loginActions.setIsLoggedIn(false));
    dispatch(loginActions.setLoginState(RequestState.IDLE));
    dispatch(loginActions.setIsLoading(false));
    dispatch(loginActions.setIsLocalLoading(false));
    dispatch(loginActions.setIsImpactedUserLoading(false));
  } catch (e) {
    console.error(e);
  }
};
