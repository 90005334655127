import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface IChartTabsWrap {
  titles: string[]
  preTitle: string
  activeIndex: number
  isContentGrow?: boolean
  setActiveIndex: (num: number) => void
  children: ReactNode
}

const ChartTabsWrap: React.FC<IChartTabsWrap> = ({
  titles,
  preTitle,
  activeIndex,
  isContentGrow = false,
  setActiveIndex,
  children,
}) => {
  const handleTitleClick = (i: number) => {
    if (i === activeIndex) return;

    setActiveIndex(i);
  };

  return (
    <Wrap>
      <TitleBlock>
        <Top>
          {preTitle && <PreTitle>{preTitle}</PreTitle>}
          {titles.map((title, i) => (
            <Title key={title} isActive={i === activeIndex} onClick={() => handleTitleClick(i)}>
              {title}
            </Title>
          ))}
        </Top>
      </TitleBlock>
      <Content isContentGrow={isContentGrow}>{children}</Content>
    </Wrap>
  );
};

const Wrap = styled.div`
  // flex: 0 0 calc(50% - 9px); // with container
  min-height: 291px;
  flex: 1 0 calc(50% - 9px);
  min-width: 505px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid rgba(196, 196, 196, 0.7);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;
const TitleBlock = styled.div`
  padding: 18px 22px 20px 25px;
`;
const Top = styled.div`
  display: flex;
  align-items: center;
`;
const PreTitle = styled.h3`
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  margin-right: 6px;
`;
const Title = styled.h3<{ isActive: boolean }>`
  font-weight: ${({ isActive }) => (isActive ? '600' : '400')};
  font-size: 16px;
  color: ${({ isActive }) => (isActive ? '#000000' : '#0066FF')};
  transition: all 0.3s ease;
  cursor: pointer;
  margin: ${({ isActive }) => (isActive ? '0 10px 0 0' : '0 15px 0 0')};
`;

const Content = styled.div<{ isContentGrow: boolean }>`
  width: 100%;
  ${({ isContentGrow }) => isContentGrow
    && css`
      flex-grow: 2;
    `}
`;

export default ChartTabsWrap;
