import React, { useState } from 'react';
import styled from 'styled-components';

import ContentContainer from '../common/ContentContainer/ContentContainer';
import AddNewUserForm from './AddNewUserForm';

export const TABS = [
  {
    id: 'step-1',
    step: 1,
    title: 'Profile',
    isActive: true,
  },
  {
    id: 'step-2',
    step: 2,
    title: 'Confirmation',
    isActive: false,
  },
];

const AddNewUser: React.FC = () => {
  const [tabs, setTabs] = useState(TABS);
  const [activeStep, setActiveStep] = useState<number>(TABS[0].step);

  const handleClick = (itemStep: number) => {
    const updatedTabs = tabs.map((tab) => {
      tab.isActive = itemStep === tab.step;
      return tab;
    });

    setTabs(updatedTabs);
    setActiveStep(itemStep);
  };

  const handleNextBtnClick = (step: number) => {
    if (step === TABS.length) return;

    setActiveStep(step + 1);
    handleClick(step + 1);
  };

  return (
    <>
      <ContentContainer title="Add a User">
        <Steps>
          {tabs.map((tab) => (
            <StepWrap key={tab.step}>
              <Step
                onClick={() => (tab.step === TABS[0].step ? handleClick(tab.step) : undefined)}
                isClickable={tab.step === TABS[0].step}
              >
                <Num isActive={tab.isActive}>{tab.step}</Num>
                <Text isActive={tab.isActive}>{tab.title}</Text>
              </Step>
              {tab.step !== tabs.length && <Divider />}
            </StepWrap>
          ))}
        </Steps>
      </ContentContainer>
      <AddUserFormWrap>
        <AddNewUserForm
          handleClick={() => handleClick(TABS[0].step)}
          onNextBtnClick={() => handleNextBtnClick(activeStep)}
          activeStep={activeStep}
        />
      </AddUserFormWrap>
    </>
  );
};

const AddUserFormWrap = styled.div`
  max-width: 795px;
`;

const Steps = styled.div`
  display: flex;
  align-items: center;
  // justify-content: space-between;
  max-width: 795px;
  margin: 22px 0 22px;
`;
const StepWrap = styled.div`
  flex: 0 0 40%;
  display: flex;
  align-items: center;

  &:last-child {
    flex: 0 1 auto;
  }
`;
const Step = styled.div<{ isClickable: boolean }>`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  cursor: ${({ isClickable }) => isClickable && 'pointer'};
`;

const Num = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 39px;
  border-radius: 50%;

  color: ${({ isActive }) => (isActive ? '#ffffff' : '#000000')};
  background: ${({ isActive }) => (isActive ? '#0066FF' : '#E5E5E5')};
`;
const Text = styled.p<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? '#0066FF' : '#000000')};
  margin: 0 16px;
`;
const Divider = styled.div`
  flex: 1 1 auto;
  height: 1px;
  background: #c4c4c4;
  margin-right: 16px;
`;

export default AddNewUser;
