import { createReducerFunction, ImmerReducer } from 'immer-reducer';
import { RequestState } from '@/store/reducers/common';
import { IRequestsSearch } from '@/api/main-protected';

export interface InitState {
  globalSearch: IRequestsSearch | null,
  globalSearchState: RequestState,
}

const initialState: InitState = {
  globalSearch: null,
  globalSearchState: RequestState.IDLE,
};

export class RequestsSearchReducer extends ImmerReducer<InitState> {
  setRequestsSearch(globalSearch: IRequestsSearch | null) {
    this.draftState.globalSearch = globalSearch;
  }

  setRequestsSearchState(globalSearchState: RequestState) {
    this.draftState.globalSearchState = globalSearchState;
  }
}

export default createReducerFunction(RequestsSearchReducer, initialState);
