import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Auth0ParseHashError, Auth0DecodedHash } from 'auth0-js';

import LocalStorage, { Filter } from '@/local-storage/LocalStorage';
import TokensLocalStorage from '@/local-storage/TokensLocalStorage';

import { auth } from '@/services/auth0.service';

import { AppDispatch } from '@/App';
import GlobalLoading from '@/Components/common/GlobalLoading';
import { PAGES } from '@/Components/Main/Main';

import { logout } from '@/store/actions/login';

const RedirectPage: React.FC = () => {
  const location = useLocation();
  const path = useHistory();
  const dispatch = useDispatch<AppDispatch>();

  const tokenStorage = TokensLocalStorage.getInstance();

  useEffect(() => {
    processHash();
  }, []);

  const processHash = () => {
    auth.parseHash(
      {
        hash: location.hash,
      },
      (error: Auth0ParseHashError | null, result: Auth0DecodedHash | null) => {
        if (error) {
          console.error('no pass', error);
          dispatch(logout());
          path.push(PAGES.LOGIN);
          return;
        }

        if (result!.accessToken) {
          tokenStorage.setAccessToken(result!.accessToken);
          LocalStorage.setFilter(Filter.LAST_7_DAYS.toString());
          path.push(PAGES.CONTROL_CENTER);
        }
      },
    );
  };

  return (<GlobalLoading />);
};

export default RedirectPage;
