import React from 'react';
import styled from 'styled-components';

import IconComponent from '@/Components/common/IconComponent';

import birdGreenIcon from '@/assets/images/icons/bird-green-icon.svg';

interface IPswConditions {
  is10?: boolean
  isUpLow?: boolean
  isA1?: boolean
  isSpecial?: boolean
  noMargin?: boolean
  width?: number
}

const PasswordConditions: React.FC<IPswConditions> = ({
  is10 = false,
  isUpLow = false,
  isA1 = false,
  isSpecial = false,
  noMargin = false,
  width = 17,

}) => (
  <ControlsBlock noMargin={noMargin}>
    <Condition>
      <Bird>{is10 && <IconComponent icon={birdGreenIcon} width={width} />}</Bird>
      <Text isValid={is10}>At least 10 characters</Text>
    </Condition>
    <Condition>
      <Bird>{isUpLow && <IconComponent icon={birdGreenIcon} width={width} />}</Bird>
      <Text isValid={isUpLow}>A mixture of both uppercase and lowercase letters</Text>
    </Condition>
    <Condition>
      <Bird>{isA1 && <IconComponent icon={birdGreenIcon} width={width} />}</Bird>
      <Text isValid={isA1}>A mixture of letters and numbers</Text>
    </Condition>
    <Condition>
      <Bird>{isSpecial && <IconComponent icon={birdGreenIcon} width={width} />}</Bird>
      <Text isValid={isSpecial}>
        Inclusion of at least one special character, e.g., ! @ # ? ]
      </Text>
    </Condition>
  </ControlsBlock>
);

const ControlsBlock = styled.div<{ noMargin: boolean }>`
  position: relative;
  width: 100%;
  margin: ${({ noMargin }) => (noMargin ? 0 : '17px 0 24px 0')};

  & > a {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
`;

const Condition = styled.div`
  display: flex;
  // align-items: center;
  margin: 5px 0;
`;
const Bird = styled.div`
  flex: 0 0 14px;
  width: 14px;
  margin: 0 7px 0 3px;
`;
const Text = styled.div<{ isValid: boolean }>`
  font-weight: 400;
  font-size: 14px;
  color: ${({ isValid }) => (isValid ? '#868484' : '#bababa')};
`;

export default PasswordConditions;
