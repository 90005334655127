import React from 'react';

const SuccessIcon: React.FC = () => (
  <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3567_20286)">
      <path
        d="M50.6667 13.7067L25.3349 37.1858L17.6374 44.32L0 25.4979L7.22242 18.73L18.1391 30.3812L25.3349 23.7065L43.9553 6.43213L50.6667 13.7067Z"
        fill="#8ADB53"
      />
      <path
        d="M50.6668 13.7067L25.335 37.1858V23.7066L43.9554 6.43213L50.6668 13.7067Z"
        fill="#5DC277"
      />
    </g>
    <defs>
      <clipPath id="clip0_3567_20286">
        <rect width="50.6667" height="50.6667" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SuccessIcon;
