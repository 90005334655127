import { useEffect } from 'react';

const getDomain = (urlString: string) => urlString.split('//')[1].split('/')[0];

const useSubdomainCheck = () => {
  useEffect(() => {
    const appBaseUrlDomain = getDomain(process.env.REACT_APP_BASE_URL);
    const domain = getDomain(window.location.origin);

    if (appBaseUrlDomain === domain) return;

    window.location.replace(process.env.REACT_APP_BASE_URL);
  }, [window.location.origin]);
};

export default useSubdomainCheck;
