import React from 'react'
import styled from 'styled-components'

interface IRadioButton {
  changed: (e: React.ChangeEvent<HTMLInputElement>) => void
  id: string
  isSelected?: boolean
  label: string
  value: string
}
const RADIO_SIZE = 20
const RADIO_CHECKED_SIZE = 14

const RadioButton: React.FC<IRadioButton> = ({ changed, id, isSelected = false, label, value }) => {
  return (
    <RadioBtn>
      <Input id={id} onChange={changed} value={value} type='radio' checked={isSelected} />
      <Label htmlFor={id} isSelected={isSelected}>
        {label}
      </Label>
    </RadioBtn>
  )
}

const RadioBtn = styled.div`
  width: 100%;
  height: 30px;
`
const Input = styled.input`
  display: none;

  &:checked + label:after {
    transform: scale(1);
  }
`
const Label = styled.label<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  height: ${RADIO_SIZE}px;
  position: relative;
  padding: 0 27px;
  margin-bottom: 0;
  cursor: pointer;
  vertical-align: bottom;

  font-weight: 500;
  font-size: 14px;
  color: #000000;

  &:before,
  &:after {
    position: absolute;
    content: '';
    border-radius: 50%;
    transition: all 0.3s ease;
  }

  &:before {
    left: 0;
    top: 0;
    width: ${RADIO_SIZE}px;
    height: ${RADIO_SIZE}px;
    border: 1px solid #c4c4c4;
  }

  &:after {
    top: ${RADIO_SIZE / 2 - RADIO_CHECKED_SIZE / 2}px;
    left: ${RADIO_SIZE / 2 - RADIO_CHECKED_SIZE / 2}px;
    width: ${RADIO_CHECKED_SIZE}px;
    height: ${RADIO_CHECKED_SIZE}px;
    transform: scale(${({ isSelected }) => (isSelected ? 1 : 0)});
    background: #0066ff;
  }
`

export default RadioButton
