import React from 'react'
import styled from 'styled-components'

import Input from '@/Components/common/Input'
import DropDown, { IVariant } from '@/Components/common/DropDown'

import { IPhoneType } from '@/api/main-protected'

interface IPhoneMultiInput {
  id: string
  name: string
  type?: string
  value: string
  variant: IPhoneVariant
  placeholder: string
  isNomargin?: boolean
  onChange: (value: string) => void
  setVariant: (varItem: IPhoneVariant | null) => void
  isError?: boolean,
  errorText?: string,
}

export interface IPhoneVariant extends IVariant {
  id: IPhoneType,
}

export const PHONE_TYPE_VARIANTS: IPhoneVariant[] = [
  { title: 'Mobile', id: IPhoneType.Mobile },
  { title: 'Home' , id: IPhoneType.Home},
  { title: 'Work' , id: IPhoneType.Work},
  { title: 'Fax' , id: IPhoneType.Fax},
  { title: 'Direct' , id: IPhoneType.Direct}
]

const PhoneMultiInput: React.FC<IPhoneMultiInput> = ({
  id,
  name,
  type = 'number',
  value,
  variant,
  placeholder,
  isNomargin = true,
  onChange,
  setVariant,
  isError = false,
  errorText = '',
}) => {
  const onChoose: (varItem: IPhoneVariant | null) => void = e => {
    setVariant(e)
  }

  return (
    <Wrap>
      <InputBox>
        <Input
          id={id}
          name={name}
          type={type}
          value={value}
          placeholder={placeholder}
          isNoMargin={isNomargin}
          onChange={onChange}
          isError={isError}
          errorText={errorText}
        />
      </InputBox>
      <InputBox>
        <DropDown
          variants={PHONE_TYPE_VARIANTS}
          chosenOption={variant}
          onChoose={onChoose}
          isSub
          isTextCenter
          minHeight={30}
        />
      </InputBox>
    </Wrap>
  )
}

const Wrap = styled.div`
  width: 100%;
  max-width: 475px;
  display: flex;

  & > div:first-child {
    flex: 1 1 auto;
  }
  & > div:last-child {
    flex: 0 0 96px;
    width: 96px;
    border-left: none;

    font-weight: 400;
    font-size: 14px;
    color: #868484;

    & > div {
      border-left: none;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
`

const InputBox = styled.div`
  width: 100%;

  & input {
    height: 30px;
    padding: 7px 10px 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`

export default PhoneMultiInput
