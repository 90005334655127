/* eslint-disable max-len */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { AppDispatch } from '@/App';
import { IAsset, IEvent, Request } from '@/api/main-protected';

import convertFromStringToDate, { FormatDate } from '@/utils/convertFromStringToDate';

import { RequestState } from '@/store/reducers/common';
import { globalActions } from '@/store/actions/global';
/* import { selectGlobalSearch, selectGlobalSearchState } from '@/store/selectors/global'; */
import { selectRequestsSearch, selectRequestsSearchState } from '@/store/selectors/requestsSearch';
import { selectAssetsSearch, selectAssetsSearchState } from '@/store/selectors/assetsSearch';
import { selectEventsSearch, selectEventsSearchState } from '@/store/selectors/eventsSearch';
import { eventsActions, getIncidentRequest } from '@/store/actions/events';
import { itemsActions } from '@/store/actions/items';

import LocalStorage from '@/local-storage/LocalStorage';

import SearchedRequestsTable from '@/Components/AllResults/SearchedRequestsTable';
import SearchedItemsTable from '@/Components/AllResults/SearchedItemsTable';
import IconComponent from '@/Components/common/IconComponent';
import SearchedEventsTable from '@/Components/AllResults/SearchedEventsTable';

import searchIcon from '@/assets/images/icons/search-icon.svg';
import LoaderDots from '../LoaderDots';

const AllResultsTables: React.FC = () => {
  /* const searchedResults = useSelector(selectGlobalSearch); */
  const searchedRequestsResults = useSelector(selectRequestsSearch);
  const searchedAssetsResults = useSelector(selectAssetsSearch);
  const searchedEventsResults = useSelector(selectEventsSearch);

  /* const searchedState = useSelector(selectGlobalSearchState); */
  const searchedRequestsState = useSelector(selectRequestsSearchState);
  const searchedAssetsState = useSelector(selectAssetsSearchState);
  const searchedEventsState = useSelector(selectEventsSearchState);

  const recentSearch = LocalStorage.getRecentSearch();

  const dispatch = useDispatch<AppDispatch>();

  const isEmpty = searchedAssetsResults?.assets.length === 0
    && searchedEventsResults?.events.length === 0
    && searchedRequestsResults?.requests.length === 0;

  const handleRecentSearchClick = () => {
    if (!recentSearch) return;

    dispatch(globalActions.setGlobalSearchQuery(recentSearch));
  };

  const handleRequestClick = (ev: Request) => {
    const modalRequest = {
      ...ev,
      date_opened: ev.date_opened ? convertFromStringToDate(ev.date_opened) : '-',
      date_closed: ev.date_closed ? convertFromStringToDate(ev.date_closed) : '-',
      action_date: ev.action_date ? convertFromStringToDate(ev.action_date, FormatDate.ShortFormat) : '-',
      isModal: true,
    };

    dispatch(eventsActions.setIncidentRequest(modalRequest));

    if (!ev.items) {
      dispatch(getIncidentRequest(ev.event_id, !ev.items, modalRequest));
    }
  };

  const handleItemClick = (item: IAsset) => {
    dispatch(itemsActions.setModalItem(item));
  };

  const handleEventClick = (event: IEvent) => {
    dispatch(eventsActions.setIncidentRequest(event.request));
    dispatch(eventsActions.setIncidentEvent(event));
    dispatch(getIncidentRequest(event.event_id));
  };

  return (
    <Container>
      {!!recentSearch && (
      <Block>
        <Title>RECENT SEARCHES</Title>
        <Recent onClick={handleRecentSearchClick}>
          <RecentText>{recentSearch}</RecentText>
          <Split />
          <IconComponent icon={searchIcon} width={11} />
        </Recent>
      </Block>
      )}
      {searchedRequestsResults && searchedRequestsResults?.requests.length > 0
          && (
            <>
              <Title>REQUESTS</Title>
              <SearchedRequestsTable
                requests={searchedRequestsResults?.requests}
                handleRequestClick={handleRequestClick}
              />
            </>
          )}
      {searchedAssetsResults && searchedAssetsResults?.assets.length > 0
          && (
            <>
              <Title>ITEMS</Title>
              <SearchedItemsTable
                assets={searchedAssetsResults?.assets}
                handleItemClick={handleItemClick}
              />
            </>
          )}
      {searchedEventsResults && searchedEventsResults?.events.length > 0
          && (
            <>
              <Title>EVENTS</Title>
              <SearchedEventsTable
                events={searchedEventsResults?.events}
                handleEventClick={handleEventClick}
              />
            </>
          )}
      {(searchedRequestsState === RequestState.LOADING || searchedAssetsState === RequestState.LOADING || searchedEventsState === RequestState.LOADING)
      && (
        <Block>
          <More><LoaderDots size={8} color="#0066FF" /></More>
        </Block>

      )}
      {(searchedRequestsState === RequestState.LOADED && searchedAssetsState === RequestState.LOADED && searchedEventsState === RequestState.LOADED) && isEmpty && (
        <Block>
          <More>
            Nothing was found
          </More>
        </Block>
      )}
      {(searchedRequestsState === RequestState.ERROR || searchedAssetsState === RequestState.ERROR || searchedEventsState === RequestState.ERROR) && (
        <Block>
          <More isError>
            Something went wrong
          </More>
        </Block>
      )}
    </Container>

  );
};
const Container = styled.div`
`;

const Block = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  padding: 5px 20px 25px;
  &:first-child {
    padding-left: 0;
  }
`;

const More = styled.p<{ isError?: boolean }>`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: ${({ isError }) => (isError ? '#ff0707' : '#000000')};
  text-align: center;
  margin: 10px 0;
`;

const Title = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #848486;
  margin: 5px 0;
`;

const Recent = styled.div`
  display: inline-flex;
  align-items: center;
  background: #D9D9D9;
  border-radius: 20px;
  padding: 0px 10px;
  margin: 7px 0px;
  cursor: pointer;
`;

const RecentText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  padding: 2px 0px;
`;

const Split = styled.span`
  width: 5px;
`;

export default AllResultsTables;
