import React from 'react';

interface Props {
  width?: number | string;
  height?: number | string;
  color?: string;
}

const FilledCloseIcon: React.FC<Props> = ({ width = '16', height = '16', color = '#E52222' }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 4.44444C0 1.98985 1.98985 0 4.44444 0H11.5556C14.0102 0 16 1.98985 16 4.44444V11.5556C16 14.0102 14.0102 16 11.5556 16H4.44444C1.98985 16 0 14.0102 0 11.5556V4.44444Z"
      fill={color}
    />
    <path
      d="M4.91574 11.2015C4.65539 10.9412 4.65539 10.5191 4.91574 10.2587L10.2583 4.91614C10.5187 4.65579 10.9408 4.6558 11.2011 4.91615V4.91615C11.4615 5.1765 11.4615 5.59861 11.2011 5.85896L5.85854 11.2015C5.59819 11.4619 5.17609 11.4619 4.91574 11.2015V11.2015Z"
      fill="white"
    />
    <path
      d="M11.2014 11.2014C10.941 11.4618 10.5189 11.4618 10.2586 11.2014L4.91599 5.85886C4.65564 5.59851 4.65564 5.1764 4.91599 4.91606V4.91606C5.17634 4.65571 5.59846 4.65571 5.85881 4.91606L11.2014 10.2586C11.4617 10.519 11.4617 10.9411 11.2014 11.2014V11.2014Z"
      fill="white"
    />
  </svg>
);

export default FilledCloseIcon;
