import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '@/App';
import TableContainer, { IMultiItemsFiltersLabel, ITableTabs } from '@/Components/Items/ItemsTable/TableContainer';
import { IDateFilters } from '@/Components/Items/ItemsTable/TableFilters';

import { getRequests, getRequestsFilters, getRequestsStatic } from '@/store/actions/events';
import { RequestOptions, RequestState } from '@/store/reducers/common';

import {
  selectRequestFilters,
  selectRequestFiltersState,
  selectRequestPaginationState,
  selectRequests,
  selectRequestsStatic,
  selectRequestState,
  selectRequestStaticState,
} from '@/store/selectors/events';

import { ITableBody } from '@/interfaces/api';

import { requestsPerPage } from '@/utils/api';
import { sort, SortOrder, SortType } from '@/utils/sortUtil';

interface Props<T> extends ITableTabs<T> {
}

const AllRequestContainer = <T extends string>({
  interval,
  selectedTab,
  setSelectedTab,
  tabs,
  title,
}: Props<T>) => {
  const dispatch = useDispatch<AppDispatch>();

  const itemsFilters = useSelector(selectRequestFilters);
  const request = useSelector(selectRequests);
  const requestTotal = useSelector(selectRequestsStatic);
  const requestState = useSelector(selectRequestState);
  const requestPaginationState = useSelector(selectRequestPaginationState);
  const requestTotalState = useSelector(selectRequestStaticState);
  const itemsFiltersState = useSelector(selectRequestFiltersState);

  const filtersLabel: IMultiItemsFiltersLabel = {
    phases: { title: 'Phases', isShowAll: true },
    statuses: { title: 'Status' },
    locations: { title: 'Locations' },
    employee_ids: { title: 'Employee ID' },
    categories: { title: 'Category' },
    departments: { title: 'Department' },
    makes: { title: 'Make' },
    models: { title: 'Model' },
    device_types: { title: 'Device type' }
  };

  const sortedFilters = useMemo(() => {
    const arrFilters = Object.entries(itemsFilters).map(
      ([key, value]) => [key, sort(
        value,
        (sortedValue) => sortedValue,
        SortOrder.ASCENDING,
        SortType.String,
      ).filter((item) => !!item)],
    );
    return Object.fromEntries(arrFilters);
  }, [itemsFilters]);

  const dateFilters: IDateFilters = {
    date_opened: {
      startDate: null,
      endDate: null,
      title: 'Date Opened',
    },
    date_closed: {
      startDate: null,
      endDate: null,
      title: 'Date Closed',
    },
    action_date: {
      startDate: null,
      endDate: null,
      title: 'Action Date',
    },
  };

  const getItems = (body: ITableBody, option?: RequestOptions) => {
    dispatch(getRequests({ ...body, ...{ interval } }, option));
  };

  const getItemsTotal = (body: ITableBody) => {
    dispatch(getRequestsStatic({ ...body, ...{ interval } }));
  };

  const getItemsFilter = () => {
    dispatch(getRequestsFilters());
  };

  return (
    <TableContainer
      defaultSort={{ sortBy: 'date_opened', direction: SortOrder.DESCENDING }}
      title={title}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabs={tabs}
      getItems={getItems}
      getItemsTotal={getItemsTotal}
      getItemsFilter={getItemsFilter}
      interval={interval}
      filtersLabel={{}}
      itemsFilters={{}}
      dateFilters={dateFilters}
      multiItemsFilters={sortedFilters}
      multiItemsFiltersLabel={filtersLabel}
      items={request}
      itemsTotal={requestTotal}
      isItemsLoading={requestState !== RequestState.LOADED}
      isItemsTotalLoading={requestTotalState !== RequestState.LOADED}
      isItemsFilterLoading={itemsFiltersState !== RequestState.LOADED}
      isItemsPaginationLoading={requestPaginationState !== RequestState.LOADED}
      initialItemsPerPage={requestsPerPage}
    />
  );
};

export default AllRequestContainer;
