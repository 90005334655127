import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { AppDispatch } from '@/App';
import { PAGES } from '@/Components/Main/Main';
import GlobalLoading from '@/Components/common/GlobalLoading';

import { loginActions, logout } from '@/store/actions/login';

import LocalStorage from '@/local-storage/LocalStorage';
// import getBrowserName, { BrowserNames } from '@/utils/browsers';
// import { selectIsDocuSigned } from '@/store/selectors/login';

export enum NeedToAgreeStates {
  Null = 'null',
  False = 'false',
}

declare const docuSignClick: any | undefined;

interface Props {
  userId: string;
  isLoading?: boolean;
}

const DocuSign: React.FC<Props> = ({ userId, isLoading = true }) => {
  // const [reloadInterval, setReloadInterval] = useState<NodeJS.Timer>();
  const history = useHistory();
  const disaptch = useDispatch<AppDispatch>();
  // const browser = getBrowserName();

  // const isDocusign = useSelector(selectIsDocuSigned);

  // useEffect(() => {
  //   console.log('1 - reloadInterval', reloadInterval);

  //   renderDocusign();

  //   if (browser === BrowserNames.FIREFOX) {
  //     console.log('if firefox browser');

  //     const interval = setInterval(() => {
  //       console.log('isDocusign in interval', isDocusign);

  //       renderDocusign();

  //       return () => clearInterval(interval);
  //     }, 5000);

  //     setReloadInterval(interval);
  //   }
  // }, [docuSignClick]);

  // useEffect(
  //   () => () => {
  //     console.log('2 - reloadInterval on clear interval', reloadInterval);

  //     clearInterval(reloadInterval);
  //   },
  //   [reloadInterval],
  // );

  useEffect(() => {
    // renderDocusign();
  }, [docuSignClick]);

  const renderDocusign = () => {
    try {
      docuSignClick.Clickwrap.render(
        {
          environment: 'https://na4.docusign.net',
          accountId: 'debc81a4-e1de-41ee-a728-711c4abeceb1',
          clickwrapId: 'cf44afe9-09a3-45a0-9033-1dc1e5f4884d',
          clientUserId: userId,

          // ++ Called when the user will be presented with an agreement
          // ++ show agreement / isDocuSigned = false
          onMustAgree() {
            // Set a local variable if needing to distinguish new agreements
          },

          // Called immediately upon user clicking ‘Agree’
          // Any calls to download will not work until onAgreed has been called
          onAgreeing() {},

          // eslint-disable-next-line max-len
          // ++ Called when the user has previously agreed OR has just successfully completed the agreement AND response has been successfully stored
          onAgreed() {
            disaptch(loginActions.setDocuSignedId(true));
            LocalStorage.setDocuSignedId(userId);
          },

          // Called immediately upon user clicking ‘Decline’
          onDeclining() {
            disaptch(logout());
            history.push(PAGES.LOGIN);
          },

          // eslint-disable-next-line max-len
          // Called when a user declines and a response has been successfully stored (if Record Decline Responses is enabled)
          onDeclined() {},

          // Called if any errors occur during agreement or configuration
          onError() {
            // disaptch(logout());
            // history.push(PAGES.LOGIN);
          },
        },
        '#ds-clickwrap',
      );
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      {isLoading && <GlobalLoading />}
    </>
  );
};

export default DocuSign;
