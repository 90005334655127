/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';

import useToggle from '@/Components/hooks/useToggle';

import customScrollCss from '@/utils/custom-scroll-css';

import arrowDownIcon from '@/assets/images/icons/arrow-down-grey.svg';
import arrowDownBlueIcon from '@/assets/images/icons/arrow-down-blue.svg';
import arrowDownGreyIcon from '@/assets/images/icons/sidebar/arrow-down.svg';

import LocalStorage from '@/local-storage/LocalStorage';

export interface IVariant {
  id?: string | number
  title: string
  icon?: string
  diff?: number
  storage?: number
  [key: string]: any
}

interface IProps<T> {
  variants: T[] | null
  chosenOption: T | null
  placeholder?: string
  onChoose: (variant: T | null) => void
  onOpen?: () => void
  isTransparent?: boolean
  isSemiBold?: boolean
  isUpperCase?: boolean
  isBlack?: boolean
  isSub: boolean
  height?: number
  minHeight?: number
  isTextCenter?: boolean
  isGreyArrow?: boolean
  dispatch?: (last: number) => void
  isError?: boolean
}

const DropDown = <T extends IVariant>({
  variants,
  chosenOption,
  placeholder,
  onChoose,
  onOpen,
  isTransparent = false,
  isSemiBold = false,
  isUpperCase = false,
  isBlack = false,
  isSub,
  height = 200,
  minHeight = 44,
  isTextCenter = false,
  isGreyArrow = false,
  isError = false,
  dispatch,
}: IProps<T>) => {
  const [isOpen, setIsOpen] = useToggle(false);
  const [isOpening, setIsOpening] = useToggle(false);
  const [isClosing, setIsClosing] = useToggle(false);
  const [userVariant, setUserVariant] = useState<T | null>(chosenOption);

  const handleOptionClick = () => {
    if (isOpen) {
      setIsClosing(true);
      setIsOpening(false);
      setIsOpen(false);
    } else {
      setIsOpening(true);
      setIsClosing(false);
      setIsOpen(true);
      if (onOpen) {
        onOpen();
      }
    }
  };

  const handleVariantChoose = (variant: T | null) => {
    if (variant === null || variant!.title !== userVariant?.title) {
      onChoose(variant);
      setIsClosing(true);
      setIsOpening(false);
      setIsOpen(false);
      setUserVariant(variant);
      return;
    }

    handleOptionClick();
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setIsOpen(false);
      setIsClosing(true);
      setIsOpening(false);
    }, 100);
  };

  return (
    <SelectContainer height={minHeight} isError={isError}>
      <Select isOpen={isOpen} isTransparent={isTransparent}>
        <OptionsWrap onClick={handleOptionClick} tabIndex={0} onBlur={handleInputBlur}>
          <Option
            className="placeholder"
            onClick={() => handleVariantChoose(chosenOption)}
            isSemiBold={isSemiBold}
            isLeftIcon={!!chosenOption?.icon}
            isTextCenter={isTextCenter}
            minHeight={minHeight}
          >
            <ChosenOptionText
              isTransparent={isTransparent}
              isBlack={isBlack}
              // isHideText={isOpen}
              isHideText={false}
              userVariant={userVariant}
              isTextCenter={isTextCenter}
            >
              {chosenOption?.icon && (
                <IconDropDownWrap isOnLeft>
                  <OptionIcon src={chosenOption!.icon} />
                </IconDropDownWrap>
              )}
              {isUpperCase
                ? chosenOption?.title.toUpperCase() || placeholder?.toUpperCase()
                : chosenOption?.title || placeholder}
            </ChosenOptionText>
            <IconDropDownWrap>
              <IconDropDown
                src={isGreyArrow
                  ? arrowDownIcon
                  : isSub
                    ? arrowDownGreyIcon
                    : arrowDownBlueIcon}
                isOpening={isOpening}
                isClosing={isClosing}
                isOpen={isOpen}
              />
            </IconDropDownWrap>
          </Option>
          <VariantsOptions height={height} isOpen={isOpen}>
            {isOpen
              && variants!.map((option) => (
                <Option
                  className="option"
                  onClick={() => {
                    handleVariantChoose(option);

                    if (dispatch) {
                      LocalStorage.setFilter(option.storage!.toString());
                      dispatch(option.storage!);
                    }
                  }}
                  isSemiBold={isSemiBold}
                  key={option.title}
                  isTextCenter={isTextCenter}
                  minHeight={minHeight}
                >
                  <OptionText
                    isTransparent={isTransparent}
                    isActive={option.isActive || option.title === chosenOption?.title}
                  >
                    {isUpperCase ? option.title.toUpperCase() : option.title}
                  </OptionText>
                </Option>
              ))}
          </VariantsOptions>
        </OptionsWrap>
      </Select>
    </SelectContainer>
  );
};

const SelectContainer = styled.div<{ height: number; isError: boolean }>`
  height: ${({ height }) => height}px;
  width: 100%;
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.black};
  border: ${({ isError }) => (isError ? '1px solid #ff0505' : '1px solid #c4c4c4')};
  border-radius: 4px;
  transition: color 0.3s ease-in;
`;

const Select = styled.div<{ isOpen: boolean; isTransparent: boolean }>`
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${({ isTransparent, isOpen, theme }) => (isTransparent ? (isOpen ? '#ffffff' : 'transparent') : theme.palette.bg)};
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin: 0;
  display: flex;
  border: ${({ isTransparent, isOpen, theme }) => (isTransparent
    ? isOpen
      ? `1px solid ${theme.palette.inactive}`
      : '1px solid transparent'
    : `1px solid ${theme.palette.inactive}`)};

  box-shadow: ${({ isOpen }) => isOpen && '0px 4px 4px rgba(0, 0, 0, 0.1)'};
  z-index: ${({ isOpen }) => isOpen && '10'};
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const ChosenOptionText = styled.div<{
  isTransparent: boolean
  isBlack: boolean
  isHideText: boolean
  userVariant: IVariant | null
  isTextCenter: boolean
}>`
  flex: 1;
  margin: 0;
  padding: 0 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  // padding-right: 5px;
  // padding-left: 5px;
  text-align: ${({ isTextCenter }) => isTextCenter && 'center'};

  /* color: ${({ theme, isTransparent, isBlack }) => isTransparent && (isBlack ? `${theme.palette.black}` : `${theme.palette.main}`)}; */
  color: #000000;
  opacity: ${({ userVariant }) => (userVariant ? '1' : '0.5')};

  ${({ isHideText }) => isHideText
    && css`
      color: transparent;
    `}
`;

const OptionsWrap = styled.div`
  width: 100%;
`;

const VariantsOptions = styled.div<{ height: number; isOpen?: boolean }>`
  max-height: ${({ height }) => `${height}px`};
  overflow: auto;
  background: #ffffff;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  margin: 3px -1px 0;

  border: ${({ isOpen }) => (isOpen ? '1px solid #DCDCDC' : 'none')};

  @media (hover: hover) {
    ${customScrollCss}
    & > div {
      transition: all 0.3s ease;
      &:hover {
        background: rgba(229, 229, 229, 0.4);
      }
    }
  }
`;

interface IIcon {
  isOpening: boolean
  isClosing: boolean
  isOpen: boolean
}

const IconDropDownWrap = styled.div<{ isOnLeft?: boolean }>`
  position: absolute;
  top: 50%;
  ${({ isOnLeft }) => !isOnLeft
    && css`
      right: 10px;
    `}
  ${({ isOnLeft }) => isOnLeft
    && css`
      left: 10px;
    `}
  transform: translateY(-50%);
  object-fit: contain;

  display: flex;
  align-items: center;
`;

const IconDropDown = styled.img<IIcon>`
  transition: all 0.3s ease 0s;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};

  ${({ isOpening }) => isOpening
    && css`
      animation: ${rotateIconOpenAnimation} 0.3s;
    `}

  ${({ isClosing }) => isClosing
    && css`
      animation: ${rotateIconCloseAnimation} 0.3s;
    `}
`;

const rotateIconOpenAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
`;

const rotateIconCloseAnimation = keyframes`
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
`;

const Option = styled.div<{
  isSemiBold: boolean
  isLeftIcon?: boolean
  isTextCenter: boolean
  minHeight: number
}>`
  position: relative;
  width: 100%;
  min-height: ${({ minHeight }) => minHeight - 2}px; // 2px of border
  padding: 0 25px 0 ${({ isLeftIcon }) => (isLeftIcon ? 25 : 10)}px;
  display: flex;
  justify-content: ${({ isTextCenter }) => isTextCenter && 'center'};
  align-items: center;
  font-weight: ${({ isSemiBold }) => isSemiBold && 500};
  transition: color 0.3s ease-in;

  // &:not(:first-child) {
  // padding-right: 30px;
  // }
`;

const OptionText = styled.div<{ isTransparent: boolean; isActive: boolean }>`
  margin: 0;
  padding: 6px 0;
  display: flex;
  align-items: center;

  // &:hover {
  //   color: #e5e5e5;
  // };
`;

const OptionIcon = styled.img`
  margin-right: 10px;
`;

export default DropDown;
