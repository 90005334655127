import { useMemo } from 'react';
import { IIncident, ITicket } from '@/api/main-protected';
import convertFromStringToDate, { FormatDate } from '@/utils/convertFromStringToDate';
import { groupBy } from '@/utils/groupBy';

export interface IItemToGroupedByDate<T> {
  date: string
  item: T
}

const groupedItemsByDate = (
  items: IItemToGroupedByDate<ITicket | IIncident>[],
  interval: number,
) => useMemo(() => {
  if (items.length === 0) return [];

  const incidentsSortedByDate = [...items].sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
  );

  const grouped = groupBy(
    incidentsSortedByDate,
    (i) => convertFromStringToDate(i.date, FormatDate.ShortMonthFormat),
  );

  return Object.entries(grouped);
}, [items, interval]);

export default groupedItemsByDate;
