const createCSVFileName = () => {
  const date = new Date();

  let dd = date.getDate() as number | string;
  let mm = date.getMonth() + 1 as number | string;
  const yyyy = date.getFullYear();
  let hours = date.getHours() as number | string;
  let mins = date.getMinutes() as number | string;
  let secs = date.getSeconds() as number | string;

  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (mins < 10) {
    mins = `0${mins}`;
  }
  if (secs < 10) {
    secs = `0${secs}`;
  }

  return `${yyyy}${mm}${dd}-${hours}${mins}${secs}-Xperienc`;
};

export default createCSVFileName;
