import React, { useState } from 'react';

import { RequestsChartItem } from '@/api/main-protected';

// import Chart from '@/Components/Requests/Chart';
import ChartTabsWrap from '@/Components/Requests/ChartTabsWrap';
import LoadingContainer from '@/Components/common/LoadingContainer';
import ChartOut from '@/Components/Requests/ChartOut';

interface IPieChartComponent {
  dataArr: RequestsChartItem[]
  arrNames: string[]
  tableTitles: string[]
  titles: string[]
  preTitle?: string
  isLoading: boolean
}

const PieChartTabsComponent: React.FC<IPieChartComponent> = ({
  dataArr,
  arrNames,
  tableTitles,
  titles,
  preTitle = '',
  isLoading,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <ChartTabsWrap
      titles={titles}
      preTitle={preTitle}
      isContentGrow
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
    >
      <LoadingContainer isLoading={isLoading}>
        {!isLoading && (
        <ChartOut
          itemsBy={dataArr[activeIndex][arrNames[activeIndex]]}
          total={dataArr[activeIndex].total}
          tableTitle={tableTitles[activeIndex]}
        />
        )}
      </LoadingContainer>
    </ChartTabsWrap>
  );
};

export default PieChartTabsComponent;
