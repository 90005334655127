import React, { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';

import TagManager from 'react-gtm-module';

import App from '@/App';

import store from '@/store';

import ThemeProviderComponent from '@/Components/common/ThemeProvider';

const GTMID = process.env.REACT_APP_GTMID;

if (GTMID !== '') {
  const tagManagerArgs = {
    gtmId: GTMID,
  };
  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProviderComponent>
          <App />
          <div id="ds-clickwrap" />
        </ThemeProviderComponent>
      </Provider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root'),
);
