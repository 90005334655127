import { ROLES } from '@/api/main';

export enum Permissions {
  EditUser = 'EditUser',
  CancelRequest = 'CancelRequest',
  Override = 'Override',
  Metrics = 'Metrics',
}

export type IPermission = {
  [user in ROLES]: {
    [permission in Permissions]: boolean
  }
};

export const usersPermissions: IPermission = {
  [ROLES.SUPER_ADMIN]: {
    [Permissions.EditUser]: true,
    [Permissions.CancelRequest]: true,
    [Permissions.Override]: true,
    [Permissions.Metrics]: true,
  },
  [ROLES.MANAGER]: {
    [Permissions.EditUser]: true,
    [Permissions.CancelRequest]: false,
    [Permissions.Override]: false,
    [Permissions.Metrics]: true,
  },
  [ROLES.USER]: {
    [Permissions.EditUser]: false,
    [Permissions.CancelRequest]: false,
    [Permissions.Override]: false,
    [Permissions.Metrics]: true,
  },
  [ROLES.DEVELOPER]: {
    [Permissions.EditUser]: false,
    [Permissions.CancelRequest]: false,
    [Permissions.Override]: false,
    [Permissions.Metrics]: true,
  },
  [ROLES.PARTNER]: {
    [Permissions.EditUser]: false,
    [Permissions.CancelRequest]: false,
    [Permissions.Override]: false,
    [Permissions.Metrics]: true,
  },
};

export const getPermissions = (
  userRole: ROLES,
  permission: Permissions,
): boolean => !!usersPermissions[userRole][permission];

export const setPermissions = (
  userRole: ROLES,
  permission: Permissions,
  value: boolean,
): void => {
  usersPermissions[userRole][permission] = value;
};

export const accessLevel: { [role in ROLES] : ROLES[] } = {
  [ROLES.SUPER_ADMIN]: [ROLES.USER, ROLES.MANAGER, ROLES.SUPER_ADMIN],
  [ROLES.MANAGER]: [ROLES.USER],
  [ROLES.USER]: [],
  [ROLES.DEVELOPER]: [],
  [ROLES.PARTNER]: [],
};

export const getUserLevelPermission = (
  currentUserRole: ROLES,
  userRole: ROLES,
): boolean => !!accessLevel[currentUserRole].some((role) => role === userRole);
