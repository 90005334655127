import React from 'react';
import styled from 'styled-components';
import { Moment } from 'moment';

import DropDown, { IVariant } from '@/Components/common/DropDown';
import LoadingContainer from '@/Components/common/LoadingContainer';
import Btn from '@/Components/common/Signin/Btn';
import DatePicker, { DatePickerAlign } from '@/Components/common/calendar/DatePicker';
import DropDownCheckBox from '@/Components/common/dropDown/DropDownCheckBox';

import { DefaultFilter, IMultiItemsFiltersLabel } from '@/Components/Items/ItemsTable/TableContainer';

export interface IDateFilters {
  [key: string]: IDateFilter
}

export interface IDateFilter {
  startDate: Moment | null,
  endDate: Moment | null,
  title: string
}

interface Props {
  isLoading: boolean;
  handleCancelClick: () => void;
  handleApplyClick: () => void;

  dateFilters: IDateFilters,
  onDateChoose: (key: string) => (dateFilter: IDateFilter) => void;

  filtersLabel: { [key: string]: string },
  choosenFilters: { [key: string]: IVariant };
  itemsFilters: { [filter: string]: string[] },
  onChoose: (key: string) => (variant: IVariant | null) => void;

  multiItemsFiltersLabel: IMultiItemsFiltersLabel,
  multiItemsFilters: { [filter: string]: string[] },
  choosenMultiItemsFilters: { [key: string]: string };
  onMultiItemsChoose: (key: string) => (value: string) => void;
}

const TableFilters: React.FC<Props> = ({
  isLoading,
  filtersLabel,
  itemsFilters,
  multiItemsFilters,
  choosenMultiItemsFilters,
  multiItemsFiltersLabel,
  choosenFilters,
  dateFilters,
  onDateChoose,
  onChoose,
  onMultiItemsChoose,
  handleCancelClick,
  handleApplyClick,
}) => {
  const allVariant: IVariant = {
    id: DefaultFilter.All,
    title: DefaultFilter.All,
  };

  const filters = Object.entries(itemsFilters).reduce((
    acc: { [key: string]: IVariant[] },
    [key, value],
  ) => {
    const filter: IVariant[] = value.map((el) => ({
      id: el,
      title: el,
    }));

    const res: { [key: string]: IVariant[] } = { [key]: [allVariant, ...filter] };

    return { ...acc, ...res };
  }, {} as { [key: string]: IVariant[] });

  const handleDateFilters = (key: string, dateKey: keyof IDateFilter) => (date: Moment | null) => {
    const newFilterDate = {
      ...dateFilters[key],
      [dateKey]: date,
    };

    onDateChoose(key)(newFilterDate);
  };

  return (
    <TableFiltersStyled>
      <LoadingContainer isLoading={isLoading}>
        <Filters>
          {Object.entries(filtersLabel).map(([key, title]) => (filters[key] ? (
            <DropDownWrap key={key}>
              <Label>{title}</Label>
              <DropDown
                variants={filters[key]}
                chosenOption={choosenFilters[key] || allVariant}
                onChoose={onChoose(key)}
                isSub
              />
            </DropDownWrap>
          ) : null))}
          {Object.entries(multiItemsFiltersLabel)
            .map(([key, { title }]) => (multiItemsFilters[key] ? (
              <DropDownWrap key={key}>
                <Label>{title}</Label>
                <DropDownCheckBox
                  dropItems={multiItemsFilters[key]}
                  onChange={onMultiItemsChoose(key)}
                  value={choosenMultiItemsFilters[key] || ''}
                  placeholder={title}
                />
              </DropDownWrap>
            ) : null))}
          {Object.entries(dateFilters).map(([key, { startDate, endDate, title }]) => (
            <DatePickerWrapper key={key}>
              <DatePickerLabel>{title}</DatePickerLabel>
              <DatePickerWraps>
                <DatePickerWrap>
                  <DatePicker
                    mainDate={startDate}
                    tempFromDate={startDate}
                    tempTillDate={endDate}
                    handleSetDate={handleDateFilters(key, 'startDate')}
                    align={DatePickerAlign.Left}
                    isFromDate
                  />
                </DatePickerWrap>
                <TO>to</TO>
                <DatePickerWrap>
                  <DatePicker
                    mainDate={endDate}
                    tempFromDate={startDate}
                    tempTillDate={endDate}
                    handleSetDate={handleDateFilters(key, 'endDate')}
                    align={DatePickerAlign.Right}
                    isFromDate={false}
                  />
                </DatePickerWrap>
              </DatePickerWraps>
            </DatePickerWrapper>
          ))}
        </Filters>
        <Btns>
          <BtnWrap>
            <Btn onClick={handleCancelClick} isTransparent>
              Cancel
            </Btn>
          </BtnWrap>
          <BtnWrap>
            <Btn onClick={handleApplyClick}>Apply</Btn>
          </BtnWrap>
        </Btns>
      </LoadingContainer>
    </TableFiltersStyled>
  );
};

const DatePickerLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-transform: capitalize;
  text-align: left;
  font-weight: 500;
  letter-spacing: 0.02em;
  width: 100%;
`;

const TO = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #4A4949;
`;

const DatePickerWraps = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const DatePickerWrap = styled.div`
  width: 130px;
`;

const Filters = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
`;

const DropDownWrap = styled.div`
  width: 200px;
  text-align: left;
  text-transform: capitalize;
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
`;

const Btns = styled.div`
  flex: 0 0 185px;
  display: flex;
  margin-top: 12px;
  margin-bottom: 15px;
`;

const BtnWrap = styled.div`
  flex: 0 0 82px;
  width: 82px;
  height: 35px;
  margin-left: 11px;

  & button {
    height: auto;
  }
`;

const TableFiltersStyled = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  padding: 20px 30px 15px 25px;
`;

export default TableFilters;
