import React from 'react';
import styled, { keyframes } from 'styled-components';

interface Props {
  position?: boolean
  width?: string
  height?: string
  color?: string
  size?: number
}

const LoaderDots: React.FC<Props> = ({ color, size = 10 }) => (
  <LoaderStyled>
    <Div color={color} size={size} />
    <Div color={color} size={size} />
    <Div color={color} size={size} />
  </LoaderStyled>
);

const scale = keyframes`
  0% {
    transform: scale(1);
    opacity: 1; 
  }
  24% {
    transform: scale(0.72);
    opacity: 0.3; 
  }
  48% {
    transform: scale(1);
    opacity: 1; 
  }
`;

const LoaderStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Div = styled.div<{ size: number, color?: string }>`
  background-color: ${({ color }) => color || '#ececec'};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 100%;
  margin: ${({ size }) => size / 3}px;
  animation-fill-mode: both;
  display: inline-block;

  &:nth-child(1) {
    animation: ${scale} 1.44s -0.24s infinite cubic-bezier(0, 0, 0.4, 1);
  }
  &:nth-child(2) {
    animation: ${scale} 1.44s -0.12s infinite cubic-bezier(0, 0, 0.4, 1);
  }
  &:nth-child(3) {
    animation: ${scale} 1.44s 0s infinite cubic-bezier(0, 0, 0.4, 1);
  }
`;

export default LoaderDots;
