import { useEffect, useState } from "react";

export interface ISectionItem {
  id: string
}

const getNestedSections = (sectionElements: HTMLElement[]) => {
  const nestedSections = [] as [] | ISectionItem[];

  sectionElements.forEach((section) => {
    const { id } = section;
    
    nestedSections.push({ id } as never);
    
  });

  return nestedSections;
};

const useSectionsData = ( blockName: string ) => {
  const [nestedSections, setNestedSections] = useState<ISectionItem[] | []>([]);

  useEffect(() => {
    const blockElement = document.querySelector(blockName)
    const elements = Array.from(
      blockElement!.querySelectorAll("section")
    );

    const newNestedSections = getNestedSections(elements);
    setNestedSections(newNestedSections);
  }, []);

  return { nestedSections };
};

export default useSectionsData