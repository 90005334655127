/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { AppDispatch } from '@/App';
import {
  eventsActions,
  getAdvancedReplacementByItemType,
  getAdvancedReplacementByReasonCode,
  getRequestsByActionDateAndStatus,
  getTasksByCategory,
  getTasksByService,
  getTasksByStatus,
} from '@/store/actions/events';
import {
  selectAdvancedReplacementByItemType,
  selectAdvancedReplacementByReasonCode,
  selectEventsArr,
  selectIncidentRequest,
  selectIncidentRequestState,
  selectModalAsset,
  selectModalNotes,
  selectRequestsByActionDateAndStatus,
  selectRequestsInterval,
  selectTasksByCategory,
  selectTasksByService,
  selectTasksByStatus,
} from '@/store/selectors/events';
import {
  IEvent, Request,
} from '@/api/main-protected';
import getUpdated from '@/utils/getUpdated';
import { Filter } from '@/local-storage/LocalStorage';
import { IVariant } from '@/Components/common/DropDown';
import { RequestState } from '@/store/reducers/common';

import ContentContainer from '@/Components/common/ContentContainer/ContentContainer';
import PieChartComponent from '@/Components/Requests/PieChartComponent';
import PieChartTabsComponent from '@/Components/Requests/PieChartTabsComponent';
import ModalItem from '@/Components/Requests/ModalItem';
import ActionDateChart from '@/Components/Requests/ActionDateChart';
import ModalNotesEvent from '@/Components/Requests/ModalNotesEvent';
import RequestContainer from '@/Components/Requests/RequestTable/RequestContainer';

export type IModalData = IEvent & Request;

interface IRequestsComponent {
  isExpanded: boolean;
}

export enum EventTabsNames {
  ALL_REQUESTS = 'all requests',
  URGENT = 'urgent',
  NEEDS_VERIFYING = 'needs verifying',
  IN_DEPLOY = 'in deploy',
  IN_TRANSIT = 'in transit',
  EVENTS = 'events',
}

export const REQUESTS = [
  EventTabsNames.ALL_REQUESTS,
  EventTabsNames.URGENT,
  EventTabsNames.NEEDS_VERIFYING,
  EventTabsNames.IN_DEPLOY,
  EventTabsNames.IN_TRANSIT,
  EventTabsNames.EVENTS,
];
export const TAB_LABELS = [EventTabsNames.ALL_REQUESTS, EventTabsNames.EVENTS];

export const DefaultFilter = {
  interval: Filter.ALL,
  phase: '',
  page: '1',
  perPage: '100',
  priority: 'all',
  status: 'all',
};

const Requests: React.FC<IRequestsComponent> = () => {
  const [actionsVariants, setActionsVariants] = useState<IVariant[] | null>(null);
  const events = useSelector(selectEventsArr);

  const tasksBycategory = useSelector(selectTasksByCategory);
  const tasksByStatus = useSelector(selectTasksByStatus);
  const tasksByService = useSelector(selectTasksByService);
  const requestsByActionDateAndStatus = useSelector(selectRequestsByActionDateAndStatus);
  const replacementByItemType = useSelector(selectAdvancedReplacementByItemType);
  const replacementByReasonCode = useSelector(selectAdvancedReplacementByReasonCode);
  const modalAsset = useSelector(selectModalAsset);
  const modalNotes = useSelector(selectModalNotes);

  const incidentRequest = useSelector(selectIncidentRequest);
  const requestState = useSelector(selectIncidentRequestState);

  const dispatch = useDispatch<AppDispatch>();
  const interval = useSelector(selectRequestsInterval);

  useEffect(() => {
    if (requestsByActionDateAndStatus) {
      const dropDownDates = Object.keys(requestsByActionDateAndStatus)
        .reduce((items, dateItem) => {
          const variantItem = { title: dateItem };
          items.push(variantItem);

          return items;
        }, [] as IVariant[])
        .reverse();

      setActionsVariants(dropDownDates);
    }
  }, [requestsByActionDateAndStatus]);

  useEffect(() => {
    dispatch(getTasksByCategory(interval));
    dispatch(getTasksByStatus(interval));
    dispatch(getTasksByService(interval));
    dispatch(getRequestsByActionDateAndStatus(interval));
    dispatch(getAdvancedReplacementByItemType(interval));
    dispatch(getAdvancedReplacementByReasonCode(interval));
  }, [interval]);

  useEffect(() => {
    if (events) {
      const updatedEvents = events.map((evItem) => {
        if (evItem.request.request_id === incidentRequest?.request_id) {
          return {
            ...evItem,
            items: incidentRequest?.items || [],
          };
        }
        return evItem;
      });
      dispatch(eventsActions.setEvents(updatedEvents));
    }
  }, [incidentRequest]);

  const onCloseModal = () => {
    dispatch(eventsActions.setIncident(null));
    dispatch(eventsActions.setEvent(null));
    dispatch(eventsActions.setIncidentEvent(null));
    dispatch(eventsActions.setModalAsset(null));
    dispatch(eventsActions.setModalNotes(null));
  };

  return (
    <>
      <ContentContainer title="Requests" updated={getUpdated(window.Date())} isExportCSV isIntervalFilter>
        <ColumnsTop>
          <Column>
            <PieChartTabsComponent
              dataArr={[tasksBycategory!, tasksByStatus!]}
              arrNames={['categories', 'top_statuses']}
              titles={['Requests by Category', 'Requests by Status']}
              tableTitles={['Category', 'Status']}
              isLoading={!tasksByStatus || !tasksBycategory}
            />
          </Column>
          <Column>
            <PieChartComponent
              data={tasksByService!}
              arrName="top_services"
              title="Shipments by Service Type"
              tableTitle="Service Type"
              isLoading={!tasksByService}
            />
          </Column>
        </ColumnsTop>
        <ColumnsTop>
          <Column>
            <ActionDateChart
              title="Action Date & Delivery Status"
              isDropDown
              variants={actionsVariants}
              requestsByActionDateAndStatus={requestsByActionDateAndStatus || {}}
              isLoading={!requestsByActionDateAndStatus}
            />
          </Column>
          <Column>
            <PieChartTabsComponent
              dataArr={[replacementByReasonCode!, replacementByItemType!]}
              arrNames={['top_data', 'top_data']}
              titles={['by Reason Code', 'by Item Type']}
              tableTitles={['Reason Code', 'Item Type']}
              preTitle="Advanced Replacements"
              isLoading={!replacementByItemType || !replacementByReasonCode}
            />
          </Column>
        </ColumnsTop>
      </ContentContainer>

      <RequestContainer interval={interval} />

      {!!modalNotes && (
        <ModalNotesEvent
          incidentRequest={modalNotes!}
          onCloseModal={onCloseModal}
          isLoading={
            requestState === RequestState.LOADING || !incidentRequest
          }
        />
      )}
      {!!modalAsset && (
        <ModalItem item={modalAsset} onCloseModal={onCloseModal} />
      )}
    </>
  );
};

const ColumnsTop = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0 0 22px 0;
`;

const Column = styled.div`
  flex: 0 0 calc(50% - 8px);
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1280px) {
    flex: 1 1 auto;
  }
`;

export default Requests;
