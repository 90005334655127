import React, { useEffect, useRef } from "react";

const useIntersectionObserver = (setActiveId: (key: string) => void, observedBlockName: string, topOffset: number) => {
  const sectionElementsRef = useRef({}) as any;
  useEffect(() => {
    const callback = (sections: React.ChangeEvent<HTMLElement>[]) => {
      sectionElementsRef.current = sections.reduce((map, sectionElement) => {
        const targetId = sectionElement.target.id
        map[targetId] = sectionElement;
        return map;
      }, sectionElementsRef.current);

      const visibleSections: any = [];
      Object.keys(sectionElementsRef.current).forEach((key) => {
        const sectionElement = sectionElementsRef.current[key];
        if (sectionElement.isIntersecting) visibleSections.push(sectionElement);
      });

      const getIndexFromId = (id: string) =>
        sectionElements.findIndex((section) => section.id === id);

      if (visibleSections.length === 1) {
        setActiveId(visibleSections[0].target.id);
      } else if (visibleSections.length > 1) {
        const sortedVisibleSections = visibleSections.slice().sort(
          (a: React.ChangeEvent<HTMLElement>, b: React.ChangeEvent<HTMLElement>) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        );
        setActiveId(sortedVisibleSections[0].target.id);
      }
    };

    const observer = new IntersectionObserver(callback as any, {
      rootMargin: `-${topOffset}px 0px -20% 0px`
      // rootMargin: "-30% 0px -40% 0px"
    });

    const observedBlock = document.querySelector(observedBlockName)
    const sectionElements = Array.from(observedBlock!.querySelectorAll("section"));

    sectionElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [setActiveId]);
};

export default useIntersectionObserver