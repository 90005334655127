import { createSelector, Selector } from 'reselect';

import { RequestState } from '@/store/reducers/common';
import { State } from '@/store';
import { IEventsSearch } from '@/api/main-protected';

const eventsSearch = (state: State) => state.EventsSearchReducer;

export const selectEventsSearch: Selector<State, IEventsSearch | null> = createSelector(
  eventsSearch,
  ({ globalSearch }) => globalSearch,
);

export const selectEventsSearchState: Selector<State, RequestState> = createSelector(
  eventsSearch,
  ({ globalSearchState }) => globalSearchState,
);
