import React, {
  Fragment, useEffect, useMemo, useRef, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import LoadingContainer from '@/Components/common/LoadingContainer';
import ViewAllComponent from '@/Components/common/ViewAll';
import ContentContainer from '@/Components/common/ContentContainer/ContentContainer';
import EventComponent from '@/Components/common/Event';
import MapLeafletContainer from '@/Components/common/MapLeafletContainer';
import { IVariant } from '@/Components/common/DropDown';
import FilterToggleComponent from '@/Components/common/FilterToggle';
import TableFilters from '@/Components/Items/ItemsTable/TableFilters';
import { DefaultFilter } from '@/Components/Items/ItemsTable/TableContainer';
import XLACell from '@/Components/common/ContentContainer/XLACell';
import XPICell from '@/Components/common/ContentContainer/XPICell';

import { AppDispatch } from '@/App';

import { IIncident } from '@/api/main-protected';

import {
  selectCCIncidents,
  selectCCIncidentsState,
  selectIncidentsFilters,
  selectIncidentsFiltersState,
  selectIncidentsTotal,
  selectRequestsInterval,
} from '@/store/selectors/events';
import { RequestState } from '@/store/reducers/common';
import {
  eventsActions, getCCIncidents, getIncidentsFilters, getIncidentsTotal,
} from '@/store/actions/events';
import { getMetrics } from '@/store/actions/utils';
import { selectMetrics, selectMetricsState } from '@/store/selectors/utils';

import customScrollCss from '@/utils/custom-scroll-css';
import getUpdated from '@/utils/getUpdated';
import { getQueries, incidentsPerPage as initialIncidentsPerPage } from '@/utils/api';
import { isEmptyObject } from '@/utils/validation';
import { sort, SortOrder, SortType } from '@/utils/sortUtil';
import { getPermissions, Permissions } from '@/utils/permissions';
import { selectUserInfo } from '@/store/selectors/user';
import groupedItemsByDate from '@/utils/groupedItemsByDate';
import formatTitleDate from '@/utils/formatTitleDate';

export const mainBlockHeight = '715px';

const ControlCenter: React.FC = () => {
  const [isFilterActive, setIsFilterActive] = useState(false);
  // const [eventsScrolling] = useState<number | null>(null);
  const [activeIncident, setActiveIncident] = useState<IIncident | null>(null);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [activeTile, setActiveTile] = useState<number>(0);
  const [savedFilters, setSavedFilters] = useState<{ [key: string]: IVariant }>({});
  const [choosenFilters, setChoosenFilters] = useState<{ [key: string]: IVariant }>({});

  const ref = useRef<HTMLDivElement | null>(null);
  const [metricsRef, setMetricsRef] = useState<HTMLDivElement | null>(null);

  const { roles } = useSelector(selectUserInfo)!;
  const incidents = useSelector(selectCCIncidents);
  const incidentsState = useSelector(selectCCIncidentsState);
  const incidentsFilters = useSelector(selectIncidentsFilters);
  const incidentsFiltersState = useSelector(selectIncidentsFiltersState);
  const incidentsTotal = useSelector(selectIncidentsTotal);
  const metrics = useSelector(selectMetrics);
  const metricsState = useSelector(selectMetricsState);

  const interval = useSelector(selectRequestsInterval);
  const dispatch = useDispatch<AppDispatch>();
  const [incidentsPerPage, setIncidentsPerPage] = useState<number>(initialIncidentsPerPage);

  const { search } = useLocation();
  const isMetricsOn = !!((new URLSearchParams(search).get('metrics') === 'on' || getPermissions(roles[0], Permissions.Metrics)));

  const isZoom = Object.keys(savedFilters).includes('locations');

  // const incidentsTotalState = useSelector(selectIncidentsTotalState);
  // const incidentsPaginationState = useSelector(selectCCIncidentsPaginationState);
  // const [currentPage, setCurrentPage] = useState<number>(1);

  const convertIncidents = incidents.map((inc) => ({
    date: inc.event.event_date,
    item: inc,
  }));

  const groupedIncidentsByDate = groupedItemsByDate(convertIncidents, interval);

  useEffect(() => {
    dispatch(eventsActions.setIncidentRequest(null));
    dispatch(getIncidentsFilters());
  }, []);

  useEffect(() => {
    if (isMetricsOn) {
      dispatch(getMetrics(interval));
    }
  }, [interval]);

  useEffect(() => {
    if (metrics.length) {
      setActiveTile(metrics[0].xla_id);
    }
  }, [metrics]);

  useEffect(() => {
    handleGetIncidents(savedFilters, incidentsPerPage, true, false);
  }, [interval]);

  const handleSetActiveTile = (tile: number) => {
    if (activeTile !== tile) {
      ref?.current?.scrollBy({ behavior: 'auto', left: -ref.current.scrollLeft });
    }

    setActiveTile(tile);
  };

  const handleGetIncidents = (
    selectedFilters: { [key: string]: IVariant },
    updatedIncidentsPerPage: number,
    // page: number = 1,
    isGetTotal = false,
    isSilent = true,
  ) => {
    const formatedFilters: { [key: string]: string } = Object.fromEntries(
      Object.entries(selectedFilters)
        .map(([key, { id }]) => [key, id!])
        .filter(([, id]) => id !== DefaultFilter.All),
    );

    const queries = getQueries({
      ...formatedFilters,
      // ...{ perPage: updatedIncidentsPerPage },
      // ...{ page },
    });

    if (isGetTotal) {
      dispatch(getIncidentsTotal(interval, queries));
    }

    // setCurrentPage(page);
    setIncidentsPerPage(updatedIncidentsPerPage);

    dispatch(getCCIncidents(interval, queries, { silent: isSilent }));
  };

  const filtersLabel: { [key: string]: string } = {
    categories: 'Event category',
    locations: 'Location',
  };

  const mapIncidentsWithLatLong = incidents?.filter(
    (incident) => incident.latitude && incident.longitude && incident,
  );

  const handleOnFilterClick = () => {
    setIsFilterActive(!isFilterActive);
    setChoosenFilters(savedFilters);
  };

  const appliedFiltersAmount = Object.keys(savedFilters).length;

  const onChoose = (key: string) => (variant: IVariant | null) => {
    if (!variant) {
      return;
    }

    setChoosenFilters((filter) => ({ ...filter, [key]: variant }));
  };

  const handleCancelClick = () => {
    if (appliedFiltersAmount) {
      setSavedFilters({});

      handleGetIncidents({}, incidentsPerPage, true, true);
    }
    setIsFilterActive(false);
  };

  const handleApplyClick = () => {
    const cleanFilters = Object.fromEntries(
      Object.entries(choosenFilters)
        .filter(([, { id }]) => id !== DefaultFilter.All),
    );

    setSavedFilters(cleanFilters);

    handleGetIncidents(cleanFilters, incidentsPerPage, true, true);

    setIsFilterActive(false);
  };

  // const handleCurrentPage = (page: number) => {
  //   handleGetIncidents(savedFilters, incidentsPerPage, page, false, true);
  // };

  // const handleSetIncidentsPerPage = (updatedIncidentsPerPage: number) => {
  //   handleGetIncidents(savedFilters, updatedIncidentsPerPage, 1, false, true);
  // };

  const sortedIncidentsFilters = useMemo(() => {
    const arrFilters = Object.entries(incidentsFilters).map(
      ([key, value]) => [key, sort(
        value,
        (sortedValue) => sortedValue,
        SortOrder.ASCENDING,
        SortType.String,
      )],
    );
    return Object.fromEntries(arrFilters);
  }, [incidentsFilters]);

  const activeMetrics = useMemo(
    () => metrics.find(({ xla_id }) => xla_id === activeTile)?.xpis || [],
    [metrics, activeTile],
  );

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollLeft: elementScrollLeft } = e.target as HTMLDivElement;
    setScrollLeft(elementScrollLeft || 0);
  };

  return (
    <>
      <ContentContainer title="Control Center" updated={getUpdated(window.Date())} isIntervalFilter>
        {isMetricsOn
        && (metricsState === RequestState.LOADED ? (
          <MetricsWrap>
            <CellWrap>
              {metrics.map(({
                value, xla_id, name, count,
              }) => (
                <XLACell
                  key={xla_id}
                  activeTile={activeTile}
                  setActiveTile={handleSetActiveTile}
                  value={value}
                  xla_id={xla_id}
                  name={name}
                  count={count}
                />
              )) }
            </CellWrap>
            {!!activeTile && (
            <CellWrap onScroll={handleScroll} ref={ref}>
              { activeMetrics.map(({
                value, xpi_id, name, description, count,
              }) => (
                <XPICell
                  key={xpi_id}
                  value={value}
                  xpi_id={xpi_id}
                  name={name}
                  description={description}
                  scrollLeft={scrollLeft}
                  count={count}
                />
              )) }
            </CellWrap>
            )}
          </MetricsWrap>
        )
          : (
            <MetricsWrap ref={(refWrap) => setMetricsRef(refWrap)}>
              <CellWrap>
                {
                  new Array(Math.ceil((metricsRef?.clientWidth || 0) / 370)).fill('').map((_, i) => (
                    <EmptyBigCell key={i}>
                      <LoadingContainer isLoading><></></LoadingContainer>
                    </EmptyBigCell>
                  ))
                }
              </CellWrap>
              <CellWrap>
                {
                  new Array(Math.ceil((metricsRef?.clientWidth || 0) / 270)).fill('').map((_, i) => (
                    <EmptySmallCell key={i}>
                      <LoadingContainer isLoading><></></LoadingContainer>
                    </EmptySmallCell>
                  ))
                }
              </CellWrap>
            </MetricsWrap>
          ))}
      </ContentContainer>

      <TitleContainer>
        <TitleWrap>
          <Title>Exceptions</Title>
          <Count>{incidentsTotal}</Count>
          <ViewAllWrap>
            <ViewAllComponent />
          </ViewAllWrap>
        </TitleWrap>
        <FilterWrap>
          {/* <PaginationSelect
            value={incidentsPerPage}
            onChange={handleSetIncidentsPerPage}
          />
          <Pagination
            currentPage={currentPage}
            itemsPerPage={incidentsPerPage}
            maxTotal={incidentsTotal}
            setCurrentPage={handleCurrentPage}
            isLoading={incidentsTotalState === RequestState.LOADING}
            isEmpty={!incidents.length}
            isArrowLoading={incidentsPaginationState !== RequestState.LOADED}
          /> */}
          <FilterToggleComponent
            onFilterIconClick={handleOnFilterClick}
            isFilterActive={isFilterActive}
            isActiveFilter={isEmptyObject(savedFilters)}
            appliedFiltersAmount={appliedFiltersAmount}
          />
        </FilterWrap>
      </TitleContainer>

      {isFilterActive && (
        <TableFilters
          isLoading={incidentsFiltersState !== RequestState.LOADED}
          filtersLabel={filtersLabel}
          itemsFilters={sortedIncidentsFilters}
          choosenFilters={choosenFilters}
          dateFilters={{}}
          onDateChoose={() => () => { }}
          onChoose={onChoose}
          handleCancelClick={handleCancelClick}
          handleApplyClick={handleApplyClick}
          multiItemsFiltersLabel={{}}
          multiItemsFilters={{}}
          choosenMultiItemsFilters={{}}
          onMultiItemsChoose={() => () => { }}
        />
      )}

      <Columns>
        <Column>
          <LoadingContainer isLoading={incidentsState !== RequestState.LOADED}>
            {incidents && incidents.length > 0 ? (
              groupedIncidentsByDate!.map((group) => (
                <Fragment key={group[0]}>
                  <TitleGroup>{formatTitleDate(group[0])}</TitleGroup>
                  {group[1].map(({ item }, i) => (
                    <EventComponent
                      key={group[1].toString() + i}
                      incident={item as IIncident}
                      setActiveIncident={setActiveIncident}
                    />
                  ))}
                </Fragment>
              ))
            ) : (
              <EmptyList>There are currently no incidents to display</EmptyList>
            )}
          </LoadingContainer>
        </Column>
        <MapLeafletContainer
          isZoom={isZoom}
          incidents={mapIncidentsWithLatLong}
          activeIncident={activeIncident}
        />
      </Columns>
    </>
  );
};

const EmptyBigCell = styled.div`
  width: 370px;
  min-width: 370px;
  height: 87px;
  border: 1px solid rgba(182, 182, 182, 0.5);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const EmptySmallCell = styled.div`
  width: 270px;
  min-width: 270px;
  height: 75px;
  border: 1px solid rgba(182, 182, 182, 0.5);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const MetricsWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-bottom: 6px;
  position: relative;
`;

const CellWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 14px;
  overflow-x: auto;
  padding-bottom: 6px;

  ${customScrollCss};
`;

const FilterWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const ViewAllWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin-left: 10px;
`;

const Count = styled.div`
  aspect-ratio: 1 / 1;
  min-width: 22px;
  background: #ff1a1a;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;

  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
`;

// const ViewAll = styled.div`
//   font-weight: 500;
//   font-size: 10px;
//   color: #0066FF;
// `;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  padding: 40px 0 22px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 22px;
`;
export const TitleGroup = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  padding: 9px;
  background: #d8d9d9;
  display: flex;
  justify-content: space-between;
`;

export const Columns = styled.div`
  display: flex;
  border: 1px solid rgba(196, 196, 196, 0.6);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
`;

export const Column = styled.div<{ width?: string }>`
  flex: 0 0 ${({ width }) => width ?? '290px'};
  width: ${({ width }) => width ?? '290px'};
  height: ${mainBlockHeight};
  overflow-y: auto;
  background: #f7f7f7;

  @media (hover: hover) {
    ${customScrollCss}
  }
`;

export const EmptyList = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #868484;
  margin: 30px 50px 30px 35px;
`;

export default ControlCenter;
