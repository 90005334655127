import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { RequestsByDateAndActivityStatus } from '@/api/main-protected';

import { IVariant } from '@/Components/common/DropDown';
import LoadingContainer from '@/Components/common/LoadingContainer';

import ChartWrap from '@/Components/Requests/ChartWrap';

interface IGraphItem {
  status: string
  total: number
  color: string
  bgColor: string
}

interface IActionDateChart {
  isDropDown?: boolean
  title: string
  variants: IVariant[] | null
  requestsByActionDateAndStatus: RequestsByDateAndActivityStatus
  isLoading: boolean;
}

const BG_COLORS = [
  '#59CC6C',
  '#FF33AD',
  '#3DD6DF',
  '#7B61FF',
  '#FFE600',
  '#FF7A00',
  '#C4C4C4',
  '#FF96D5',
];

const COLORS = [
  '#000000',
  '#FFFFFF',
  '#000000',
  '#FFFFFF',
  '#000000',
  '#FFFFFF',
  '#000000',
  '#000000',
];

const ActionDateChart: React.FC<IActionDateChart> = ({
  title,
  isDropDown,
  variants,
  requestsByActionDateAndStatus,
  isLoading,
}) => {
  const [variant, setVariant] = useState<IVariant | null>(null);
  const [data, setData] = useState<IGraphItem[] | null>(null);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    if (variants) {
      setVariant(variants[0]);
    }
  }, [variants]);

  useEffect(() => {
    const isEmptyObj = isEmpty(requestsByActionDateAndStatus);

    if (isEmptyObj) {
      setData(null);
      setTotal(0);
      return;
    }

    if (variant) {
      const graphData = requestsByActionDateAndStatus[variant.title].map((dataItem, i) => ({
        ...dataItem,
        color: COLORS[i],
        bgColor: BG_COLORS[i],
      }));

      const totalNumber = requestsByActionDateAndStatus[variant.title].reduce(
        (totalNum, item) => totalNum + item.total,
        0,
      );

      setData(graphData);
      setTotal(totalNumber);
    }
  }, [variant]);

  const onChoose: (varItem: IVariant | null) => void = (e) => {
    setVariant(e);
  };

  const isEmpty = (obj: Object) => Object.keys(obj).length === 0;

  return (
    <ChartWrap
      title={title}
      variant={variant!}
      variants={variants!}
      onChoose={onChoose}
      isDropDown={isDropDown}
    >
      <LoadingContainer isLoading={isLoading}>
        <Main>
          <LineChart>
            <ChartTitle>{variant?.title}</ChartTitle>
            <Chart>
              {data
              && data.length > 0
              && data.map((d) => (
                <Line
                  key={d.status}
                  width={(d.total / total) * 100}
                  color={d.color}
                  bgColor={d.bgColor}
                >
                  {d.total}
                </Line>
              ))}
              <Total>{total}</Total>
            </Chart>
          </LineChart>
          <DescBlock>
            <DeskTitle>Delivery status (groups)</DeskTitle>
            <Items>
              {data
              && data.length > 0
              && data.map((item, i) => (
                <Item key={item.status} color={BG_COLORS[i]}>
                  {item.status}
                </Item>
              ))}
            </Items>
          </DescBlock>
        </Main>
      </LoadingContainer>
    </ChartWrap>
  );
};

const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
`;
const LineChart = styled.div`
  flex: 1 0 auto;
  padding: 20px 0;
`;
const ChartTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  margin: 7px 0;
`;
const Chart = styled.div`
  flex: 0 1 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;
const Line = styled.div<{ width: number; color: string; bgColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: ${({ width }) => width}%;
  background: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  font-weight: 600;
  font-size: ${({ width }) => (width > 20 ? 16 : width < 5 ? 0 : (16 * width) / 15)}px;

  @media (max-width: 1280px) {
    font-size: ${({ width }) => (width > 10 ? 16 : width < 5 ? 0 : (16 * width) / 10)}px;
  }
`;
const Total = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  margin-left: 15px;
`;
const DescBlock = styled.div``;
const DeskTitle = styled.p`
  font-weight: 700;
  font-size: 10px;
  line-height: 24px;
`;
const Items = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const Item = styled.div<{ color: string }>`
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  padding: 0 5px 0 15px;
  text-transform: capitalize;

  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${({ color }) => color};
  }
`;

export default ActionDateChart;
