import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import IconComponent from '@/Components/common/IconComponent';
import LoaderDots from '@/Components/LoaderDots';
import EventsViewAllComponent from '@/Components/Requests/EventsViewAllComponent';

import { AppDispatch } from '@/App';
import { IEvent, Request, IAsset } from '@/api/main-protected';
import { eventsActions, getIncidentRequest } from '@/store/actions/events';

import playIcon from '@/assets/images/icons/play-arrow-icon.svg';
import notesIcon from '@/assets/images/icons/notes-icon.svg';

interface IExpandTableLine {
  req: Request;
  setItemModal?: (item: IAsset) => void;
  setIsEventModal?: () => void;
  setNotesModal?: (request: Request) => void;
}

const ExpandTableLine: React.FC<IExpandTableLine> = ({
  req,
  setItemModal,
  setNotesModal,
  setIsEventModal,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpening, setIsOpening] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (isOpen) {
      setIsOpening(false);
    }
  }, [isOpen]);

  const handleArrowClick = async () => {
    if (isOpen) {
      setIsOpen(false);
      return;
    }

    setIsOpening(true);

    if (req.events) {
      // set given request for loading
      await dispatch(eventsActions.setIncidentRequest({ ...req, isModal: false }));
      setIsOpen(true);
      return;
    }

    // fetch full request info
    await dispatch(getIncidentRequest(req.event_id, true, { ...req, isModal: false }));
    setIsOpen(true);
  };

  const handleNotesLocalClick = (request: Request) => {
    if (setNotesModal) {
      setNotesModal(request);
    }

    dispatch(eventsActions.setIncidentRequest(request));
  };

  // need to be moved to EventsViewAllComponent
  const handleEventClick = (event: IEvent, request: Request) => {
    dispatch(eventsActions.setIncidentRequest(request));
    dispatch(eventsActions.setIncidentEvent(event));

    if (setIsEventModal) {
      setIsEventModal();
    }
  };

  const handleItemClick = (item: IAsset) => {
    if (setItemModal) {
      setItemModal(item);
    }
  };

  return (
    <>
      <ArrowImg onClick={handleArrowClick} isOpen={isOpen}>
        {!isOpening ? <img src={playIcon} alt="play" /> : <LoaderDots />}
      </ArrowImg>

      {isOpen && (
        <Wrap isOpen={isOpen}>
          <Row>
            {/* <Column>
            <Title>REQUESTOR</Title>
            <Text>{req.requestor || '-'}</Text>
            <Text>{`-`}</Text>
            <Text>{`-`}</Text>
          </Column> */}
            <UsersColumn>
              <Title>Impacted user(s)</Title>
              {req.impacted_user
              && Object.values(req.impacted_user).filter((val) => !!val).length > 0 ? (
                <ImpactedUser>
                  <Text>{`${req.impacted_user.first_name}  ${req.impacted_user.last_name}`}</Text>
                  <Text isNoCapitalize>{req.impacted_user.email}</Text>
                  <Text>{req.impacted_user.phone}</Text>
                </ImpactedUser>
                ) : (
                  <Text>N/A</Text>
                )}
            </UsersColumn>
            <Column>
              <Title>Action date</Title>
              <Text>{req.action_date || 'N/A'}</Text>
            </Column>
            <Column>
              <Title>Department</Title>
              <Text>{req.agency || 'N/A'}</Text>
            </Column>
            {/* <Column>
            <Title>ASSociated PlAYBOOKS</Title>
            <Text isBlue>{req.playbook.replaceAll('-', ' ')}</Text>
          </Column> */}
            <ItemsColumn>
              <Title>ITEMS - Serial Number</Title>
              {req.items?.length > 0
                ? req.items.map((item) => (
                  <Text key={item.asset_tag} isBlue isLink onClick={() => handleItemClick(item)}>
                    {`${item.model} - ${item.serial_number}`}
                  </Text>
                ))
                : req.serial_numbers.map((num, i) => <Text key={i}>{num || 'N/A'}</Text>)}
            </ItemsColumn>
            <EventsViewAllComponent
              req={req}
              handleEventClick={handleEventClick}
            />
            {/* <Column>
            <Title>INCIDENTS</Title>
            {req.incidents?.length > 0 &&
              req.incidents.map(item => (
                <Text key={item.incident_id} isBlue>
                  {item.incident_id}
                </Text>
              ))}
          </Column> */}
            <Column>
              <Title>NOTES</Title>
              <NotesIcon>
                <IconBox onClick={() => handleNotesLocalClick(req)}>
                  <IconComponent icon={notesIcon} width={23} />
                </IconBox>
              </NotesIcon>
            </Column>
          </Row>
        </Wrap>
      )}
    </>
  );
};

const Wrap = styled.div<{ isOpen: boolean; isExpanded?: boolean }>`
  position: relative;
  z-index: 2;

  // flex: 0 1 1075px; // with container
  // width: 1075px; // with container
  flex: 0 0 ${({ isExpanded }) => (!isExpanded ? 'calc(100vw - 350px)' : 'calc(100vw - 245px)')};
  width: ${({ isExpanded }) => (!isExpanded ? 'calc(100vw - 350px)' : 'calc(100vw - 245px)')};

  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  transition: ${({ isOpen }) => (isOpen ? 'all 0.3s ease' : 'none')};

  // margin: ${({ isOpen }) => (isOpen ? '8px 0 28px 36px' : '8px 0 0px 36px')}; // with container
  margin: ${({ isOpen }) => (isOpen ? '55px 0 28px 36px' : '8px 0 0px 36px')};
  padding: 10px 0 0;
  // border-top: 1px solid #e5e5e5; // with container
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #e5e5e5;
  }

  @media (max-width: 1650px) {
    flex: 0 0 ${({ isExpanded }) => (!isExpanded ? 1500 : 1290)}px; // with expanded
    width: ${({ isExpanded }) => (!isExpanded ? 1500 : 1290)}px; // with expanded
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Column = styled.div`
  &:last-child {
    flex: 0 0 90px;
    width: 90px;
  }
`;

const UsersColumn = styled.div`
  flex: 0 0 215px;
  width: 215px;
`;
const ItemsColumn = styled.div`
  flex: 0 0 185px;
  width: 185px;
`;

const Title = styled.h5`
  font-weight: 600;
  font-size: 14px;
  /* line-height: 37px; */
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding: 0 0 10px 0;
`;
const Text = styled.p<{ isBlue?: boolean; isLink?: boolean; isNoCapitalize?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: ${({ isBlue }) => (isBlue ? '#0066FF' : '#000000')};
  text-transform: ${({ isNoCapitalize }) => (isNoCapitalize ? 'none' : 'capitalize')};

  ${({ isLink }) => isLink
    && css`
    @media (hover: hover) {
      cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  `}
`;
const ArrowImg = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 10px;
  left: 15px;
  display: flex;
  align-items: center;
  transform: rotate(${({ isOpen }) => (isOpen ? 90 : 0)}deg);
  transition: all 0.3s ease;
  cursor: pointer;

  & img {
    max-width: 100%;
  }

  // loading dots
  & > div {
    height: 13px;
  }
  & > div > div {
    width: 5px;
    height: 5px;
    margin: 1px;
  }
`;
const NotesIcon = styled.div<{ isDisabled?: boolean }>`
  padding-left: 15px;
  & img {
    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  }
`;

const IconBox = styled.div``;

const ImpactedUser = styled.div`
  margin-bottom: 10px;
`;

export default ExpandTableLine;
