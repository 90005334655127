import React, { useState } from 'react'
import styled from 'styled-components'

import { TABS } from './PersonaManager'
import DropDown, { IVariant } from '../common/DropDown'
import useToggle from '@/Components/hooks/useToggle'

import profileDefaultIcon from '@/assets/images/icons/user-settings/user-profile-default-icon.svg'
import editIcon from '@/assets/images/icons/edit-icon.svg'
import prevIcon from '@/assets/images/icons/prev-arrow-icon.svg'
import closeIcon from '@/assets/images/icons/close-icon.svg'

import Input from '@/Components/common/Input'
import useInput from '@/Components/hooks/useInput'
import Checkbox from '@/Components/common/Checkbox'
import Btn from '@/Components/common/Signin/Btn'
import IconComponent from '../common/IconComponent'
import InputFile from '../common/InputFile'
import PhoneMultiInput, { PHONE_TYPE_VARIANTS } from '../common/PhoneMultiInput'
import Button from '@/Components/common/Signin/Btn'
import RadioButton from '../common/RadioButton'

interface IAddNewUserForm {
  activeTab: number
  // handleClick: () => void
  // onNextBtnClick: () => void
}

const HOURS = [
  { title: '00 hrs' },
  { title: '01 hrs' },
  { title: '02 hrs' },
  { title: '03 hrs' },
  { title: '04 hrs' },
  { title: '05 hrs' },
  { title: '06 hrs' },
  { title: '07 hrs' },
  { title: '08 hrs' },
  { title: '09 hrs' },
  { title: '10 hrs' },
  { title: '11 hrs' },
  { title: '12 hrs' }
]

const syxtyNums = () => {
  let sixsty: { title: string }[] = []
  for (let index = 0; index < +60; index++) {
    if (index.toString().length === 1) {
      sixsty.push({ title: `0${index}` })
    } else {
      sixsty.push({ title: `${index}` })
    }
  }
  return sixsty
}

const MINUTES = syxtyNums().map(item => ({ title: `${item.title} min` }))
const SECONDS = syxtyNums().map(item => ({ title: `${item.title} sec` }))

const RADIOBTNS = [
  { value: 'less1k', label: '< $999' },
  { value: '1k', label: '$1,000 - 1,499' },
  { value: '1.5k', label: '$1,500+' }
]

const PMTabs: React.FC<IAddNewUserForm> = ({ activeTab }) => {
  const [defaultName, setDefaultName] = useInput(TABS[0].title)
  const [defaultIdentNum, setDefaultIdentNum] = useInput(TABS[0].tab.toString())
  const [emailH, setEmailH] = useState<IVariant | null>(HOURS[0])
  const [emailM, setEmailM] = useState<IVariant | null>(MINUTES[0])
  const [emailS, setEmailS] = useState<IVariant | null>(SECONDS[0])
  const [chatH, setChatH] = useState<IVariant | null>(HOURS[0])
  const [chatM, setChatM] = useState<IVariant | null>(MINUTES[0])
  const [chatS, setChatS] = useState<IVariant | null>(SECONDS[0])
  const [phoneH, setPhoneH] = useState<IVariant | null>(HOURS[0])
  const [phoneM, setPhoneM] = useState<IVariant | null>(MINUTES[0])
  const [phoneS, setPhoneS] = useState<IVariant | null>(SECONDS[0])
  const [l1H, setL1H] = useState<IVariant | null>(HOURS[0])
  const [l1M, setL1M] = useState<IVariant | null>(MINUTES[0])
  const [l1S, setL1S] = useState<IVariant | null>(SECONDS[0])
  const [l2H, setL2H] = useState<IVariant | null>(HOURS[0])
  const [l2M, setL2M] = useState<IVariant | null>(MINUTES[0])
  const [l2S, setL2S] = useState<IVariant | null>(SECONDS[0])
  const [l3H, setL3H] = useState<IVariant | null>(HOURS[0])
  const [l3M, setL3M] = useState<IVariant | null>(MINUTES[0])
  const [l3S, setL3S] = useState<IVariant | null>(SECONDS[0])
  // shipping speed checkboxes
  const [isGround, setIsGround] = useToggle(true)
  const [isOvernight, setIsOvernight] = useToggle(true)
  const [isInternational, setIsInternational] = useToggle(true)
  // Authorized Contact Channels checkboxes
  const [isBusinessEmail, setIsBusinessEmail] = useToggle(true)
  const [isBusinessCell, setIsBusinessCell] = useToggle(true)
  const [isPersonalEmail, setIsPersonalEmail] = useToggle(true)
  const [isPersonalCell, setIsPersonalCell] = useToggle(true)
  // Authorized Time to Contact checkboxes
  const [is24Hours, setIs24Hours] = useToggle(false)
  const [isBusinessHours, setIsBusinessHours] = useToggle(true)
  const [isExtendedHours, setIsExtendedHours] = useToggle(true)
  //  Pre-Authorized Hardware Value checkboxes
  const [preAuthHardwareValue, setPreAuthHardwareValue] = useState(RADIOBTNS[2].value)
  // Pre-authorized Hardware Types checkboxes
  const [isLaptop, setIsLaptop] = useToggle(true)
  const [isDesktop, setIsDesktop] = useToggle(true)
  const [isMobile, setIsMobile] = useToggle(true)
  const [isTablet, setIsTablet] = useToggle(true)
  const [isMonitor, setIsMonitor] = useToggle(true)
  const [isHeadset, setIsHeadset] = useToggle(true)
  const [isOthers, setIsOthers] = useToggle(true)

  // executive tab
  const [executiveName, setExecutiveName] = useInput(TABS[1].title)
  const [executiveIdentNum, setExecutiveIdentNum] = useInput(TABS[1].tab.toString())
  const [emailH_E, setEmailH_E] = useState<IVariant | null>(HOURS[0])
  const [emailM_E, setEmailM_E] = useState<IVariant | null>(MINUTES[0])
  const [emailS_E, setEmailS_E] = useState<IVariant | null>(SECONDS[0])
  const [chatH_E, setChatH_E] = useState<IVariant | null>(HOURS[0])
  const [chatM_E, setChatM_E] = useState<IVariant | null>(MINUTES[0])
  const [chatS_E, setChatS_E] = useState<IVariant | null>(SECONDS[0])
  const [phoneH_E, setPhoneH_E] = useState<IVariant | null>(HOURS[0])
  const [phoneM_E, setPhoneM_E] = useState<IVariant | null>(MINUTES[0])
  const [phoneS_E, setPhoneS_E] = useState<IVariant | null>(SECONDS[0])
  const [l1H_E, setL1H_E] = useState<IVariant | null>(HOURS[0])
  const [l1M_E, setL1M_E] = useState<IVariant | null>(MINUTES[0])
  const [l1S_E, setL1S_E] = useState<IVariant | null>(SECONDS[0])
  const [l2H_E, setL2H_E] = useState<IVariant | null>(HOURS[0])
  const [l2M_E, setL2M_E] = useState<IVariant | null>(MINUTES[0])
  const [l2S_E, setL2S_E] = useState<IVariant | null>(SECONDS[0])
  const [l3H_E, setL3H_E] = useState<IVariant | null>(HOURS[0])
  const [l3M_E, setL3M_E] = useState<IVariant | null>(MINUTES[0])
  const [l3S_E, setL3S_E] = useState<IVariant | null>(SECONDS[0])
  // shipping speed checkboxes
  const [isGround_E, setIsGround_E] = useToggle(true)
  const [isOvernight_E, setIsOvernight_E] = useToggle(true)
  const [isInternational_E, setIsInternational_E] = useToggle(true)
  // Authorized Contact Channels checkboxes
  const [isBusinessEmail_E, setIsBusinessEmail_E] = useToggle(true)
  const [isBusinessCell_E, setIsBusinessCell_E] = useToggle(true)
  const [isPersonalEmail_E, setIsPersonalEmail_E] = useToggle(true)
  const [isPersonalCell_E, setIsPersonalCell_E] = useToggle(true)
  // Authorized Time to Contact checkboxes
  const [is24Hours_E, setIs24Hours_E] = useToggle(false)
  const [isBusinessHours_E, setIsBusinessHours_E] = useToggle(true)
  const [isExtendedHours_E, setIsExtendedHours_E] = useToggle(true)
  //  Pre-Authorized Hardware Value checkboxes
  const [preAuthHardwareValue_E, setPreAuthHardwareValue_E] = useState(RADIOBTNS[2].value)
  // Pre-authorized Hardware Types checkboxes
  const [isLaptop_E, setIsLaptop_E] = useToggle(true)
  const [isDesktop_E, setIsDesktop_E] = useToggle(true)
  const [isMobile_E, setIsMobile_E] = useToggle(true)
  const [isTablet_E, setIsTablet_E] = useToggle(true)
  const [isMonitor_E, setIsMonitor_E] = useToggle(true)
  const [isHeadset_E, setIsHeadset_E] = useToggle(true)
  const [isOthers_E, setIsOthers_E] = useToggle(true)

  const radioChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (activeTab === TABS[0].tab) {
      setPreAuthHardwareValue(e.target.value)
      return
    }
    setPreAuthHardwareValue_E(e.target.value)
  }

  return (
    <Wrap isFirstTab={activeTab === TABS[0].tab}>
      {activeTab === TABS[0].tab && (
        <Column>
          <Block>
            <BlockTitle>Persona Details</BlockTitle>
            <Row jc='space-between' isTabWrap>
              <Row gap='19px'>
                <Item width={185}>
                  <ItemLabel>Persona Name</ItemLabel>
                  <Input
                    id='persona-default-name'
                    name='persona-default-name'
                    value={defaultName}
                    onChange={setDefaultName}
                    isNoMargin
                  />
                </Item>
                <Item width={95}>
                  <ItemLabel>Identifier No.</ItemLabel>
                  <Input
                    id='persona-default-name'
                    name='persona-default-name'
                    type='number'
                    value={defaultIdentNum}
                    onChange={setDefaultIdentNum}
                    isNoMargin
                  />
                </Item>
              </Row>
              <Row gap='10px'>
                <Button isTransparent>Edit</Button>
                <Button isTransparent>Delete</Button>
              </Row>
            </Row>
          </Block>
          <Row gap='25px' isWrap>
            <Column>
              <Row>
                <Block>
                  <BlockTitle>Service Levels</BlockTitle>
                  <Row gap='40px' isTabWrap>
                    <SubBlock>
                      <SubBlockTitle>Mean time to acknowledge (MTTA)</SubBlockTitle>
                      <Item width={270}>
                        <ItemLabel>Email</ItemLabel>
                        <Row gap='7px'>
                          <DropdownBox>
                            <DropDown
                              variants={HOURS}
                              chosenOption={emailH}
                              onChoose={setEmailH}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={MINUTES}
                              chosenOption={emailM}
                              onChoose={setEmailM}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={SECONDS}
                              chosenOption={emailS}
                              onChoose={setEmailS}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                        </Row>
                      </Item>
                      <Item width={270}>
                        <ItemLabel>Chat</ItemLabel>
                        <Row gap='7px'>
                          <DropdownBox>
                            <DropDown
                              variants={HOURS}
                              chosenOption={chatH}
                              onChoose={setChatH}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={MINUTES}
                              chosenOption={chatM}
                              onChoose={setChatM}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={SECONDS}
                              chosenOption={chatS}
                              onChoose={setChatS}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                        </Row>
                      </Item>
                      <Item width={270}>
                        <ItemLabel>Phone</ItemLabel>
                        <Row gap='7px'>
                          <DropdownBox>
                            <DropDown
                              variants={HOURS}
                              chosenOption={phoneH}
                              onChoose={setPhoneH}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={MINUTES}
                              chosenOption={phoneM}
                              onChoose={setPhoneM}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={SECONDS}
                              chosenOption={phoneS}
                              onChoose={setPhoneS}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                        </Row>
                      </Item>
                    </SubBlock>
                    <SubBlock>
                      <SubBlockTitle>Mean time to resolve (MTTR)</SubBlockTitle>
                      <Item width={270}>
                        <ItemLabel>L1 Incident</ItemLabel>
                        <Row gap='7px'>
                          <DropdownBox>
                            <DropDown
                              variants={HOURS}
                              chosenOption={l1H}
                              onChoose={setL1H}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={MINUTES}
                              chosenOption={l1M}
                              onChoose={setL1M}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={SECONDS}
                              chosenOption={l1S}
                              onChoose={setL1S}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                        </Row>
                      </Item>
                      <Item width={270}>
                        <ItemLabel>L2 Incident</ItemLabel>
                        <Row gap='7px'>
                          <DropdownBox>
                            <DropDown
                              variants={HOURS}
                              chosenOption={l2H}
                              onChoose={setL2H}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={MINUTES}
                              chosenOption={l2M}
                              onChoose={setL2M}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={SECONDS}
                              chosenOption={l2S}
                              onChoose={setL2S}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                        </Row>
                      </Item>
                      <Item width={270}>
                        <ItemLabel>L3 Incident</ItemLabel>
                        <Row gap='7px'>
                          <DropdownBox>
                            <DropDown
                              variants={HOURS}
                              chosenOption={l3H}
                              onChoose={setL3H}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={MINUTES}
                              chosenOption={l3M}
                              onChoose={setL3M}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={SECONDS}
                              chosenOption={l3S}
                              onChoose={setL3S}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                        </Row>
                      </Item>
                    </SubBlock>
                  </Row>
                  <SubBlock>
                    <SubBlockTitle>Shipping Speed</SubBlockTitle>
                    <Item width={580}>
                      <Row gap='0px' isTabWrap>
                        <Checkbox
                          onCheck={setIsGround}
                          isChecked={isGround}
                          isRoundCorners
                          isGrey
                          noMargins
                        >
                          Ground (3-5 days)
                        </Checkbox>
                        <Checkbox
                          onCheck={setIsOvernight}
                          isChecked={isOvernight}
                          isRoundCorners
                          isGrey
                          noMargins
                        >
                          Overnight (Next day)
                        </Checkbox>
                        <Checkbox
                          onCheck={setIsInternational}
                          isChecked={isInternational}
                          isRoundCorners
                          isGrey
                          noMargins
                        >
                          International(DHL, etc)
                        </Checkbox>
                      </Row>
                    </Item>
                  </SubBlock>
                </Block>
              </Row>
              <Block>
                <BlockTitle>Communication</BlockTitle>
                <Row gap='20px' isTabWrap>
                  <SubBlock>
                    <SubBlockTitle>Authorized Contact Channels</SubBlockTitle>
                    <Item width={280}>
                      <Row gap='20px'>
                        <Checkbox
                          onCheck={setIsBusinessEmail}
                          isChecked={isBusinessEmail}
                          isRoundCorners
                          isGrey
                          noMargins
                        >
                          Business email
                        </Checkbox>
                        <Checkbox
                          onCheck={setIsBusinessCell}
                          isChecked={isBusinessCell}
                          isRoundCorners
                          isGrey
                          noMargins
                        >
                          Business cell
                        </Checkbox>
                      </Row>
                      <Row gap='20px'>
                        <Checkbox
                          onCheck={setIsPersonalEmail}
                          isChecked={isPersonalEmail}
                          isRoundCorners
                          isGrey
                          noMargins
                        >
                          Personal email
                        </Checkbox>
                        <Checkbox
                          onCheck={setIsPersonalCell}
                          isChecked={isPersonalCell}
                          isRoundCorners
                          isGrey
                          noMargins
                        >
                          Personal cell
                        </Checkbox>
                      </Row>
                    </Item>
                  </SubBlock>
                  <SubBlock>
                    <SubBlockTitle>Authorized Time to Contact</SubBlockTitle>
                    <Item width={280}>
                      <Checkbox
                        onCheck={setIs24Hours}
                        isChecked={is24Hours}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        24 hours
                      </Checkbox>
                      <Checkbox
                        onCheck={setIsBusinessHours}
                        isChecked={isBusinessHours}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        Business hours: Mon-Fri, 9am-5pm (local)
                      </Checkbox>
                      <Checkbox
                        onCheck={setIsExtendedHours}
                        isChecked={isExtendedHours}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        Extended hours: 7am-9pm (local)
                      </Checkbox>
                    </Item>
                  </SubBlock>
                </Row>
              </Block>
            </Column>
            <Column>
              <Block>
                <BlockTitle>Authorized Kit Types</BlockTitle>
                <SubBlock>
                  <SubBlockTitle>Pre-Authorized Hardware Value</SubBlockTitle>
                  <Item width={295}>
                    {RADIOBTNS.map(radio => (
                      <RadioButton
                        key={radio.value}
                        id={radio.value}
                        label={radio.label}
                        isSelected={preAuthHardwareValue === radio.value}
                        changed={radioChangeHandler}
                        value={radio.value}
                      />
                    ))}
                  </Item>
                </SubBlock>
                <SubBlock>
                  <SubBlockTitle>Pre-authorized Hardware Types</SubBlockTitle>
                  <Item width={295}>
                    <Row gap='0px'>
                      <Checkbox
                        onCheck={setIsLaptop}
                        isChecked={isLaptop}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        Laptop
                      </Checkbox>
                      <Checkbox
                        onCheck={setIsMonitor}
                        isChecked={isMonitor}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        Monitor
                      </Checkbox>
                    </Row>
                    <Row gap='0px'>
                      <Checkbox
                        onCheck={setIsDesktop}
                        isChecked={isDesktop}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        Desktop
                      </Checkbox>
                      <Checkbox
                        onCheck={setIsHeadset}
                        isChecked={isHeadset}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        Headset
                      </Checkbox>
                    </Row>
                    <Row gap='0px'>
                      <Checkbox
                        onCheck={setIsMobile}
                        isChecked={isMobile}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        Mobile
                      </Checkbox>
                      <Checkbox
                        onCheck={setIsOthers}
                        isChecked={isOthers}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        Other Accessories
                      </Checkbox>
                    </Row>
                    <Row gap='0px'>
                      <Checkbox
                        onCheck={setIsTablet}
                        isChecked={isTablet}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        Tablet
                      </Checkbox>
                    </Row>
                  </Item>
                </SubBlock>
              </Block>
            </Column>
          </Row>
        </Column>
      )}
      {activeTab === TABS[1].tab && (
        <Column>
          <Block>
            <BlockTitle>Persona Details</BlockTitle>
            <Row jc='space-between' isTabWrap>
              <Row gap='19px'>
                <Item width={185}>
                  <ItemLabel>Persona Name</ItemLabel>
                  <Input
                    id='persona-executive-name'
                    name='persona-executive-name'
                    value={executiveName}
                    onChange={setExecutiveName}
                    isNoMargin
                  />
                </Item>
                <Item width={95}>
                  <ItemLabel>Identifier No.</ItemLabel>
                  <Input
                    id='persona-executive-name'
                    name='persona-executive-name'
                    type='number'
                    value={executiveIdentNum}
                    onChange={setExecutiveIdentNum}
                    isNoMargin
                  />
                </Item>
              </Row>
              <Row gap='10px'>
                <Button isTransparent>Edit</Button>
                <Button isTransparent>Delete</Button>
              </Row>
            </Row>
          </Block>
          <Row gap='25px' isWrap>
            <Column>
              <Row>
                <Block>
                  <BlockTitle>Service Levels</BlockTitle>
                  <Row gap='40px' isTabWrap>
                    <SubBlock>
                      <SubBlockTitle>Mean time to acknowledge (MTTA)</SubBlockTitle>
                      <Item width={270}>
                        <ItemLabel>Email</ItemLabel>
                        <Row gap='7px'>
                          <DropdownBox>
                            <DropDown
                              variants={HOURS}
                              chosenOption={emailH_E}
                              onChoose={setEmailH_E}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={MINUTES}
                              chosenOption={emailM_E}
                              onChoose={setEmailM_E}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={SECONDS}
                              chosenOption={emailS_E}
                              onChoose={setEmailS_E}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                        </Row>
                      </Item>
                      <Item width={270}>
                        <ItemLabel>Chat</ItemLabel>
                        <Row gap='7px'>
                          <DropdownBox>
                            <DropDown
                              variants={HOURS}
                              chosenOption={chatH_E}
                              onChoose={setChatH_E}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={MINUTES}
                              chosenOption={chatM_E}
                              onChoose={setChatM_E}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={SECONDS}
                              chosenOption={chatS_E}
                              onChoose={setChatS_E}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                        </Row>
                      </Item>
                      <Item width={270}>
                        <ItemLabel>Phone</ItemLabel>
                        <Row gap='7px'>
                          <DropdownBox>
                            <DropDown
                              variants={HOURS}
                              chosenOption={phoneH_E}
                              onChoose={setPhoneH_E}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={MINUTES}
                              chosenOption={phoneM_E}
                              onChoose={setPhoneM_E}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={SECONDS}
                              chosenOption={phoneS_E}
                              onChoose={setPhoneS_E}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                        </Row>
                      </Item>
                    </SubBlock>
                    <SubBlock>
                      <SubBlockTitle>Mean time to resolve (MTTR)</SubBlockTitle>
                      <Item width={270}>
                        <ItemLabel>L1 Incident</ItemLabel>
                        <Row gap='7px'>
                          <DropdownBox>
                            <DropDown
                              variants={HOURS}
                              chosenOption={l1H_E}
                              onChoose={setL1H_E}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={MINUTES}
                              chosenOption={l1M_E}
                              onChoose={setL1M_E}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={SECONDS}
                              chosenOption={l1S_E}
                              onChoose={setL1S_E}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                        </Row>
                      </Item>
                      <Item width={270}>
                        <ItemLabel>L2 Incident</ItemLabel>
                        <Row gap='7px'>
                          <DropdownBox>
                            <DropDown
                              variants={HOURS}
                              chosenOption={l2H_E}
                              onChoose={setL2H_E}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={MINUTES}
                              chosenOption={l2M_E}
                              onChoose={setL2M_E}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={SECONDS}
                              chosenOption={l2S_E}
                              onChoose={setL2S_E}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                        </Row>
                      </Item>
                      <Item width={270}>
                        <ItemLabel>L3 Incident</ItemLabel>
                        <Row gap='7px'>
                          <DropdownBox>
                            <DropDown
                              variants={HOURS}
                              chosenOption={l3H_E}
                              onChoose={setL3H_E}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={MINUTES}
                              chosenOption={l3M_E}
                              onChoose={setL3M_E}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                          <DropdownBox>
                            <DropDown
                              variants={SECONDS}
                              chosenOption={l3S_E}
                              onChoose={setL3S_E}
                              isSub={false}
                              minHeight={35}
                            />
                          </DropdownBox>
                        </Row>
                      </Item>
                    </SubBlock>
                  </Row>
                  <SubBlock>
                    <SubBlockTitle>Shipping Speed</SubBlockTitle>
                    <Item width={580}>
                      <Row gap='0px' isTabWrap>
                        <Checkbox
                          onCheck={setIsGround_E}
                          isChecked={isGround_E}
                          isRoundCorners
                          isGrey
                          noMargins
                        >
                          Ground (3-5 days)
                        </Checkbox>
                        <Checkbox
                          onCheck={setIsOvernight_E}
                          isChecked={isOvernight_E}
                          isRoundCorners
                          isGrey
                          noMargins
                        >
                          Overnight (Next day)
                        </Checkbox>
                        <Checkbox
                          onCheck={setIsInternational_E}
                          isChecked={isInternational_E}
                          isRoundCorners
                          isGrey
                          noMargins
                        >
                          International(DHL, etc)
                        </Checkbox>
                      </Row>
                    </Item>
                  </SubBlock>
                </Block>
              </Row>
              <Block>
                <BlockTitle>Communication</BlockTitle>
                <Row gap='20px' isTabWrap>
                  <SubBlock>
                    <SubBlockTitle>Authorized Contact Channels</SubBlockTitle>
                    <Item width={280}>
                      <Row gap='20px'>
                        <Checkbox
                          onCheck={setIsBusinessEmail_E}
                          isChecked={isBusinessEmail_E}
                          isRoundCorners
                          isGrey
                          noMargins
                        >
                          Business email
                        </Checkbox>
                        <Checkbox
                          onCheck={setIsBusinessCell_E}
                          isChecked={isBusinessCell_E}
                          isRoundCorners
                          isGrey
                          noMargins
                        >
                          Business cell
                        </Checkbox>
                      </Row>
                      <Row gap='20px'>
                        <Checkbox
                          onCheck={setIsPersonalEmail_E}
                          isChecked={isPersonalEmail_E}
                          isRoundCorners
                          isGrey
                          noMargins
                        >
                          Personal email
                        </Checkbox>
                        <Checkbox
                          onCheck={setIsPersonalCell_E}
                          isChecked={isPersonalCell_E}
                          isRoundCorners
                          isGrey
                          noMargins
                        >
                          Personal cell
                        </Checkbox>
                      </Row>
                    </Item>
                  </SubBlock>
                  <SubBlock>
                    <SubBlockTitle>Authorized Time to Contact</SubBlockTitle>
                    <Item width={280}>
                      <Checkbox
                        onCheck={setIs24Hours_E}
                        isChecked={is24Hours_E}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        24 hours
                      </Checkbox>
                      <Checkbox
                        onCheck={setIsBusinessHours_E}
                        isChecked={isBusinessHours_E}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        Business hours: Mon-Fri, 9am-5pm (local)
                      </Checkbox>
                      <Checkbox
                        onCheck={setIsExtendedHours_E}
                        isChecked={isExtendedHours_E}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        Extended hours: 7am-9pm (local)
                      </Checkbox>
                    </Item>
                  </SubBlock>
                </Row>
              </Block>
            </Column>
            <Column>
              <Block>
                <BlockTitle>Authorized Kit Types</BlockTitle>
                <SubBlock>
                  <SubBlockTitle>Pre-Authorized Hardware Value</SubBlockTitle>
                  <Item width={295}>
                    {RADIOBTNS.map(radio => (
                      <RadioButton
                        key={radio.value + 'exec'}
                        id={radio.value + 'exec'}
                        label={radio.label}
                        isSelected={preAuthHardwareValue_E === radio.value}
                        changed={radioChangeHandler}
                        value={radio.value}
                      />
                    ))}
                  </Item>
                </SubBlock>
                <SubBlock>
                  <SubBlockTitle>Pre-authorized Hardware Types</SubBlockTitle>
                  <Item width={295}>
                    <Row gap='0px'>
                      <Checkbox
                        onCheck={setIsLaptop_E}
                        isChecked={isLaptop_E}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        Laptop
                      </Checkbox>
                      <Checkbox
                        onCheck={setIsMonitor_E}
                        isChecked={isMonitor_E}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        Monitor
                      </Checkbox>
                    </Row>
                    <Row gap='0px'>
                      <Checkbox
                        onCheck={setIsDesktop_E}
                        isChecked={isDesktop_E}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        Desktop
                      </Checkbox>
                      <Checkbox
                        onCheck={setIsHeadset_E}
                        isChecked={isHeadset_E}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        Headset
                      </Checkbox>
                    </Row>
                    <Row gap='0px'>
                      <Checkbox
                        onCheck={setIsMobile_E}
                        isChecked={isMobile_E}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        Mobile
                      </Checkbox>
                      <Checkbox
                        onCheck={setIsOthers_E}
                        isChecked={isOthers_E}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        Other Accessories
                      </Checkbox>
                    </Row>
                    <Row gap='0px'>
                      <Checkbox
                        onCheck={setIsTablet_E}
                        isChecked={isTablet_E}
                        isRoundCorners
                        isGrey
                        noMargins
                      >
                        Tablet
                      </Checkbox>
                    </Row>
                  </Item>
                </SubBlock>
              </Block>
            </Column>
          </Row>
        </Column>
      )}
    </Wrap>
  )
}

const Wrap = styled.div<{ isFirstTab: boolean }>`
  padding: 24px 22px 22px;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border-top-left-radius: ${({ isFirstTab }) => (isFirstTab ? 0 : 4)}px;
  background: #ffffff;
  position: relative;
  z-index: 1;
`

const Block = styled.div`
  // flex: 1 1 auto;
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 20px;
`

const BlockTitle = styled.h3`
  font-weight: 600;
  font-size: 16px;
  padding: 5px 0 10px;
  margin-bottom: 6px;
  border-bottom: 1px solid #c4c4c4;
`

const SubBlock = styled.div`
  padding: 20px 0 0;
`

const SubBlockTitle = styled.h5`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
`

const Row = styled.div<{ gap?: string; jc?: string; isWrap?: boolean; isTabWrap?: boolean }>`
  display: flex;
  gap: ${({ gap }) => gap ?? 0};
  justify-content: ${({ jc }) => (jc ? jc : 'flex-start')};

  @media (max-width: 1280px) {
    flex-wrap: ${({ isWrap }) => (isWrap ? 'wrap' : 'none')};
  }

  @media (max-width: 900px) {
    flex-wrap: ${({ isTabWrap }) => (isTabWrap ? 'wrap' : 'none')};
  }

  & button {
    width: 110px;
    height: 30px;
    margin-top: 20px;
    font-weight: 600;
    font-size: 14px;

    &:first-child {
      color: #0066ff;
      border: 1px solid #0066ff;
    }
    &:last-child {
      color: #d60000;
      border: 1px solid #d60000;
    }
  }
`
const Column = styled.div<{ gap?: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
`

const DropdownBox = styled.div`
  flex: 0 0 83px;
  width: 83px;
`

const Item = styled.div<{ width: number }>`
  flex: 0 0 ${({ width }) => width}px;
  width: ${({ width }) => width}px;

  & input {
    height: 35px;
  }
`
const ItemLabel = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin: 20px 0 5px;
`

export default PMTabs
