import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import customScrollCss from '@/utils/custom-scroll-css';

import { Request } from '@/api/main-protected';
import { AppDispatch } from '@/App';

import closeIcon from '@/assets/images/icons/close-black-icon.svg';

import IconComponent from '@/Components/common/IconComponent';
import LoaderDots from '@/Components/LoaderDots';
import Button from '@/Components/common/Signin/Btn';
import NotesIncidentrequestComponent from '@/Components/Requests/NotesIncidentrequestComponent';
import EventsViewAllComponent from '@/Components/Requests/EventsViewAllComponent';

import useOnClickOutside from '@/Components/hooks/useOnClickOutside';

import { cancelRequest, eventsActions } from '@/store/actions/events';
import { selectUserInfo } from '@/store/selectors/user';
import { selectCancelRequestState } from '@/store/selectors/events';
import { RequestState } from '@/store/reducers/common';

import { getPermissions, Permissions } from '@/utils/permissions';
import convertFromStringToDate, { FormatDate } from '@/utils/convertFromStringToDate';

interface IModal {
  isLoading: boolean;
  isEventsStatus?: boolean;
  isRequestModal?: boolean;
  incidentRequest: Request;
  onCloseModal: () => void;
}

const CancelStatus = 'Request cancelled';

const ModalIncidentRequests: React.FC<IModal> = ({
  incidentRequest,
  isLoading,
  isEventsStatus = false,
  isRequestModal = false,
  onCloseModal,
}) => {
  const [isAllEventsModal, setIsAllEventsModal] = useState(false);
  const [isCancelRequestClicked, setIsCancelRequestClicked] = useState(false);
  const { roles } = useSelector(selectUserInfo)!;
  const cancelRequestState = useSelector(selectCancelRequestState);

  const editModalClickOutsideRef = useOnClickOutside(isAllEventsModal ? () => {} : onCloseModal);

  const dispatch = useDispatch<AppDispatch>();

  const handleCancel = async () => {
    dispatch(cancelRequest(incidentRequest));
  };

  const handleClick = () => {
    dispatch(eventsActions.setCancelRequestState(RequestState.IDLE));
    setIsCancelRequestClicked(true);
  };

  return (
    <Layout>
      {isLoading ? (
        <LoaderDots />
      ) : (
        <ModalWrap ref={editModalClickOutsideRef}>
          <>
            <Title>
              {incidentRequest.itsm_id}
              <CloseIconBox onClick={onCloseModal}>
                <IconComponent icon={closeIcon} width={20} />
              </CloseIconBox>
            </Title>
            <Main>
              <Row>
                <Column>
                  <Label>DATE OPENED</Label>
                  <Text>{incidentRequest.date_opened || 'N/A'}</Text>
                </Column>
                <Column>
                  <Label>STATUS</Label>
                  <Text>{incidentRequest.status || 'N/A'}</Text>
                </Column>
                {/* <Column>
                  <Label>CATEGORY</Label>
                  <Text>{incidentRequest.category.replace(/[_-]/g, ' ')}</Text>
                </Column> */}
                {/* <Column>
                  <Label>Exceptions</Label>
                  {incidentIds.length > 0
                    && incidentIds.map((incidentId) => (
                      <Text isBlue key={incidentId}>
                        {`I-${incidentId}`}
                      </Text>
                    ))}
                </Column> */}
                <Column>
                  <Label isNoWrap>Associated PlAYBOOK</Label>
                  <Text>{incidentRequest.category.replace(/[_-]/g, ' ') || 'N/A'}</Text>

                </Column>
                <Column>
                  <Label>CLOSED date</Label>
                  <Text>{incidentRequest.date_closed || 'N/A'}</Text>
                </Column>
              </Row>
              <Row>
                {/* <Column>
                  <Label>REQUESTOR</Label>
                  <Text>-</Text>
                  <Text>-</Text>
                  <Text>-</Text>
                </Column> */}
                <Column>
                  <Label>Impacted users</Label>
                  {incidentRequest.impacted_user
                  && Object.values(incidentRequest.impacted_user)
                    .filter((val) => !!val).length > 0 ? (
                      <ImpactedUser>
                        <Text>{`${incidentRequest.impacted_user.first_name}  ${incidentRequest.impacted_user.last_name}`}</Text>
                        <Text isNoCapitalize>{incidentRequest.impacted_user.email}</Text>
                        <Text>{incidentRequest.impacted_user.phone}</Text>
                      </ImpactedUser>
                    ) : (
                      <Text>N/A</Text>
                    )}
                </Column>
                <Column>
                  <Label>Action date</Label>
                  <Text>{incidentRequest?.action_date || 'N/A'}</Text>
                </Column>
                <Column>
                  <Label>Department</Label>
                  <Text>{incidentRequest.agency ? incidentRequest.agency : 'N/A'}</Text>
                </Column>
                <Column>
                  <Label>ITEMS - Serial Number</Label>
                  {(incidentRequest.items && incidentRequest.items.length > 0)
                    ? incidentRequest.items.map(
                      (item, i) => item && (
                      <Text isBlue key={`${item.asset_tag + i}req`}>
                        {item.model}
                        {' '}
                        -
                        {' '}
                        {item.serial_number}
                      </Text>
                      ),
                    )
                    : incidentRequest.serial_numbers.map((num, i) => (
                      <Text key={`${i}req-modal-itsm`}>{num || 'N/A'}</Text>
                    ))}

                  {/* {incidentRequest.items
                  && incidentRequest.items.length === 0
                  && incidentRequest.serial_numbers.map((num) => (
                    <Text>{num || '-'}</Text>
                  ))} */}
                </Column>
                <Column>
                  <Wrap>
                    <EventsViewAllComponent
                      req={incidentRequest}
                      // onCloseRequestModal={onCloseModal}
                      handleAllEventsClick={() => setIsAllEventsModal(true)}
                    />
                  </Wrap>
                </Column>
              </Row>
              <RowInfo>
                <ColumnInner>
                  <BlockWrap>
                    <SubTitle>
                      DEPLOYMENT
                    </SubTitle>
                    <GridWrap>
                      <Wrapper>
                        <Label isNoWrap>OUTBOUND TRACKING</Label>
                        {incidentRequest.tracking_number ? (
                          <Link
                            href={`https://www.ups.com/track?loc=en_US&Requester=DAN&tracknum=${incidentRequest.tracking_number}/trackdetails`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {incidentRequest.tracking_number}
                          </Link>
                        )
                          : <Text>N/A</Text>}
                        <Label />
                      </Wrapper>
                      <Wrapper>
                        {incidentRequest.expected_delivery_date ? (
                          <Wrapper>
                            <Label isNoWrap>Expected Delivery Date</Label>
                            <Text>
                              {convertFromStringToDate(
                                incidentRequest.expected_delivery_date,
                                FormatDate.ShortFormat,
                              )}
                            </Text>
                          </Wrapper>
                        ) : (
                          <Wrapper>
                            <Label isNoWrap>Estimated Delivery Date</Label>
                            <Text>
                              {incidentRequest.estimated_delivery_dates
                                ? incidentRequest.estimated_delivery_dates
                                : 'N/A'}
                            </Text>
                          </Wrapper>
                        )}
                      </Wrapper>
                      <Wrapper>
                        <Label isNoWrap>Date delivered</Label>
                        <Text>
                          {incidentRequest.delivery_date
                            ? convertFromStringToDate(
                              incidentRequest.delivery_date,
                              FormatDate.ShortFormat,
                            )
                            : 'N/A'}
                        </Text>
                      </Wrapper>
                      <Wrapper>
                        <Label isNoWrap>Return TRACKING</Label>
                        {incidentRequest.return_tracking_number ? (
                          <Link
                            href={`https://www.ups.com/track?loc=en_US&Requester=DAN&tracknum=${incidentRequest.return_tracking_number}/trackdetails`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {incidentRequest.return_tracking_number}
                          </Link>
                        )
                          : <Text>N/A</Text>}
                      </Wrapper>
                    </GridWrap>
                  </BlockWrap>
                </ColumnInner>
                <BlockWrap>
                  <SubTitle>
                    NOTES
                  </SubTitle>
                  <NoteBlock>
                    <NotesIncidentrequestComponent
                      isRequestModal={isRequestModal}
                      incidentRequest={incidentRequest}
                    />
                  </NoteBlock>
                </BlockWrap>
              </RowInfo>
              {incidentRequest.status.toLowerCase() !== CancelStatus.toLowerCase()
                      && getPermissions(roles[0], Permissions.CancelRequest) && (
                        <CancelRequest>
                          <Button onClick={handleClick}>
                            Cancel Request
                          </Button>
                          {cancelRequestState === RequestState.ERROR
                            && (
                            <CanceledInfoBlock isError>
                              <CancelText>Failed to cancel request</CancelText>
                            </CanceledInfoBlock>
                            )}

                          {isCancelRequestClicked && cancelRequestState !== RequestState.ERROR && (
                            <CancelConfirmBlock>
                              <CancelText>Are you sure you want to cancel this request?</CancelText>
                              <Button
                                onClick={handleCancel}
                                isActive={cancelRequestState !== RequestState.LOADING}
                              >
                                {cancelRequestState === RequestState.LOADING ? (
                                  <LoaderDots size={4} />
                                ) : (
                                  'Yes'
                                )}
                              </Button>
                              <Button
                                isActive={cancelRequestState !== RequestState.LOADING}
                                onClick={() => setIsCancelRequestClicked(false)}
                              >
                                No
                              </Button>
                            </CancelConfirmBlock>
                          )}
                        </CancelRequest>
              )}
              {incidentRequest.status.toLowerCase() === CancelStatus.toLowerCase()
                      && isCancelRequestClicked && cancelRequestState === RequestState.LOADED && (
                        <CancelRequest>
                          <Button
                            onClick={() => setIsCancelRequestClicked(true)}
                            isActive={
                              incidentRequest.status.toLowerCase() !== CancelStatus.toLowerCase()
                            }
                          >
                            Cancel Request
                          </Button>
                          <CanceledInfoBlock>
                            <CancelText>Request cancelled!</CancelText>
                          </CanceledInfoBlock>
                        </CancelRequest>
              )}
            </Main>
          </>
          <GradientLine />
        </ModalWrap>
      )}
    </Layout>
  );
};

const Wrapper = styled.div`
`;

const Layout = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const ModalWrap = styled.div`
  max-height: calc(100vh - 40px);
  max-width: 1375px;
  min-width: 675px;
  background: #ffffff;
  border-radius: 10px;
  padding: 0px 45px;
  overflow-y: auto;

  position: relative;

  @media (max-width: 725px) {
    min-width: 100%;
  }

  ${customScrollCss}
`;

const GradientLine = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
`;

const SubTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #4A4949;
  padding-bottom: 6px;
  border-bottom: 1px solid #C4C4C4;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #000000;
  background: #ffffff;
  padding: 35px 40px 25px 0;

  position: sticky;
  top: 0;
  z-index: 1;
`;

const Main = styled.div`
  margin: 25px 0 0;
`;

const Wrap = styled.div`
  h5 {
    color: #959494;
    font-size: 12px;
  }
`;

const RowInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 16px;

  &:last-child {
    & > div:first-child {
      width: 50%;
    }
    & > div:last-child {
      width: 50%;
    }
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  border-top: 1px solid #c4c4c4;
  
  &:last-child {
    & > div:first-child {
      /* flex: 1 0 auto; */
      max-width: 50%;
      min-width: 44%;
      margin-right: 0;
      padding-right: 15px;
    }
    & > div:last-child {
      /* flex: 1 1 38%; */
      max-width: 50%;
      margin-right: 0;
    }
  }
`;

const Column = styled.div`
  &:not(:last-child) {
    margin-right: 4.5vw;
  }
`;
const ColumnInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BlockWrap = styled.div`
  padding: 20px 24px 24px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  background: #FFFFFF;
  border: 1px solid rgba(182, 182, 182, 0.33);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  min-height: 300px;
`;

const GridWrap = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  row-gap: 12px;
  column-gap: 24px;

  & > div:nth-child(odd) {
    padding-right: 30px;
  }
`;

const CancelRequest = styled.div`
  display: flex;
  align-items: center;
  margin-top: 42px;

  & button {
    width: 110px;
    height: 35px;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    margin-left: 10px;
  }
`;

const CancelConfirmBlock = styled.div`
  /* position: absolute;
  top: -50px;
  left: 10px; */
  padding: 6px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffd1d1;
  border: 1px solid #ff1a1a;
  margin-left: 30px;

  & button {
    width: 43px;
    height: 20px;
    font-weight: 600;
    font-size: 12px;
  }
`;
const CanceledInfoBlock = styled.div<{ isError?: boolean }>`
  margin-left: 30px;

  ${({ isError }) => isError && css`
    color: #ff1a1a;
  `}
`;

const Label = styled.p<{ isNoWrap?: boolean }>`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding: 0 0 10px 0;
  color: #959494;

  ${({ isNoWrap }) => isNoWrap && css`
    white-space: nowrap;
  `}
`;

const Text = styled.p<{ isBlue?: boolean, isNoCapitalize?: boolean, isHover?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${({ isBlue }) => (isBlue ? '#0066FF' : '#000000')};
  text-transform: ${({ isNoCapitalize }) => (isNoCapitalize ? 'none' : 'capitalize')};
  margin-bottom: 7px;

  ${({ isHover }) => isHover && css`
    @media(hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  `}
`;

const Link = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0066FF;
  margin-bottom: 7px;
  white-space: nowrap;

  &:hover {
   text-decoration: underline;
  }
`;

const CancelText = styled.p`
  font-size: 14px;
`;

const NoteBlock = styled.div`
  div > div{
    max-height: 150px;
  }
`;

const CloseIconBox = styled.div`
  position: absolute;
  top: 35px;
  right: 0px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
const ImpactedUser = styled.div`
  margin-bottom: 10px;
`;

const DeliveredBlock = styled.div`
  margin-top: 20px;
`;

export default ModalIncidentRequests;
