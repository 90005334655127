export const EVENTS = [
  {
    warning: 1,
    description: 'Failed Warranty Claim',
    address: 'New York, USA',
    note: 'Repair not covered',
    info: 'Compliance Policy Exception - Device Wipe Procedure incomplete after 3 days.',
  },
  {
    warning: 1,
    description: 'Endpoint Wipe Exception',
    address: 'London, UK',
    note: ' ',
    info: 'DEM reports "Unstable Connection" for 3 consecutive days. ',
  },
  {
    warning: 2,
    description: 'Onboarding Kit Delayed',
    address: 'Sao Paulo, Brazil',
    note: 'Delivery exception',
    info: 'For the hiring manager/people operations to manage the exception:',
  },
  {
    warning: 3,
    description: 'Device Performance Issue',
    address: 'Asheville, NC, USA',
    note: 'Battery Health Condition',
    info: 'For the hiring manager/people operations to manage the exception:',
  },
  {
    warning: 3,
    description: 'Poor Connectivity Service',
    address: 'Los Angeles, USA',
    note: 'Remote connectivity',
    info: 'DEM reports "Unstable Connection" for 3 consecutive days. ',
  },
];
