import { createSelector, Selector } from 'reselect';

import { ILocation } from '@/api/main-protected';

import { State } from '@/store';
import { RequestState } from '@/store/reducers/common';

import { IMetrics } from '@/interfaces/metrics';

const selectUtils = (state: State) => state.UtilsReducer;

export const selectLocations: Selector<State, ILocation[] | null> = createSelector(
  selectUtils,
  ({ locations }) => locations,
);

export const selectFilterApliedItemsAmount: Selector<State, number> = createSelector(
  selectUtils,
  ({ filterApliedItemsAmount }) => filterApliedItemsAmount,
);

export const selectMetrics: Selector<State, IMetrics[]> = createSelector(
  selectUtils,
  ({ metrics }) => metrics,
);

export const selectMetricsState: Selector<State, RequestState> = createSelector(
  selectUtils,
  ({ metricsState }) => metricsState,
);
