/* eslint-disable import/prefer-default-export */
import { createSelector, Selector } from 'reselect';
import { State } from '@/store';

import { IToast } from '@/interfaces/toast';

const toastState = (state: State) => state.toastReducer;

export const selectToastQueue: Selector<State, IToast[]> = createSelector(
  toastState,
  ({ queue }) => queue,
);
