import { createActionCreators } from 'immer-reducer';

import { AsyncAction } from '@/store/actions/common';
import { ItemsReducer } from '@/store/reducers/items';
import { RequestOptions, RequestState } from '@/store/reducers/common';

import { ITableBody } from '@/interfaces/api';

export const itemsActions = createActionCreators(ItemsReducer);

export type ItemsActionsType =
    | ReturnType<typeof itemsActions.setReturns>
    | ReturnType<typeof itemsActions.setReturnsState>
    | ReturnType<typeof itemsActions.setAssetsByType>
    | ReturnType<typeof itemsActions.setAssetsByTypeState>
    | ReturnType<typeof itemsActions.setAssetsByMake>
    | ReturnType<typeof itemsActions.setAssetsByMakeState>
    | ReturnType<typeof itemsActions.setAssets>
    | ReturnType<typeof itemsActions.setGlobalSearchItems>
    | ReturnType<typeof itemsActions.setAssetsTotal>
    | ReturnType<typeof itemsActions.setItemsFiltersState>
    | ReturnType<typeof itemsActions.setAssetsState>
    | ReturnType<typeof itemsActions.setAssetsPaginationState>
    | ReturnType<typeof itemsActions.setAssetsTotalState>
    | ReturnType<typeof itemsActions.setGlobalSearchItemsState>
    | ReturnType<typeof itemsActions.setItemsFilters>
    | ReturnType<typeof itemsActions.setPeripheralsState>
    | ReturnType<typeof itemsActions.setPeripheralsTotalState>
    | ReturnType<typeof itemsActions.setPeripheralsTotal>
    | ReturnType<typeof itemsActions.setPeripheralsPaginationState>
    | ReturnType<typeof itemsActions.setPeripherals>;

export const getReturns = (interval: number): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(itemsActions.setReturnsState(RequestState.LOADING));

    const response = await mainProtectedApi.getReturns(interval);

    dispatch(itemsActions.setReturns(response));
    dispatch(itemsActions.setReturnsState(RequestState.LOADED));
  } catch (e) {
    dispatch(itemsActions.setReturnsState(RequestState.ERROR));
  }
};

export const getAssetsByType = (interval: number): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(itemsActions.setAssetsByTypeState(RequestState.LOADING));

    const response = await mainProtectedApi.getAssetsByType(interval);

    dispatch(itemsActions.setAssetsByType(response));
    dispatch(itemsActions.setAssetsByTypeState(RequestState.LOADED));
  } catch (e) {
    dispatch(itemsActions.setAssetsByTypeState(RequestState.ERROR));
  }
};

export const getAssetsByMake = (interval: number): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(itemsActions.setAssetsByMakeState(RequestState.LOADING));

    const response = await mainProtectedApi.getAssestsByMake(interval);

    dispatch(itemsActions.setAssetsByMake(response));
    dispatch(itemsActions.setAssetsByMakeState(RequestState.LOADED));
  } catch (e) {
    dispatch(itemsActions.setAssetsByMakeState(RequestState.ERROR));
  }
};

export const getAssets = (
  body: ITableBody,
  option?: RequestOptions,
): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    if (!option?.silent) {
      dispatch(itemsActions.setAssetsState(RequestState.LOADING));
    }
    dispatch(itemsActions.setAssetsPaginationState(RequestState.LOADING));

    const { assets } = await mainProtectedApi.getAssets(body);

    dispatch(itemsActions.setAssets(assets));

    if (!option?.silent) {
      dispatch(itemsActions.setAssetsState(RequestState.LOADED));
    }
    dispatch(itemsActions.setAssetsPaginationState(RequestState.LOADED));
  } catch (e) {
    if (!option?.silent) {
      dispatch(itemsActions.setAssetsState(RequestState.ERROR));
    }
    dispatch(itemsActions.setAssetsPaginationState(RequestState.ERROR));
  }
};

export const getItemsFilters = (): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(itemsActions.setItemsFiltersState(RequestState.LOADING));

    const filters = await mainProtectedApi.getItemsFilters();

    dispatch(itemsActions.setItemsFilters(filters));

    dispatch(itemsActions.setItemsFiltersState(RequestState.LOADED));
  } catch (e) {
    dispatch(itemsActions.setItemsFiltersState(RequestState.ERROR));
  }
};

export const getAssetsTotal = (body: ITableBody): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(itemsActions.setAssetsTotalState(RequestState.LOADING));

    const response = await mainProtectedApi.getAssetsTotal(body);

    dispatch(itemsActions.setAssetsTotal(response.total));

    dispatch(itemsActions.setAssetsTotalState(RequestState.LOADED));
  } catch (e) {
    dispatch(itemsActions.setAssetsTotalState(RequestState.ERROR));
  }
};

export const getPeripherals = (
  body: ITableBody,
  option?: RequestOptions,
): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    if (!option?.silent) {
      dispatch(itemsActions.setPeripheralsState(RequestState.LOADING));
    }
    dispatch(itemsActions.setPeripheralsPaginationState(RequestState.LOADING));

    const { peripherals } = await mainProtectedApi.getPeripherals(body);

    dispatch(itemsActions.setPeripherals(peripherals));

    if (!option?.silent) {
      dispatch(itemsActions.setPeripheralsState(RequestState.LOADED));
    }
    dispatch(itemsActions.setPeripheralsPaginationState(RequestState.LOADED));
  } catch (e) {
    dispatch(itemsActions.setPeripheralsState(RequestState.ERROR));
    dispatch(itemsActions.setPeripheralsPaginationState(RequestState.ERROR));
  }
};

export const getPeripheralsTotal = (body: ITableBody): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(itemsActions.setPeripheralsTotalState(RequestState.LOADING));

    const response = await mainProtectedApi.getPeripheralsTotal(body);

    dispatch(itemsActions.setPeripheralsTotal(response.total));

    dispatch(itemsActions.setPeripheralsTotalState(RequestState.LOADED));
  } catch (e) {
    dispatch(itemsActions.setPeripheralsTotalState(RequestState.ERROR));
  }
};
