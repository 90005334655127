import React from 'react';
import styled, { css } from 'styled-components';

import { colorNA } from '@/Components/Items/Items';

interface INullChart {
  title: string;
  noLeftMargin?: boolean;
}

const ChartNullState: React.FC<INullChart> = ({ title, noLeftMargin = false }) => (
  <Wrap>
    <Pie noLeftMargin={noLeftMargin}>N/A</Pie>
    <ChartBox>
      <TitleRow>
        <Title>{title}</Title>
        <Total>Total: N/A</Total>
      </TitleRow>
      <Info>
        <Row>
          <Text pointColor={colorNA}>
            No data at this time
          </Text>
          <Text>N/A</Text>
        </Row>
      </Info>
    </ChartBox>
  </Wrap>
);

const Wrap = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  font-weight: 500;
  font-size: 8px;
  padding: 0px 0px 25px 25px;
`;

const Pie = styled.div<{ noLeftMargin: boolean }>`
  flex: 0 0 160px;
  width: 160px;
  height: 160px;
  font-weight: 600;
  font-size: 14px;
  color: #000000;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #c4c4c4;
  border-radius: 50%;
  /* margin-top: 30px;
  margin-right: 35px;
  margin-left: ${({ noLeftMargin }) => (noLeftMargin ? 0 : '-15')}px; */
  margin: 30px 64px 0px 30px;
`;
const ChartBox = styled.div`
  flex: 1 0 auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize;
`;

const Total = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

const Info = styled.div`
  width: 100%;
  min-height: 130px;
  border-bottom: 1px solid #c4c4c4;
  border-top: 1px solid #c4c4c4;
  margin: 15px 0;
`;

const Text = styled.p<{ pointColor?: string; isPlain?: boolean }>`
  font-weight: 400;
  font-size: 12px;
  padding-left: 22px;
  margin: 16px 0;

  :first-child {
    text-transform: ${({ isPlain }) => (isPlain ? 'none' : 'capitalize')};
  }

  ${({ pointColor }) => pointColor
    && css`
      position: relative;

      &: after {
        content: '';
        position: absolute;
        top: 1px;
        left: 4px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: ${pointColor};
      }
    `}
`;

export default ChartNullState;
