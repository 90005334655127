import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { AppDispatch } from '@/App';
import ContentContainer from '@/Components/common/ContentContainer/ContentContainer';
// import AssetsByMake from '@/Components/Items/AssetsByMake';
import ItemsContainer from '@/Components/Items/ItemsTable/ItemsContainer';

import {
  selectAssetsByMake,
  selectAssetsByMakeState,
  selectAssetsByType,
  selectAssetsByTypeState,
  selectReturns,
  selectReturnsState,
} from '@/store/selectors/items';
import {
  getAssetsByMake,
  getAssetsByType,
  getReturns,
} from '@/store/actions/items';
import { selectRequestsInterval } from '@/store/selectors/events';

import getUpdated from '@/utils/getUpdated';
import PieChartComponent from '../Requests/PieChartComponent';
import { RequestState } from '@/store/reducers/common';
import { RequestsChartItem } from '@/api/main-protected';

export interface IItems {
  title: string
  monthsAmount: string[]
}

export interface IDevices {
  laptop: number
  mobile: number
  tablet: number
}

export interface IOrderDevicesData {
  month: string
  devices: IDevices
  max?: number
}

export const colorNA = '#d9d9d9';
const DEVICE_RETURNS_COLORS = ['#59CC6C', '#3DD6DF', '#FFBF1A', '#E8484A', '#715cff'];
const COLORS_MAKE = [
  '#59CC6C',
  '#FF33AD',
  '#3DD6DF',
  '#7B61FF',
  '#FFE600',
  '#FF7A00',
  '#FF96D5',
  '#FF1919',
  '#305A41',
  '#0066FF',
  '#8215C6',
];
export const DATES = [
  'May ‘21',
  'Jun ‘21',
  'Jul ‘21',
  'Aug ‘21',
  'Sep ‘21',
  'Oct ‘21',
  'Nov ‘21',
  'Dec ‘21',
  'Jan ‘22',
  'Feb ‘22',
  'Mar ‘22',
  'Apr ‘22',
];

const Items: React.FC = () => {
  const returns = useSelector(selectReturns);
  const assetsByType = useSelector(selectAssetsByType);
  const assetsByMake = useSelector(selectAssetsByMake);
  const interval = useSelector(selectRequestsInterval);

  const returnsState = useSelector(selectReturnsState);
  const assetsByTypeState = useSelector(selectAssetsByTypeState);
  const assetsByMakeState = useSelector(selectAssetsByMakeState);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getReturns(interval));
    dispatch(getAssetsByType(interval));
    dispatch(getAssetsByMake(interval));
  }, [interval]);

  return (
    <>
      <ContentContainer title="Items" updated={getUpdated(window.Date())} isIntervalFilter/* isExportCSV */>
        {/* <CellsComponent cells={PERCENT_INFO_ITEMS} /> */}
        <ColumnsTop>
          <Column>
            <PieChartComponent
              data={returns!}
              arrName="device_returns"
              title="Device Returns"
              tableTitle="Status"
              isLoading={!returns && returnsState === RequestState.LOADING}
              colors={DEVICE_RETURNS_COLORS}
              isWhitePieText
            />
            <PieChartComponent
              data={assetsByType!}
              arrName="statuses"
              title="Status by Type"
              tableTitle="Status"
              isLoading={!assetsByType && assetsByTypeState === RequestState.LOADING}
              colors={DEVICE_RETURNS_COLORS}
              isWhitePieText
            />
          </Column>
          <Column isHeightAuto>
            <PieChartComponent
              data={assetsByMake! as unknown as RequestsChartItem}
              arrName="topAssets"
              title="Assets by Make/Model"
              tableTitle="Make/Model"
              isLoading={!assetsByMake && assetsByMakeState === RequestState.LOADING}
              colors={COLORS_MAKE}
              isWhitePieText
            />
            {/* <AssetsByMake
              isLoading={!assetsByMake && assetsByMakeState === RequestState.LOADING}
              assetsByMake={assetsByMake!}
            /> */}
          </Column>
        </ColumnsTop>
      </ContentContainer>
      <ItemsContainer
        interval={interval}
      />
    </>
  );
};

const ColumnsTop = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0 0 22px 0;
`;

const Column = styled.div<{ isHeightAuto?: boolean }>`
  flex: 0 0 calc(50% - 8px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: ${({ isHeightAuto }) => (isHeightAuto ? 'auto' : '100%')};

  @media (max-width: 1280px) {
    flex: 1 1 auto;
  }
`;

export default Items;
