import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';

import { PAGES } from '@/Components/Main/Main';
import IconComponent from '@/Components/common/IconComponent';
import { AppDispatch } from '@/App';

import { selectUserInfo } from '@/store/selectors/user';
import { logout } from '@/store/actions/login';

import profileDefaultIcon from '@/assets/images/icons/user-settings/user-profile-default-icon.svg';

// interface IUser {
//   name: string
//   email: string
//   icon: string
//   status: string
//   isOnLine: boolean
// }

export interface IUserPopup {
  closeUserInfo: () => void
}

const UserProfilePopupComponent: React.FC<IUserPopup> = ({ closeUserInfo }) => {
  const user = useSelector(selectUserInfo);
  const dispatch = useDispatch<AppDispatch>();

  const history = useHistory();

  const handleLogout = () => {
    // if (!user) return
    if (!user?.user_id) return;

    dispatch(logout());
    history.push(PAGES.LOGIN);

    // to logout
    // auth.logout({
    //   clientID: '',
    //   returnTo: '/login',
    //   federated: true
    // })
  };

  return (
    <Container>
      <UserInfo>
        <Icon>
          <IconComponent icon={user?.picture ?? profileDefaultIcon} />
        </Icon>
        <Info>
          <Name>{`${user?.first_name || 'guest'} ${user?.last_name || ''}`}</Name>
          <Mail>{user?.email}</Mail>
        </Info>
      </UserInfo>
      <Line />
      <Block>
        {/* <Link to={PAGES.USER_PROFILE} onClick={closeUserInfo}>
          <Text>Profile & Account</Text>
        </Link> */}
        <Link to={PAGES.USER_SETTINGS} onClick={closeUserInfo}>
          <Text>Settings</Text>
        </Link>
        <Link to={PAGES.CHANGELOG} onClick={closeUserInfo}>
          <Text>Changelog</Text>
        </Link>
        <Link to={PAGES.SUPPORT} onClick={closeUserInfo}>
          <Text>Support Center</Text>
        </Link>
      </Block>
      <Line />
      <Text onClick={handleLogout}>Sign out</Text>
    </Container>
  );
};

const fadeIn = keyframes`
 from {
    transform: scale(0.8);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const Container = styled.div`
  position: absolute;
  bottom: 0;
  right: -10px;
  min-width: 180px;
  transform: translateY(calc(100% + 10px));
  background: #ffffff;
  border: 1px solid rgba(229, 229, 229, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 20px 0px 3px;
  z-index: 5;

  transition: ${fadeIn} 10s ease;
`;

const UserInfo = styled.div`
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0px 14px;
  margin-bottom: 7px;
`;
const Icon = styled.div`
  flex: 0 0 34px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  overflow: hidden;
`;
const Info = styled.div`
  margin-left: 12px;
`;
const Name = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.01em;
  white-space: nowrap;
  color: #000000;
`;
const Mail = styled(Name)`
  font-weight: 400;
`;
const Text = styled(Mail)`
  font-size: 11px;
  line-height: 34px;
  padding: 0px 14px;

  cursor: pointer;
  transition: 0.3s ease;

  &:hover {
    background: rgba(229, 229, 229, 0.4);
  }
`;

const Block = styled.div``;

const Line = styled.div`
  width: calc(100% - 28px);
  height: 1px;
  background: #e5e5e5;
  margin: 0 auto;
`;

export default UserProfilePopupComponent;
