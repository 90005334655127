import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import moreIcon from '@/assets/images/icons/arrow-more-blue.svg';

import { PAGES } from '@/Components/Main/Main';
import IconComponent from '@/Components/common/IconComponent';

const ViewAllComponent: React.FC = () => (
  <Link to={PAGES.REQUESTS}>
    <ViewAll>
      <Text>View All</Text>
      <IconComponent icon={moreIcon} alt="view all" width={12} />
    </ViewAll>
  </Link>
);

const ViewAll = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0066ff;
  margin-left: 18px;
  margin-right: 18px;

  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  cursor: pointer;
`;

const Text = styled.p`
  white-space: nowrap;
  margin-right: 3px;
`;

export default ViewAllComponent;
