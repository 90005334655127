import {
  createStore, applyMiddleware, compose, combineReducers,
} from 'redux';
import thunk from 'redux-thunk';

import Main from '@/api/main';
import MainProtected from '@/api/main-protected';
import MainProtectedFile from '@/api/main-protected-file';

import { InitialActions } from '@/store/actions/login';
import { UserActionsType } from '@/store/actions/user';
import { ToastActions } from '@/store/actions/toast';
import { EventsActionsType } from '@/store/actions/events';
import { ItemsActionsType } from '@/store/actions/items';
import { SupportActionsType } from '@/store/actions/support';
import {
  GlobalActionsType, RequestsSearchActionsType, AssetsSearchActionsType, EventsSearchActionsType,
} from '@/store/actions/global';
import { UtilsActionsType } from '@/store/actions/utils';
import { ThemeActions } from '@/store/actions/theme';

import LoginReducer from '@/store/reducers/login';
import UserReducer from '@/store/reducers/user';
import EventsReducer from '@/store/reducers/events';
import ItemsReducer from '@/store/reducers/items';
import SupportReducer from '@/store/reducers/support';
import GlobalReducer from '@/store/reducers/global';
import UtilsReducer from '@/store/reducers/utils';
import themeReducer from '@/store/reducers/theme';
import toastReducer from '@/store/reducers/toast';
import RequestsSearchReducer from '@/store/reducers/requestsSearch';
import AssetsSearchReducer from '@/store/reducers/assetsSearch';
import EventsSearchReducer from '@/store/reducers/eventsSearch';

export const api = {
  mainApi: Main.getInstance(),
  mainProtectedApi: MainProtected.getInstance(),
  mainProtectedFile: MainProtectedFile.getInstance(),
};

const rootReducer = combineReducers({
  LoginReducer,
  UserReducer,
  EventsReducer,
  ItemsReducer,
  SupportReducer,
  GlobalReducer,
  RequestsSearchReducer,
  AssetsSearchReducer,
  EventsSearchReducer,
  UtilsReducer,
  themeReducer,
  toastReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk.withExtraArgument(api)),
);

export type State = ReturnType<typeof rootReducer>;
export type Actions =
  | InitialActions
  | UserActionsType
  | EventsActionsType
  | ItemsActionsType
  | SupportActionsType
  | GlobalActionsType
  | RequestsSearchActionsType
  | AssetsSearchActionsType
  | EventsSearchActionsType
  | UtilsActionsType
  | ToastActions
  | ThemeActions;

export default createStore(rootReducer, enhancer);
