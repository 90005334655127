import React, { useState } from 'react';
import styled from 'styled-components';

import ContentContainer from '@/Components/common/ContentContainer/ContentContainer';
// import AddNewUserForm from './AddNewUserForm'
import Btn from '@/Components/common/Signin/Btn';
import PMTabs from '@/Components/PersonaManager/PMTabs';

export const TABS = [
  {
    id: 'default-tab',
    tab: 1,
    title: 'Default',
    isActive: true,
  },
  {
    id: 'executive-tab',
    tab: 2,
    title: 'Executive',
    isActive: false,
  },
];

const PersonaManager: React.FC = () => {
  const [tabs, setTabs] = useState(TABS);
  const [activeTab, setActiveTab] = useState<number>(TABS[0].tab);

  const handleClick = (itemStep: number) => {
    const updatedTabs = tabs.map((tabItem) => {
      tabItem.isActive = itemStep === tabItem.tab;
      return tabItem;
    });

    setTabs(updatedTabs);
    setActiveTab(itemStep);
  };

  const onAddPersonaClick = () => {
  };

  return (
    <>
      <ContentContainer title="Persona Manager" />
      <Header>
        <Tabs>
          {tabs.map((tab) => (
            <TabWrap
              key={tab.tab}
              isActive={tab.isActive}
              isFirstTab={tab.tab === TABS[0].tab}
              onClick={() => handleClick(tab.tab)}
            >
              <Tab>{tab.title}</Tab>
            </TabWrap>
          ))}
        </Tabs>
        <AddBtn>
          <Btn onClick={onAddPersonaClick}>Add Persona</Btn>
        </AddBtn>
      </Header>
      <PMTabs activeTab={activeTab} />
    </>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 9px 0 0;
`;
const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const AddBtn = styled.div`
  flex: 0 0 125px;
  width: 125px;

  & button {
    height: 30px;
  }
`;
const TabWrap = styled.div<{ isActive: boolean; isFirstTab: boolean }>`
  flex: 0 0 133px;
  width: 133px;
  height: 41px;
  background: ${({ isActive }) => (isActive ? '#ffffff' : '#EFEFEF')};
  border: 1px solid #e5e5e5;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid ${({ isActive }) => (isActive ? '#ffffff' : 'transparent')};
  margin-right: 9px;
  margin-bottom: -1px;
  padding: 0 15px;
  box-shadow: ${({ isActive }) => (isActive ? '0px 2px 4px rgba(0, 0, 0, 0.25)' : 'transparent')};

  font-weight: ${({ isActive }) => (isActive ? 800 : 500)};
  font-size: 14px;
  line-height: 20px;
  color: #4a4949;

  display: flex;
  align-items: center;

  position: relative;
  z-index: ${({ isActive }) => (isActive ? '2' : '0')};
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: ${({ isFirstTab }) => (isFirstTab ? 0 : -3)}px;
    width: calc(100% + 6px);
    height: 7px;
    background: ${({ isActive }) => (isActive ? '#ffffff' : 'transparent')};
  }
`;
const Tab = styled.p``;

export default PersonaManager;
