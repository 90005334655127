import React, { useMemo } from 'react';
import { useMap } from 'react-leaflet';
import { LatLngBoundsExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { IIncident } from '@/api/main-protected';

interface IProps {
  incidents: IIncident[];
  isZoom: boolean;
}

const MapMemoComponent: React.FC<IProps> = ({ incidents, isZoom }) => {
  const map = useMap();

  const filterPeriod = localStorage.getItem('filter');

  if (!incidents || !map) {
    return null;
  }

  const memoMap = useMemo(() => {
    const bounds = incidents.map((incident) => [
      incident.latitude,
      incident.longitude,
    ]) as LatLngBoundsExpression;

    map.fitBounds(bounds, {
      maxZoom: 11,
    });

    return null;
  }, [isZoom, incidents, filterPeriod]);

  return memoMap;
};

export default MapMemoComponent;
