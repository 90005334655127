import React, { useMemo } from 'react';
import styled from 'styled-components';

import { IXLA } from '@/interfaces/metrics';

import { singleLine } from '@/styles/mixins';

interface Props extends IXLA {
  activeTile: number;
  setActiveTile: (id: number) => void;
}

const nullStates = 'Insufficient data';

const XLACell: React.FC<Props> = ({
  activeTile,
  setActiveTile,
  xla_id,
  name,
  value,
  count,
}) => {
  const handleClick = () => {
    setActiveTile(xla_id);
  };

  const percentage = (count > 0) ? useMemo(() => value * 100, [value]) : 0;

  return (
    <CellStyled
      isActive={xla_id === activeTile}
      onClick={handleClick}
    >
      <CellTitle>
        <Title>{name || '-'}</Title>
      </CellTitle>
      <CellInfo>
        <ScaleBlock>
          <Num>0</Num>
          <ScaleBox>
            <ScaleColor width={percentage} />
            <TopPerNum left={percentage || 0}>
              {!!percentage && percentage >= 0 && count > 0 ? `${percentage.toFixed(0)}%` : nullStates}
            </TopPerNum>
          </ScaleBox>
          <Num>100</Num>
        </ScaleBlock>
      </CellInfo>
    </CellStyled>
  );
};

const CellStyled = styled.div<{ isActive: boolean }>`
  min-width: 370px;
  border: ${({ isActive }) => (isActive ? '1px solid #0066FF' : '1px solid rgba(182, 182, 182, 0.5)')};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;

  cursor: pointer;
`;

const CellTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h6`
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #4A4949;
  flex: 1;

  ${singleLine}
`;

const CellInfo = styled.div`
  width: 100%;
`;

const ScaleBlock = styled.div`
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 3px 0;
`;

const Num = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
`;

const ScaleBox = styled.div`
  position: relative;
  flex: 1 1 auto;
  height: 11px;
  background: #c4c4c4;
  border-radius: 10px;
  margin: 0 12px;
  display: flex;
`;

const ScaleColor = styled.div<{ width: number }>`
  flex: 0 0 ${({ width }) => width && `${width}%`};
  width: ${({ width }) => width && `${width}%`};
  height: 100%;
  border-radius: 10px;

   /* this will do the magic */
  -webkit-mask:linear-gradient(#fff 0 0);
          mask:linear-gradient(#fff 0 0);

  &::before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: #0066ff; /* your gradient here */
  }
`;

const TopPerNum = styled(Num)<{ left: number }>`
  position: absolute;
  top: -25px;
  left: ${({ left }) => left && `${left}%`};
  transform: ${({ left }) => (left === 0 ? 'translateX(0%)' : 'translateX(-50%)')};
  font-weight: 600;
  font-size: 16px;
`;

export default XLACell;
