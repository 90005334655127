import { useEffect } from 'react';
import { resourceUrl } from './useZendeskScript';
import { IUser } from '@/api/main-protected';

const BG_COLOR = '#735af1'; //'#7159ee'; //#7B61FF by design
const COLOR = '#ffffff';
const ERROR = 'red';

const useZendeskCustomizationScript = (isLoadZendesk: boolean, user: IUser | null) => {
  useEffect(() => {
    if (!isLoadZendesk || !user) return;

    const script = document.createElement('script');
    script.id = 'zendesk-widget';
    script.type = 'text/javascript';
    script.textContent = `
    window.zESettings = {
      webWidget: {
        position: { horizontal: 'left', vertical: 'bottom' },
        color: {
          theme: '${BG_COLOR}',
          launcher: '${BG_COLOR}', // This will also update the badge
          launcherText: '${COLOR}',
          button: '${BG_COLOR}',
          resultLists: '${COLOR}',
          header: '${BG_COLOR}',
          articleLinks: '${ERROR}'
        },
        contactForm: {
          title: {
            '*': 'CONTACT US'
          },
          fields: [
            { id: "name", prefill: { "*": "${user.first_name} ${user.last_name}" } },
            { id: "email", prefill: { "*": "${user.email}" } }
          ],
        },
        zIndex: 50,
      },
    };
    `;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [resourceUrl, isLoadZendesk, user]);
};

export default useZendeskCustomizationScript;
