import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import useToggle from '@/Components/hooks/useToggle';

import eyeIcon from '@/assets/images/icons/eye-icon.svg';
import closedEyeIcon from '@/assets/images/icons/closed-eye-icon.svg';

import { BrowserNames } from '@/utils/browsers';

interface IProps {
  label?: string
  id: string
  name?: string
  type?: string
  onChange?: (value: string) => void
  onClick?: () => void
  value: string
  disabled?: boolean
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  autoComplete?: 'off' | 'on'
  autoFocus?: boolean
  min?: string
  placeholder?: string
  isEmpty?: boolean
  // width?: number
  height?: number
  // maxWidth?: string
  // fullWidth?: boolean
  iconSrc?: string
  iconSize?: number
  afterIconSrc?: string
  readOnly?: boolean
  hintText?: string
  required?: boolean
  isHideMode?: boolean
  isNoMargin?: boolean
  errorText?: string
  isError?: boolean
  inputRef?: React.RefObject<HTMLInputElement>
  onSelect?: (e: React.TouchEvent<HTMLInputElement>) => void
  onMouseDown?: (e: React.MouseEvent<HTMLInputElement>) => void
  beforeContent?: string
  isAfterIcon?: boolean
  isNoBorder?: boolean
  maxLength?: number
  browser?: string
}

const Input: React.FC<IProps> = ({
  label = null,
  id,
  name,
  type = 'text',
  onChange,
  onClick,
  value,
  disabled = false,
  onBlur,
  onFocus,
  autoComplete = 'on',
  autoFocus = false,
  min,
  placeholder,
  isEmpty,
  // width = 320,
  height = 44,
  // maxWidth = 'none',
  // fullWidth = false,
  iconSrc = null,
  iconSize = 24,
  afterIconSrc = null,
  readOnly = false,
  hintText = null,
  required = false,
  isHideMode = false,
  isNoMargin = false,
  errorText = null,
  isError = false,
  inputRef = null,
  onSelect,
  onMouseDown,
  beforeContent = '',
  isNoBorder = false,
  maxLength,
  browser,
}) => {
  const [isValue, setIsValue] = useState(value.length > 0);
  const [hideText, setHideText] = useToggle(true);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.target.value;
    if (onChange) {
      onChange(targetValue);
    }
    if (targetValue === '') {
      setIsValue(false);
    } else {
      setIsValue(true);
    }
  };

  const hideTextClickHandler = (e: React.MouseEvent<HTMLImageElement>) => {
    setHideText(e);
  };

  return (
    <InputWrapper isNoMargin={isNoMargin}>
      <LabelBlock isLabel={label !== null}>
        <Label htmlFor={id}>{label}</Label>
      </LabelBlock>
      <InputIconWrap isPsw={isHideMode && hideText} height={height}>
        <InputStyled
          id={id}
          name={name}
          type={isHideMode && hideText ? 'password' : type}
          onChange={changeHandler}
          onClick={onClick}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          min={min || ''}
          // isValue={isValue}
          isError={(isEmpty && !isValue) || isError || false}
          // isIcon={iconSrc !== null}
          // isBeforeSign={beforeContent !== ''}
          readOnly={readOnly}
          required={required}
          ref={inputRef}
          onSelect={onSelect}
          onMouseDown={onMouseDown}
          isAfterIcon={afterIconSrc !== null || isHideMode}
          // isNoBorder={isNoBorder}
          isPassword={isHideMode && hideText && isValue}
          maxLength={maxLength}
          browser={browser}
        />
        {/* {iconSrc && <Icon src={iconSrc} size={iconSize} isNoBorder={isNoBorder} />} */}
        {/* {afterIconSrc && <AfterIcon src={afterIconSrc} onClick={() => onChange!('')} />} */}
        {isHideMode && (
          <HideIcon src={hideText ? closedEyeIcon : eyeIcon} onClick={hideTextClickHandler} />
        )}
        {isHideMode && hideText && <LineTop />}
        {isHideMode && hideText && <LineBottom />}
      </InputIconWrap>
      {(isEmpty && !isValue) || isError ? (
        <RequireError>{errorText ?? `${label} is required`}</RequireError>
      ) : (
        ''
      )}
    </InputWrapper>
  );
};

const InputWrapper = styled.div<{ isNoMargin?: boolean }>`
  position: relative;
  width: 100%;

  margin: 0 0 ${({ isNoMargin }) => (isNoMargin ? 0 : 31)}px 0;

  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: #000000;
  outline: none;
  transition: color 0.3s ease-in;
`;

const LabelBlock = styled.div<{ isLabel: boolean }>`
  display: ${({ isLabel }) => (isLabel ? 'flex' : 'none')};
  align-items: center;
`;

const Label = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;

  transition: color 0.3s ease-in;
  margin: 0 0 8px;
`;

const InputIconWrap = styled.div<{ isPsw: boolean; height: number }>`
  position: relative;

  height: ${({ isPsw, height }) => (isPsw ? `${height}px` : 'auto')};
`;

// const Icon = styled.img<{ size: number; isNoBorder: boolean }>`
//   position: absolute;
//   left: 16px;
//   top: ${({ size, isNoBorder }) => (isNoBorder ? `${(30 - size) / 2}px` : `${(40 - size) / 2}px`)};
//   width: ${({ size }) => `${size}px`};
//   height: ${({ size }) => `${size}px`};
// `

// const BeforeText = styled.p`
//   position: absolute;
//   left: 14px;
//   top: 13px;
//   font-size: 14px;
//   font-weight: 400;
//   line-height: 14px;
//   color: ${({ theme }) => theme.palette.inactive};
// `

const HideIcon = styled.img`
  position: absolute;
  right: 16px;
  top: 10px;
  width: 24px;
  height: 24px;
  object-fit: contain;
  cursor: pointer;
`;

// top/bottom cover psw input caret
const Line = styled.div`
  position: absolute;
  left: 1px;
  width: calc(100% - 46px);
  background: #ffffff;
  height: 10px;
  z-index: 1;
`;

const LineTop = styled(Line)`
  top: 1px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;
const LineBottom = styled(Line)`
  bottom: 1px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

// const AfterIcon = styled.img`
//   position: absolute;
//   right: 16px;
//   top: 14px;
//   width: 12px;
//   height: 12px;
//   cursor: pointer;
// `

interface IInput {
  isError: boolean
  isAfterIcon: boolean
  isPassword: boolean
  browser?: string
  // isIcon: boolean
  // isValue: boolean
  // isBeforeSign: boolean
  // isNoBorder: boolean
}

const InputStyled = styled.input<IInput>`
  position: relative;
  caret-color: #000000;
  width: 100%;
  height: 44px;
  background: #ffffff;
  padding: 10px 12px;
  padding-right: ${({ isAfterIcon }) => isAfterIcon && '45px'};
  border: 1px solid #c4c4c4;
  border-radius: 4px;

  outline: none;

  // letter-spacing: 0.125em;
  letter-spacing: 1px;

  font-size: 14px;
  font-weight: 400;
  // line-height: 14px;
  color: #000000;
  // transition: 0.3s ease-in;

  ${({ isPassword, browser }) => isPassword
    && browser === BrowserNames.CHROME
    && css`
      font: caption;
      font-size: 30px;
      letter-spacing: -1px;
      color: #4a4949;
    `}

  ${({ isPassword, browser }) => isPassword
    && browser === BrowserNames.SAFARI
    && css`
      font: caption;
      font-size: 20px;
      letter-spacing: -1px;
      color: #4a4949;
    `}

  ${({ isPassword, browser }) => isPassword
    && browser === BrowserNames.FIREFOX
    && css`
      font-family: 'fontello';
      font-style: normal;
      font-weight: normal;
      font-size: xx-large;
      letter-spacing: 0.2px;
      // line-height: 4px;

      color: #4a4949;
      speak: none;

      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;

      /* Font smoothing. That was taken from TWBS */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `}

  ${({ max }) => max === 1
    && css`
      display: flex;
      justify-content: center;
    `}

  &::-webkit-input-placeholder {
    color: #c4c4c4;
    font-size: 14px;
    font-weight: 400;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus {
    outline: none;
    border: linear-gradient(0deg, #0066ff 0%, #3888ff 78.65%, #0066ff 100%);
  }

  &::placeholder {
    height: 24px;
    color: #c4c4c4;
    overflow: visible;
  }

  ${({ isError }) => isError
    && css`
      border: 1px solid #ff0505;
    `};
`;

const RequireError = styled.p`
  position: absolute;
  bottom: -23px;
  left: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #ff0505;
`;

export default Input;
