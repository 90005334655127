import { createActionCreators } from 'immer-reducer';

import { AsyncAction } from '@/store/actions/common';
import { SupportReducer } from '@/store/reducers/support';
import { RequestOptions, RequestState } from '@/store/reducers/common';

import { ITableBody } from '@/interfaces/api';
import { ITicket, ITicketCreate, ITicketUpdate } from '@/api/main-protected';
import { ToastIcon } from '@/interfaces/toast';
import { createMessageToast } from '@/utils/toast';
import { toastActions } from './toast';

export const supportActions = createActionCreators(SupportReducer);

export type SupportActionsType =
    | ReturnType<typeof supportActions.setTickets>
    | ReturnType<typeof supportActions.setTicketsState>
    | ReturnType<typeof supportActions.setTicket>
    | ReturnType<typeof supportActions.setTicketState>
    | ReturnType<typeof supportActions.setTicketsFilters>
    | ReturnType<typeof supportActions.setTicketsFiltersState>
    | ReturnType<typeof supportActions.setTicketsTotal>
    | ReturnType<typeof supportActions.setTicketsTotalState>
    | ReturnType<typeof supportActions.setTicketsPaginationState>;

export const getTickets = (
  body: ITableBody,
  option?: RequestOptions,
): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    if (!option?.silent) {
      dispatch(supportActions.setTicketsState(RequestState.LOADING));
    }

    dispatch(supportActions.setTicketsPaginationState(RequestState.LOADING));

    const { results } = await mainProtectedApi.getTickets();

    dispatch(supportActions.setTickets(results));

    if (!option?.silent) {
      dispatch(supportActions.setTicketsState(RequestState.LOADED));
    }
    dispatch(supportActions.setTicketsPaginationState(RequestState.LOADED));
  } catch (e) {
    if (!option?.silent) {
      dispatch(supportActions.setTicketsState(RequestState.ERROR));
    }
    dispatch(supportActions.setTicketsPaginationState(RequestState.ERROR));
  }
};

export const getTicket = (id: number): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(supportActions.setTicketState(RequestState.LOADING));

    const ticket = await mainProtectedApi.getTicket(id);
    // console.log('action:', ticket);
    dispatch(supportActions.setTicket(ticket));
    dispatch(supportActions.setTicketState(RequestState.LOADED));
  } catch (e: any) {
    console.error(e);
    dispatch(supportActions.setTicketState(RequestState.ERROR));
    const toast = createMessageToast({
      icon: ToastIcon.Error,
      header: e.response.data.data.message,
    });
    dispatch(toastActions.addToast(toast));
    throw new Error(e.response.data.data.message);
  }
};

export const getTicketsFilters = (): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(supportActions.setTicketsFiltersState(RequestState.LOADING));

    const filters = await mainProtectedApi.getTicketsFilters();

    dispatch(supportActions.setTicketsFilters(filters));

    dispatch(supportActions.setTicketsFiltersState(RequestState.LOADED));
  } catch (e) {
    dispatch(supportActions.setTicketsFiltersState(RequestState.ERROR));
  }
};

export const getTicketsTotal = (body: ITableBody): AsyncAction => async (
  dispatch,
  _,
  { mainProtectedApi },
) => {
  try {
    dispatch(supportActions.setTicketsTotalState(RequestState.LOADING));

    const response = await mainProtectedApi.getTicketsTotal(body);

    dispatch(supportActions.setTicketsTotal(response.total));

    dispatch(supportActions.setTicketsTotalState(RequestState.LOADED));
  } catch (e) {
    dispatch(supportActions.setTicketsTotalState(RequestState.ERROR));
  }
};

export const addTicket = (body: ITicketCreate): AsyncAction => async (
  dispatch,
  getState,
  { mainProtectedApi },
) => {
  try {
    dispatch(supportActions.setTicketState(RequestState.LOADING));

    await mainProtectedApi.addTicket(body);

    dispatch(supportActions.setTicketState(RequestState.LOADED));
  } catch (e: any) {
    console.error(e);
    dispatch(supportActions.setTicketState(RequestState.ERROR));
    const toast = createMessageToast({
      icon: ToastIcon.Error,
      header: e.response.data.data.message,
    });
    dispatch(toastActions.addToast(toast));
    throw new Error(e.response.data.data.message);
  }
};

export const updateTicket = (id: number, body: ITicketUpdate): AsyncAction => async (
  dispatch,
  getState,
  { mainProtectedApi },
) => {
  try {
    dispatch(supportActions.setTicketsState(RequestState.LOADING));

    await mainProtectedApi.updateTicket(id, body);

    dispatch(supportActions.setTicketState(RequestState.LOADED));
  } catch (e: any) {
    console.error(e);
    dispatch(supportActions.setTicketState(RequestState.ERROR));
    const toast = createMessageToast({
      icon: ToastIcon.Error,
      header: e.response.data.data.message,
    });
    dispatch(toastActions.addToast(toast));
    throw new Error(e.response.data.data.message);
  }
};
