import React from 'react';
import { useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

interface IProps {
  center: [number, number];
  zoom: number;
}

const MapNullComponent: React.FC<IProps> = ({ center, zoom }) => {
  const map = useMap();

  map.setView(center, zoom);

  return null;
};

export default MapNullComponent;
