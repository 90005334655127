import React from 'react';

interface Props {
  width?: number | string;
  height?: number | string;
  color?: string;
}

const TickIcon: React.FC<Props> = ({ width = '16', height = '16', color = '#5CC99C' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3.2002" y="4" width="10.4" height="8" fill="#FEFEFE" />
    <path
      d="M11.472 0C14.184 0 16 1.904 16 4.736V11.2728C16 14.096 14.184 16 11.472 16H4.536C1.824 16 0 14.096 0 11.2728V4.736C0 1.904 1.824 0 4.536 0H11.472ZM11.344 5.6C11.072 5.328 10.624 5.328 10.352 5.6L7.048 8.904L5.648 7.504C5.376 7.232 4.928 7.232 4.656 7.504C4.384 7.776 4.384 8.216 4.656 8.496L6.56 10.392C6.696 10.528 6.872 10.592 7.048 10.592C7.232 10.592 7.408 10.528 7.544 10.392L11.344 6.592C11.616 6.32 11.616 5.88 11.344 5.6Z"
      fill={color}
    />
  </svg>
);

export default TickIcon;
