import { createSelector, Selector } from 'reselect';

import { RequestState } from '@/store/reducers/common';
import { State } from '@/store';
import { IGlobalSearch } from '@/api/main-protected';

const selectGlobal = (state: State) => state.GlobalReducer;

export const selectGlobalSearch: Selector<State, IGlobalSearch | null> = createSelector(
  selectGlobal,
  ({ globalSearch }) => globalSearch,
);

export const selectGlobalsearchValue: Selector<State, string> = createSelector(
  selectGlobal,
  ({ globalSearchQuery }) => globalSearchQuery,
);

export const selectGlobalSearchState: Selector<State, RequestState> = createSelector(
  selectGlobal,
  ({ globalSearchState }) => globalSearchState,
);
