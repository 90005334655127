import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { PAGES } from '../Main/Main';

import Button from '@/Components/common/Signin/Btn';

interface IComponent {
  info: string;
  btnText?: string;
}

const AuthInfoComponent: React.FC<IComponent> = ({ info, btnText = 'Return to Login Screen' }) => (
  <Container>
    <InfoText>{info}</InfoText>
    <Link to={PAGES.LOGIN}>
      <Btn>
        <Button>{btnText}</Button>
      </Btn>
    </Link>
  </Container>
);

const fadein = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const Container = styled.div`
  width: 100%;
  height: auto;

  background: #ffffff;
  padding: 20px 0;
  animation-duration: 0.3s;
  animation-name: ${fadein};
  text-align: center;
`;

const InfoText = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  padding: 0 20px 40px;
`;
const Btn = styled.div`
  margin: 10px auto;

  & button {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
  }
`;

export default AuthInfoComponent;
