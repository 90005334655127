import React, { Fragment } from 'react';
import styled from 'styled-components';
import customScrollCss from '@/utils/custom-scroll-css';

import useOnClickOutside from '@/Components/hooks/useOnClickOutside';
import IconComponent from '@/Components/common/IconComponent';
import LoaderDots from '@/Components/LoaderDots';

import NotesIncidentrequestComponent from '@/Components/Requests/NotesIncidentrequestComponent';
import { IEvent, Request } from '@/api/main-protected';
import convertFromStringToDate from '@/utils/convertFromStringToDate';

import closeIcon from '@/assets/images/icons/close-black-icon.svg';

interface IAssociatedIncident {
  description: string | null;
  incident_id: number | null;
  last_modified: string | null;
  start_date: string | null;
  status: string | null;
}

interface IModal {
  isLoading: boolean
  incidentRequest: Request | null;
  event: IEvent | null;
  // events: IEvent[];
  onCloseModal: () => void;
}

const ModalEventEvents: React.FC<IModal> = ({
  isLoading,
  incidentRequest,
  event,
  // events,
  onCloseModal,
}) => {
  const editModalClickOutsideRef = useOnClickOutside(onCloseModal);

  if (!event) {
    return <Text>No data</Text>;
  }

  const associatedIncidents = event.incidents.reduce((arr, inc) => (
    inc.incident_id
      ? [...arr, inc]
      : arr
  ), [] as IAssociatedIncident[]);

  if (!event) {
    return (
      <Layout>
        No data to display
      </Layout>
    );
  }

  const events = incidentRequest?.events?.filter((ev) => ev.event_id !== event.event_id)
    || event?.events;

  const setEventTitle = () => {
    if (event?.status) {
      return event.status;
    }

    if (event?.short_desc) {
      return event.short_desc;
    }

    return '';
  };

  return (
    <Layout>
      {isLoading ? (
        <LoaderDots />
      ) : (
        <ModalWrap ref={editModalClickOutsideRef}>
          <>
            <Title>
              {setEventTitle()}
              <CloseIconBox onClick={onCloseModal}>
                <IconComponent icon={closeIcon} width={20} />
              </CloseIconBox>
            </Title>
            <Main>
              <>
                <InnerRow>
                  <Column>
                    <Label>DATE</Label>
                    <Text>{convertFromStringToDate(event.event_date)}</Text>
                  </Column>
                  {/* <Column>
                      <Label>Priority</Label>
                      <Text>{event.priority}</Text>
                    </Column> */}
                  <ColumnLongDesc>
                    <Label>long description</Label>
                    <Text>{event.long_desc || 'N/A'}</Text>
                  </ColumnLongDesc>
                  <Column>
                    <Label>Request</Label>
                    <Text isBlue>{`${event?.request?.itsm_id || incidentRequest?.itsm_id}`}</Text>
                  </Column>
                </InnerRow>

                <NoteBlock>
                  {associatedIncidents.length > 0
                    && associatedIncidents.map((incident) => (
                      <Fragment key={incident.incident_id}>
                        <AsociatedLabel>
                          {`Associated Incident - I-${incident.incident_id}`}
                        </AsociatedLabel>
                        <InnerRow>
                          <Column>
                            <Label>Start Date</Label>
                            <Text>
                              {convertFromStringToDate(
                                incident?.start_date ? incident.start_date : 'N/A',
                              )}
                            </Text>
                          </Column>
                          <Column>
                            <Label>STATUS</Label>
                            <Text>{event?.status ? event.status : 'N/A'}</Text>
                          </Column>
                          <Column>
                            <Label>Description</Label>
                            <Text>{incident?.description ? incident.description : 'N/A'}</Text>
                          </Column>
                          <Column>
                            <Label>Last modified</Label>
                            <Text>{convertFromStringToDate(event.incidents[0].last_modified)}</Text>
                          </Column>
                        </InnerRow>
                      </Fragment>
                    ))}
                </NoteBlock>
                <Row>
                  <NoteBlockWithBorder>
                    <NotesLabel>
                      <Label>NOTES</Label>
                    </NotesLabel>
                    <NotesIncidentrequestComponent incidentRequest={incidentRequest!} />
                  </NoteBlockWithBorder>
                  <EventsBlock>
                    <NotesLabel>
                      <Label>EVENTS</Label>
                    </NotesLabel>
                    {/* {event?.events && event.events.length > 0
                      && event.events.map((ev) => (
                        <Text
                          key={ev.event_key + ev.event_id}
                          isBlue
                        >
                          {`E-${ev.event_id} - ${ev.short_desc}`}
                        </Text>
                      ))} */}
                    <EventsWrap>
                      {events
                        && events.length > 0
                        && events.map((ev) => (
                          <Text key={ev.event_key + ev.event_id} isBlue>
                            {ev.short_desc}
                          </Text>
                        ))}
                    </EventsWrap>
                  </EventsBlock>
                </Row>
              </>
            </Main>
          </>
          <GradientLine />
        </ModalWrap>
      )}
    </Layout>
  );
};

const Layout = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
const ModalWrap = styled.div`
  max-width: 975px;
  width: 100%;
  max-height: calc(100vh - 160px);
  background: #ffffff;
  border-radius: 10px;
  padding: 0px 45px;
  overflow-y: auto;

  position: relative;

  ${customScrollCss}
`;

const GradientLine = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #000000;
  background: #ffffff;
  padding: 35px 40px 25px 0;

  position: sticky;
  top: 0;
  z-index: 1;
`;

const Main = styled.div`
  margin: 25px 0 0;
`;

const InnerRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  border-bottom: 1px solid #c4c4c4;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
`;

const Column = styled.div`
  &:not(:last-child) {
    margin-right: 4.5vw;
  }
`;

const ColumnLongDesc = styled.div`
  max-width: 200px;
  margin-right: 4.5vw;
`;
const Label = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding: 0 0 10px 0;
`;
const AsociatedLabel = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  margin: 30px 0 0px;
`;
const Text = styled.p<{ isBlue?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ isBlue }) => (isBlue ? '#0066FF' : '#000000')};
  margin: 7px 0;
`;

const NotesLabel = styled.div``;
const NoteBlock = styled.div``;
const NoteBlockWithBorder = styled.div`
  border-right: 1px solid #c4c4c4;
  margin-right: -1px;
  padding-right: 20px;
  flex: 1;
`;
const EventsBlock = styled.div`
  flex: 0 1 43.5%;
  min-width: 43.5%;
  height: 100%;
  // border-left: 1px solid #c4c4c4;
  padding-left: 35px;

  ${customScrollCss}
`;

const EventsWrap = styled.div`
  overflow-y: auto;
  max-height: 354px;

  ${customScrollCss}
`;

const CloseIconBox = styled.div`
  position: absolute;
  top: 35px;
  right: 0px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export default ModalEventEvents;
