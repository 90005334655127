import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { AppDispatch } from '@/App';
import ModalEventEvents from '@/Components/Requests/ModalEventEvents';
import ModalIncidentRequests from '@/Components/Requests/ModalIncidentRequest';
import ModalItem from '@/Components/Requests/ModalItem';
import ModalViewAllEvents from '@/Components/Requests/ModalViewAllEvents';

import { eventsActions } from '@/store/actions/events';
import { RequestState } from '@/store/reducers/common';
import {
  selectIncidentRequest,
  selectIncidentEvent,
  selectIncidentRequestState,
  selectModalNotes,
  selectViewAllEvents,
} from '@/store/selectors/events';
import { selectModalItem } from '@/store/selectors/items';
import { itemsActions } from '@/store/actions/items';
import { PAGES } from '@/Components/Main/Main';

const Modals: React.FC = () => {
  const incidentRequest = useSelector(selectIncidentRequest);
  const incidentEvent = useSelector(selectIncidentEvent);
  const requestState = useSelector(selectIncidentRequestState);
  const modalNotes = useSelector(selectModalNotes);
  const itemModal = useSelector(selectModalItem);
  const viewAllEvents = useSelector(selectViewAllEvents);

  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();

  const onCloseModal = () => {
    dispatch(eventsActions.setIncident(null));
    dispatch(eventsActions.setEvent(null));
    dispatch(eventsActions.setIncidentEvent(null));
    dispatch(eventsActions.setModalAsset(null));
    dispatch(eventsActions.setModalNotes(null));
    dispatch(itemsActions.setModalItem(null));
    dispatch(eventsActions.setViewAllEvents(null));
  };

  const onCloseIncidentrequestModal = () => {
    history.push(PAGES.REQUESTS);
    dispatch(eventsActions.setIncidentRequest(null));
  };

  return (
    <>
      {!!incidentEvent && (
        <ModalEventEvents
          incidentRequest={incidentRequest!}
          event={incidentEvent!}
          onCloseModal={onCloseModal}
          isLoading={requestState === RequestState.LOADING || !incidentRequest || !incidentEvent}
        />
      )}
      {!!incidentRequest?.isModal && !modalNotes && !incidentEvent && (
        <ModalIncidentRequests
          incidentRequest={incidentRequest!}
          onCloseModal={onCloseIncidentrequestModal}
          isLoading={requestState === RequestState.LOADING}
          isRequestModal
        />
      )}
      {itemModal && <ModalItem item={itemModal} onCloseModal={onCloseModal} />}
      {viewAllEvents && (
        <ModalViewAllEvents viewAllEvents={viewAllEvents} onCloseModal={onCloseModal} />
      )}
    </>
  );
};

export default Modals;
