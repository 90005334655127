/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import IconComponent from '@/Components/common/IconComponent';
import SearchResultsComponent from '@/Components/SearchBlock/SearchResults';

import searchIcon from '@/assets/images/icons/search-icon.svg';
import closeIcon from '@/assets/images/icons/close-black-icon.svg';

import { AppDispatch } from '@/App';
import { PAGES } from '@/Components/Main/Main';

import useDebounce from '@/Components/hooks/useDebounce';

import LocalStorage from '@/local-storage/LocalStorage';

import { RequestState } from '@/store/reducers/common';
import { /* selectGlobalSearch, selectGlobalSearchState, */ selectGlobalsearchValue } from '@/store/selectors/global';
import { selectRequestsSearch, selectRequestsSearchState } from '@/store/selectors/requestsSearch';
import { selectAssetsSearch, selectAssetsSearchState } from '@/store/selectors/assetsSearch';
import { selectEventsSearch, selectEventsSearchState } from '@/store/selectors/eventsSearch';
import {
  getGlobalSearch, globalActions, requestsSearchActions, assetsSearchActions, eventsSearchActions,
} from '@/store/actions/global';

const Search: React.FC = () => {
  const inputValue = useSelector(selectGlobalsearchValue);
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const { pathname } = useLocation();
  const recentSearch = LocalStorage.getRecentSearch();

  const searchedRequestsResults = useSelector(selectRequestsSearch);
  const searchedAssetsResults = useSelector(selectAssetsSearch);
  const searchedEventsResults = useSelector(selectEventsSearch);

  const searchedRequestsState = useSelector(selectRequestsSearchState);
  const searchedAssetsState = useSelector(selectAssetsSearchState);
  const searchedEventsState = useSelector(selectEventsSearchState);

  const [isSearched, setIsSearched] = React.useState(false);
  const [isAllRequestsPage, setIsAllRequestsPage] = React.useState(false);
  const [, debounceValue] = useDebounce(inputValue);

  useEffect(() => {
    globalSearch(debounceValue);
  }, [debounceValue]);

  useEffect(() => {
    if (pathname === PAGES.ALL_RESULTS) {
      setIsSearched(false);
      setIsAllRequestsPage(false);
      if ((searchedRequestsState !== RequestState.IDLE && searchedEventsState !== RequestState.IDLE && searchedAssetsState !== RequestState.IDLE)) setIsAllRequestsPage(true);
      return;
    }
    setIsAllRequestsPage(false);
  }, [pathname]);

  const globalSearch = (val: string) => {
    if (!val) return;
    dispatch(getGlobalSearch(val));
  };

  const onClose = () => {
    if (inputValue) {
      LocalStorage.setRecentSearch(inputValue);
    }
    setIsSearched(false);
    dispatch(globalActions.setGlobalSearchQuery(''));
    dispatch(requestsSearchActions.setRequestsSearch({ requests: [] }));
    dispatch(assetsSearchActions.setAssetsSearch({ assets: [] }));
    dispatch(eventsSearchActions.setEventsSearch({ events: [] }));
    dispatch(globalActions.setGlobalSearchState(RequestState.IDLE));
    dispatch(requestsSearchActions.setRequestsSearchState(RequestState.IDLE));
    dispatch(assetsSearchActions.setAssetsSearchState(RequestState.IDLE));
    dispatch(eventsSearchActions.setEventsSearchState(RequestState.IDLE));
  };

  const handleCrossBtnClick = () => {
    setIsSearched(false);
    dispatch(globalActions.setGlobalSearchQuery(''));
    dispatch(requestsSearchActions.setRequestsSearch({ requests: [] }));
    dispatch(assetsSearchActions.setAssetsSearch({ assets: [] }));
    dispatch(eventsSearchActions.setEventsSearch({ events: [] }));
    dispatch(globalActions.setGlobalSearchState(RequestState.IDLE));
    dispatch(requestsSearchActions.setRequestsSearchState(RequestState.IDLE));
    dispatch(assetsSearchActions.setAssetsSearchState(RequestState.IDLE));
    dispatch(eventsSearchActions.setEventsSearchState(RequestState.IDLE));
  };

  const handleBackIconClick = () => {
    onClose();
    history.goBack();
  };

  const handleRecentSearchClick = () => {
    dispatch(globalActions.setGlobalSearchQuery(recentSearch!));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < 3) return;
    if (pathname !== PAGES.ALL_RESULTS) {
      setIsSearched(true);
    }
    dispatch(globalActions.setGlobalSearchQuery(e.target.value));
  };

  return (
    <Inner>
      <SearchInput isSearched={isSearched}>
        {isAllRequestsPage
          ? <BackIcon onClick={handleBackIconClick}>{'<'}</BackIcon>
          : <IconComponent icon={searchIcon} alt="search" />}
        <Split />
        <Input
          // value={inputValue}
          onChange={handleInputChange}
          type="text"
          placeholder="Search"
          style={{ width: '100%' }}
          isSearched={isSearched}
        />
        <Split />
        {isSearched
          && (
          <CloseIcon onClick={handleCrossBtnClick}>
            <IconComponent icon={closeIcon} alt="close" width={10} />
          </CloseIcon>
          )}
      </SearchInput>
      <SearchResultsComponent
        isSearched={isSearched}
        searchedRequestsState={searchedRequestsState}
        searchedAssetsState={searchedAssetsState}
        searchedEventsState={searchedEventsState}
        isError={searchedRequestsState === RequestState.ERROR || searchedAssetsState === RequestState.ERROR || searchedEventsState === RequestState.ERROR}
        requests={searchedRequestsResults?.requests.slice(0, 3) || []}
        events={searchedEventsResults?.events.slice(0, 3) || []}
        assets={searchedAssetsResults?.assets.slice(0, 3) || []}
        handleClose={onClose}
        handleRecentSearchClick={handleRecentSearchClick}
      />
    </Inner>
  );
};

const Inner = styled.div`
  position: relative;
`;

const SearchInput = styled.div<{ isSearched?: boolean }>`
  width: 374px;
  height: 39px;
  background: ${({ isSearched }) => (isSearched ? '#F0F0F0' : '#ffffff')};
  border: 1px solid #F0F0F0;
  border-radius: 5px;

  display: flex;
  align-items: center;
  padding: 10px 15px;
`;

const Split = styled.span`
  width: 5px;
  `;
const Input = styled.input<{ isSearched?: boolean }>`
  width: 100%;
  height: 100%;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
  margin-right: 10px;
  background: ${({ isSearched }) => (isSearched ? '#F0F0F0' : '#ffffff')};

  &::placeholder {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #868484;
  }
`;

const CloseIcon = styled.div`
  cursor: pointer;
`;

const BackIcon = styled.div`
  flex: 0 0 17px;
  width: 17px;
  font-size: 20px;
  cursor: pointer;
`;
export default Search;
