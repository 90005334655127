import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import Day from '@/Components/common/calendar/Day';

import { defaultLocale, formatWeekDay } from '@/utils/date';

interface Props {
  date: moment.Moment;
  selectedDate: null | moment.Moment;
  minFromDate?: moment.Moment;
  onClick: (date: moment.Moment) => void;
  isFromDate: boolean;
  tempFromDate: moment.Moment | null;
  tempTillDate: moment.Moment | null;
  todayDate: moment.Moment;
  isTillToday?: boolean;
  isFromToday?: boolean;
  isDisableNextMonth?: boolean;
}

const Days: React.FC<Props> = ({
  date,
  selectedDate,
  minFromDate,
  onClick,
  isFromDate,
  tempFromDate,
  tempTillDate,
  todayDate,
  isTillToday,
  isFromToday,
  isDisableNextMonth = false,
}) => {
  const labels = React.useMemo(
    () => [...Array(7)].map((_, i) => formatWeekDay(defaultLocale, i)),
    [],
  );

  const daysInMonth = React.useMemo(() => moment(date).utc().daysInMonth(), [date]);
  const firstDayOfMonth = React.useMemo(() => moment(date).utc().startOf('month'), [date]);
  const firstDayOfMonthInWeek = React.useMemo(() => firstDayOfMonth.day(), [firstDayOfMonth]);

  const previousDates = React.useMemo(
    () => [...Array(firstDayOfMonthInWeek)]
      .map((_, idx) => (
        moment(firstDayOfMonth).utc().subtract(idx + 1, 'days')
      ))
      .reverse(),
    [firstDayOfMonthInWeek, firstDayOfMonth],
  );

  const currentDates = React.useMemo(
    () => [...Array(daysInMonth)]
      .map((_, idx) => (
        moment(firstDayOfMonth).utc().add(idx, 'days')
      )),
    [daysInMonth, firstDayOfMonth],
  );

  const nextDates = React.useMemo(
    () => {
      const totalPreviousCurrentDates = daysInMonth + firstDayOfMonthInWeek;
      const totalRow = Math.ceil(totalPreviousCurrentDates / 7);
      const totalDates = totalRow * 7 - totalPreviousCurrentDates;

      return [...Array(totalDates)].map((_, idx) => (
        moment(firstDayOfMonth).utc().add(idx + daysInMonth, 'days')
      ));
    },
    [daysInMonth, firstDayOfMonthInWeek, firstDayOfMonth],
  );

  const renderDays = (dates: moment.Moment[]) => dates.map((mDate) => (
    <Day
      key={mDate.format('DD MM YYYY')}
      onClick={(date1) => onClick(date1)}
      currentDate={date}
      date={moment(mDate).utc()}
      selectedDate={selectedDate}
      minFromDate={minFromDate}
      isFromDate={isFromDate}
      tempFromDate={tempFromDate}
      tempTillDate={tempTillDate}
      todayDate={todayDate}
      isTillToday={isTillToday}
      isFromToday={isFromToday}
      isDisableNextMonth={isDisableNextMonth}
    />
  ));

  return (
    <Wrapper>
      <WeekDays>
        {labels.map((label) => (
          <WeekDay key={label}>
            <Text>{label}</Text>
          </WeekDay>
        ))}
      </WeekDays>
      <WrapDays>
        {renderDays(previousDates)}
        {renderDays(currentDates)}
        {renderDays(nextDates)}
      </WrapDays>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

const WeekDays = styled.div`
  display: flex;
  margin-top: 18px;
  margin-bottom: 12px;
`;

const WeekDay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  user-select: none;
  width: calc(100% / 7);
  height: 16px;
`;

const WrapDays = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Text = styled.p`
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  user-select: none;
`;

export default Days;
