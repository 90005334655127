/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React, { Fragment } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import styled, { css } from 'styled-components';

import { InnerChartItem } from '@/api/main-protected';

import ChartNullState from '@/Components/Items/ChartNullState';

interface IChart {
  itemsBy: InnerChartItem[]
  total: number
  startAngle?: number
  tableTitle?: string
  colors?: string[]
  isWhitePieText?: boolean
}

interface IChartOption {
  title: string
  value: number
  color: string
  number: number
  text: string
}

const DEFAULT_COLORS = ['#59CC6C', '#FF33AD', '#3DD6DF', '#7B61FF', '#FFE600', '#D9D9D9'];
const MID_PERSENTAGE = 10;
const MINIMUM_PERSENTAGE = 3;
const SHIFT_SIZE = 15;

const ChartOut: React.FC<IChart> = ({
  itemsBy,
  total,
  startAngle = 275,
  tableTitle = 'Status',
  colors,
  isWhitePieText,
}) => {
  const COLORS = colors || DEFAULT_COLORS;

  const options = itemsBy.reduce((arr, i, index: number) => {
    const chartItem = {
      title: `${((i.total / total) * 100).toFixed(1)} %`,
      value: +((i.total / total) * 100).toFixed(1),
      number: i.total,
      text: i?.name || '', // texts[index],
      color: COLORS[index],
    };

    arr.push(chartItem);
    return arr;
  }, [] as IChartOption[]);

  const getDataWithoutZeros = options.filter((op) => op.value !== 0);

  const getstartDeviation = () => {
    const other = itemsBy.find((opt) => opt.name === 'Other');
    if (other && !!other.total) {
      return startAngle + ((other.total / total) * 360);
    }

    return startAngle;
  };

  const pieOptions = [...getDataWithoutZeros].sort((a, b) => b.value - a.value);

  const labelStyle = {
    fontSize: '5px',
    fontColor: 'FFFFFA',
    fontWeight: '600',
    letterSpacing: '-0.5px',
  };

  return (
    <Wrap isNA={total === 0}>
      {total > 0 ? (
        <>
          <Pie>
            <PieChart
              animate
              animationDuration={500}
              animationEasing="ease-out"
              center={[50, 50]}
              data={pieOptions}
              lengthAngle={360}
              lineWidth={100}
              paddingAngle={0}
              radius={50 - SHIFT_SIZE}
              startAngle={-30}
              viewBoxSize={[100, 100]}
              label={({ dataEntry }) => dataEntry.title}
              labelPosition={108}
              labelStyle={labelStyle}
              // animate
              // animationDuration={500}
              // animationEasing="ease-out"
              // center={[50, 50]}
              // data={pieOptions}
              // lengthAngle={360}
              // startAngle={getstartDeviation()}
              // radius={47}
              // viewBoxSize={[100, 100]}
              // label={({
              //   x, y, dx, dy, dataEntry, dataIndex,
              // }) => (
              //   <Fragment key={dataEntry.text}>
              //     <text
              //       x={dataEntry.percentage > MID_PERSENTAGE ? x : 55}
              //       y={dataEntry.percentage > MID_PERSENTAGE
              //         ? y
              //         : dataEntry.percentage < MINIMUM_PERSENTAGE
              //           ? 50 + (getDataWithoutZeros.length - dataIndex) * 10
              //           // dataIndex * 10 // put nums in ladder
              //           : 42}
              //       dx={dataEntry.percentage > MID_PERSENTAGE ? dx : dx + dx}
              //       dy={dataEntry.percentage > MID_PERSENTAGE ? dy : dy + dy}
              //       dominantBaseline="central"
              //       textAnchor={dataEntry.percentage > MID_PERSENTAGE ? 'middle' : 'end'}
              //       style={{
              //         fontWeight: dataEntry.percentage > MID_PERSENTAGE ? '600' : '400',
              //         fontSize: dataEntry.percentage > MID_PERSENTAGE ? '8px' : '6px',
              //         fill: dataEntry.percentage > MID_PERSENTAGE && isWhitePieText ? '#ffffff' : '#000000',
              //       }}
              //     >
              //       {`${dataEntry.value}%`}
              //     </text>

              //     {dataEntry.percentage <= MID_PERSENTAGE
              //       && dataEntry.percentage >= MINIMUM_PERSENTAGE && (
              //         <text
              //           x={50}
              //           y={50}
              //           dx={dx + dx}
              //           dy={dy + dy}
              //           dominantBaseline="central"
              //           textAnchor="middle"
              //           style={{
              //             fontWeight: dataEntry.percentage > MID_PERSENTAGE ? '600' : '400',
              //             fontSize: '6px',
              //             fill: '#000000',
              //           }}
              //         >
              //           \
              //         </text>
              //     )}
              //   </Fragment>
              // )}
              // labelStyle={(index) => ({
              //   fontWeight: getDataWithoutZeros[index].value > MID_PERSENTAGE ? '600' : '400',
              //   fontSize: '8px',
              // })}

            />
          </Pie>
          <ChartBox>
            <TitleRow>
              <Title>{tableTitle}</Title>
              <Total>{`Total: ${total}`}</Total>
            </TitleRow>
            <Info>
              {getDataWithoutZeros.map((opt, i) => (
                <Row key={opt.text + i}>
                  <Text pointColor={opt.color}>{opt.text.replace(/[_-]/g, ' ')}</Text>
                  <Text>{opt.number}</Text>
                </Row>
              ))}
            </Info>
          </ChartBox>
        </>
      ) : (
        <ChartNullState title={tableTitle} noLeftMargin />
      )}
    </Wrap>
  );
};

const Wrap = styled.div<{ isNA: boolean }>`
  height: 100%;
  min-height: 240px; // to fit pie correctly
  display: flex;
  justify-content: space-between;
  gap: 45px;
  font-weight: 500;
  font-size: 8px;
  padding: 0px 20px 25px 0px;
  margin-top: ${({ isNA }) => (isNA ? 20 : 0)}px;
`;

const Pie = styled.div`
  flex: 0 0 255px;
  width: 255px;
  height: 100%;
  font-size: 12px;
  color: #000000;

  position: relative;

  & svg {
    position: absolute;
    top: 0;
    height: 240px;
    padding: 10px 0 0 20px;
  }

  & .line {
    // position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 2px;
    height: 10px;
    background: red;
    color: red;
    z-index: 10;
  }
`;
const ChartBox = styled.div`
  flex: 1 1 auto;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
`;

const Total = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

const Info = styled.div`
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
  border-top: 1px solid #c4c4c4;
  margin: 15px 0;
  padding: 10px 0;
`;

const Text = styled.p<{ pointColor?: string }>`
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
  padding-left: 22px;
  margin: 10px 0;

  min-height: 12px;
  display: flex;
  align-items: center;

  :first-child {
    text-transform: capitalize;
  }

  ${({ pointColor }) => pointColor
    && css`
      position: relative;

      &: after {
        content: '';
        position: absolute;
        top: 1px;
        left: 4px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: ${pointColor};
      }
    `}
`;

export default ChartOut;
