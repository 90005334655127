import React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import TitleBlock from '@/Components/common/ContentContainer/TitleBlock';

import { PAGES } from '@/Components/Main/Main';

interface IContentContainer {
  title: string
  updated?: string
  total?: number
  totalItems?: string
  amount?: number
  isSubContainer?: boolean
  isViewAll?: boolean
  isFiltersShow?: boolean
  isInfoBtn?: boolean
  isHelpCenter?: boolean
  isTabs?: boolean
  activeTab?: string
  tabs?: string[]
  setActiveTab?: (label: string) => void
  setIsFiltersShow?: () => void
  setIsApplyBtnClicked?: () => void
  children?: React.ReactNode
  appliedFiltersAmount?: number
  isExportCSV?: boolean
  isIntervalFilter?: boolean
}

const ContentContainer: React.FC<IContentContainer> = ({
  title,
  updated = '',
  total,
  totalItems,
  amount = 0,
  isSubContainer = false,
  isViewAll = false,
  children,
  isFiltersShow = false,
  isInfoBtn = false,
  isHelpCenter = false,
  setIsFiltersShow,
  isTabs,
  activeTab,
  tabs,
  setActiveTab,
  setIsApplyBtnClicked,
  appliedFiltersAmount = 0,
  isExportCSV = false,
  isIntervalFilter = false,
}) => {
  const { pathname } = useLocation();

  const isBottomBorderOfMainBlock = pathname === PAGES.CONTROL_CENTER
    || pathname === PAGES.USER_SETTINGS
    || pathname === PAGES.PLAYBOOKS
    || pathname === PAGES.EVENTS
    || pathname === PAGES.INCIDENTS
    || pathname === PAGES.REQUESTS
    || pathname === PAGES.ITEMS;

  return (
    <Container isBorderBottom={!isSubContainer && isBottomBorderOfMainBlock}>
      <TitleBlock
        title={title}
        updated={updated}
        total={total!}
        totalItems={totalItems!}
        isSubContainer={isSubContainer}
        amount={amount}
        isViewAll={isViewAll}
        isFiltersShow={isFiltersShow}
        isInfoBtn={isInfoBtn}
        isHelpCenter={isHelpCenter}
        onFilterIconClick={setIsFiltersShow!}
        isTabs={isTabs}
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        appliedFiltersAmount={appliedFiltersAmount}
        isExportCSV={isExportCSV}
        isIntervalFilter={isIntervalFilter}
      />
      {children}
    </Container>
  );
};

const Container = styled.section<{ isBorderBottom: boolean }>`
  padding-bottom: 6px;
  ${({ isBorderBottom }) => isBorderBottom && 'border-bottom: 1px solid rgba(196, 196, 196, 0.7)'}
`;

export default ContentContainer;
