import { ROLES } from '@/api/main';

export const getUserRoleName = (role: ROLES): string => {
  switch (role) {
    case ROLES.SUPER_ADMIN:
      return 'Administrator';
    case ROLES.MANAGER:
      return 'Manager';
    case ROLES.USER:
      return 'User';
    case ROLES.DEVELOPER:
      return 'Developer';
    case ROLES.PARTNER:
      return 'Partner';
    default:
      return role;
  }
};
