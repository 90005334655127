import React from 'react';
import styled, { css } from 'styled-components';

interface IIcon {
  icon: string
  alt?: string
  width?: number
}

const IconComponent: React.FC<IIcon> = ({ icon, alt, width }) => (
  <IconWrap width={width!}>
    <img src={icon} alt={alt} />
  </IconWrap>
);

const IconWrap = styled.div<{ width: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ width }) => width
    && css`
      width: ${width}px;
    `}

  & img {
    max-width: 100%;
  }
`;

export default IconComponent;
