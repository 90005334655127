import { createReducerFunction, ImmerReducer } from 'immer-reducer';
import { RequestState } from '@/store/reducers/common';
import { IEventsSearch } from '@/api/main-protected';

export interface InitState {
  globalSearch: IEventsSearch | null,
  globalSearchState: RequestState,
}

const initialState: InitState = {
  globalSearch: null,
  globalSearchState: RequestState.IDLE,
};

export class EventsSearchReducer extends ImmerReducer<InitState> {
  setEventsSearch(globalSearch: IEventsSearch | null) {
    this.draftState.globalSearch = globalSearch;
  }

  setEventsSearchState(globalSearchState: RequestState) {
    this.draftState.globalSearchState = globalSearchState;
  }
}

export default createReducerFunction(EventsSearchReducer, initialState);
