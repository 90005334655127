/* eslint-disable no-console */
import { createActionCreators } from 'immer-reducer';

import { ToastReducer } from '@/store/reducers/toast';

export const toastActions = createActionCreators(ToastReducer);

export type ToastActions =
  | ReturnType<typeof toastActions.addToast>
  | ReturnType<typeof toastActions.removeToast>;
