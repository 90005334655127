import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import customScrollCss from '@/utils/custom-scroll-css';

import { selectUsers, selectUsersStatic } from '@/store/selectors/user';
import { AppDispatch } from '@/App';
import { getUsers } from '@/store/actions/user';
import { IUsersStatic } from '@/api/main-protected';
import getUpdated from '@/utils/getUpdated';

import ContentContainer from '@/Components/common/ContentContainer/ContentContainer';
import UsersTableBlock from '@/Components/UserAdmin/UsersTable';
import LoaderDots from '@/Components/LoaderDots';

export const PERCENT_INFO_ITEMS = [
  { cellTitle: 'Total Users', numOf: '', name: 'total' },
  { cellTitle: 'Active Members', numOf: '', name: 'active members' },
  { cellTitle: 'New / Returning', numOf: '', name: 'new-returning' },
  { cellTitle: 'Active', numOf: '', name: 'active' },
];

const UserAdmin: React.FC = () => {
  // const [, setStaticArr] = useState<ICell[] | null>(null);
  const users = useSelector(selectUsers);
  const usersStatic = useSelector(selectUsersStatic);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!users || !usersStatic) {
      dispatch(getUsers());
    }
  }, []);

  useEffect(() => {
    if (!usersStatic || !users) return;

    const usersStaticInfo = PERCENT_INFO_ITEMS.map((st) => ({
      ...st,
      numOf:
        st.name === 'active'
          ? `${((usersStatic[st.name as keyof IUsersStatic] / usersStatic.total) * 100).toFixed(
            2,
          )}%`
          : st.name === 'new-returning'
            ? usersStatic[st.name as keyof IUsersStatic]
            && `${usersStatic[st.name as keyof IUsersStatic]}%`
            : st.name === 'active members'
              ? `${usersStatic.active}`
              : usersStatic[st.name as keyof IUsersStatic],
    }));

    // setStaticArr(usersStaticInfo);
  }, [usersStatic, users]);

  return (
    <>
      <ContentContainer title="User Admin" updated={getUpdated(window.Date())}>
        {/* {staticArr ? <CellsComponent cells={staticArr} /> : <LoaderDots />} */}
      </ContentContainer>
      <Container>{users ? <UsersTableBlock users={users} /> : <LoaderDots />}</Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  border: 1px solid #e5e5e5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  ${customScrollCss}

  @media(max-width: 1445px) {
    overflow-x: auto;
    border: none;
    box-shadow: none;
    border-radius: 4px;
  }
`;

export default UserAdmin;
