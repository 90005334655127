import moment, { Moment } from 'moment';

export const defaultLocale = 'en-us';

export const dateToISO = (date: Moment | null) => {
  if (!date) {
    return date;
  }

  return date.utc().format('YYYY-MM-DD');
};

export const isToday = (timestamp: number | string, formatter = 'YYYY-MM-DD'): boolean => {
  const today = moment(new Date().setHours(0, 0, 0, 0)).utc();
  const compare: Moment = typeof timestamp === 'number'
    ? moment.unix(timestamp).utc()
    : moment(timestamp, formatter).utc();

  return today.diff(compare, 'days') === 0;
};

export const startOf = (unit: moment.unitOfTime.StartOf = 'day') => (
  moment().utc().startOf(unit)
);

export const isSame = (date1: Date | null, date2: Date | null) => moment(date1).utc().isSame(date2, 'day');

export const isSameDayWithToday = (date: Moment) => isSame(
  date.utc().toDate(),
  moment.utc().toDate(),
);

export const formatWeekDay = (lan: string, weekDay: number): string => (
  moment().locale(lan.toLowerCase()).day(weekDay).format('dd')
);

export const lastMonthDays = (): number => {
  const today = moment();
  const sameDayInLastMonth = moment().subtract(1, 'months');
  const diffDays = today.diff(sameDayInLastMonth, 'day');
  return diffDays;
};
